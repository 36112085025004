import "./Settings.css";
import { useCallback, useRef, useState } from "react";
import { Button, message, Progress, Modal, Slider } from "antd";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { getInitials } from "../../utils";
import { useSelector } from "react-redux";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Cropper from "react-easy-crop";

export default function SettingsAvatar({ url, onUpload, name }) {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const uid = useSelector((state) => state.app.uid);

  const [localImage, setLocalImage] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const closeModal = () => {
    setLocalImage(null);
  };

  async function uploadToFirebase(file) {
    try {
      setUploading(true);

      const storage = getStorage();

      const metadata = {
        contentType: file.type,
      };

      const storageRef = ref(storage, "images/" + uid + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);

          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              break;
            case "storage/canceled":
              break;
            case "storage/unknown":
              break;
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            onUpload(downloadURL);
            setUploading(false);
            setUploadProgress(0);
            closeModal();
          });
        }
      );
    } catch (error) {
      closeModal();
      alert(error.message);
    }
  }

  function processFile(event) {
    setLocalImage(null);
    if (event.target.files.length == 1) {
      // We got a file!
      var file = event.target.files[0];

      var newUrl = URL.createObjectURL(file);

      setLocalImage(newUrl);
    }
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        localImage,
        croppedAreaPixels,
        0
      );
      uploadToFirebase(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [localImage, uploadToFirebase, croppedAreaPixels]);

  return (
    <div className="settings-item" style={{ rowGap: "14px" }}>
      {url ? (
        <img src={url} alt="Avatar" className="user-avatar" />
      ) : (
        <div className="user-avatar"> {getInitials(name)}</div>
      )}

      <Modal
        open={localImage != null}
        onCancel={closeModal}
        width={400}
        title={null}
        footer={null}
        closable={false}
        maskClosable={false}
        zIndex={1003}
      >
        <div className="image-cropper">
          <div className="image-cropper-header">Crop Image</div>
          <div className="image-cropper-container">
            <Cropper
              cropShape="round"
              showGrid={false}
              image={localImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>

          <Slider value={zoom} min={1} max={3} step={0.1} onChange={setZoom} />

          {!uploading && (
            <div className="image-cropper-buttons">
              <div className="button" onClick={closeModal}>
                Cancel
              </div>
              <div className="button primary" onClick={showCroppedImage}>
                Continue
              </div>
            </div>
          )}

          {uploading && (
            <Progress
              style={{ marginTop: "10px" }}
              /* Format percent to be whole number */
              percent={Math.round(uploadProgress)}
              status="active"
            />
          )}
        </div>
      </Modal>

      <div className="same-line">
        <label className="button" htmlFor="single">
          {uploading ? "Uploading ..." : "Upload photo"}
          <input
            style={{
              visibility: "hidden",
              position: "absolute",
              width: "10px",
            }}
            type="file"
            id="single"
            accept=".jpg,.png"
            onChange={(event) => {
              processFile(event);
            }}
            disabled={uploading}
          />
        </label>

        {url && (
          <div
            onClick={() => {
              onUpload(null);
            }}
            className="button"
          >
            Remove
          </div>
        )}
      </div>
    </div>
  );
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/png");
  });
}

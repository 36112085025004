import React from "react";
import { useSelector } from "react-redux";
import "./Column.css";

import Card from "../Card";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import Droppable from "../../DnDContainer/Containers/Droppable";
import SortableCard from "../../DnDContainer/Containers/Sortable";
import moment from "moment";

function ColumnBody({
  order,
  date,
  indexSelected,
  manuallySelectColumn,
  navigatedViaKeyboard,
  setIndexSelected,
  mini,
  disableCompletedOpacity = false,
}) {
  const { data: taskData } = useSelector((state) => state.tasks);

  const {
    label_filters: activeLabels,
    hide_complete,
    hide_timeboxed,
  } = useSelector((state) => state.app?.currentUser);

  // Function that checks if a task should be visible
  function shouldShowTask(task) {
    // If the task is complete and hide_complete is true, don't show it
    if (task.complete && hide_complete) {
      return false;
    }

    // If the task is timeboxed (start time exists and is on the same day as the column)
    // and hide_timeboxed is true, don't show it
    if (
      task.start &&
      moment(task.start?.toDate ? task.start.toDate() : task.start).format(
        "YYYY-MM-DD"
      ) === date &&
      hide_timeboxed
    ) {
      return false;
    }

    // If there are no active labels, show the task
    if (!activeLabels || activeLabels.length === 0) {
      return true;
    }

    // If there are active labels, check if task.label exists and is in activeLabels
    if (task.label) {
      return activeLabels.includes(task.label);
    } else {
      return false;
    }
  }

  return (
    <Droppable id={date} mini={mini}>
      <SortableContext
        id={date}
        items={order}
        strategy={verticalListSortingStrategy}
      >
        {order
          .filter(
            (taskId) => taskData[taskId] && shouldShowTask(taskData[taskId])
          )
          .map((taskId, index) => {
            if (taskData[taskId]) {
              return (
                <SortableCard columnId={date} id={taskId} key={taskId}>
                  <Card
                    index={index}
                    item={taskData[taskId]}
                    key={taskId + "_card"}
                    activelySelected={indexSelected === index}
                    manuallySelectColumn={manuallySelectColumn}
                    navigatedViaKeyboard={navigatedViaKeyboard}
                    columnId={date}
                    setIndexSelected={setIndexSelected}
                    disableCompletedOpacity={disableCompletedOpacity}
                  />
                </SortableCard>
              );
            } else {
              return <SortableCard columnId={date} id={taskId} key={taskId} />;
            }
          })}
      </SortableContext>
    </Droppable>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  return (
    prev.order.length === next.order.length &&
    prev.order.every((taskId, index) => taskId === next.order[index]) &&
    prev.indexSelected === next.indexSelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard &&
    prev.disableCompletedOpacity === next.disableCompletedOpacity
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(ColumnBody, areEqual);

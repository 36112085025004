import { Modal, Radio, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStaging,
  revertStagedGoogleEvent,
  updateGoogleCalendarEvent,
} from "../../redux/calendarSlice";

export default function SendUpdatesModal({}) {
  const { originalCalendarEvent, newGoogleEventData, originalGoogleEvent } =
    useSelector((state) => state.calendar.stagedGoogleEvent || {});

  const dispatch = useDispatch();

  return (
    <Modal
      open={newGoogleEventData != null}
      onClose={() => dispatch(clearStaging())}
      title={null}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <div className="send-updates-modal">
        <div className="title">
          Would you like to send update emails to existing Google Calendar
          guests?
        </div>
        <div className="description">
          If you choose to send updates, guests will receive an email with the
          new event details. If you choose not to send updates, the event will
          be updated but guests will not receive an email.
        </div>
        <div className="footer-buttons">
          <button
            onClick={() => {
              dispatch(revertStagedGoogleEvent());
            }}
            className="button"
          >
            Cancel
          </button>
          <div className="right-side">
            <button
              onClick={() => {
                dispatch(
                  updateGoogleCalendarEvent({
                    eventId: originalGoogleEvent.id,
                    newData: newGoogleEventData,
                    sendUpdates: "none",
                  })
                );
              }}
              className="button"
            >
              Update, but don't send
            </button>
            <button
              onClick={() => {
                dispatch(
                  updateGoogleCalendarEvent({
                    eventId: originalGoogleEvent.id,
                    newData: newGoogleEventData,
                    sendUpdates: "all",
                  })
                );
              }}
              className="button primary"
            >
              Send update
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

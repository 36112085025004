import { Drawer } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BiCheckCircle, BiTime } from "react-icons/bi";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useSelector } from "react-redux";
import {
  generateCompletedTasksPerDayPerLabel,
  getAverageEstimateAndActualTimesThisPeriodAndLast,
  getNumberOfTasksCompletedThisPeriodAndLast,
  transformDataForActualVsEstimated,
} from "../AnalyticsFunctions";
import TaskTable from "../TaskTable";
import { analytics } from "../../../utils";

export default function ActualvsEstimatedCard({ taskData, dateRange }) {
  const { data: labels } = useSelector((state) => state.labels);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [averageDifferenceThisPeriodText, setAverageDifferenceThisPeriodText] =
    useState("");

  const [underlyingTasks, setUnderlyingTasks] = useState([]);

  useEffect(() => {
    const {
      averageEstimatedTime,
      averageActualTime,
      estimatedActualDifference,
      averageDifferenceThisPeriodText,
      tasks,
    } = getAverageEstimateAndActualTimesThisPeriodAndLast(
      taskData,
      dateRange.dates,
      dateRange.lastPeriodDates
    );

    setUnderlyingTasks(tasks);

    setAverageDifferenceThisPeriodText(averageDifferenceThisPeriodText);
  }, [taskData, dateRange, labels]);

  return (
    <div
      className="chart sl"
      style={{
        gridArea: "actual-vs-estimated-small",
      }}
    >
      <Drawer
        title="Tasks"
        placement="right"
        closable={true}
        onClose={() => {
          setSelectedTasks(null);
        }}
        open={selectedTasks !== null}
        width={700}
        zIndex={10}
      >
        <TaskTable tasks={selectedTasks} labels={labels} showTimes={true} />
      </Drawer>
      <div
        onClick={() => {
          analytics("Analytics drilldown", {
            type: "actual-vs-estimated-card",
          });
          setSelectedTasks(underlyingTasks);
        }}
        className="title"
      >
        <BiTime className="title-icon" /> <span>Actual vs Estimated</span>
      </div>
      <div className="single-title">{averageDifferenceThisPeriodText}</div>
    </div>
  );
}

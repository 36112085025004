import { PauseIcon, PlayIcon, StopIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdMinimize } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTimerAccumulatedTime,
  setCardModalActive,
  updateCurrentUser,
} from "../../redux/appSlice";
import { addTasks, updateTask } from "../../redux/tasksSlice";
import "./Timer.css";

import { useSpring, animated, useSpringRef, useChain } from "react-spring";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import FloatingTimer from "../Generics/FloatingTimer";

export default function Timer({}) {
  const { active_timer = null, floating_timer_enabled = false } = useSelector(
    (state) => state.app.currentUser
  );

  const task = useSelector((state) => state.tasks?.data[active_timer?.task_id]);

  // Let's useChain to animate the timer in and out

  if (!active_timer || !task) return null;

  return (
    <div
      className={`${
        isDesktopApp() && floating_timer_enabled
          ? ""
          : "absolute right-[50px] bottom-[20px] z-50 rounded-xl overflow-hidden"
      }`}
    >
      <FloatingTimer />
    </div>
  );
}

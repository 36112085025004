import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import _ from "lodash";

import { db } from "../firebase";
import { doc, setDoc, deleteDoc, writeBatch } from "firebase/firestore";
import { analytics } from "../utils";

const initialState = {
  data: {},
  loading: false,
};

export const updateLabel = createAsyncThunk(
  "labels/updateLabel",
  async ({ labelId, newData, previousData }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    try {
      analytics("Label updated", {
        title: newData.name,
        color: newData.color,
      });

      await setDoc(doc(db, "users", userId, "labels", labelId), newData, {
        merge: true,
      });

      return { labelId, newData, previousData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createLabel = createAsyncThunk(
  "labels/createLabel",
  async (newLabel, { getState, rejectWithValue, dispatch }) => {
    const userId = getState().app.uid;

    analytics("Label created", {
      title: newLabel.name,
      color: newLabel.color,
    });

    try {
      await setDoc(
        doc(db, "users", userId, "labels", newLabel.id),
        {
          ...newLabel,
          created_at: new Date(),
        },
        {
          merge: true,
        }
      );

      return newLabel;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Bulk create labels
export const bulkCreateLabels = createAsyncThunk(
  "labels/bulkCreateLabels",
  async (newLabels, { getState, rejectWithValue, dispatch }) => {
    const userId = getState().app.uid;

    analytics("Labels bulk created");

    try {
      const batch = writeBatch(db);

      newLabels.forEach((newLabel) => {
        const docRef = doc(db, "users", userId, "labels", newLabel.id);

        batch.set(
          docRef,
          {
            ...newLabel,
            created_at: new Date(),
          },
          {
            merge: true,
          }
        );
      });

      await batch.commit();

      dispatch(addLabels({ labels: [...newLabels] }));

      return newLabels;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLabel = createAsyncThunk(
  "labels/deleteLabel",
  async ({ labelId, currentLabel }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    analytics("Label deleted");

    try {
      await deleteDoc(doc(db, "users", userId, "labels", labelId));

      return currentLabel;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    addLabels: (state, action) => {
      const { labels } = action.payload;

      state.loading = false;

      if (_.keyBy(labels, "id")) {
        state.data = { ...state.data, ..._.keyBy(labels, "id") };
      }
    },
    removeLabel: (state, action) => {
      const { labelId } = action.payload;

      // Delete the task from the data
      delete state.data[labelId];
    },
  },
  extraReducers: {
    [updateLabel.pending]: (state, action) => {
      // Let's just update the store
      const { labelId, newData } = action.meta.arg;

      state.data[labelId] = { ...state.data[labelId], ...newData };
    },
    [updateLabel.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { labelId, previousData } = action.meta.arg;

      state.data[labelId] = previousData;
    },
    [createLabel.pending]: (state, action) => {
      // Let's just update the store

      const newLabel = action.meta.arg;

      state.data[newLabel.id] = newLabel;
    },
    [createLabel.rejected]: (state, action) => {
      // Roll back the update if this failed
      const newLabel = action.meta.arg;

      delete state.data[newLabel.id];
    },
    [deleteLabel.pending]: (state, action) => {
      const { labelId } = action.meta.arg;

      delete state.data[labelId];
    },
    [deleteLabel.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { labelId, currentLabel } = action.meta.arg;

      state.data[labelId] = currentLabel;
    },
  },
});

export const { addLabels, removeLabel } = labelsSlice.actions;

export default labelsSlice.reducer;

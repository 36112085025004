import {
  updateRecurringTask,
  bulkDeleteTasks,
  bulkUpdateTasks,
  convertGhostTaskToTask,
} from "./tasksSlice";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { db } from "../firebase";
import {
  doc,
  setDoc,
  query,
  where,
  collection,
  getDocs,
  writeBatch,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

import {
  analytics,
  generateTemplateFromTask,
  recurringTasksServerUrl,
  updateTaskToMatchTemplate,
} from "../utils";
import axios from "axios";
import { setToastVisible } from "./appSlice";
import { toast } from "sonner";

export const stopRecurringTask = createAsyncThunk(
  "tasks/stopRecurringTask",
  async (
    { recurringTask, taskToStop },
    { dispatch, getState, rejectWithValue }
  ) => {
    let promise;

    // Let's materialize this task if it is a ghost task
    if (!recurringTask.branched_tasks.includes(taskToStop.id)) {
      var taskTopStopClone = _.cloneDeep(taskToStop);
   
      promise = dispatch(
        convertGhostTaskToTask({ ghostTask: taskTopStopClone, saveOrder: true })
      );
    } else {
      promise = Promise.resolve();
    }

    return promise.then(async () => {
     
      try {
        const idToken = await getAuth().currentUser.getIdToken(true);
        const axiosPromise = axios
          .post(
            `${recurringTasksServerUrl}/stopRecurringTasksAfterSpecificTask`,
            {
              recurringTask: recurringTask,
              taskToStopAfter: taskToStop,
              forceDeleteRecurringTask: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                idToken: idToken,
              },
            }
          )
          .then((response) => {
          
            return response.data;
          })
          .catch((error) => {
            console.log(error);

            dispatch(
              setToastVisible({
                toastType: "error",
                message:
                  "Something went wrong stopping the recurring task. Please contact support",
              })
            );

            throw error;
          });

        toast.promise(axiosPromise, {
          loading: 'Stopping recurring task...',
          success: 'Recurring task stopped successfully!',
          error: 'Error stopping recurring task'
        });

        return axiosPromise;
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }
);

export const createRecurringTask = createAsyncThunk(
  "tasks/createRecurringTask",
  async (
    { recurringTask },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    analytics("Recurring task created", {
      recurring_task_id: recurringTask.id,
      source: "web"
    });


    try {
      const userId = getState().app.uid;
      await setDoc(
        doc(db, "users", userId, "recurring_tasks", recurringTask.id),
        {
          ...recurringTask,
          // set created_at
          created_at: new Date(),
        },
        {
          merge: true,
        }
      );

      return fulfillWithValue({
        recurringTask,
        dates: getState().app.dates,
      });
    } catch (error) {
      console.log("error", error);
      rejectWithValue(error);
    }
  }
);

export const deleteAllRecurringInstances = createAsyncThunk(
  "tasks/deleteAllRecurringInstances",
  async ({ recurringTask }, { dispatch, getState, rejectWithValue }) => {
    try {
      const idToken = await getAuth().currentUser.getIdToken(true);

      const axiosPromise = axios
        .post(
          `${recurringTasksServerUrl}/deleteRecurringTaskAndAllInstances`,
          {
            recurringTaskId: recurringTask.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              idToken: idToken,
            },
          }
        )
        .then((response) => {
        
          return response.data;
        })
        .catch((error) => {
          console.log(error);

          throw error;
        });

      toast.promise(axiosPromise, {
        loading: 'Deleting recurring instances...',
        success: 'Recurring instances deleted successfully!',
        error: 'Something went wrong stopping the recurring task. Please contact support'
      });

      return axiosPromise;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateIncompleteInstances = createAsyncThunk(
  "tasks/updateIncompleteInstances",
  async (
    { recurringTask, taskToMatch, includeTask = false },
    { dispatch, getState, rejectWithValue }
  ) => {
    // Let's all incomplete instances of the recurring task
    try {
      const idToken = await getAuth().currentUser.getIdToken(true);

      const axiosPromise = axios
        .post(
          `${recurringTasksServerUrl}/updateRecurringTaskAndBranchedTasks`,
          {
            recurringTask: recurringTask,
            taskToMatch: taskToMatch,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              idToken: idToken,
            },
          }
        )
        .then((response) => {
        
          return response.data;
        })
        .catch((error) => {
          console.log(error);

          throw error;
        });

      toast.promise(axiosPromise, {
        loading: 'Updating recurring instances...',
        success: 'Recurring instances updated!',
        error: 'Something went wrong updating the recurring tasks. Please contact support'
      });

      return axiosPromise;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

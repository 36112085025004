import { Modal, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";

// ...

import {
  BsCalendar,
  BsPencil,
  BsPeople,
  BsPersonBadge,
  BsTrash,
  BsX,
  BsXLg,
} from "react-icons/bs";
import {
  TbCalendar,
  TbCalendarEvent,
  TbClock,
  TbMapPin,
  TbNote,
} from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { setEventModalActive, setToastVisible } from "../../redux/appSlice";

import Linkify from "linkify-react";

import "./EventModal.css";
import { MdNotes, MdOutlineClose } from "react-icons/md";
import {
  BiCheck,
  BiPencil,
  BiQuestionMark,
  BiTrash,
  BiX,
} from "react-icons/bi";
import axios from "axios";
import { googleServerUrl } from "../../utils";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import {
  deleteAppleCalendarEvent,
  deleteCalenderEvent,
  deleteOutlookCalendarEvent,
  setCalendarEventEditModalActiveFor,
} from "../../redux/calendarSlice";
import { HiOutlineExternalLink } from "react-icons/hi";

export function EventModal({
  googleEventId,
  setEventPopupActive,
  appleEventId,
  outlookEventId,
}) {
  const dispatch = useDispatch();

  const options = { defaultProtocol: "https", target: "_blank" };
  const userId = useSelector((state) => state.app.uid);

  // const { eventModalActiveFor } = useSelector((state) => state.app);

  const [eventEditable, setEventEditable] = useState(null);

  const [conferenceData, setConferenceData] = useState(null);

  const googleEvent = useSelector(
    (state) => state.calendar.googleEventData?.[googleEventId]
  );

  const appleEvent = useSelector(
    (state) => state.calendar.appleEvents[appleEventId]
  );

  const outlookEvent = useSelector(
    (state) => state.calendar.outlookEvents?.[outlookEventId]
  );

  const { time_format = "12_hour" } = useSelector(
    (state) => state.app.currentUser
  );
  const timeFormat = time_format === "24_hour" ? "HH:mm" : "h:mma";

  useEffect(() => {
    if (appleEvent) {
      setConferenceData(
        processConferenceData({
          conferenceData: null,
          location: appleEvent.location,
          notes: null,
        })
      );
      setEventEditable(appleEvent);
    }
  }, [appleEvent]);

  useEffect(() => {
    if (googleEvent) {
      setConferenceData(
        processConferenceData({
          conferenceData: googleEvent.conferenceData,
          location: googleEvent.location,
          notes: googleEvent.description,
        })
      );
      setEventEditable(googleEvent);
    }
  }, [googleEvent]);

  useEffect(() => {
    if (outlookEvent) {
      setConferenceData(
        processConferenceData({
          conferenceData: null,
          location: outlookEvent?.extendedProps?.data?.location,
          notes: outlookEvent?.extendedProps?.data?.notes,
        })
      );
      setEventEditable(outlookEvent);
    }
  }, [outlookEvent]);

  useEffect(() => {
    if (outlookEvent) {
      setEventEditable(outlookEvent);
    }
  }, [outlookEvent]);

  function randomColor() {
    return `hsl(${Math.floor(Math.random() * 360)}, 100%, 50%)`;
  }

  function zoomToDeepLink(url) {
    const meetingIdMatch = url.match(/us\d{2}web\.zoom\.us\/j\/(\d+)/);
    const pwdMatch = url.match(/pwd=([a-zA-Z0-9]+)/);

    if (meetingIdMatch && pwdMatch) {
      const meetingId = meetingIdMatch[1];
      const pwd = pwdMatch[1];
      return `zoomus://zoom.us/join?confno=${meetingId}&pwd=${pwd}`;
    }

    return url; // Return the original link if it's not in the expected format
  }

  function processConferenceData({ conferenceData, location, notes }) {
    if (!conferenceData && !location) {
      return null;
    }

    var processedConferenceData = {};

    if (conferenceData) {
      const {
        conferenceId,
        conferenceSolution,
        createRequest,
        entryPoints,
        signature,
        conferenceDataVersion,
      } = conferenceData;

      if (conferenceSolution.name?.toLowerCase() === "zoom meeting") {
        processedConferenceData.link = zoomToDeepLink(entryPoints[0].uri);
        processedConferenceData.summary = "Join Zoom meeting";
        processedConferenceData.icon = require("../../images/zoom-icon.png");
        processedConferenceData.type = "Zoom";

        return processedConferenceData;
      }

      if (conferenceSolution.name === "Google Meet") {
        processedConferenceData.link = entryPoints[0].uri;
        processedConferenceData.summary = "Join Google Meet";
        processedConferenceData.icon = conferenceSolution.iconUri;
        processedConferenceData.type = "Google Meet";

        return processedConferenceData;
      }
    }

    if (location && location.toLowerCase().includes("zoom")) {
      processedConferenceData.link = zoomToDeepLink(location);
      processedConferenceData.summary = "Join Zoom meeting";
      processedConferenceData.icon = require("../../images/zoom-icon.png");
      processedConferenceData.type = "Zoom";

      return processedConferenceData;
    }

    if (notes && notes.toLowerCase().includes("zoom")) {
      processedConferenceData.link = zoomToDeepLink(notes);
      processedConferenceData.summary = "Join Zoom meeting";
      processedConferenceData.icon = require("../../images/zoom-icon.png");
      processedConferenceData.type = "Zoom";

      return processedConferenceData;
    }

    return null;
  }

  function randomLinearGradientBasedOnLetter(letter) {
    // Generate a list of beautiful primary colors
    const colors = [
      "hsl(0, 100%, 50%)",
      "hsl(40, 100%, 50%)",
      "hsl(80, 100%, 50%)",
      "hsl(120, 100%, 50%)",
      "hsl(160, 100%, 50%)",
      "hsl(200, 100%, 50%)",
      "hsl(240, 100%, 50%)",
      "hsl(280, 100%, 50%)",
      "hsl(320, 100%, 50%)",
    ];

    // Get the first letter of the string
    const firstLetter = letter[0].toUpperCase();

    // Get the index of the letter in the alphabet
    const letterIndex = firstLetter.charCodeAt(0) - 65;

    // Get the hue of the color
    const hue = colors[letterIndex];

    // Get the hue of the next color
    const nextHue = colors[letterIndex + 1] || colors[0];

    // Return the linear gradient
    return `linear-gradient(135deg, ${hue} 0%, ${nextHue} 100%)`;
  }

  function handleEventResponse(response) {
    axios
      .post(
        `${googleServerUrl}/updateCalendarEvent`,
        {
          attendees: [
            ...eventEditable.attendees.map((attendee) => {
              if (attendee.self) {
                return {
                  ...attendee,
                  responseStatus: response,
                };
              }

              return attendee;
            }),
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            eventId: googleEvent.id,
            calendarId: googleEvent.calendarId,
            sendUpdates: "none",
          },
        }
      )
      .then((res) => {
        setEventEditable({
          ...eventEditable,
          attendees: [
            ...eventEditable.attendees.map((attendee) => {
              if (attendee.self) {
                return {
                  ...attendee,
                  responseStatus: response,
                };
              }

              return attendee;
            }),
          ],
        });
      })
      .catch((err) => {
        dispatch(
          setToastVisible({
            toastType: "error",
            message:
              "Something went wrong updating the event. If this keeps happening, please contact support.",
          })
        );
      });
  }

  function getAttendanceStatus() {
    const user = eventEditable?.attendees.find((attendee) => attendee.self);

    if (!user) {
      return "not-responded";
    }

    return user.responseStatus;
  }

  return (
    <div className="event-modal">
      <div className="header">
        <div className="topbar">
          {!appleEvent && (
            <HiOutlineExternalLink
              className="icon"
              onClick={() => {
                // Let's open the google calendar link
                if (googleEvent) {
                  window.open(googleEvent.htmlLink, "_blank");
                  setEventPopupActive(null);
                }

                if (outlookEvent) {
                  window.open(outlookEvent?.extendedProps?.url, "_blank");
                  setEventPopupActive(null);
                }
              }}
            />
          )}

          {!appleEvent ||
          (appleEvent && !appleEvent?.extendedProps?.recurring) ? (
            <BiTrash
              className="icon"
              onClick={() => {
                // Let's delete the event
                if (appleEvent) {
                  // Let's delete the event
                  dispatch(
                    deleteAppleCalendarEvent({
                      appleEvent: appleEvent,
                    })
                  );
                } else if (googleEvent) {
                  dispatch(
                    deleteCalenderEvent({
                      googleEvent: googleEvent,
                    })
                  );
                } else if (outlookEvent) {
                  dispatch(
                    deleteOutlookCalendarEvent({
                      outlookEvent: outlookEvent,
                    })
                  );
                }
              }}
            />
          ) : null}

          <MdOutlineClose
            className="icon"
            onClick={() => {
              setEventPopupActive(null);
            }}
          />
        </div>
        <div className="title">
          {appleEvent
            ? appleEvent?.title
            : eventEditable?.summary
            ? eventEditable?.summary
            : outlookEvent?.title}
        </div>
        {appleEvent ? (
          <div className="subtitle">
            {`${moment(appleEvent.start).format("dddd, MMMM Do")} ${moment(
              appleEvent?.start
            ).format(timeFormat)} -
  ${moment(appleEvent?.end).format(timeFormat)}`}
          </div>
        ) : (
          <>
            {googleEvent && eventEditable?.start?.date ? (
              <div className="subtitle">
                {`${moment(
                  eventEditable?.start?.date || eventEditable?.start
                ).format("dddd, MMMM Do")} (all day)`}
              </div>
            ) : (
              <div className="subtitle">
                {`${moment(
                  eventEditable?.start?.dateTime || eventEditable?.start
                ).format("dddd, MMMM Do")} ${moment(
                  eventEditable?.start?.dateTime || eventEditable?.start
                ).format(timeFormat)} -
${moment(eventEditable?.end?.dateTime || eventEditable?.end).format(
  timeFormat
)}`}
              </div>
            )}
          </>
        )}
      </div>
      <div className="meta">
        <div className="properties">
          {conferenceData && (
            <div className="property">
              <div className="title ph-mask">
                {conferenceData && (
                  <img
                    src={conferenceData.icon}
                    className="icon"
                    alt="Zoom Logo"
                  />
                )}
              </div>
              <div
                onClick={() => {
                  // Open in a new window
                  window.open(conferenceData.link, "_blank");
                }}
                className={`value ${
                  conferenceData.type === "Zoom" ? "zoom" : "google-meet"
                }`}
              >
                {conferenceData.summary}
              </div>
            </div>
          )}

          {eventEditable?.location && (
            <div className="property">
              <div className="title">
                <TbMapPin className="icon" />
              </div>
              <div className="value">
                <Linkify as="p" options={options}>
                  {eventEditable?.location}
                </Linkify>
              </div>
            </div>
          )}

          {eventEditable?.extendedProps?.data?.location && (
            <div className="property">
              <div className="title">
                <TbMapPin className="icon" />
              </div>
              <div className="value">
                <Linkify as="p" options={options}>
                  {eventEditable?.extendedProps?.data?.location}
                </Linkify>
              </div>
            </div>
          )}

          {eventEditable?.attendees && !outlookEvent && (
            <div className="property">
              <div className="title">
                <BsPeople className="icon" />
              </div>
              <div className="value">
                {eventEditable?.attendees?.map((attendee) => (
                  <Attendee attendee={attendee} />
                ))}
              </div>
            </div>
          )}

          {eventEditable?.description && (
            <div className="property">
              <div className="title">
                <MdNotes className="icon" />
              </div>
              <div className="value">
                <Linkify as="p" options={options}>
                  {eventEditable?.description}
                </Linkify>
              </div>
            </div>
          )}

          {eventEditable?.extendedProps?.data?.notes && (
            <div className="property">
              <div className="title">
                <MdNotes className="icon" />
              </div>
              <div className="value">
                <Linkify as="p" options={options}>
                  {DOMPurify.sanitize(
                    eventEditable?.extendedProps?.data?.notes,
                    { ALLOWED_TAGS: [] }
                  )}
                </Linkify>
              </div>
            </div>
          )}

          {!appleEvent && eventEditable?.creator && (
            <div className="property">
              <div className="title">
                <TbCalendarEvent className="icon" />
              </div>
              <div className="value">{eventEditable?.creator?.email}</div>
            </div>
          )}
        </div>
      </div>
      {!appleEvent && !outlookEvent && eventEditable?.attendees && (
        <div className="footer">
          <div className="left">Going?</div>
          <div className="right">
            <button
              onClick={() => {
                handleEventResponse("accepted");
              }}
              className={
                `event-response-button` +
                (getAttendanceStatus() === "accepted" ? " active" : "")
              }
            >
              Yes
            </button>

            <button
              onClick={() => {
                handleEventResponse("declined");
              }}
              className={
                `event-response-button` +
                (getAttendanceStatus() === "declined" ? " active" : "")
              }
            >
              No
            </button>

            <button
              onClick={() => {
                handleEventResponse("tentative");
              }}
              className={
                `event-response-button` +
                (getAttendanceStatus() === "tentative" ? " active" : "")
              }
            >
              Maybe
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Attendee({ attendee = {} }) {
  function getStatusIcon(status) {
    switch (status) {
      case "accepted":
        return <BiCheck className="icon" />;
      case "declined":
        return <BiX className="icon" />;
      case "tentative":
        return <BiQuestionMark className="icon" />;
      default:
        return <BiQuestionMark className="icon" />;
    }
  }

  function getStatusContainer(status) {
    switch (status) {
      case "accepted":
        return (
          <div className="status-container accepted">
            {getStatusIcon(status)}
          </div>
        );
      case "declined":
        return (
          <div className="status-container declined">
            {getStatusIcon(status)}
          </div>
        );
      case "tentative":
        return (
          <div className="status-container tentative">
            {getStatusIcon(status)}
          </div>
        );
      default:
        return <div className="status-container">{getStatusIcon(status)}</div>;
    }
  }

  function getStatusTooltip(status) {
    switch (status) {
      case "accepted":
        return "accepted";
      case "declined":
        return "declined";
      case "tentative":
        return "tentatively accepted";
      default:
        return "has not responded";
    }
  }

  return (
    <div className="attendee">
      <div className="avatar-container">
        <div className="avatar">{attendee?.email[0]?.toUpperCase()}</div>
        <Tooltip
          title={`
          ${
            attendee?.displayName ? attendee?.displayName : attendee?.email
          } ${getStatusTooltip(attendee?.responseStatus)}
          `}
        >
          {getStatusContainer(attendee?.responseStatus)}
        </Tooltip>
      </div>
      <div className="meta">
        <div className="email">{attendee?.email}</div>
        {attendee?.organizer && <div className="organizer">Organizer</div>}
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import "./Card.css";

import _ from "lodash";
import TextareaAutosize from "react-textarea-autosize";

import CompleteInput from "./Components/CompleteInput";
import LabelSelector from "./Components/LabelSelector";
import Subtasks from "./Components/Subtasks";
import SubtasksIcon from "./Components/Subtasks/SubtaskIcon";

import { createTask } from "../../../redux/tasksSlice";
import { createRecurringTask } from "../../../redux/recurringTasksSlice";

import { useDispatch, useSelector } from "react-redux";

import OutsideClickHandler from "react-outside-click-handler";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";

import { setToastVisible, setColumnSelected } from "../../../redux/appSlice";

import TimeSection from "./Components/TimeSection";
import { useHotkeys } from "react-hotkeys-hook";
import RecurringInput from "./Components/RecurringInput";

import { generateTemplateFromTask } from "../../../utils";
import PrioritySelector from "../../Generics/PrioritySelector";

export default function CreateCard({
  setCreateActive,
  date,
  setNavigatedViaKeyboard,
  listId,
}) {
  const { label_filters: activeLabels } = useSelector(
    (state) => state.app?.currentUser
  );

  const lists = useSelector((state) => state.tasks.lists || {});

  const dispatch = useDispatch();
  const [itemEditable, setItemEditable] = useState({
    date: date ? moment(date, "YYYY-MM-DD").toDate() : null,
    id: uuidv4(),
    complete: false,
    listId: listId ? listId : null,
  });

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);
  const [recurringTask, setRecurringTask] = useState(null);

  const [labelPickerActive, setLabelPickerActive] = useState(false);
  const [timeSectionActive, setTimeSectionActive] = useState(false);
  const [prioritySelectorVisible, setPrioritySelectorVisible] = useState(false);
  const taskInputRef = useRef(null);
  async function updateItemEditableValue(key, value, save = false) {
    const tempItemEditable = _.cloneDeep(itemEditable);

    tempItemEditable[key] = value;

    setItemEditable((prev) => {
      return { ...prev, ...tempItemEditable };
    });
  }

  const [subtasksActive, setSubtasksActive] = useState(false);

  useHotkeys(
    "cmd+l, ctrl+l",
    (e) => {
      e.preventDefault();

      if (prioritySelectorVisible) {
        setPrioritySelectorVisible(false);
      }

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [setCreateActive, labelPickerActive, prioritySelectorVisible]
  );

  useHotkeys(
    "cmd+i, ctrl+i",
    (e) => {
      e.preventDefault();

      if (timeSectionActive) {
        setTimeSectionActive(false);
        // Let's focus on input
      } else {
        setTimeSectionActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [setCreateActive, timeSectionActive]
  );

  useHotkeys(
    "s",
    (e) => {
      e.preventDefault();

      if (subtasksActive) {
        setSubtasksActive(false);
      } else {
        setSubtasksActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
    },
    [setCreateActive, subtasksActive]
  );

  useEffect(() => {
    // If the label has changed, let's create the new task
    if (itemEditable?.label != null && !isBlank(itemEditable.description)) {
      createTaskFunction(true);
    }
  }, [itemEditable?.label]);

  // If priority changes
  useEffect(() => {
    if (itemEditable?.priority != null && !isBlank(itemEditable.description)) {
      createTaskFunction(true);
    }
  }, [itemEditable?.priority]);

  // If estimated time has changed, let's create the new task
  useEffect(() => {
    if (
      itemEditable?.estimated_time != null &&
      !isBlank(itemEditable.description)
    ) {
      createTaskFunction(true);
    }
  }, [itemEditable?.estimated_time]);

  // Same this for recurring
  useEffect(() => {
    if (
      itemEditable?.recurring &&
      recurringTask &&
      !isBlank(itemEditable.description)
    ) {
      createTaskFunction(false);
    }
  }, [itemEditable?.recurring, recurringTask]);

  useHotkeys(
    "enter, cmd+enter, ctrl+enter",
    (e) => {
      e.preventDefault();

      if (!isBlank(itemEditable.description)) {
        createTaskFunction(true);
      } else {
        setCreateActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        !recurringTaskMenuOpen &&
        setCreateActive &&
        !labelPickerActive &&
        !timeSectionActive &&
        !subtasksActive
          ? true
          : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [
      setCreateActive,
      itemEditable,
      taskInputRef,
      labelPickerActive,
      timeSectionActive,
      subtasksActive,
      recurringTaskMenuOpen,
    ]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      setCreateActive(false);

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [setCreateActive, taskInputRef]
  );

  useEffect(() => {
    // If there is no label selected, but there is 1 label in activeLabels, select it
    if (!itemEditable.label && activeLabels && activeLabels.length === 1) {
      updateItemEditableValue("label", activeLabels[0], true);
    }
  }, [activeLabels?.length, itemEditable.label]);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  useEffect(() => {
    if (itemEditable.recurring && recurringTask) {
      updateItemEditableValue("recurring_id", recurringTask.id, true);
    } else {
      updateItemEditableValue("recurring_id", null, true);
    }
  }, [itemEditable.recurring, recurringTask]);

  function createTaskFunction(keepOpen) {
    // If no labels are selected for this item, and there is more than 1 label filter active,
    // then notify the user that the item was hidden

    // Also create the recurring task, if there is one
    if (recurringTask) {
      const template = generateTemplateFromTask(itemEditable);
      const recurringTaskTemp = _.cloneDeep(recurringTask);
      recurringTaskTemp.task_template = template;

      dispatch(createRecurringTask({ recurringTask: recurringTaskTemp }));

      // Let's make sure recurring_id is set
      var itemEditableClone = _.cloneDeep(itemEditable);
      itemEditableClone.recurring_id = recurringTask.id;

      dispatch(createTask(itemEditableClone));
    } else {
      // if there is a listId, let's check if there's an auto_label_id on the list
      let newTaskClone = _.cloneDeep(itemEditable);

      if (newTaskClone?.listId) {
        const list = lists[newTaskClone?.listId];
        // check if there's no label on the task, and if there's an auto_label_id on the list
        if (!newTaskClone?.label && list?.auto_label_id) {
          newTaskClone.label = list.auto_label_id;
        }
      }
      // Let's go ahead and create
      dispatch(createTask(newTaskClone));
    }

    setRecurringTask(null);

    setItemEditable({
      date: date ? moment(date, "YYYY-MM-DD").toDate() : null,
      description: "",
      estimated_time: null,
      label: null,
      id: uuidv4(),
      complete: false,
      listId: listId ? listId : null,
    });
    if (keepOpen) {
      setCreateActive(true);

      // Auto focus on input

      if (labelPickerActive) {
        setLabelPickerActive(false);
      }

      if (prioritySelectorVisible) {
        setPrioritySelectorVisible(false);
      }

      taskInputRef.current.focus();

      setNavigatedViaKeyboard(true);
      if (date) {
        dispatch(setColumnSelected(date));
      } else {
        dispatch(setColumnSelected("braindump"));
      }
    } else {
      setCreateActive(null);
    }
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isBlank(itemEditable.description)) {
          setCreateActive(null);
        } else {
          createTaskFunction(false);
        }
      }}
      disabled={
        labelPickerActive || recurringTaskMenuOpen || prioritySelectorVisible
      }
    >
      <div className="draggable-card-container">
        <div
          className={`card-container-create ${
            itemEditable.complete ? " complete" : ""
          }`}
        >
          <div className="card-header">
            <TextareaAutosize
              className="card-description editable"
              ref={taskInputRef}
              value={itemEditable.description}
              onChange={(e) => {
                updateItemEditableValue("description", e.target.value);
              }}
              autoFocus
            />

            <div
              onClick={(event) => {
                setTimeSectionActive(!timeSectionActive);
              }}
              className="card-time-estimate"
            >
              {!isNaN(itemEditable.estimated_time)
                ? moment.utc(itemEditable.estimated_time * 1000).format("H:mm")
                : "0:00"}
            </div>
          </div>
          <div className="card-footer creation">
            <div className="card-left-buttons">
              <LabelSelector
                label={itemEditable.label}
                updateLabelLocal={(newValue) => {
                  updateItemEditableValue("label", newValue, true);
                }}
                labelPickerActive={labelPickerActive}
                setLabelPickerActive={setLabelPickerActive}
                alwaysVisible={true}
              />

              <PrioritySelector
                item={itemEditable}
                modal={false}
                alwaysVisible={true}
                creation={true}
                action={(newValue) => {
                  updateItemEditableValue("priority", newValue, true);
                }}
                prioritySelectorVisible={prioritySelectorVisible}
                setPrioritySelectorVisible={setPrioritySelectorVisible}
              />

              <SubtasksIcon
                action={() => {
                  setSubtasksActive(!subtasksActive);
                }}
                item={itemEditable}
                alwaysVisible={true}
              />
              <RecurringInput
                item={itemEditable}
                recurringTaskLocal={recurringTask}
                updateRecurringLocal={(newValue) => {
                  if (newValue) {
                    // There is a recurring task, let's set the itemEditable
                    updateItemEditableValue("recurring", true, true);
                  } else {
                    // There is no recurring task, let's set the itemEditable
                    updateItemEditableValue("recurring", false, true);
                  }

                  setRecurringTask(newValue);
                }}
                creation={true}
                recurringTaskMenuOpen={recurringTaskMenuOpen}
                setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
                updateLocalTask={(newValue) => {
                  setItemEditable((prevState) => {
                    return {
                      ...prevState,
                      ...newValue,
                    };
                  });
                }}
              />
            </div>
          </div>
          <TimeSection
            item={itemEditable}
            updateTimeLocal={(key, newValue) => {
              updateItemEditableValue(key, newValue, true);
            }}
            timeSectionActive={timeSectionActive}
            setTimeSectionActive={setTimeSectionActive}
            activelySelected={true}
          />

          <Subtasks
            item={itemEditable}
            updateSubtasksLocal={(newValue) => {
              updateItemEditableValue("subtasks", newValue, true);
            }}
            subtasksActive={subtasksActive}
            setSubtasksActive={setSubtasksActive}
            activelySelected={true}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
}

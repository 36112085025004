import { useDispatch, useSelector } from "react-redux";
import { toggleSettings } from "../../redux/appSlice";
import { getInitials } from "../../utils";
import { Popover } from "antd";
import {
  HiCog6Tooth,
  HiOutlineCog6Tooth,
  HiArrowRightStartOnRectangle,
  HiOutlineCommandLine,
  HiOutlineLifebuoy,
  HiOutlineChatBubbleOvalLeft,
  HiOutlineCake,
  HiOutlineChartBar,
  HiChartBar,
  HiCake,
  HiChatBubbleOvalLeft,
  HiLifebuoy,
  HiCommandLine,
  HiSun,
  HiGift,
} from "react-icons/hi2";
import { HiOutlineCog, HiOutlineSun, HiCog } from "react-icons/hi";
import { useEffect, useState } from "react";
import KeyboardShortcuts from "./KeyboardShortcuts";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut, updateCurrentUser } from "firebase/auth";
import { IoMdSunny } from "react-icons/io";
import { IoCloudyNight, IoMoon, IoPartlySunny } from "react-icons/io5";
import { RiSettings4Fill } from "react-icons/ri";
import { MdNightlight } from "react-icons/md";

export default function SettingsMenu({}) {
  const dispatch = useDispatch();

  const { avatar_url, name, user_devices } = useSelector(
    (state) => state.app.currentUser
  );

  const [open, setOpen] = useState(false);

  const appTheme = useSelector((state) => state.app.appTheme || "light");

  const { analytics_enabled, daily_planning_enabled } = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        daily_planning_enabled: true,
      }
  );

  useEffect(() => {
    console.log("initCanny");
    window.Canny("initChangelog", {
      appID: "637d2ce47a741d0ff12b8c61",
      position: "bottom",
      align: "right",
      theme: appTheme === "dark" ? "dark" : "light",
    });
  }, [open, appTheme]);

  const [shortcutsVisible, setShortcutsVisible] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth();

  return (
    <>
      <KeyboardShortcuts
        visible={shortcutsVisible}
        setVisible={setShortcutsVisible}
      />
      <Popover
        open={open}
        onOpenChange={(open) => setOpen(open)}
        placement="bottomRight"
        trigger="click"
        content={
          <div className="dark:bg-[#212121] flex flex-col gap-0 items-start w-56 text-sm p-1 border dark:border-neutral-700 rounded-lg">
            <SettingsMenuItem
              icon={<RiSettings4Fill className="w-[15px] h-[15px]" />}
              text="Settings"
              onClick={() => {
                setOpen(false);
                dispatch(toggleSettings());
              }}
            />
            <div className="w-full h-[1.5px] dark:bg-neutral-800 bg-neutral-100 my-1" />
            {analytics_enabled && (
              <SettingsMenuItem
                onClick={() => {
                  // Go to /analytics
                  navigate("/analytics");
                }}
                icon={<HiChartBar className="w-[14px] h-[14px]" />}
                text="Analytics"
              />
            )}
            {daily_planning_enabled && (
              <SettingsMenuItem
                onClick={() => {
                  // Go to /daily-planning
                  navigate("/daily-planning");
                }}
                icon={<IoPartlySunny className="w-[14px] h-[14px]" />}
                text="Daily Planning"
              />
            )}
              {daily_planning_enabled && (
              <SettingsMenuItem
                onClick={() => {
                  // Go to /daily-planning
                  navigate("/daily-shutdown");
                }}
                icon={<IoMoon className="w-[14px] h-[14px]" />}
                text="Daily Shutdown"
              />
            )}
            <SettingsMenuItem
              onClick={() => {
                setOpen(false);
                setShortcutsVisible(true);
              }}
              icon={<HiCommandLine className="w-[14px] h-[14px]" />}
              text="Keyboard Shortcuts"
            />

            <div className="w-full h-[1.5px] dark:bg-neutral-800 bg-neutral-100 my-1" />
            <SettingsMenuItem
              onClick={() => {
                // mail chris@aloa.co
                window.open("mailto:chris@aloa.co");
              }}
              icon={<HiLifebuoy className="w-[14px] h-[14px]" />}
              text="Help & Support"
            />
            <a data-canny-changelog className="w-full">
              <SettingsMenuItem
                icon={<HiGift className="w-[14px] h-[14px]" />}
                text="What's new"
              />
            </a>
            <SettingsMenuItem
              onClick={() => {
                // Open   href="https://feedback.ellieplanner.com/feature-requests"
                window.open(
                  "https://feedback.ellieplanner.com/feature-requests",
                  "_blank"
                );
              }}
              icon={<HiChatBubbleOvalLeft className="w-[14px] h-[14px]" />}
              text="Give feedback"
            />
            <div className="w-full h-[1.5px] dark:bg-neutral-800 bg-neutral-100 my-1" />
            <SettingsMenuItem
              onClick={async () => {
                // First, lets check check if there is an FCM Token active
                // Get ellie_fcm_token from localstorage if it exists
                const fcmToken = await localStorage.getItem("ellie_fcm_token");

                // If there is an FCM token and its in the user_devices
                if (fcmToken && user_devices?.[fcmToken]?.enabled) {
                  try {
                    await dispatch(
                      updateCurrentUser({
                        newValues: {
                          user_devices: {
                            [fcmToken]: {
                              enabled: false,
                              last_updated: new Date(),
                            },
                          },
                        },
                        previousValues: {
                          user_devices: user_devices,
                        },
                      })
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }

                signOut(auth)
                  .then(() => {
                    // Sign-out successful.
                    // clear FCM token if its there
                    localStorage.removeItem("ellie_fcm_token");
                  })
                  .catch((error) => {
                    // An error happened.
                  });
              }}
              icon={
                <HiArrowRightStartOnRectangle className="w-[14px] h-[14px]" />
              }
              text="Log Out"
            />
          </div>
        }
      >
        {avatar_url && <img src={avatar_url} className="avatar-button" />}
        {!avatar_url && (
          <div className="avatar-button">{getInitials(name)}</div>
        )}
      </Popover>
    </>
  );
}

function SettingsMenuItem({ icon, text, onClick }) {
  return (
    <div
      onClick={onClick}
      className="flex flex-row gap-2 items-center px-2.5 py-1.5 dark:text-neutral-400 text-neutral-600 dark:hover:bg-neutral-700 hover:bg-neutral-200 w-full rounded-lg cursor-pointer"
    >
      <div className="">{icon}</div>
      <span className="dark:text-neutral-300 text-neutral-700 font-medium">
        {text}
      </span>
    </div>
  );
}

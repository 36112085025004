import "./CardModal.css";
import React, { useState, useEffect, useRef } from "react";
import linkify from "linkifyjs";
import { find, tokenize } from "linkifyjs";

const AutoGrowingTextAreaWithLinks = ({
  placeholder,
  value,
  setValue,
  onBlur,
}) => {
  const [caretPosition, setCaretPosition] = useState(0);
  const textAreaRef = useRef();
  const displayAreaRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.closest("a")) {
        const clickedPos = getCaretPositionFromMouseEvent(
          displayAreaRef.current,
          e
        );
        syncHeights();
        displayAreaRef.current.style.display = "none";
        textAreaRef.current.style.display = "block";
        textAreaRef.current.focus();
        textAreaRef.current.selectionStart = clickedPos;
        textAreaRef.current.selectionEnd = clickedPos;
      }
    };

    displayAreaRef.current.addEventListener("click", handleClick);

    return () => {
      if (displayAreaRef.current) {
        displayAreaRef.current.removeEventListener("click", handleClick);
      }
    };
  }, []);

  // ... (the rest of the code remains the same)

  const getCaretPositionFromMouseEvent = (el, event) => {
    const x = event.clientX;
    const y = event.clientY;

    let range, startNode, startOffset;

    if (document.caretRangeFromPoint) {
      // Chrome
      range = document.caretRangeFromPoint(x, y);
      if (range) {
        startNode = range.startContainer;
        startOffset = range.startOffset;
      }
    } else if (document.caretPositionFromPoint) {
      // Firefox
      const pos = document.caretPositionFromPoint(x, y);
      if (pos) {
        startNode = pos.offsetNode;
        startOffset = pos.offset;
      }
    }

    if (startNode && startOffset !== undefined) {
      const walker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT);
      let currentNode;
      let charCount = 0;

      while ((currentNode = walker.nextNode())) {
        if (currentNode === startNode) {
          charCount += startOffset;
          break;
        } else {
          charCount += currentNode.textContent.length;
        }
      }

      return charCount;
    }

    // Return some fallback value or throw an error here if you prefer
    return 0;
  };

  const handleBlur = () => {
    displayAreaRef.current.style.display = "block";
    textAreaRef.current.style.display = "none";

    if (onBlur) {
      onBlur();
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  const syncHeights = () => {
    const displayAreaHeight = displayAreaRef.current.offsetHeight;
    textAreaRef.current.style.height = displayAreaHeight + "px";
  };

  const handleMouseUp = () => {
    const caretPosition = textAreaRef.current.selectionStart;
    setCaretPosition(caretPosition);
  };

  const generateLinkifiedHtml = (text, isPlaceholder) => {
    const tokens = tokenize(text);
    return tokens
      .map((token) => {
        if (token.type === "nl") {
          return "<br>";
        } else if (token.isLink) {
          const href = token.toHref();
          const formattedHref = token.toString();
          return `<a href="${href}" class="auto-link" target="_blank" rel="noopener noreferrer">${formattedHref}</a>`;
        } else {
          return isPlaceholder
            ? `<span class="placeholder-text">${token.toString()}</span>`
            : token.toString();
        }
      })
      .join("");
  };

  const sharedStyles = {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    lineHeight: "1.5",
    fontSize: "14px",
    fontFamily: "inherit",
    width: "100%",
  };

  return (
    <div>
      <textarea
        ref={textAreaRef}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onMouseUp={handleMouseUp}
        placeholder={placeholder}
        style={{
          ...sharedStyles,
          display: "none",
          overflow: "hidden",
          resize: "none",
        }}
        className="card-modal-notes"
      />
      <div
        ref={displayAreaRef}
        dangerouslySetInnerHTML={{
          __html: generateLinkifiedHtml(value || placeholder, !value),
        }}
        contentEditable={false}
        className="card-modal-notes ph-mask"
        style={{ ...sharedStyles }}
      />
    </div>
  );
};

export default AutoGrowingTextAreaWithLinks;

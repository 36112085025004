import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import ShutdownToday from './ShutdownToday';
import FinalizeTomorrow from './FinalizeTomorrow';

function DailyShutdown({
    taskOrderEditable,
    activelyReorderingSidebar,
    sortedLists,
}) {
    const [step, setStep] = useState(0);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    if (isMobile) {
      return (
        <div className="p-6 text-black dark:text-white">
          😓 Daily planning is not (yet) supported on mobile. Please reach out to Chris to let him know
          you'd like to see it on mobile!
        </div>
      );
    }

    if (step === 0) {
        return (
            <ShutdownToday
            taskOrderEditable={taskOrderEditable}
            setStep={setStep}
          />
        )
    }

    if (step === 1) {
        return (
            <FinalizeTomorrow
            taskOrderEditable={taskOrderEditable}
            setStep={setStep}
            activelyReorderingSidebar={activelyReorderingSidebar}
            sortedLists={sortedLists}
            // setSkipStep2Active={setSkipStep2Active}
            // totalEstimatedTime={totalEstimatedTime}
            // work_threshold={work_threshold}
          />
        )
    }
}

export default DailyShutdown
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelDeletion, proceedDeletion } from "../../redux/tasksSlice";
import { useHotkeys } from "react-hotkeys-hook";
import { toast } from "sonner";
import { analytics } from "../../utils";

export default function UndoDeletion() {
  const dispatch = useDispatch();
  const tasksToBeDeleted = useSelector((state) => state.tasks.tasksToBeDeleted);

  const tasksToBeDeletedRef = useRef(tasksToBeDeleted);
  tasksToBeDeletedRef.current = tasksToBeDeleted;

  const toastIdsRef = useRef({});

  // Store the timeout id in a ref so that it can be accessed and cleared in button onClick handlers
  const deletionTimeoutRefs = useRef({});
  useEffect(() => {
    tasksToBeDeleted.forEach((task) => {
      if (!deletionTimeoutRefs.current[task.id]) {
        // Display the toast
        toastIdsRef.current[task.id] = toast("Task Deleted", {
          description: `${task.description}`,
          action: {
            label: "Undo",
            onClick: () => {
              analytics("Undo deletion", {
                source: "Web",
                mode: "button",
              });
              clearTimeout(deletionTimeoutRefs.current[task.id]);
              delete deletionTimeoutRefs.current[task.id];
              dispatch(cancelDeletion(task));
            },
          },
        });
        // Set the timeout for each task
        deletionTimeoutRefs.current[task.id] = setTimeout(() => {
          dispatch(proceedDeletion(task));
        }, 5000);
      }
    });
    return () => {
      Object.keys(deletionTimeoutRefs.current).forEach((id) => {
        clearTimeout(deletionTimeoutRefs.current[id]); // Clear the timeout
      });
    };
  }, [tasksToBeDeleted, dispatch]);

  useHotkeys(
    "cmd+z, ctrl+z",
    () => {
      const latestTask =
        tasksToBeDeletedRef.current[tasksToBeDeletedRef.current.length - 1];
      if (latestTask) {
        analytics("Undo deletion", {
          source: "Web",
          mode: "shortcut",
        });
        clearTimeout(deletionTimeoutRefs.current[latestTask.id]);
        delete deletionTimeoutRefs.current[latestTask.id];
        toast.dismiss(toastIdsRef.current[latestTask.id]);
        delete toastIdsRef.current[latestTask.id];
        dispatch(cancelDeletion(latestTask));
      }
    },
    {
      enabled: true,
    },
    [tasksToBeDeletedRef.current]
  );

  return <></>;
}

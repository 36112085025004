import { Dropdown, Menu, Popconfirm, Popover, Progress } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTask } from "../../../redux/tasksSlice";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import { FileIcon, defaultStyles } from "react-file-icon";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import { FaExclamationCircle } from "react-icons/fa";
import { auth } from "../../../firebase";
import { attachmentServerUrl } from "../../../utils";
import { readAndCompressImage } from "browser-image-resizer";
import { BsThreeDots } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { FiDownload, FiZoomIn, FiZoomOut } from "react-icons/fi";
import { setToastVisible } from "../../../redux/appSlice";
import { toast } from "sonner";
import Lightbox from "./Lightbox"; // Adjust the import path as needed

function AttachmentSelector({
  attachments = [],
  item,
  modal = false,
  userId = null,
}) {
  const attachmentInputRef = useRef(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [expandAttachments, setExpandAttachments] = useState(false);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [zoom, setZoom] = useState(100);

  window.Buffer = Buffer;

  const dispatch = useDispatch();

  const handleAttachmentOpen = () => {
    attachmentInputRef.current.click();
  };

  // Funciton to delete an attachment
  const confirm = (e, attachmentId) => {
    console.log(e);
    console.log(attachmentId);

    // call /deleteAttachments endpoint and send in keys
    // get defaultKey and thumbnailKey (if exists)
    // send these keys to the backend to delete

    const attachment = attachments.find(
      (attachment) => attachment.id === attachmentId
    );

    if (!attachment) {
      return;
    }

    const keys = [attachment.defaultKey];

    if (attachment.thumbnailKey) {
      keys.push(attachment.thumbnailKey);
    }

    deleteAttachments(keys);
  };

  const deleteAttachments = async (keys) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);

      const response = await fetch(
        `${attachmentServerUrl}/deleteAttachments?idToken=${idToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          body: JSON.stringify({ keys }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete attachments");
      }

      const updatedAttachments = attachments.filter(
        (attachment) => !keys.includes(attachment.defaultKey)
      );

      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: { attachments: updatedAttachments },
        })
      );
    } catch (error) {
      console.error("Failed to delete attachments", error);
    }
  };

  const uploadAttachments = async (event) => {
    try {
      const selectedAttachments = event.target.files;

      if (!selectedAttachments || !selectedAttachments.length) {
        console.log("No attachments selected");
        attachmentInputRef.current.value = null;
        return;
      }

      // File size max is 25MB
      // If there are any files over 25MB, stop this function and alert the user
      for (let i = 0; i < selectedAttachments.length; i++) {
        if (selectedAttachments[i].size > 25000000) {
          // 25MB in bytes
          toast.error(
            "One or more files are over 25MB. Please ensure all files are under 25MB."
          );
          attachmentInputRef.current.value = null;
          return;
        }
      }

      const idToken = await auth.currentUser.getIdToken(true);
      const baseKey = `${userId}/${item.id}`;
      const filesToUpload = [];
      const newAttachments = [];

      const resizeConfig = {
        quality: 0.7,
        maxWidth: 200,
        maxHeight: 200,
        autoRotate: true,
        debug: true,
      };

      for (const attachment of selectedAttachments) {
        const attachmentId = uuidv4();
        var thumbnailKey = null;

        // if the the file is an image, we want to upload a thumbnail as well
        if (attachment.type.startsWith("image/")) {
          console.log("Image file selected");

          const thumbnail = await readAndCompressImage(
            attachment,
            resizeConfig
          );

          console.log("thumbnail", thumbnail);

          thumbnailKey =
            `${baseKey}/thumbnail-${attachmentId}-${attachment.name}`.trim();

          // let's put this in the queue to be uploaded
          filesToUpload.push({
            key: thumbnailKey,
            file: thumbnail,
            type: thumbnail.type,
          });
        }

        // if the file is a video, we want to take a snapshot of the video and upload that as a thumbnail

        // we still need to process the orginal file
        const defaultKey =
          `${baseKey}/default-${attachmentId}-${attachment.name}`.trim();

        filesToUpload.push({
          key: defaultKey,
          file: attachment,
          type: attachment.type,
        });

        // let's store this in firebase
        const attachmentObj = {
          name: attachment?.name,
          created_at: new Date(),
          id: attachmentId,
          fileSize: attachment?.size / (1024 * 1024).toFixed(10),
          defaultKey: defaultKey,
          type: attachment.type,
        };

        if (thumbnailKey) {
          attachmentObj.thumbnailKey = thumbnailKey;
        }

        newAttachments.push(attachmentObj);
      }

      const fileRequests = filesToUpload.map(({ key, type }) => ({
        fileName: key,
        contentType: type,
      }));

      // now we want to send the files to the backend to get signed urls
      const response = await fetch(
        `${attachmentServerUrl}/s3UploadUrl?idToken=${idToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          body: JSON.stringify({ files: fileRequests }),
        }
      );

      if (!response.ok) {
        attachmentInputRef.current.value = null;
        throw new Error("Failed to obtain signed URLs");
      }

      const data = await response.json();
      const signedUrls = data.signedUrls;

      const uploadPromises = filesToUpload.map((fileToUpload) => {
        return new Promise((resolve, reject) => {
          const { key, file, type } = fileToUpload;
          const signedUrl = signedUrls.find(
            (url) => url.fileName === key
          ).signedUrl;

          var xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", type);

          xhr.upload.onprogress = function (e) {
            if (e.lengthComputable) {
              var percentComplete = (e.loaded / e.total) * 100;
              setUploadProgress((prev) => ({
                ...prev,
                [key]: percentComplete,
              }));
            }
          };

          xhr.onload = function () {
            if (xhr.status === 200) {
              resolve({ name: file.name, url: signedUrl, type: type });
              // Clear the upload progress for this file
              setUploadProgress((prev) => {
                const newProgress = { ...prev };
                delete newProgress[key];
                return newProgress;
              });
            } else {
              reject(new Error("Failed to upload file to S3 for " + key));
            }
          };

          xhr.onerror = function () {
            reject(new Error("Failed to upload file to S3 for " + key));
            // Clear the upload progress for this file
            setUploadProgress((prev) => {
              const newProgress = { ...prev };
              delete newProgress[key];
              return newProgress;
            });
          };
          xhr.send(file);
        });
      });

      const uploadedFiles = await Promise.all(uploadPromises);

      console.log("uploadedFiles", uploadedFiles);

      // let's store the attachments into db
      const updatedAttachments = [...attachments, ...newAttachments];

      console.log("updatedAttachments", updatedAttachments);

      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: { attachments: updatedAttachments },
        })
      );
      attachmentInputRef.current.value = null;
    } catch (error) {
      attachmentInputRef.current.value = null;
      console.error(error);
    }
  };

  // I need a function that will parse a file name and return the file extension
  const parseAttachmentExtension = (fileName) => {
    const parts = fileName.split(".");
    return parts[parts.length - 1];
  };

  const openAttachment = async (key, attachment) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);

      if (!userId || !item.id || !key || !attachment) {
        throw new Error("Missing required data");
      }

      const response = await fetch(
        `${attachmentServerUrl}/s3AccessUrl?idToken=${idToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          body: JSON.stringify({ keys: [key] }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const signedUrl = data.signedUrls[0].url;

      // Trigger the download using fetch and Blob
      const downloadResponse = await fetch(signedUrl);
      const blob = await downloadResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = attachment?.name || "file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to obtain access URL", error);
    }
  };

  const openLightbox = async (key, attachment) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await fetch(
        `${attachmentServerUrl}/s3AccessUrl?idToken=${idToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          body: JSON.stringify({ keys: [key] }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const signedUrl = data.signedUrls[0].url;

      setCurrentAttachment({ ...attachment, url: signedUrl });
      setIsLightboxOpen(true);
    } catch (error) {
      console.error("Failed to obtain access URL", error);
    }
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setCurrentAttachment(null);
    setZoom(100);
  };
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 25, 200));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 25, 50));
  };

  const loadThumbnail = async () => {
    try {
      if (!attachments || attachments.length === 0) {
        return;
      }

      const thumbnailAttachments = attachments.filter(
        (attachment) => attachment.thumbnailKey
      );

      if (!thumbnailAttachments || thumbnailAttachments.length === 0) {
        return;
      }

      const thumbnailKeys = thumbnailAttachments.map(
        (attachment) => attachment.thumbnailKey
      );

      // let's bulk send the attachments over to the backend to get the signed urls
      const idToken = await auth.currentUser.getIdToken(true);

      const response = await fetch(
        `${attachmentServerUrl}/s3AccessUrl?idToken=${idToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          body: JSON.stringify({ keys: thumbnailKeys }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to obtain signed URLs");
      }

      const data = await response.json();
      const signedUrls = data.signedUrls;

      setThumbnails(signedUrls);
    } catch (error) {
      console.error("Failed to obtain thumbnails", error);
    }
  };

  // we need to load the thumbnails on load
  useEffect(() => {
    loadThumbnail();
  }, [attachments]);

  const formatDate = (timestamp = null) => {
    if (!timestamp) return null;

    const date = timestamp?.toDate ? timestamp?.toDate() : timestamp;
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date?.toLocaleDateString("en-US", options);
  };

  const truncateMiddle = (string, length) => {
    if (string.length <= length) return string;
    const front = Math.ceil(length / 2);
    const back = Math.floor(length / 2);
    return string.slice(0, front) + "..." + string.slice(string.length - back);
  };

  return (
    <div>
      <input
        ref={attachmentInputRef}
        type="file"
        className="hidden"
        onChange={uploadAttachments}
        multiple
      />
      {attachments && attachments.length > 0 ? (
        <div className="flex flex-col gap-1">
          {attachments.map((attachment) => {
            const extension = parseAttachmentExtension(attachment.name) || "";

            const thumbnail = thumbnails.find(
              (thumbnail) => thumbnail.key === attachment.thumbnailKey
            );

            return (
              <div
                className="flex items-center w-full group hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-lg cursor-pointer px-1"
                key={attachment.id}
              >
                <div
                  className="flex flex-row gap-3 items-center w-full min-w-[20rem] py-1 px-1 "
                  onClick={() =>
                    // If this is an image, open the lightbox
                    attachment.type.startsWith("image/")
                      ? openLightbox(attachment.defaultKey, attachment)
                      : openAttachment(attachment.defaultKey, attachment, false)
                  }
                >
                  <div className="h-10 w-10">
                    {!attachment?.thumbnailKey ||
                    (attachment?.thumbnailKey && !thumbnail?.url) ? (
                      <div className="flex items-center justify-center h-10 w-10 rounded-md border-2 dark:border-none border-white shadow-lg p-1">
                        <FileIcon
                          extension={extension}
                          {...defaultStyles[extension]}
                        />
                      </div>
                    ) : (
                      <img
                        src={thumbnail?.url}
                        alt="thumbnail"
                        className="h-10 w-10 rounded-md object-cover border-4 dark:border border-white dark:border-neutral-200 shadow-lg"
                      />
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="text-black dark:text-white cursor-pointer truncate">
                      {truncateMiddle(attachment?.name, 20)}
                    </div>
                    <div className="flex flex-row items-center gap-2 text-xs text-neutral-400">
                      <div className="">
                        {attachment?.fileSize &&
                          Number(attachment.fileSize).toFixed(2)}{" "}
                        MB
                      </div>
                      {/* <div className="">
                        {attachment?.created_at &&
                          formatDate(attachment?.created_at)}
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <Popconfirm
                  title="Remove attachment?"
                  onConfirm={(e) => confirm(e, attachment.id)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  overlayInnerStyle={{ padding: "0.5rem" }}
                  // icon={<FaExclamationCircle className="mt-[5px] mr-1" />}
                >
                  <div className="text-[#494949] hover:text-red-500 cursor-pointer flex justify-start">
                    <IoMdClose />
                  </div>
                </Popconfirm> */}

                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "download",
                        label: (
                          <div
                            className="flex flex-row gap-2 items-center"
                            onClick={() =>
                              openAttachment(
                                attachment.defaultKey,
                                attachment,
                                true
                              )
                            }
                          >
                            <FiDownload />
                            <div>Download</div>
                          </div>
                        ),
                      },
                      {
                        key: "delete",
                        label: (
                          <div
                            onClick={(e) => confirm(e, attachment.id)}
                            className="flex flex-row gap-2 items-center"
                          >
                            <MdDeleteOutline />
                            <div>Delete</div>
                          </div>
                        ),
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <div className="border border-neutral-200 dark:border-neutral-800 rounded-lg p-1.5 bg-white dark:bg-neutral-700 drop-shadow-sm cursor-pointer hidden group-hover:flex">
                    <BsThreeDots className="dark:text-white" />
                  </div>
                </Dropdown>
              </div>
            );
          })}
          <Lightbox
            isOpen={isLightboxOpen}
            onClose={closeLightbox}
            currentAttachment={currentAttachment}
            zoom={zoom}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onDownload={() =>
              openAttachment(
                currentAttachment.defaultKey,
                currentAttachment,
                true
              )
            }
          />
          <div>
            {Object.keys(uploadProgress).map((key) => (
              <div key={key} className="mb-2 py-1 px-2 w-[80%]">
                <Progress percent={Math.round(uploadProgress[key])} />
              </div>
            ))}
          </div>
          <div
            className="flex flex-row gap-3 items-center w-full min-w-[20rem] py-1 px-2 hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-md cursor-pointer group"
            onClick={handleAttachmentOpen}
          >
            <div className="h-8 w-10 rounded-md border border-dashed border-neutral-300 dark:border-neutral-600 flex items-center justify-center">
              <IoMdAdd className="w-6 h-6 text-neutral-300" />
            </div>
            <div className="flex flex-col text-neutral-400">
              <div className="text-xs">Add more</div>
              <div className="text-xs">Max 25 MB</div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            {Object.keys(uploadProgress).map((key) => (
              <div key={key} className="mb-2 py-1 px-2 w-[200px]">
                <Progress percent={Math.round(uploadProgress[key])} />
              </div>
            ))}
          </div>
          <div className="mcf-button empty" onClick={handleAttachmentOpen}>
            Click to add
          </div>
        </>
      )}
    </div>
  );
}

export default AttachmentSelector;

import { Modal } from "antd";
import { useState } from "react";
import { BiCheck } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { bulkCreateLabels } from "../../redux/labelsSlice";

export default function LabelLibrary({ open, setOpen }) {
  // Groups of labels with name and a hex code color
  const labelGroups = [
    {
      name: "Students",
      labels: [
        {
          name: "Homework",
          color: "#FFB7B2",
        },
        {
          name: "Exams",
          color: "#FFDAC1",
        },
        {
          name: "Projects",
          color: "#E2F0CB",
        },
        {
          name: "Personal",
          color: "#B5EAD7",
        },
        {
          name: "Extra-Curricular",
          color: "#C7CEEA",
        },
      ],
    },
    {
      name: "Professionals",
      labels: [
        {
          name: "Meetings",
          color: "#A2D2FF",
        },
        {
          name: "Events",
          color: "#FF9AA2",
        },
        {
          name: "Projects",
          color: "#FFB500",
        },
        {
          name: "Emails",
          color: "#FFDAC1",
        },
        {
          name: "Personal",
          color: "#E2F0CB",
        },
      ],
    },
    {
      name: "Parents",
      labels: [
        {
          name: "Kids",
          color: "#B5EAD7",
        },
        {
          name: "Home",
          color: "#C7CEEA",
        },
        {
          name: "Work",
          color: "#9B5DE5",
        },
        {
          name: "Health",
          color: "#F15BB5",
        },
        {
          name: "Bills",
          color: "#FEE440",
        },
        {
          name: "Meals",
          color: "#00BBF9",
        },
        {
          name: "Appointments",
          color: "#F95738",
        },
        {
          name: "Personal",
          color: "#FFDAC1",
        },
      ],
    },
    {
      name: "Freelancers",
      labels: [
        {
          name: "Projects",
          color: "#FF9AA2",
        },
        {
          name: "Invoices",
          color: "#FFB7B2",
        },
        {
          name: "Networking",
          color: "#FFDAC1",
        },
        {
          name: "Personal Development",
          color: "#E2F0CB",
        },
        {
          name: "Client Communication",
          color: "#B5EAD7",
        },
        {
          name: "Marketing",
          color: "#C7CEEA",
        },
        {
          name: "Contract Negotiation",
          color: "#A2D2FF",
        },
        {
          name: "Personal",
          color: "#FF9AA2",
        },
      ],
    },
    {
      name: "Medical Students",
      labels: [
        {
          name: "Lectures",
          color: "#FFB7B2",
        },
        {
          name: "Exams",
          color: "#FFDAC1",
        },
        {
          name: "Clinical Rotations",
          color: "#E2F0CB",
        },
        {
          name: "Research",
          color: "#B5EAD7",
        },
        {
          name: "Study Groups",
          color: "#C7CEEA",
        },
        {
          name: "Volunteering",
          color: "#A2D2FF",
        },
        {
          name: "Networking",
          color: "#FF9AA2",
        },
        {
          name: "Personal",
          color: "#FFB7B2",
        },
      ],
    },
  ];

  const [selectedLabels, setSelectedLabels] = useState([]);

  const dispatch = useDispatch();

  const handleLabelClick = (groupIndex, labelIndex) => {
    setSelectedLabels((prevState) => {
      const newSelectedLabels = [...prevState];
      if (!newSelectedLabels[groupIndex]) {
        newSelectedLabels[groupIndex] = [];
      }

      newSelectedLabels[groupIndex][labelIndex] =
        !newSelectedLabels[groupIndex][labelIndex];
      return newSelectedLabels;
    });
  };

  return (
    <Modal
      open={open}
      footer={null}
      header={null}
      closable={false}
      width={800}
      destroyOnClose={true}
      onCancel={() => setOpen(false)}
    >
      <div className="p-5">
        <div className="text-lg">Commonly used labels</div>
        <div className="text-md text-gray-500">
          Click to add to your labels 👇
        </div>
        <div className="flex flex-col gap-1">
          {labelGroups.map((group, groupIndex) => {
            return (
              <LabelLibraryGroup
                key={groupIndex}
                name={group.name}
                labels={group.labels}
                selectedLabels={selectedLabels[groupIndex]}
                onLabelClick={(labelIndex) =>
                  handleLabelClick(groupIndex, labelIndex)
                }
              />
            );
          })}
        </div>
        <div className="flex flex-row justify-end mt-4 gap-2">
          <div
            onClick={() => {
              // Reset the selected labels
              setSelectedLabels([]);
              setOpen(false);
            }}
            className="button"
          >
            Cancel
          </div>
          <div
            onClick={() => {
              // Lets generate a list of labels based on selectedLabels
              // Just a final array of labels to add
              const labelsToAdd = [];

              for (
                let groupIndex = 0;
                groupIndex < selectedLabels.length;
                groupIndex++
              ) {
                const group = selectedLabels[groupIndex];
                if (group) {
                  for (
                    let labelIndex = 0;
                    labelIndex < group.length;
                    labelIndex++
                  ) {
                    const label = group[labelIndex];
                    if (label) {
                      labelsToAdd.push({
                        ...labelGroups[groupIndex].labels[labelIndex],
                        id: uuidv4(),
                      });
                    }
                  }
                }
              }

              if (labelsToAdd.length > 0) {
                dispatch(bulkCreateLabels(labelsToAdd));
              }

              setSelectedLabels([]);
              setOpen(false);
            }}
            className="button primary"
          >
            Done
          </div>
        </div>
      </div>
    </Modal>
  );
}

function LabelLibraryGroup({ name, labels, selectedLabels, onLabelClick }) {
  return (
    <div className="flex flex-col gap-1 rounded-lg py-2">
      <div className="text-md">{name}</div>
      <div className="flex flex-wrap gap-2">
        {labels.map((label, labelIndex) => (
          <div
            key={labelIndex}
            className={`flex flex-row gap-1 items-center justify-center rounded-full border px-2 cursor-pointer ${
              selectedLabels && selectedLabels[labelIndex]
                ? "border-primary"
                : ""
            }`}
            onClick={() => onLabelClick(labelIndex)}
          >
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: label.color }}
            ></div>
            <div>{label.name}</div>
            {selectedLabels && selectedLabels[labelIndex] && <BiCheck />}
          </div>
        ))}
      </div>
    </div>
  );
}

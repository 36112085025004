import React, { useState } from "react";
import { MdNotes } from "react-icons/md";
import { TbNotes } from "react-icons/tb";

export default function NotesIcon({
  item,
  action,
  preview = false,
  alwaysVisible = false,
}) {
  function checkIfStringBlank(string) {
    return !string || /^\s*$/.test(string);
  }
  return (
    <div
      onClick={(event) => {
        action();
      }}
      className={`notes-button-container ${
        (item.notes && !checkIfStringBlank(item.notes)) || alwaysVisible
          ? "visible"
          : ""
      }`}
      data-no-dnd="true"
    >
      <MdNotes className="subtask-button notes" />
    </div>
  );
}

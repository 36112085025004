import React, { useState } from "react";
import { MdEmail, MdNotes, MdOutlineEmail } from "react-icons/md";
import { TbNotes } from "react-icons/tb";

export default function EmailIcon({
  item,
  action,
  preview = false,
  alwaysVisible = false,
}) {
  function checkIfStringBlank(string) {
    return !string || /^\s*$/.test(string);
  }
  return (
    <div
      onClick={(event) => {
        action();
      }}
      className={`notes-button-container ${
        (item?.email_info?.HtmlBody &&
          !checkIfStringBlank(item?.email_info?.HtmlBody)) ||
        alwaysVisible
          ? "visible"
          : ""
      }`}
      data-no-dnd="true"
    >
      <MdOutlineEmail className="subtask-button notes" />
    </div>
  );
}

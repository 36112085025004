import { BiChevronRight } from "react-icons/bi";
import { useSelector } from "react-redux";

export default function MiniListDragPreview({ listId }) {
  const listData = useSelector((state) => {
    // If listId is brain_dump, we want to return the brainDumpOrder
    if (listId === "brain_dump") {
      return {
        title: "Brain Dump",
        icon: "🧠",
        id: "brain_dump",
      };
    }
    return state.tasks.lists[listId];
  });

  if (!listData) {
    return null;
  }

  return (
    <div className="flex flex-col gap-0 mb-0 px-2 py-1 bg-slate-50 rounded-lg border border-neutral-200 shadow-lg">
      <div className="flex flex-row gap-1 items-center justify-between cursor-pointer select-none">
        <div className="flex flex-row gap-2 items-center">
          <div className="text-base font-semibold dark:text-neutral-300 ph-mask">
            {listData.icon} {listData.title}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { XMarkIcon } from "@heroicons/react/24/outline";

import { Popover, Modal } from "antd";
import { Default, Mobile, isMac } from "../../utils";

import "./KeyboardShortcuts.css";
import { useHotkeys } from "react-hotkeys-hook";

export default function KeyboardShortcuts({ visible = false, setVisible }) {
  useHotkeys(
    "shift+/",
    (e) => {
      e.preventDefault();

      setVisible(!visible);
    },
    [visible]
  );

  const actionKey = isMac() ? "⌘" : "Ctrl";

  const { analytics_enabled, daily_planning_enabled } = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        daily_planning_enabled: true,
      }
  );

  return (
    <Modal
      closable={false}
      open={visible}
      //   onOk={this.handleOk}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      bodyStyle={{ padding: "0px" }}
      destroyOnClose={true}
      transitionName=""
      maskTransitionName=""
      zIndex={1002}
      autoFocusButton={null}
    >
      <div className="shortcuts-container">
        <div className="shortcuts-header">
          <div className="header-title">Keyboard shortcuts</div>{" "}
          <XMarkIcon
            onClick={() => {
              setVisible(false);
            }}
            className="shortcuts-close-icon"
          />
        </div>

        <div className="shortcuts-body">
          <div className="shortcuts-section">
            <div className="shortcuts-section-header">Most Popular</div>

            <div className="shortcuts-section-body">
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">?</div>
                </div>
                <div className="shortcut-title">View keyboard shortcuts</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + K</div>
                </div>
                <div className="shortcut-title">Task search</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">SHIFT + F</div>
                </div>
                <div className="shortcut-title">Show only today</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">B</div>
                </div>
                <div className="shortcut-title">Create Braindump task</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">T</div>
                </div>
                <div className="shortcut-title">Create task today</div>
              </div>
            </div>
          </div>

          <div className="shortcuts-section">
            <div className="shortcuts-section-header">
              <div className="header-title">Task Actions</div>
            </div>
            <div className="shortcuts-section-description">
              A trick to remember these: "C is for complete", "S is for
              subtasks", "L is for labels" etc...
            </div>
            <div className="shortcuts-section-body">
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">Enter</div>
                </div>
                <div className="shortcut-title">Edit / save description</div>
              </div>
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + Enter</div>
                </div>
                <div className="shortcut-title">Create task (from modal)</div>
              </div>
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + Delete</div>
                </div>
                <div className="shortcut-title">Delete task</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + L</div>
                </div>
                <div className="shortcut-title">Select label</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + I</div>
                </div>
                <div className="shortcut-title">Toggle time section</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + E</div>
                </div>
                <div className="shortcut-title">Set estimated/planned time</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + B</div>
                </div>
                <div className="shortcut-title">Set actual time</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">Esc</div>
                </div>
                <div className="shortcut-title">De-select task</div>
              </div>
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">C</div>
                </div>
                <div className="shortcut-title">Mark as complete</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">S</div>
                </div>
                <div className="shortcut-title">View / hide subtasks</div>
              </div>
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">{actionKey} + S</div>
                </div>
                <div className="shortcut-title">Create new subtask</div>
              </div>
            </div>
          </div>

          <div className="shortcuts-section">
            <div className="shortcuts-section-header">
              <div className="header-title">Navigation</div>
            </div>
            <div className="shortcut">
              <div className="shortcut-keys">
                <div className="shortcut-key">D</div>
              </div>
              <div className="shortcut-title">Day view (in calendar mode)</div>
            </div>
            <div className="shortcut">
              <div className="shortcut-keys">
                <div className="shortcut-key">W</div>
              </div>
              <div className="shortcut-title">Week view (in calendar mode)</div>
            </div>
            <div className="shortcut">
              <div className="shortcut-keys">
                <div className="shortcut-key">M</div>
              </div>
              <div className="shortcut-title">Month view (in calendar mode)</div>
            </div>
            <div className="shortcut">
              <div className="shortcut-keys">
                <div className="shortcut-key">Shift + T</div>
              </div>
              <div className="shortcut-title">Go to (and select first task) today</div>
            </div>
            <div className="shortcut">
              <div className="shortcut-keys">
                <div className="shortcut-key">Shift + B</div>
              </div>
              <div className="shortcut-title">
                Select first task of the braindump
              </div>
            </div>

            <div className="shortcut">
              <div className="shortcut-keys">
                <div className="shortcut-key">Tab</div>
              </div>
              <div className="shortcut-title">
                Switch between Calendar and Kanban view
              </div>
            </div>

            <div className="shortcuts-section-body">
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">↓</div>
                </div>
                <div className="shortcut-title">Next Task</div>
              </div>
              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">↑</div>
                </div>
                <div className="shortcut-title">Previous Task</div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">→</div>
                </div>
                <div className="shortcut-title">
                  Select first task on next day
                </div>
              </div>

              <div className="shortcut">
                <div className="shortcut-keys">
                  <div className="shortcut-key">←</div>
                </div>
                <div className="shortcut-title">
                  Select first task on previous day
                </div>
              </div>

              {daily_planning_enabled && (
                <div className="shortcut">
                  <div className="shortcut-keys">
                    <div className="shortcut-key">{actionKey} + SHIFT + P</div>
                  </div>
                  <div className="shortcut-title">Go to Daily Planning</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

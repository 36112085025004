import "./RecurringInput.css";
import { useState, useRef, useEffect } from "react";
import { BiTrash, BiStopCircle, BiCopy, BiRightArrowAlt } from "react-icons/bi";
import { TiArrowRepeat } from "react-icons/ti";
import { IoCloseCircleSharp } from "react-icons/io5";
import { BsCheck } from "react-icons/bs";
import {
  getRecurrenceText,
  weekdays,
  months,
  ordinal_suffix_of,
} from "../../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { RRule } from "rrule";
import moment from "moment";
import {
  stopRecurringTask,
  deleteAllRecurringInstances,
  updateIncompleteInstances,
  createRecurringTask,
} from "../../../../../redux/recurringTasksSlice";

import { addMinutes } from "date-fns";

import { setUpgradeVisible } from "../../../../../redux/appSlice";
// Improt dayjs
import dayjs from "dayjs";
import {
  updateTask,
  updateRecurringTask,
} from "../../../../../redux/tasksSlice";
import { v4 as uuidv4 } from "uuid";
import _, { set } from "lodash";

import { generateTemplateFromTask } from "../../../../../utils";
import { Modal, Switch, TimePicker } from "antd";
import ReactDatePicker from "react-datepicker";
import CustomRecurrenceEditor from "./CustomRecurrenceEditor";
import CustomTimePicker from "../../../../Sidebar/DatePicker/CustomTimePicker";

export default function RecurringTaskMenu({
  task,
  creation,
  options,
  recurrenceText,
  isCustom,
  recurringTaskEditable,
  setRecurringTaskEditable,
  specificTimeEditable,
  setSpecificTimeEditable,
  closeWithoutSaving,
  stopRecurrence,
  setStopRecurrence,
  setUpdateIncompleteWithNewTask,
  customRecurrenceEditorOpen,
  setCustomRecurrenceEditorOpen,
}) {


  const dispatch = useDispatch();

  const { time_format = "12_hour" } = useSelector(
    (state) => state.app.currentUser
  );

  const [recurrencePickerActive, setRecurrencePickerActive] = useState(false);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const [rruleEditable, setRruleEditable] = useState(null);

  function createRecurringTaskFromRule(recurrenceRule, callback) {
    if (!subscriptionActive) {
      dispatch(setUpgradeVisible(true));
    } else {
      if (recurrenceRule) {
        var newRecurringTask = {
          id: uuidv4(),
          rrule: recurrenceRule.toString(),
          branched_tasks: [task.id],
          exclusions: [moment(task.date).format("YYYY-MM-DD")],
          task_template: generateTemplateFromTask(task),
        };

        // If there is a recurringtasklocal, lets take the task_template from there and merge
        if (recurringTaskEditable) {
          newRecurringTask.task_template = {
            ...newRecurringTask.task_template,
            ...recurringTaskEditable.task_template,
          };
        }

        // If there is a start in the task_template, let's set setSpecificTimeEditable to that
        if (newRecurringTask.task_template.start) {
          setSpecificTimeEditable(newRecurringTask.task_template.start);
        }

        setRecurringTaskEditable(newRecurringTask);
        if (callback) callback(newRecurringTask);
      } else {
        if (creation) {
          setSpecificTimeEditable(null);
          setRecurringTaskEditable(null);
        }
      }
    }
  }

  useEffect(() => {
    if (recurringTaskEditable && recurringTaskEditable.rrule) {
      try {
        const newRRule = new RRule.fromString(recurringTaskEditable.rrule);

        setRruleEditable(newRRule);
      } catch (err) {
        console.log(err);
      }
    }
  }, [recurringTaskEditable, options]);

  function isRecurrenceEqual(rrule1, rrule2) {
    if (!rrule1 && !rrule2) {
      return true;
    }

    if (!rrule1 || !rrule2) {
      return false;
    }

    return rrule1.toText() === rrule2.toText();
  }

  function safeRRuleFromString(rruleString) {
    try {
      return RRule.fromString(rruleString);
    } catch (error) {
      console.error("Failed to parse RRule string: ", error);
      return null;
    }
  }

  if (!options) {
    return (
      <div className="ellie-menu">
        Tasks in the braindump or lists cannot be recurring
      </div>
    );
  }

  return (
    <div className="ellie-menu" data-no-dnd="true">
      <Modal
        title={null}
        footer={null}
        closeIcon={null}
        closable={false}
        width={600}
        open={customRecurrenceEditorOpen}
        onOk={() => {
          setCustomRecurrenceEditorOpen(false);
        }}
        onCancel={() => {
          setCustomRecurrenceEditorOpen(false);
        }}
        zIndex={1002}
      >
        <CustomRecurrenceEditor
          taskDate={task.date}
          rrule={rruleEditable}
          onUpdate={setRruleEditable}
          onClose={() => {
            setCustomRecurrenceEditorOpen(false);
          }}
          onDonePressed={(newRule) => {
            if (newRule) {
              if (!creation && task.recurring) {
                // Let's update rrule of the reccuringTaskEditable
                setRecurringTaskEditable({
                  ...recurringTaskEditable,
                  rrule: newRule.toString(),
                });

                setStopRecurrence(false);
              } else {
                createRecurringTaskFromRule(newRule);
              }
            }

            setCustomRecurrenceEditorOpen(false);
          }}
        />
      </Modal>
      {(recurrencePickerActive || !task.recurring || creation) && (
        <>
          <div className="ellie-menu-header">
            <div className="ellie-menu-header-text">
              {task.recurring ? "Update recurrence" : "Make task recurring"}
            </div>
            {creation ||
              (task.recurring && (
                <IoCloseCircleSharp
                  onClick={() => {
                    setRecurrencePickerActive(false);
                  }}
                  className="ellie-menu-close-icon"
                />
              ))}
          </div>

          {options.map((option, index) => (
            <div
              key={option.recurrenceRule?.toString() || "no-recurrence"}
              onClick={() => {
                // Get rrule string
                const rrule = option.recurrenceRule
                  ? option.recurrenceRule.toString()
                  : null;

                // If this is a recurring task, we need to update the recurring task with the new recurrence rule
                if (!creation && task.recurring) {
                  if (rrule) {
                    /*
                    dispatch(
                      updateRecurringTask({
                        recurringTaskId: recurringTask.id,
                        currentRecurringTask: recurringTask,
                        newData: {
                          rrule: rrule,
                        },
                      })
                    ); */

                    // Let's update the rrule of the recurringTaskEditable
                    setRecurringTaskEditable({
                      ...recurringTaskEditable,
                      rrule: rrule,
                    });

                    setStopRecurrence(false);
                  } else {
                    // We are making it not repeating, basically delete

                    // Stop recurring by setting recurringTaskEditable to null
                    setStopRecurrence(true);
                  }
                } else {
                  // If it is not, let's create a new recurring task
                  createRecurringTaskFromRule(rrule);
                  if (rrule) {
                    setStopRecurrence(false);
                  } else {
                    setStopRecurrence(true);
                  }
                }
              }}
              className="ellie-menu-item"
            >
              <div className="check-container">
             


{creation &&
  isRecurrenceEqual(
    recurringTaskEditable
      ? safeRRuleFromString(recurringTaskEditable.rrule)
      : null,
    option.recurrenceRule
  ) && <BsCheck className="ellie-menu-item-check" />}

{!creation &&
  !stopRecurrence &&
  isRecurrenceEqual(
    recurringTaskEditable
      ? safeRRuleFromString(recurringTaskEditable.rrule)
      : null,
    option.recurrenceRule
  ) && <BsCheck className="ellie-menu-item-check" />}

                {!creation && stopRecurrence && !option.recurrenceRule && (
                  <BsCheck className="ellie-menu-item-check" />
                )}
              </div>

              <div className="elie-menu-item-text">
                {option.title}
                <span className="elie-menu-item-subtitle">
                  {option.subTitle}
                </span>
              </div>
            </div>
          ))}

          <div
            onClick={() => {
              // IF there is no rrule, we need to create a new recurring task

              if (!rruleEditable) {
                var weeklyRule = options[2].recurrenceRule.toString();

                createRecurringTaskFromRule(weeklyRule);
              }

              setCustomRecurrenceEditorOpen(true);
            }}
            className="ellie-menu-item"
          >
            <div className="check-container">
              {isCustom && <BsCheck className="ellie-menu-item-check" />}
            </div>
            <div className="elie-menu-item-text">Custom...</div>
          </div>
          <div className="specific-time-section">
            <div className="enable">
              <div>At a specific time?</div>
              <Switch
                onChange={(checked) => {
                  // Let's update this task and the recurring task template
                  // If checked is true, we need to set the start time to the current time
                  // If checked is false, we need to set the start time to null

                  const date = new Date(
                    task.date.toDate ? task.date.toDate() : task.date
                  );

                  if (creation) {
                    // If there is no recurring task local, create one
                    if (!recurringTaskEditable) {
                      var weeklyRule = options[2].recurrenceRule.toString();

                      createRecurringTaskFromRule(
                        weeklyRule,
                        (newRecurringTask) => {
                          //   setSpecificTimeEditable(checked ? date : null);
                        }
                      );
                    }
                  } else {
                    if (!recurringTaskEditable) {
                      var weeklyRule = options[2].recurrenceRule.toString();

                      createRecurringTaskFromRule(
                        weeklyRule,
                        (newRecurringTask) => {
                          // setSpecificTimeEditable(checked ? date : null);
                        }
                      );
                    } else {
                      const newTask = {
                        ...task,
                        start: checked ? date : null,
                      };

                      // Let's update the recurring task template
                      setRecurringTaskEditable({
                        ...recurringTaskEditable,
                        task_template: {
                          ...recurringTaskEditable.task_template,
                          start: checked ? date : null,
                        },
                      });

                      setUpdateIncompleteWithNewTask(newTask);
                    }
                  }

                  setSpecificTimeEditable(checked ? date : null);
                }}
                checked={specificTimeEditable != null ? true : false}
                size="small"
              />
            </div>
            {specificTimeEditable && (
              <CustomTimePicker
                time_format={time_format}
                value={dayjs(
                  specificTimeEditable.toDate
                    ? specificTimeEditable.toDate()
                    : specificTimeEditable
                ).format(time_format === "12_hour" ? "hh:mm A" : "HH:mm")}
                onChange={(timeString) => {
                  // Parse the time string back to a Date object
                  const timeParts = timeString.split(/[: ]/);
                  let hours = parseInt(timeParts[0], 10);
                  const minutes = parseInt(timeParts[1], 10);
                  const ampm = timeParts[2];

                  // Convert 12-hour format to 24-hour format
                  if (ampm === "PM" && hours < 12) hours += 12;
                  if (ampm === "AM" && hours === 12) hours = 0;

                  // Get the current date or task's start date
                  var date = task.start
                    ? new Date(
                        task.start.toDate ? task.start.toDate() : task.start
                      )
                    : new Date();

                  // Set the selected time to the date
                  date.setHours(hours);
                  date.setMinutes(minutes);
                  date.setSeconds(0);

                  if (creation) {
                    setSpecificTimeEditable(date);
                  } else {
                    // Update the task with the new start time
                    const newTask = {
                      ...task,
                      start: date,
                      date: date,
                    };
                    setSpecificTimeEditable(date);
                    setUpdateIncompleteWithNewTask(newTask);
                  }
                }}
              />
            )}
          </div>
        </>
      )}
      {!creation && task.recurring && !recurrencePickerActive && (
        <>
          <div
            onClick={() => {
              setRecurrencePickerActive(true);
            }}
            className="ellie-menu-item clickable"
          >
            <TiArrowRepeat className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">{recurrenceText}</div>
            <BiRightArrowAlt className="ellie-menu-item-arrow" />
          </div>
          <div
            onClick={() => {
              dispatch(
                stopRecurringTask({
                  recurringTask: recurringTaskEditable,
                  taskToStop: task,
                })
              );

              closeWithoutSaving();
            }}
            className="ellie-menu-item"
          >
            {" "}
            <BiStopCircle className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">Stop repeating</div>
          </div>
          <div
            onClick={() => {
              dispatch(
                updateIncompleteInstances({
                  recurringTask: recurringTaskEditable,
                  taskToMatch: task,
                })
              );
              closeWithoutSaving();
            }}
            className="ellie-menu-item"
          >
            <BiCopy className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">
              Update all incomplete tasks to match this task
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(
                deleteAllRecurringInstances({
                  recurringTask: recurringTaskEditable,
                })
              );
              closeWithoutSaving();
            }}
            className="ellie-menu-item"
          >
            <BiTrash className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">Delete all instances</div>
          </div>
        </>
      )}
    </div>
  );
}

// Function to get the last instance of a month
function getLastDayOccurence(date, dayOfWeek) {
  var result = moment(date).endOf("month");

  while (result.day() !== dayOfWeek) {
    result.subtract(1, "day");
  }

  return result.toDate();
}

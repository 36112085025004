import { IoSparkles } from "react-icons/io5";
import { electronUpdater } from "@todesktop/client-core";
import { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

export default function DesktopUpdater({}) {
  const [updateReadyForInstall, setUpdateReadyForInstall] = useState(false);
  const [updateFound, setUpdateFound] = useState(false);

  electronUpdater.on("update-downloaded", (info) => {
    console.log("update-downloaded", info);
    // show indicator on your app UI.
    setUpdateReadyForInstall(true);
  });

  electronUpdater.on("update-available", (info) => {
    console.log("update-available", info);
    // Show something on the DOM if you like.
    setUpdateFound(true);
  });

  useInterval(
    () => {
      if (!updateFound && !updateReadyForInstall) {
        console.log("checking for updates...")
        electronUpdater.checkForUpdates();
      }
    },
    // Delay in milliseconds or null to stop it
    // Set it to one hour
    1000 * 60 * 60
  );

  if (!updateReadyForInstall) {
    return null;
  }

  return (
    <div className="update-avaialble-card">
      <div className="title">
        <IoSparkles className="icon" />
        <span>New version is available</span>
      </div>
      <div
        onClick={() => {
          electronUpdater.quitAndInstall();
          // window.location.reload();
        }}
        className="update-button"
      >
        Update
      </div>
    </div>
  );
}

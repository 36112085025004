import moment from "moment";
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Select, Button } from "antd";
import { CSVLink } from "react-csv";
import { HiDownload } from "react-icons/hi";

const LabelIcon = ({ color }) => (
  <span
    className="mr-2 inline-block w-2 h-2 rounded-full"
    style={{ backgroundColor: color }}
  />
);

const GroupButton = ({ active, onClick, children }) => (
  <button
    className={`px-4 py-2 rounded-lg mr-2 ${
      active ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700 dark:bg-neutral-700 dark:text-gray-300"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

const formatTime = (seconds) => {
  if (isNaN(seconds)) return "0m";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

export default function CompletedTasksTable({ taskData, dateRange }) {
  const [groupBy, setGroupBy] = useState("none");
  const { data: labels } = useSelector((state) => state.labels);

  const completedTasks = useMemo(() => {
    if (!taskData || !labels || !dateRange) return [];

    const startDate = moment(dateRange.dates[0]).startOf('day').toDate();
    const endDate = moment(dateRange.dates[dateRange.dates.length - 1]).endOf('day').toDate();

    return Object.values(taskData)
      .filter((task) => {
        const taskDate = moment(task.date).toDate();
        return task.complete && taskDate >= startDate && taskDate <= endDate;
      })
      .map((task) => ({
        ...task,
        label: labels[task.label] || { name: "No Label", color: "#CCCCCC" },
      }))
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [taskData, labels, dateRange]);

  const groupedTasks = useMemo(() => {
    if (groupBy === "none") return { "All Tasks": completedTasks };

    return completedTasks.reduce((acc, task) => {
      const key =
        groupBy === "label"
          ? task.label.name
          : moment(task.date).format("M/D/YYYY");
      if (!acc[key]) acc[key] = [];
      acc[key].push(task);
      return acc;
    }, {});
  }, [completedTasks, groupBy]);

  const csvHeaders = [
    "Task Description",
    "Label",
    "Estimated Time",
    "Actual Time",
    "Date",
  ];

  const csvData = useMemo(() => {
    const data = [];

    data.push(csvHeaders);

    Object.entries(groupedTasks).forEach(([group, tasks]) => {
      if (groupBy !== "none") {
        data.push([group, "", "", "", ""]);
      }
      tasks.forEach((task) => {
        data.push([
          task.description,
          task.label.name,
          formatTime(task.estimated_time),
          formatTime(task.actual_time),
          new Date(task.date).toLocaleDateString(),
        ]);
      });
      if (groupBy !== "none") {
        const totalEstimatedTime = tasks.reduce(
          (sum, task) => sum + (task.estimated_time || 0),
          0
        );
        const totalActualTime = tasks.reduce(
          (sum, task) => sum + (task.actual_time || 0),
          0
        );
        data.push([
          "Total",
          "",
          formatTime(totalEstimatedTime),
          formatTime(totalActualTime),
          "",
        ]);
        data.push([]);
      }
    });
    return data;
  }, [groupedTasks, groupBy]);

  return (
    <div className="bg-white dark:bg-[#282828] rounded-xl shadow-sm p-6 w-full border border-neutral-100 dark:border-none dark:text-white">
      <div className="mb-8 flex flex-row gap-2 items-center justify-between">
        <h2 className="text-lg font-semibold">Completed Tasks</h2>
        <div className="flex flex-row gap-4 items-center">
          <Select value={groupBy} onChange={setGroupBy} style={{ width: 150 }}>
            <Select.Option value="none">No Grouping</Select.Option>
            <Select.Option value="label">Group by Label</Select.Option>
            <Select.Option value="date">Group by Date</Select.Option>
          </Select>
          <CSVLink data={csvData} filename={"completed_tasks.csv"} className="">
            <div className="flex flex-row gap-2 items-center border border-neutral-300 dark:border-neutral-600 rounded-md bg-white dark:bg-neutral-700 py-2 px-3 cursor-pointer text-xs dark:text-gray-300">
              <HiDownload className="h-4 w-4" />
              <span>Download CSV</span>
            </div>
          </CSVLink>
        </div>
      </div>

      {Object.entries(groupedTasks).map(([group, tasks]) => {
        const totalEstimatedTime = tasks.reduce(
          (sum, task) =>
            sum +
            (isNaN(task.estimated_time) || task.estimated_time === null
              ? 0
              : task.estimated_time),
          0
        );
        const totalActualTime = tasks.reduce(
          (sum, task) =>
            sum +
            (isNaN(task.actual_time) || task.actual_time === null
              ? 0
              : task.actual_time),
          0
        );

        return (
          <div key={group} className={`${groupBy !== "none" ? "mb-4" : ""}`}>
            {groupBy !== "none" && (
              <h3 className="text-base font-semibold mb-2">{group}</h3>
            )}
            <table className="w-full">
              <thead>
                <tr className="text-left text-gray-500 dark:text-gray-400 text-xs">
                  <th className="pb-2">Task Description</th>
                  <th className="pb-2">Label</th>
                  <th className="pb-2 pr-4">Estimated Time</th>
                  <th className="pb-2 pr-4">Actual Time</th>
                  <th className="pb-2">Date</th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {tasks.map((task) => (
                  <tr key={task.id} className="border-t border-gray-100 dark:border-neutral-700">
                    <td className="py-2 pr-4 truncate w-[450px] max-w-[450px]">
                      <div className="truncate">{task.description}</div>
                    </td>
                    <td className="py-2 pr-4">
                      <div className="flex items-center min-w-[200px]">
                        <LabelIcon color={task.label.color} />
                        <span className="truncate max-w-xs">
                          {task.label.name}
                        </span>
                      </div>
                    </td>
                    <td className="py-2">{formatTime(task.estimated_time)}</td>
                    <td className="py-2">{formatTime(task.actual_time)}</td>
                    <td className="py-2">
                      {new Date(task.date).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
                <tr className="border-t border-gray-200 dark:border-neutral-600 font-semibold">
                  <td className="py-2 pr-4" colSpan="2">
                    Total
                  </td>
                  <td className="py-2">{formatTime(totalEstimatedTime)}</td>
                  <td className="py-2">{formatTime(totalActualTime)}</td>
                  <td className="py-2"></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}
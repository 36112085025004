import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
} from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import {
  ChevronRightIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";

import { Button, Input, Select, Switch } from "antd";
import { FcGoogle } from "react-icons/fc";
import { TbArrowRight, TbMail, TbPlus } from "react-icons/tb";

import axios from "axios";
import { googleServerUrl } from "../../utils";
import { AiOutlineApple } from "react-icons/ai";
import { FaAppStoreIos } from "react-icons/fa";

import calenderImageDark from "../../images/cal_integration_dark.png";
import calenderImageLight from "../../images/cal_integration.png";
import { Toaster, toast } from "sonner";
import { getFunctions, httpsCallable } from "firebase/functions";

const { Option } = Select;

export default function API() {
  const api_key = useSelector((state) => state.app.currentUser.api_key);
  const userId = useSelector((state) => state.app.uid);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const dispatch = useDispatch();

  const functions = getFunctions();
  const generateNewAPIKey = httpsCallable(functions, "createApiKey");

  const [showKey, setShowKey] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(api_key);
    toast.success(`Key copied to clipboard!`);
  };

  const generateNewApiKey = async () => {
    return new Promise((resolve, reject) => {
      // Firebase callables
      generateNewAPIKey()
        .then((result) => {
          if (result.data.message == "API key updated successfully") {
            //  toast.success("New API key generated!");
            resolve();
          } else {
            //   toast.error("Error generating new API key.");
            reject(new Error("Error generating new API key."));
          }
        })
        .catch((error) => {
          ///  toast.error("Error: " + error.message);
          reject(error);
        });
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-0">
        <div className="text-lg">Ellie API Token</div>
        <div className="text-sm text-gray-500 dark:text-gray-300">
          Connect Ellie with other apps and services. You can use Ellie with
          apps like{" "}
          <a
            className="text-blue-800 dark:text-blue-400"
            href="https://zapier.com"
            target="_blank"
          >
            Zapier
          </a>
          . Your API token allows access to your Ellie data and should be kept
          secret.
        </div>
      </div>
      {api_key ? (
        <div className="flex flex-col gap-4 border p-4 rounded-lg dark:border-neutral-500">
          <div className="flex flex-row gap-3 items-center">
            <input
              type={showKey ? "text" : "password"}
              value={api_key}
              readOnly
              className="p-1 rounded-lg border w-9/12 dark:bg-neutral-800 dark:border-neutral-700"
            />
            <div className="button" onClick={() => setShowKey(!showKey)}>
              {showKey ? "Hide" : "Show"} Token
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <div className="button primary" onClick={copyToClipboard}>
              Copy API Token
            </div>
            <div
              className="button"
              onClick={() => {
                // Firebase callables
                toast.promise(generateNewApiKey(), {
                  loading: "Generating new API token...",
                  success: (data) => {
                    return `New API token generated!`;
                  },
                  error: "Error",
                });
              }}
            >
              Generate new API Token
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="button primary"
            onClick={() => {
              // Firebase callables
              toast.promise(generateNewApiKey(), {
                loading: "Generating new API token...",
                success: (data) => {
                  return `New API token generated!`;
                },
                error: "Error",
              });
            }}
          >
            Generate new API Token
          </div>
        </div>
      )}

      <div className="text-sm text-gray-500 dark:text-gray-300">
        Are you a developer looking to use the Ellie API? Email{" "}
        <a className="text-blue-700 dark:text-blue-400" href="mailto:chris@aloa.co">
          chris@aloa.co
        </a>{" "}
        for access.
      </div>
    </div>
  );
}

import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import nlp from "compromise";
import plg from "compromise-dates";
import * as chrono from "chrono-node";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { useHotkeys } from "react-hotkeys-hook";
import { createTask } from "../../redux/tasksSlice";
import { v4 as uuidv4 } from "uuid";
import { setQuickCaptureActive } from "../../redux/appSlice";
import { BsCheck } from "react-icons/bs";
import { FiCalendar, FiClock } from "react-icons/fi";
import parse from "parse-duration";
import { isMac } from "../../utils";
nlp.plugin(plg);

export default function QuickCapture() {
  const quickCaptureActive = useSelector(
    (state) => state.app.quickCaptureActive || false
  );
  const { data: labelData, loading } = useSelector((state) => state.labels);

  const lists = useSelector((state) => {
    const tasksLists = state.tasks.lists || {};
    return {
      brain_dump: {
        title: "Brain Dump",
        icon: "🧠",
        id: "brain_dump",
      },
      ...tasksLists,
    };
  });

  const [previousTaskInput, setPreviousTaskInput] = useState(""); // We'll use this to store the previous task input so we can compare it to the current one
  const [taskInput, setTaskInput] = useState("");
  const inputRef = useRef(null);

  const [parsedData, setParsedData] = useState(null);

  useEffect(() => {
    setPreviousTaskInput(taskInput);
  }, [taskInput]);

  const parseTask = (input) => {
    const didDeleteteDate = didDeleteDate(previousTaskInput, input);

    const deletedDuration = didDeleteDuration(previousTaskInput, input);

    // If we did delete a date, we actually only want to remove the last character of the date
    if (didDeleteteDate) {
      const index = previousTaskInput.indexOf(didDeleteteDate.fullMatch);
      input = previousTaskInput.replace(
        didDeleteteDate.fullMatch,
        didDeleteteDate.date.slice(0, -1)
      );

      // Set cursor position to right after the remaining date text
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.selectionStart =
            index + didDeleteteDate.date.length - 1;
          inputRef.current.selectionEnd =
            index + didDeleteteDate.date.length - 1;
        }, 0);
      }
    }

    // If we did delete a duration, we actually only want to remove the last character of the duration
    if (deletedDuration) {
      const index = previousTaskInput.indexOf(deletedDuration.fullMatch);
      input = previousTaskInput.replace(
        deletedDuration.fullMatch,
        deletedDuration.text.slice(0, -1)
      );

      // Set cursor position to right after the remaining duration text
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.selectionStart =
            index + deletedDuration.text.length - 1;
          inputRef.current.selectionEnd =
            index + deletedDuration.text.length - 1;
        }, 0);
      }
    }

    const labels = extractLabels(input);
    const dates = extractDates(input);
    const extractedDurations = extractDurations(input);

    const extractedLists = extractListNames(input);

    const cleanedInput = cleanInput({
      input,
      dates,
      labels,
      extractedLists,
      extractedDurations,
    });

    // This will be outputed as an array of objects with durations/terms/text
    const lastDuration = parseDurations(cleanedInput);

    // Convert to seconds
    var durationInSeconds = lastDuration?.duration
      ? lastDuration?.duration
      : null;

    const lastCleanedDate = getLastDate(cleanedInput);

    const date = parseDate(lastCleanedDate);

    let formattedInput = formatNewInput({
      input: cleanedInput,
      dateStrings: lastCleanedDate ? [lastCleanedDate] : [],
      labels,
      extractedLists,
      lastDurationText: lastDuration?.text || null,
    });

    setTaskInput(formattedInput);

    const newDates = extractDates(formattedInput);
    const newDurations = extractDurations(formattedInput);

    const taskDescription = cleanTaskDescription({
      input: formattedInput,
      dates: newDates,
      labels,
      extractedLists,
      extractedDurations: newDurations,
    });

    return {
      taskDescription,
      date,
      label: labels.length > 0 ? labels[labels.length - 1].id : null,
      list:
        extractedLists.length > 0
          ? extractedLists[extractedLists.length - 1].id
          : null,
      duration: durationInSeconds || null,
    };
  };

  function didDeleteDate(previousTaskInput, taskInput) {
    // Compare the previous task input to the current one
    // Look if anything with format $[X](Y) was deleted
    // If so, return the date that was deleted
    // If not, return null

    if (previousTaskInput.length > taskInput.length) {
      const previousDates = extractDates(previousTaskInput);
      const currentDates = extractDates(taskInput);

      if (previousDates.length > currentDates.length) {
        const deletedDate = previousDates.filter(
          (date) => !currentDates.includes(date)
        )[0];

        // Check if only a single date was deleted and nothing else
        if (
          previousTaskInput.length - taskInput.length ===
          deletedDate.fullMatch.length
        ) {
          return deletedDate;
        }
      }
    }

    return null;
  }

  function didDeleteDuration(previousTaskInput, taskInput) {
    // Compare the previous task input to the current one
    // Look if anything with format ^[X](Y) was deleted
    // If so, return the duration that was deleted
    // If not, return null

    if (previousTaskInput.length > taskInput.length) {
      const previousDurations = extractDurations(previousTaskInput);
      const currentDurations = extractDurations(taskInput);

      if (previousDurations.length > currentDurations.length) {
        const deletedDuration = previousDurations.filter(
          (duration) => !currentDurations.includes(duration)
        )[0];

        // Check if only a single duration was deleted and nothing else
        if (
          previousTaskInput.length - taskInput.length ===
          deletedDuration.fullMatch.length
        ) {
          return deletedDuration;
        }
      }
    }

    return null;
  }

  function extractLabels(input) {
    const regex = /@\[(.*?)\]\((.*?)\)/g;
    const labels = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      labels.push({ fullMatch: match[0], label: match[1], id: match[2] });
    }
    return labels;
  }

  function extractDates(input) {
    const regexDates = /\$\[(.*?)\]\((.*?)\)/g;
    const dates = [];
    let matchDates;
    while ((matchDates = regexDates.exec(input)) !== null) {
      dates.push({
        fullMatch: matchDates[0],
        date: matchDates[1],
        id: matchDates[2],
      });
    }
    return dates;
  }

  function extractDurations(input) {
    const regexDurations = /\^\[(.*?)\]\((.*?)\)/g;
    const durations = [];
    let matchDurations;
    while ((matchDurations = regexDurations.exec(input)) !== null) {
      durations.push({
        fullMatch: matchDurations[0],
        text: matchDurations[1],
        id: matchDurations[2],
      });
    }
    return durations;
  }

  function cleanInput({
    input,
    dates,
    labels,
    extractedLists,
    extractedDurations,
  }) {
    let cleaned = input;
    dates.forEach(
      (date) => (cleaned = cleaned.replace(date.fullMatch, date.date))
    );
    labels.forEach(
      (label) => (cleaned = cleaned.replace(label.fullMatch, label.label))
    );

    extractedLists.forEach(
      (list) => (cleaned = cleaned.replace(list.fullMatch, list.listName))
    );

    extractedDurations.forEach(
      (duration) =>
        (cleaned = cleaned.replace(duration.fullMatch, duration.text))
    );

    return cleaned;
  }

  function extractListNames(input) {
    const regex = /%\[(.*?)\]\((.*?)\)/g;
    const listNames = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      listNames.push({ fullMatch: match[0], listName: match[1], id: match[2] });
    }
    return listNames;
  }

  function getLastDate(input) {
    var date = "";

    var dates = nlp(input).dates().out("array");

    // Use compromise to strip out any durations
    var durations = nlp(input).durations().out("array");

    // Go through the dates and if any of them are purely made up of a duration, remove the duration part
    dates = dates.map((date) => {
      durations.forEach((duration) => {
        if (duration.includes(date)) {
          date = date.replace(duration, "").trim();
        }
      });
      return date;
    });

    dates = dates.map((date2) => {
      return date2.replace(/=.*$/g, "");
    });

    // Remove any empty dates
    dates = dates.filter((date) => date !== "");

    // Get the last date
    date = dates[dates.length - 1] || null;

    // remove the duration from the date
    if (date) {
      durations.forEach((duration) => {
        date = date.replace(duration, "");
      });

      // remove any trailing white space or commas
      date = date.replace(/,$/, "").trim();
    }

    return date;
  }

  function parseDurations(input) {
    // using regular expression to match duration format
    const duration = input.match(/=(.*?)( |$)/);
    if (duration && duration[1]) {
      // convert duration to seconds
      return {
        text: `=${duration[1]}`,
        duration: parse(duration[1], "s"),
      };
    }
    return null;
  }

  function parseDate(dateStr) {
    // parse this with compromise
    // convert it to format with AM/PM
    var parsed = nlp(dateStr).dates().format("iso").out("text");

    var chronoDate = chrono.parseDate(parsed ? parsed : dateStr, new Date(), {
      forwardDate: true,
    });

    // if chrono can't get it, attempt to parse it and convert with compromise itself (like holidays) and convert to a date text
    if (!chronoDate) {
      var converted = nlp(dateStr).dates().format("iso").out("text");

      chronoDate = chrono.parseDate(converted, new Date(), {
        forwardDate: true,
      });
    }

    return chronoDate;
  }

  function cleanTaskDescription({
    input,
    dates,
    labels,
    extractedLists,
    extractedDurations,
  }) {
    let desc = nlp(input).text();

    [
      ...dates.map((d) => d.fullMatch),
      ...labels.map((l) => l.fullMatch),
      ...extractedLists.map((l) => l.fullMatch),
      ...extractedDurations.map((l) => l.fullMatch),
    ].forEach((item) => {
      desc = desc.replace(item, "");
    });

    return desc;
  }

  function generateId(string) {
    return btoa(
      encodeURIComponent(string).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }

  function formatNewInput({
    input,
    dateStrings,
    labels,
    extractedLists,
    lastDurationText,
  }) {
    let newInput = input;

    dateStrings.forEach((dateString) => {
      const id = generateId(dateString);
      newInput = newInput.replace(dateString, `$[${dateString}](${id})`);
    });

    // labels
    if (labels.length > 0) {
      const lastLabel = labels[labels.length - 1];
      newInput = newInput.replace(
        lastLabel.label,
        `@[${lastLabel.label}](${lastLabel.id})`
      );
    }

    // lists
    if (extractedLists.length > 0) {
      const lastList = extractedLists[extractedLists.length - 1];
      newInput = newInput.replace(
        lastList.listName,
        `%[${lastList.listName}](${lastList.id})`
      );
    }

    // duration
    if (lastDurationText) {
      const randomId = Math.random().toString(36).substr(2, 9);
      newInput = newInput.replace(
        lastDurationText,
        `^[${lastDurationText}](${randomId})`
      );
    }

    return newInput;
  }

  const mentionStyle = {
    backgroundColor: "var(--label-mention-background)",
    borderRadius: "6px",
    padding: "1px",
    margin: "-1.5px",
  };

  const dateMentionStyle = {
    backgroundColor: "var(--date-mention-background)",
    borderRadius: "6px",
    padding: "1px",
    margin: "-1.5px",
  };

  const durationMentionStyle = {
    backgroundColor: "var(--duration-mention-background)",
    borderRadius: "6px",
    padding: "1px",
    margin: "-1.5px",
  };

  const listMentionsStyle = {
    backgroundColor: "var(--list-mention-background)",
    borderRadius: "6px",
    padding: "1px",
    margin: "-1.5px",
  };

  const styleDefault = {
    control: {
      backgroundColor: "transparent",
      fontSize: 14,
      fontWeight: "normal",
    },

    "&multiLine": {
      flex: "1 auto",
      padding: "16px",
      input: {
        padding: "16px",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "transparent",
        outline: "none",
      },
    },

    "&singleLine": {
      flex: "1 auto",
      padding: "16px",
      input: {
        padding: "16px",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "transparent",
        outline: "none",
      },
    },

    suggestions: {
      backgroundColor: "var(--suggestions-mention-background)",
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
      border: "var(--suggestions-mention-border)",
      overflow: "hidden",
      marginLeft: "18px",
      padding: "4px",
      list: {
        backgroundColor: "transparent",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px 5px 10px",
        borderRadius: "8px",
        "&focused": {
          backgroundColor: "var(--suggestions-mention-focused-background)",
        },
      },
    },
  };

  const dispatch = useDispatch();

  // Use CMD+Enter, or CTRL+Enter to create task
  useHotkeys(
    "cmd+enter, ctrl+enter",
    (e) => {
      e.preventDefault();

      if (parsedData) {
        let description = parsedData.taskDescription;
        let date = parsedData.date;
        let lastLabel = parsedData.label || null;
        let lastList = parsedData.list || "brain_dump";
        const duration = parsedData.duration || null;

        var startDate = date;
        // If the start date is at midnight, don't set it (it means one was not provided)
        if (date && moment(date).format("h:mm a") === "12:00 am") {
          startDate = null;
        }

        // If there is a list, lets check if there is an auto_label_id
        if (lastList && lists && lists[lastList]) {
          const list = lists[lastList];

          if (!lastLabel && list.auto_label_id) {
            lastLabel = list.auto_label_id;
          }
        }

        if (description && description != "") {
          dispatch(
            createTask({
              id: uuidv4(),
              description,
              date,
              label: lastLabel,
              complete: false,
              listId: date ? null : lastList,
              start: startDate,
              estimated_time: duration,
            })
          );

          dispatch(setQuickCaptureActive(false));
        }
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: quickCaptureActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [quickCaptureActive]
  );

  useHotkeys(
    "q",
    (e) => {
      e.preventDefault();

      if (quickCaptureActive) {
        dispatch(setQuickCaptureActive(false));
      } else {
        dispatch(setQuickCaptureActive(true));
      }
    },
    {
      enabled: true,
    },
    [quickCaptureActive]
  );

  const actionKey = isMac() ? "⌘" : "Ctrl";

  return (
    <Modal
      open={quickCaptureActive}
      title={null}
      footer={null}
      closable={false}
      width={700}
      onCancel={() => {
        dispatch(setQuickCaptureActive(false));
      }}
      afterOpenChange={(open) => {
        if (open && inputRef.current) {
          inputRef.current.focus();
        } else {
          setTaskInput("");
          setParsedData(null);
        }
      }}
    >
      <div className="rounded-lg">
        <div className="flex justify-center items-center">
          <MentionsInput
            type="text"
            placeholder="Example: Buy milk tomorrow (#labels, @lists, =1h30m duration)"
            value={taskInput}
            singleLine={false}
            onChange={(event, newValue, newPlainTextValue, mentions) => {
              const parsed = parseTask(event.target.value);

              setParsedData(parsed);
            }}
            style={styleDefault}
            customSuggestionsContainer={(children) => {
              return <div>{children}</div>;
            }}
            inputRef={inputRef}
            autoFocus={true}
          >
            <Mention
              trigger="#"
              data={
                Object.values(labelData).map((label) => ({
                  id: label.id,
                  display: label.name,
                })) || []
              }
              style={mentionStyle}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => {
                var label = labelData[suggestion.id];

                if (!label) return null;

                return (
                  <div
                    className="flex flex-row gap-2 items-center text-sm"
                    key={label.id + "suggestion"}
                  >
                    <div
                      className="h-2 w-2 rounded-full"
                      style={{ backgroundColor: label.color }}
                    />
                    <span>{label.name}</span>
                  </div>
                );
              }}
            />

            <Mention
              trigger="@"
              data={
                Object.values(lists).map((list) => ({
                  id: list.id,
                  display: list.title,
                })) || []
              }
              style={listMentionsStyle}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => {
                var list = lists[suggestion.id];

                if (!list) return null;

                return (
                  <div
                    className="flex flex-row gap-2 items-center text-sm"
                    key={list.id + "suggestion"}
                  >
                    <span>{list.icon}</span>
                    <span>{list.title}</span>
                  </div>
                );
              }}
              markup="%[__display__](__id__)"
            />

            <Mention
              trigger="$"
              style={dateMentionStyle}
              data={[]}
              markup="$[__display__](__id__)"
            />

            <Mention
              trigger="^"
              style={durationMentionStyle}
              data={[]}
              markup="^[__display__](__id__)"
            />
          </MentionsInput>

          <div className="flex flex-row gap-1 items-center pr-3">
            <div className="bg-neutral-200 dark:bg-neutral-600 px-1.5 py-1 rounded-md p-1 text-xs">
              {actionKey}
            </div>{" "}
            <div className="bg-neutral-200 dark:bg-neutral-600 px-1.5 py-1 rounded-md p-1 text-xs">
              Enter
            </div>
          </div>
        </div>
        {taskInput && taskInput != "" && (
          <div>
            <div
              className={`${
                parsedData && Object.keys(parsedData).length > 0
                  ? "border-t dark:border-t-neutral-700 border-t-slate-200"
                  : ""
              } max-h-96 overflow-y-auto px-4 py-4 flex flex-col gap-4 items-center justify-start bg-transparent rounded-b-lg`}
            >
              <div className="flex flex-row gap-2 items-center justify-between w-full">
                <div className="flex flex-row items-center gap-2 justify-start flex-1">
                  <div className="h-5 w-5 border border-2 rounded-md bg-white dark:bg-transparent dark:border-neutral-500" />
                  <div className="text-base">{parsedData?.taskDescription}</div>
                </div>
              </div>

              {(parsedData?.label ||
                parsedData?.date ||
                parsedData?.list ||
                parsedData?.duration) && (
                <div className="flex flex-row gap-2 items-center justify-start w-full">
                  {parsedData?.label && (
                    <div className="flex flex-row items-center gap-1 border dark:border-neutral-700 rounded-lg px-3 py-1 text-neutral-600 dark:text-neutral-400  shadow-sm">
                      <div
                        className="label-box"
                        style={{
                          backgroundColor: labelData[parsedData?.label].color,
                        }}
                      />
                      <span
                        style={{
                          color: labelData[parsedData?.label].color,
                        }}
                      >
                        {labelData[parsedData?.label].name}
                      </span>
                    </div>
                  )}

                  {parsedData?.date && (
                    <div className="flex flex-row items-center gap-2">
                      <div className="flex flex-row items-center gap-2 border dark:border-neutral-700 rounded-lg px-3 py-1 text-neutral-600 dark:text-neutral-400  shadow-sm">
                        <FiCalendar />
                        {moment(parsedData?.date).format("MMM Do")}
                        {moment(parsedData?.date).format("h:mm a") !==
                        "12:00 am"
                          ? ` (${moment(parsedData?.date).format("h:mm a")})`
                          : ""}
                      </div>
                    </div>
                  )}

                  {!parsedData?.date &&
                    parsedData?.list &&
                    lists[parsedData?.list] && (
                      <div className="flex flex-row items-center gap-2">
                        <div className="border rounded-lg px-3 py-1 flex flex-row items-center dark:border-neutral-700 flex-2 text-neutral-600 dark:text-neutral-400 shadow-sm">
                          <div
                            className="flex flex-row gap-2 items-center text-sm"
                            key={parsedData?.list + "little list"}
                          >
                            <span>{lists[parsedData?.list].icon}</span>
                            <span>{lists[parsedData?.list].title}</span>
                          </div>
                        </div>
                      </div>
                    )}

                  {parsedData?.duration && (
                    <div className="flex flex-row items-center gap-2">
                      <div className="flex flex-row items-center gap-2 border dark:border-neutral-700 rounded-lg px-3 py-1 text-neutral-600 dark:text-neutral-400 shadow-sm">
                        <FiClock />
                        {!isNaN(parsedData?.duration)
                          ? moment
                              .utc(parsedData?.duration * 1000)
                              .format("H:mm")
                          : "0:00"}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

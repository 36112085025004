import React, { useState } from "react";
import PermissionModal from "./PermissionModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { outlookServerUrl } from "../../utils";
import { auth } from "../../firebase";
import { updateCurrentUser } from "../../redux/appSlice";

function Outlook() {
  const location = useLocation();
  const navigate = useNavigate();
  //   const userId = useSelector((state) => state.app.uid);
  const [error, setError] = useState(null);
  const [modalStatus, setModalStatus] = useState("default");
  const { showCalendarSuccessModal = null } = useSelector(
    (state) => state.app.currentUser
  );

  const dispatch = useDispatch();

  const handleAuthorizationClick = async () => {
    try {
      console.log("authorizeAction");
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");

      if (!code) {
        setError("No code provided");
        setModalStatus("error");
        return;
      }

      const idToken = await auth.currentUser.getIdToken(
        /* forceRefresh */ true
      );

      const currentBaseUrl = window.location.origin;

      fetch(
        `${outlookServerUrl}/getAccessToken?code=${code}&idToken=${idToken}&uri=${currentBaseUrl}/outlook-oauth`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setModalStatus("success");
            dispatch(
              updateCurrentUser({
                newValues: { showCalendarSuccessModal: data.accountId },
                previousValues: {
                  showCalendarSuccessModal: showCalendarSuccessModal,
                },
              })
            );
            console.log("success getting access token");
          });
        } else {
          setModalStatus("error");
          console.log("error getting access token");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  var modalContent = null;

  if (modalStatus === "error") {
    modalContent = (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="text-xl font-semibold">Error 😵</div>
        <div>{error}</div>
      </div>
    );
  } else if (modalStatus === "success") {
    modalContent = (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="text-xl font-semibold text-center">
          Successfully connected outlook 🙌
        </div>
        <div>You can close out this window now!</div>
      </div>
    );
  } else if (modalStatus === "cancel") {
    modalContent = (
      <div className="w-full h-full flex flex-col gap-2 items-center justify-center">
        <div className="text-xl font-semibold">Authorization Cancelled ✋</div>
        <div>Redirecting you in Ellie homepage in 5 seconds</div>
      </div>
    );
  }

  const handleCancel = () => {
    setModalStatus("cancel");
    // setTimeout(() => {
    //   navigate("/");
    // }, 5000);
  };

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      <PermissionModal
        logo={require("../../images/icons8-outlook-calendar-480.png")}
        name={"Outlook"}
        scopes={[
          "Access Ellie data such as lists, labels and tasks",
          "Create and modify Ellie tasks",
        ]}
        modalContent={modalContent}
        authorizeAction={handleAuthorizationClick}
        cancelAction={handleCancel}
      />
    </div>
  );
}

export default Outlook;

import React from "react";
import "./Card.css";

import CompleteInput from "./Components/CompleteInput";
import LabelSelector from "./Components/LabelSelector";
import RecurringInput from "./Components/RecurringInput";
import TimeSection from "./Components/TimeSection";
import Subtasks from "./Components/Subtasks";

import { useSelector } from "react-redux";

import SubtasksIcon from "./Components/Subtasks/SubtaskIcon";

import moment from "moment";
import NotesIcon from "./Components/NotesIcon";
import EmailIcon from "./Components/EmailIcon";

export default function CardPreview({
  taskId,
  stackedCount = 0,
  draggable = false,
  mini = false,
}) {
  const item = useSelector((state) => state.tasks.data[taskId]);

  const card_layout = useSelector(
    (state) => state.app.currentUser.card_layout || "compact"
  );

  if (!item) return null;

  if (item.listId && card_layout === "compact") {
    return (
      <div id={taskId} className="card-drag-preview">
        {draggable && stackedCount > 0 && (
          <div
            className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-purple-600 text-white rounded-full h-6 w-6 flex items-center justify-center"
            style={{ zIndex: 9999 }}
          >
            {stackedCount}
          </div>
        )}
        <div
          className={`card-draggable card-description-mini dark:text-neutral-200 select-none pb-2 pt-1 px-2 border border-transparent rounded-lg hover:bg-cardBackgroundLight dark:hover:bg-cardBackgroundDark hover:border hover:border-neutral-300 dark:hover:border-cardBorderColorDark ${
            item.complete ? " complete" : ""
          }`}
        >
          <div className="card-description-mini flex flex-row items-center gap-2">
            <CompleteInput
              item={item}
              complete={item?.complete}
              noCheckbox={true}
            />

            <div className="card-description-mini cursor-pointer mt-[2px] bg-transparent !text-sm overflow-hidden overflow-ellipsis whitespace-nowrap">
              {item?.description}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={taskId} className="card-drag-preview">
      <div
        className={`card-draggable card-container ${
          item?.complete ? " complete" : ""
        }`}
      >
        {draggable && stackedCount > 0 && (
          <div
            className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-purple-600 text-white rounded-full h-6 w-6 flex items-center justify-center"
            style={{ zIndex: 9999 }}
          >
            {stackedCount}
          </div>
        )}
        <div className="card-header">
          <CompleteInput
            item={item}
            complete={item?.complete}
            noCheckbox={true}
          />
          <div className="card-description no-hover ph-mask">
            {item?.description}
          </div>
          <div className="card-time-estimate">
            {!isNaN(item.estimated_time)
              ? moment.utc(item.estimated_time * 1000).format("H:mm")
              : "0:00"}
          </div>
        </div>
        <div className="card-footer">
          <div className="card-left-buttons">
            <LabelSelector
              label={item?.label}
              key={item.id + "_label_selector"}
              item={item}
            />
            {generateItemStartTime(item) && (
              <div className="task-time">{generateItemStartTime(item)}</div>
            )}

            <RecurringInput item={item} />
            <SubtasksIcon item={item} preview={true} />
            <NotesIcon item={item} />
            <EmailIcon item={item} />
          </div>
        </div>
        <TimeSection item={item} />

        <Subtasks item={item} />
      </div>
    </div>
  );
}

function generateItemStartTime(item) {
  var startTime = null;
  var itemDate = null;

  if (item == null) return null;

  if (item?.start?.toDate) {
    startTime = item?.start?.toDate();
  }

  if (item?.date?.toDate) {
    itemDate = item?.date?.toDate();
  }

  if (item?.start && moment(item?.start).isValid()) {
    startTime = item?.start;
  }

  if (item?.date && moment(item?.date).isValid()) {
    itemDate = item?.date;
  }

  if (!startTime || !itemDate) {
    return null;
  }

  // If startTime and date are the day, it is valid
  if (moment(startTime).isSame(itemDate, "day")) {
    return moment(startTime).format("h:mma");
  }

  return null;
}

import "./Subtasks.css";
import React, { useState } from "react";

import icon from "../../../../../images/subtasks.svg";

import blueIcon from "../../../../../images/subtasks-blue.svg";

export default function SubtaskIcon({
  item,
  action,
  preview = false,
  alwaysVisible = false,
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={(event) => {
        action();
      }}
      className={`subtask-button-container ${
        (item.subtasks?.length > 0 && !preview) || alwaysVisible
          ? "visible"
          : ""
      }`}
      data-no-dnd="true"
    >
      {hovered ? (
        <img src={blueIcon} alt="subtasks" className="subtask-button" />
      ) : (
        <img src={icon} alt="subtasks" className="subtask-button" />
      )}
      {item.subtasks?.length > 0 && (
        <span>
          {item?.subtasks?.filter((subtask) => subtask.complete)?.length}/
          {item?.subtasks?.length}
        </span>
      )}
    </div>
  );
}

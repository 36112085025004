import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import moment from "moment";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import PlannerContainer, { BackButton, NextButton } from "./PlannerContainer";

export default function ReviewTodayCapacity({
  taskOrderEditable,
  setStep,
  totalEstimatedTime,
  work_threshold,
  skipStep2Active,
}) {
  const tasks = useSelector((state) => state.tasks.data);

  const taskOrder = useSelector((state) => state.tasks.order);

  const labels = useSelector((state) => state.labels.data);

  const [todaysTasks, setTodaysTasks] = useState([]);

  const [labelBreakdownData, setLabelBreakdownData] = useState([]);

  const [todayDateKey, setTodayDateKey] = useState(null);

  // Function that will get the tasks from yesterday
  // And then output a breakdown of the labels used
  // Adding up how much actual_time was spent on each label
  // And how much estimated_time was spent on each label
  // and the count of how many tasks
  function getLabelBreakdownData(todaysTasks) {
    const breakdown = {};

    todaysTasks.forEach((task) => {
      // Use task.label if it exists, otherwise use "no_label"
      const label = task.label || "no_label";

      if (!breakdown[label]) {
        breakdown[label] = {
          actual_time: 0,
          estimated_time: 0,
          count: 0,
        };
      }

      breakdown[label].actual_time += task.actual_time || 0;
      breakdown[label].estimated_time += task.estimated_time || 0;
      breakdown[label].count += 1;
    });

    return breakdown;
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours === 0) {
      return `${minutes}m`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  }

  // Utility function to convert seconds to hours and minutes
  const secondsToHoursAndMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return { hours, minutes };
  };

  // Utility function to generate the tooltip message based on the threshold
  const getTooltipMessage = (totalTime, threshold) => {
    const thresholdSeconds = threshold * 3600;
    if (totalTime > thresholdSeconds) {
      const exceededTime = secondsToHoursAndMinutes(
        totalTime - thresholdSeconds
      );

      const hoursPart =
        exceededTime.hours > 0
          ? `${exceededTime.hours} hour${
              exceededTime.hours > 1 ? "s" : ""
            } and `
          : "";

      return `😓 You have exceeded your work threshold (${threshold} hours) by ${hoursPart}${exceededTime.minutes} mins`;
    } else if (totalTime >= thresholdSeconds - 7200) {
      // 7200 seconds = 2 hours
      const remainingTime = secondsToHoursAndMinutes(
        thresholdSeconds - totalTime
      );

      const hoursPart =
        remainingTime.hours > 0
          ? `${remainingTime.hours} hour${
              remainingTime.hours === 1 ? "" : "s"
            } and `
          : "";

      return `👀 You are ${hoursPart}${remainingTime.minutes} mins away from hitting your work threshold (${threshold} hours)`;
    }
    return ""; // Default case
  };

  useEffect(() => {
    // Lets get the tasks from yesterday
    try {
      setTodayDateKey(moment().format("YYYY-MM-DD"));

      const todaysTasks = taskOrder[moment().format("YYYY-MM-DD")].order
        .map((taskId) => tasks[taskId])
        .filter((task) => task);

      setTodaysTasks(todaysTasks);

      const labelBreakdownData = getLabelBreakdownData(todaysTasks);

      console.log(labelBreakdownData);

      setLabelBreakdownData(labelBreakdownData);
    } catch {
      console.log("No tasks from yesterday");
    }
  }, [tasks, taskOrder]);

  const tomorrowDateKey = moment().add(1, "days").format("YYYY-MM-DD");

  // Calculate the date for the next Monday
  const now = moment();
  const nextMonday = now.day() <= 1 ? now.day(1 + 7) : now.day(1 + 14);
  const nextMondayDateKey = nextMonday.format("YYYY-MM-DD");

  return (
    <PlannerContainer
      sidebarContent={
        <div
          className="flex flex-col gap-5 justify-start"
          style={{ WebkitAppRegion: "no-drag" }}
        >
          <div className="flex flex-col gap-2 items-start">
            <div className="inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline bg-violet-500/15 text-violet-700 group-data-[hover]:bg-violet-500/25 dark:text-violet-400 dark:group-data-[hover]:bg-violet-500/20">
              Step 3
            </div>
            <div className="text-xl font-semibold">Can anything wait?</div>
          </div>

          {getTooltipMessage(totalEstimatedTime, work_threshold)}

          <div className="flex flex-col gap-4 justify-start rounded-lg ">
            <div className="flex flex-row gap-2 items-center justify-between">
              <div>{todaysTasks.filter((task) => task).length} tasks</div>
              <div
                className={`${
                  totalEstimatedTime / 3600 > work_threshold
                    ? "text-red-600 underline font-semibold"
                    : totalEstimatedTime / 3600 >= work_threshold - 2
                    ? " text-yelloow-600 underline font-semibold"
                    : ""
                }`}
              >
                {formatTime(totalEstimatedTime)} total time{" "}
              </div>
            </div>

            {/* Single bar chart showing a breakdown of time spent */}

            <div className="flex w-full bg-gray-200 rounded-md overflow-hidden">
              {Object.entries(labelBreakdownData)
                .sort(([labelIdA], [labelIdB]) => {
                  if (labelIdA === "no_label") return 1;
                  if (labelIdB === "no_label") return -1;
                  return 0;
                })
                .map(([labelId, data]) => (
                  <Tooltip
                    key={labelId}
                    title={`${labels[labelId]?.name || "No label"} ${(
                      (data.estimated_time / totalEstimatedTime) *
                      100
                    ).toFixed(1)}%`}
                  >
                    <div
                      style={{
                        width: `${(
                          (data.estimated_time / totalEstimatedTime) *
                          100
                        ).toFixed(1)}%`,
                        backgroundColor: labels[labelId]?.color || "gray",
                        height: "10px",
                      }}
                    />
                  </Tooltip>
                ))}
            </div>

            <div className="flex flex-col gap-2">
              {Object.keys(labelBreakdownData).map((labelId) => {
                // If labelId is "no_label", use "No Label" instead
                const label = labels[labelId];

                return (
                  <div
                    key={labelId}
                    className="flex flex-row gap-2 items-center justify-between"
                  >
                    <div className="flex flex-row gap-2 items-center text-sm">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{
                          backgroundColor: label?.color || "gray",
                        }}
                      />
                      {label?.name || "No Label"}
                    </div>
                    <div className="text-neutral-400">
                      {formatTime(labelBreakdownData[labelId].estimated_time)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
      mainContent={
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-0">
            <MiniKanbanView
              key={todayDateKey}
              orderEditable={taskOrderEditable?.[todayDateKey]?.order}
              date={todayDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">Today</div>
                  <div className="text-sm text-neutral-500">Add some tasks</div>
                </div>
              }
              maxWidth={300}
            />
          </div>
          <div className="flex flex-col gap-0 overflow-y-scroll">
            <MiniKanbanView
              key={tomorrowDateKey}
              orderEditable={taskOrderEditable?.[tomorrowDateKey]?.order}
              date={tomorrowDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">Tomorrow</div>
                  <div className="text-sm text-neutral-500">
                    Add tasks that can wait
                  </div>
                </div>
              }
              maxWidth={300}
            />
          </div>
          <div className="flex flex-col gap-0 overflow-y-scroll">
            <MiniKanbanView
              key={nextMondayDateKey}
              orderEditable={taskOrderEditable?.[nextMondayDateKey]?.order}
              date={nextMondayDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">Next week</div>
                  <div className="text-sm text-neutral-500">
                    Add tasks that can wait
                  </div>
                </div>
              }
              maxWidth={300}
            />
          </div>
        </div>
      }
      backButton={<BackButton onClick={() => setStep(1)} />}
      nextButton={
        <NextButton
          onClick={() => {
            setStep(3);
          }}
        />
      }
    />
  );
}

import React, { useState } from "react";
import { EventModal } from "./EventModal";
import { Popover } from "antd";
import moment from "moment";
import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";

export default function GoogleCalendarEvent({
  googleEvent,
  event,
  eventObject,
  textColor,
  calendarView,
}) {
  const [eventPopupActive, setEventPopupActive] = useState(null);

  const durationLessThan15Minutes =
    moment(event.end).diff(moment(event.start), "minutes") <= 15;

  const googleEventData = useSelector(
    (state) => state.calendar.googleEventData?.[googleEvent.id]
  );

  if (!googleEventData) {
    return null;
  }

  function getAttendanceStatus() {
    if (googleEventData?.attendees) {
      const attendee = googleEventData.attendees.find(
        (attendee) => attendee.self
      );

      if (attendee) {
        return attendee.responseStatus;
      }
    }

    return null;
  }

  function isNotAttending() {
    return getAttendanceStatus() === "declined";
  }

  const isAllDayEvent =
    moment(googleEvent.start).isSame(
      moment(googleEvent.end).subtract(1, "day"),
      "day"
    ) || moment(googleEvent.end).diff(moment(googleEvent.start), "hours") > 24;

  function customTimeFormat(date) {
    const momentDate = moment(date);
    if (momentDate.minutes() === 0) {
      return momentDate.format("ha");
    } else {
      return momentDate.format("h:mma");
    }
  }

  return (
    <Popover
      content={
        <EventModal
          googleEventId={googleEvent.id}
          setEventPopupActive={setEventPopupActive}
        />
      }
      placement="left"
      title={null}
      trigger="click"
      open={eventPopupActive === event.id && eventPopupActive !== null}
      onOpenChange={(open) => {
        if (open) {
          setEventPopupActive(event.id);
        } else {
          setEventPopupActive(null);
        }
      }}
    >
      {calendarView !== "month" ? (
        <div
          className={`event-content ${
            durationLessThan15Minutes ? "small-text" : ""
          } ${isNotAttending() ? "not-attending" : ""}`}
        >
          <div
            className="event-meta"
            style={{
              color: textColor,
            }}
          >
            <div className={`event-title`}>{googleEvent.title}</div>
            <div className="event-time">{eventObject.timeText}</div>
          </div>
          <FcGoogle
            className={`event-complete-check ${
              durationLessThan15Minutes ? "small" : ""
            }`}
            style={{
              color: eventObject.textColor,
            }}
          />
        </div>
      ) : (
        //full month calendar view
        <div
          className={`overflow-hidden event-content w-full ${
            durationLessThan15Minutes ? "small-text" : ""
          } ${isNotAttending() ? "not-attending" : ""}`}
        >
          <div className="flex flex-row items-center gap-1 overflow-hidden text-neutral-700 dark:text-neutral-300 px-1">
            {!isAllDayEvent && (
              <div class="flex justify-center items-center h-1 px-0.5">
                <div
                  class="h-2.5 w-1 rounded-full"
                  style={{
                    backgroundColor: textColor,
                  }}
                ></div>
              </div>
            )}
            {!isAllDayEvent && (
              <div className="event-time">
                {" "}
                {eventObject.timeText ||
                  (event.start && customTimeFormat(event.start))}
              </div>
            )}
            <div className="event-title ph-masktruncate pl-0.5">
              {googleEvent.title}
            </div>
          </div>
        </div>
      )}
    </Popover>
  );
}

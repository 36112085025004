import { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Papa from "papaparse";
import sunsamaLogo from "../../images/sunsama-logo.svg";
import { set } from "lodash";
import MapSunsama from "./UploadSunsama";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import SettingsBackButton from "../Generics/SettingsBackButton";

export default function ImportSunsama({ setSunamaImportActive }) {
  const [hasUploaded, setHasUploaded] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [mapSunsamaActive, setMapSunsamaActive] = useState(false);
  const [sunsamaData, setSunsamaData] = useState([]);
  const { Dragger } = Upload;
  const expectedFields = [
    "text",
    "notes",
    "assignee",
    "createdAt",
    "completedDate",
    "startDate",
    "dueDate",
    "subtasks",
    "channel",
    "context",
    "objective",
    "timeEstimate",
    "actualTime",
    "scheduledTime",
    "integration",
    "sequenceStart",
    "sequenceRule",
  ];
  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".csv",
    fileList: fileList,
    beforeUpload: (file) => {
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          setSunsamaData(results.data);
          const firstRow = results.data[0];
          const isFormatCorrect = expectedFields.every((field) =>
            Object.keys(firstRow).includes(field)
          );

          if (!isFormatCorrect) {
            message.error("Invalid CSV format. Export from Sunsama.");
            setFileList([
              {
                ...file,
                status: "error",
                name: file.name,
                uid: file.uid,
              },
            ]);
            setHasUploaded(false);
            return false;
          } else {
            setFileList([
              {
                ...file,
                status: "done",
                name: file.name,
                uid: file.uid,
              },
            ]);
            setMapSunsamaActive(true);
            return false;
          }
        },
      });
      return false;
    },

    onChange(info) {
      if (info.file.status !== "error") {
        setFileList(info.fileList);
        setHasUploaded(true);
      }
      const { status } = info.file;

      if (status !== "uploading") {
        return;
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      return;
    },
  };

  return (
    <div>
      {mapSunsamaActive && (
        <MapSunsama
          setMapSunsamaActive={setMapSunsamaActive}
          sunsamaData={sunsamaData}
        />
      )}
      {!mapSunsamaActive && (
        <div>
          <div className="mb-3 flex justify-between w-full">
            <SettingsBackButton
              onClick={() => {
                setSunamaImportActive(false);
              }}
            />
            <button
              onClick={() => setMapSunsamaActive(true)}
              disabled={!hasUploaded}
              className="back-button disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
              <ArrowRightCircleIcon className="back-button-icon pl-1 pr-0" />
            </button>
          </div>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Import CSV file</p>
            <p className="ant-upload-hint">
              Click or drag file to this area to upload
            </p>
          </Dragger>
          <div className="bg-[#F6F6F6] dark:bg-[#282828] flex flex-col my-8 p-4 gap-1 rounded-lg">
            <div className="flex gap-2 items-center">
              <img src={sunsamaLogo} alt="Sunsama Logo" />
              <div className="font-semibold pb-0.5 text-[0.9rem]">
                How to export your data from Sunsama
              </div>
            </div>
            <div className="text-sm">
              Go to Settings -{">"} Account Management and click “Export my
              data” to download a CSV
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

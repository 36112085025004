import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

function SettingsBackButton({ onClick, title, icon }) {
  return (
    <div onClick={onClick} className="back-button">
      {icon ? icon : <ArrowLeftCircleIcon className="back-button-icon" />}
      {title ? title : "Back"}
    </div>
  );
}

export default SettingsBackButton;

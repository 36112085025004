import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import { updateCurrentUser } from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Select, Switch } from "antd";
import { analytics, app_version } from "../../utils";
import {
  RiAppleFill,
  RiAppStoreFill,
  RiWindow2Fill,
  RiWindowFill,
  RiWindowsFill,
} from "react-icons/ri";

export default function DownloadApps() {
  return (
    <div>
      <div className="settings">
        <div className="download-containers">
          <div className="download-app-container">
            <div className="left">
              <div className="title">Desktop app</div>
              <div className="description">
                Quick access to Ellie directly from your desktop.
              </div>
              <div className="download-app-buttons">
                <div
                  onClick={() => {
                    analytics("Download Mac App", {
                      source: "Web",
                      type: "Intel",
                    });
                    window.open(`https://download.ellieplanner.com`);
                  }}
                  className="download-button"
                >
                  <RiAppleFill className="download-button-icon" />
                  <span>Mac App</span>
                </div>
                <div
                  onClick={() => {
                    analytics("Download Windows", {
                      source: "Web",
                      type: "Windows",
                    });
                    window.open(
                      `https://download.ellieplanner.com/windows/nsis/x64`
                    );
                  }}
                  className="download-button"
                >
                  <RiWindowsFill className="download-button-icon" />
                  <span>Windows app</span>
                </div>
              </div>
            </div>
            <div className="right mac">
              <img
                src={require("../../images/ellie-laptop.png")}
                alt="Ellie Mac App"
              />
            </div>
          </div>

          <div className="download-app-container">
            <div className="left">
              <div className="title">iOS app</div>
              <div className="description">
                Get the iOS app for your iPhone or iPad.
              </div>
              <div className="download-app-buttons">
                <div
                  onClick={() => {
                    analytics("Download iOS App", {
                      source: "Web",
                    });
                    window.open(
                      "https://apps.apple.com/us/app/ellie-daily-planner/id1602196200"
                    );
                  }}
                  className="download-button"
                >
                  <RiAppStoreFill className="download-button-icon" />
                  <span>App store</span>
                </div>
              </div>
            </div>
            <div className="right">
              <img
                src={require("../../images/iphone-screenshot.png")}
                alt="Ellie iOS App"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

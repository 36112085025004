import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { analytics } from "../../utils";
import {
  cancelListDeletion,
  proceedListDeletion,
} from "../../redux/tasksSlice";
import { useHotkeys } from "react-hotkeys-hook";

export default function UndoListDeletion() {
  const dispatch = useDispatch();
  const { listsToBeDeleted } = useSelector((state) => state.tasks);

  const listsToBeDeletedRef = useRef(listsToBeDeleted);
  listsToBeDeletedRef.current = listsToBeDeleted;

  const toastIdsRef = useRef({});

  // Store the timeout id in a ref so that it can be accessed and cleared in button onClick handlers
  const deletionTimeoutRefs = useRef({});

  useEffect(() => {
    listsToBeDeleted.forEach((list) => {
      if (!deletionTimeoutRefs.current[list.id]) {
        // Display the toast
        toastIdsRef.current[list.id] = toast("List Deleted", {
          description: `${list.title}`,
          action: {
            label: "Undo",
            onClick: () => {
              analytics("Undo deletion", {
                source: "Web",
                mode: "button",
              });
              clearTimeout(deletionTimeoutRefs.current[list.id]);
              delete deletionTimeoutRefs.current[list.id];
              dispatch(cancelListDeletion(list));
            },
          },
        });
        // Set the timeout for each task
        deletionTimeoutRefs.current[list.id] = setTimeout(() => {
          dispatch(proceedListDeletion(list));
        }, 5000);
      }
    });
    return () => {
      Object.keys(deletionTimeoutRefs.current).forEach((id) => {
        clearTimeout(deletionTimeoutRefs.current[id]); // Clear the timeout
      });
    };
  }, [listsToBeDeleted, dispatch]);

  useHotkeys(
    "cmd+z, ctrl+z",
    () => {
      const latestList =
        listsToBeDeletedRef.current[listsToBeDeletedRef.current.length - 1];
      if (latestList) {
        analytics("Undo deletion", {
          source: "Web",
          mode: "shortcut",
        });
        clearTimeout(deletionTimeoutRefs.current[latestList.id]);
        delete deletionTimeoutRefs.current[latestList.id];
        toast.dismiss(toastIdsRef.current[latestList.id]);
        delete toastIdsRef.current[latestList.id];
        dispatch(cancelListDeletion(latestList));
      }
    },
    {
      enabled: true,
    },
    [listsToBeDeletedRef.current]
  );

  return <></>;
}

import { configureStore } from "@reduxjs/toolkit";
import tasksReducer from "../src/redux/tasksSlice";
import labelsReducer from "../src/redux/labelsSlice";
import appReducer from "../src/redux/appSlice";
import calendarReducer from "../src/redux/calendarSlice";

export default configureStore({
  reducer: {
    tasks: tasksReducer,
    labels: labelsReducer,
    app: appReducer,
    calendar: calendarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

import React from "react";

import TopbarActions from "../Kanban/TopbarActions";

import styled from "@emotion/styled";

import "./Calendar.css";

import SendUpdatesModal from "./SendUpdatesModal";

import CustomFullCalendar from "./CustomFullCalendar";
import Timer from "../Timer";
import { useDispatch, useSelector } from "react-redux";
import { calculateHeightForZoomLevel, isMac } from "../../utils";
import { useDroppable } from "@dnd-kit/core";
import { setUpgradeVisible } from "../../redux/appSlice";
import { GoArrowRight } from "react-icons/go";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";

export default function WeekView() {
  const dispatch = useDispatch();

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const {
    active_calendar_type = "week",
    calendar_zoom_level = 100,
    floating_timer_enabled = false,
  } = useSelector((state) => state.app.currentUser);

  const { isOver, setNodeRef } = useDroppable({
    id: "CALENDAR",
  });

  return (
    <div className="kanban-board">
      <TopbarActions />

      <div ref={setNodeRef} className="weekview-container">
        <StyleCalendarWrapper
          monthMode={active_calendar_type === "month"}
          zoomLevel={calculateHeightForZoomLevel(calendar_zoom_level)}
        >
          <StyleCalendarWeekWrapper
            monthMode={active_calendar_type === "month"}
          >
            {active_calendar_type === "week" && (
              <CustomFullCalendar calendar_view="week" />
            )}
            {active_calendar_type === "day" && (
              <CustomFullCalendar calendar_view="day" />
            )}
            {active_calendar_type === "month" && (
              <CustomFullCalendar calendar_view="month" />
            )}
            {active_calendar_type === "timeGridTwoDay" && (
              <CustomFullCalendar calendar_view="timeGridTwoDay" />
            )}
            {active_calendar_type === "timeGridThreeDay" && (
              <CustomFullCalendar calendar_view="timeGridThreeDay" />
            )}
            {active_calendar_type === "timeGridFourDay" && (
              <CustomFullCalendar calendar_view="timeGridFourDay" />
            )}
            {active_calendar_type === "timeGridFiveDay" && (
              <CustomFullCalendar calendar_view="timeGridFiveDay" />
            )}
            {active_calendar_type === "timeGridSixDay" && (
              <CustomFullCalendar calendar_view="timeGridSixDay" />
            )}
          </StyleCalendarWeekWrapper>
        </StyleCalendarWrapper>
        {!subscriptionActive && (
          <div className="absolute bottom-0 w-full flex flex-row justify-center items-center z-10  my-2">
            <div className=" bg-white dark:bg-neutral-900 px-4 py-4 rounded-lg drop-shadow-xl border border-neutral-100 border-0.5 flex-col flex gap-2 dark:border-neutral-800">
              <div className="text-sm font-medium dark:text-white">
                Upgrade to unlock full timeboxing features ✨
              </div>
              <div
                className="flex items-center text-xs gap-1 text-purple-500 cursor-pointer hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-500"
                onClick={() => {
                  dispatch(setUpgradeVisible(true));
                }}
              >
                Learn more <GoArrowRight className="w-3 h-3" />
              </div>
            </div>
          </div>
        )}
      </div>
      {(!isDesktopApp() || !floating_timer_enabled) && <Timer />}
    </div>
  );
}

export const StyleCalendarWeekWrapper = styled.div`
  .fc-theme-standard td {
    border: none;
    border-bottom: none;

    ${(props) =>
      props.monthMode &&
      `  border-right: 0.5px solid var(--calendar-border-color) !important;
        border-top: 0.5px solid var(--calendar-border-color) !important;`}

    border-right: 0.5px solid var(--calendar-border-color);
    border-top: 0.5px solid var(--calendar-border-color);
  }

  @media (max-width: 767px) {
    .fc-theme-standard td {
      border-right: 1px solid var(--calendar-border-color);
      border-top: 1px solid var(--calendar-border-color);
    }
  }

  .fc .fc-timegrid-axis-frame {
    color: var(--calendar-sidebar-time-color);
  }

  height: 100%;
  width: 100%;
  background-color: var(--calendar-background-color);
`;

export const StyleCalendarWrapper = styled.div`
  .fc .fc-timegrid-axis-frame {
    color: var(--calendar-sidebar-time-color);
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    --ribbon-width: 4px;
    --ribbon-spacing: 1px;
    --z-index-background: 1;
    --z-index-ribbon: 2;
    --z-index-highlight-overlay: 3;
    --z-index-content: 3;
    --z-index-resize-handle: 5;
    --resize-handle-height: 4px;
    --resize-handle-width: 4px;
    --highlight-overlay-start-opacity: 0.35;
    --highlight-overlay-end-opacity: 0.25;
    --highlight-overlay-start-opacity-dark: 0.04;
    --highlight-overlay-end-opacity-dark: 0.03;
    position: absolute;
    border-color: var(--border-color);
    border-radius: var(--radius-m);
    border-width: 1px;
    padding: 2px 4px 1px 6px;
    opacity: 1;
    --background-color: rgb(255, 227, 200);
    --foreground-color-primary: rgb(163, 48, 0);
    --foreground-color-secondary: rgb(174, 69, 23);
    --ribbon-color-primary: rgb(254, 112, 50);
    --ribbon-color-secondary: rgb(254, 112, 50);
    --border-color: transparent;
    box-shadow: 0px 0px 0px 1px var(--calender-event-box-shadow-color);
    text-decoration-color: rgb(174, 69, 23);
  }

  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }

  .fc .fc-daygrid-day-number {
    color: var(--text-primary);
  }

  .fc-day-today {
    ${(props) =>
      !props.monthMode
        ? "background: transparent !important;"
        : "background: transparent !important"}
  }

  .fc-day-today .fc-daygrid-day-number {
    ${(props) =>
      props.monthMode &&
      `    background: #6b00ff;
      border-radius: 6px;
      /* color: white; */
      color: white;
      padding: 1px 4px;
      margin: 3px;`}
  }

  .fc .fc-timegrid-slot-minor {
    border-top-style: hidden;
  }

  .fc .fc-timegrid-slot-label {
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.04em;
    text-align: right;
    font-feature-settings: "tnum", "lnum", "ss01", "ss02", "ss03", "ss04";
    color: var(--calendar-sidebar-time-color);
    text-transform: uppercase;
    border-right: 0.5px solid var(--calendar-border-color);
  }

  .fc .fc-timegrid-slot {
    border-bottom: 0px;
    height: ${(props) => props.zoomLevel}em;
  }

  .fc-theme-standard td {
    // border: none;
    border-bottom: none;
    // border-right: none;
    border-top: 0.5px solid var(--calendar-border-color);
  }

  @media (max-width: 767px) {
    .fc-theme-standard td {
      border-top: 1px solid var(--calendar-border-color);
    }
  }

  .fc-theme-standard th {
    border: none;
  }

  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0px;
  }

  .fc .fc-timegrid-axis-cushion {
    max-width: 60px;
    flex-shrink: 0;
    font-size: 11px;
  }

  .fc-timegrid-divider fc-cell-shaded {
    border-bottom: none;
    border-top: none;
  }

  .fc .fc-cell-shaded,
  .fc .fc-day-disabled {
    border: none;
    padding: 0px !important;
    height: 0px;
    background: transparent;
  }

  height: 100%;
  width: 100%;

  .fc .fc-timegrid-slot-label {
    vertical-align: top;
    padding-top: 0.5em;
    border-right: 0.5px solid var(--calendar-border-color);
  }

  @media (max-width: 767px) {
    .fc .fc-timegrid-slot-label {
      border-right: 1px solid var(--calendar-border-color);
    }
  }

  ${(props) =>
    props.todayMode
      ? "background-color: transparent;"
      : "background-color: var(--calendar-background-color);"}
`;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { getAuth } from "firebase/auth";

import axios from "axios";
import { usersServerUrl } from "../../utils";

export default function CannyAuth() {
  const [userInfo, setUserInfo] = useState(null);
  const [ssoToken, setSsoToken] = useState(null);

  const { name } = useSelector((state) => state.app.currentUser);

  function getQueryParameterByName(name) {
    var pairStrings = window.location.search.slice(1).split("&");
    var pairs = pairStrings.map(function (pair) {
      return pair.split("=");
    });
    return pairs.reduce(function (value, pair) {
      if (value) return value;
      return pair[0] === name ? decodeURIComponent(pair[1]) : null;
    }, null);
  }

  function getRedirectURL(ssoToken) {
    var redirectURL = getQueryParameterByName("redirect");
    var companyID = getQueryParameterByName("companyID");
    if (redirectURL.indexOf("https://") !== 0 || !companyID) {
      return null;
    }

    return (
      "https://canny.io/api/redirects/sso?companyID=" +
      companyID +
      "&ssoToken=" +
      ssoToken +
      "&redirect=" +
      redirectURL
    );
  }

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    setUserInfo({
      name: name || "No name",
      email: user.email,
      id: user.uid,
    });
  }, [name]);

  useEffect(() => {
    if (userInfo) {
      axios
        .get(`${usersServerUrl}/createCannyToken`, {
          params: {
            userInfo: userInfo,
          },
          headers: {
            "Content-Type": "application/json",

            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          if (response.data) {
            setSsoToken(response.data.token);
          }
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (ssoToken) {
      var redirectURL = getRedirectURL(ssoToken);
      if (redirectURL) {
        window.location.assign(redirectURL);
      }
    }
  }, [ssoToken]);

  return <div>redirecting...</div>;
}

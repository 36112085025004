import React from "react";
import { FiZoomIn, FiZoomOut, FiDownload, FiX } from "react-icons/fi";

const Lightbox = ({
  isOpen,
  onClose,
  currentAttachment,
  zoom,
  onZoomIn,
  onZoomOut,
  onDownload,
}) => {
  if (!isOpen || !currentAttachment) return null;

  const handleBackgroundClick = (e) => {
    onClose();
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="relative w-full h-full flex flex-col items-center justify-center">
        <img
          src={currentAttachment.url}
          alt={currentAttachment.name}
          className="max-w-full max-h-[calc(100%-40px)] object-contain"
          style={{ transform: `scale(${zoom / 100})` }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="absolute w-64 bottom-5 rounded-full  bg-black bg-opacity-75 text-white py-1 px-4 flex justify-between items-center"
        >
          <div className="flex items-center space-x-1">
            <button
              className="p-1 rounded-sm bg-transparent hover:bg-gray-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                onZoomOut();
              }}
            >
              <FiZoomOut size={20} />
            </button>
            <div className="bg-transparent px-2 py-0.5 text-sm">
              <span className="text-neutral-300">{zoom}%</span>
            </div>
            <button
              className="p-1 rounded-sm bg-transparent hover:bg-gray-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                onZoomIn();
              }}
            >
              <FiZoomIn size={20} />
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="p-1 rounded-sm bg-transparent hover:bg-gray-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                onDownload();
              }}
            >
              <FiDownload size={20} />
            </button>
            <button
              className="p-1 rounded-sm bg-transparent hover:bg-gray-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <FiX size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lightbox;

import { useState } from "react";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import "./LabelSelector.css";

export default function LabelSelectorMini({
  onLabelSelect,
  children,
  excludeLabels,
  setLabelPickerActive,
  labelPickerActive,
}) {
  const { data: labels } = useSelector((state) => state.labels);

  return (
    <Popover
      open={labelPickerActive}
      onOpenChange={(visible) => {
        setLabelPickerActive(visible);
      }}
      title={null}
      content={
        labelPickerActive ? (
          <LabelSelectorContent
            labels={
              excludeLabels
                ? Object.values(labels).filter(
                    (label) => !excludeLabels.includes(label.id)
                  )
                : labels
            }
            onLabelSelect={onLabelSelect}
            setLabelPickerActive={setLabelPickerActive}
          />
        ) : null
      }
      placement="bottom"
      trigger="click"
    >
      {children}
    </Popover>
  );
}

function LabelSelectorContent({ labels, onLabelSelect, setLabelPickerActive }) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className="label-selector-container"
      data-no-dnd="true"
    >
      <div className="label-selector-search">
        <MagnifyingGlassIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
        />
      </div>
      <div className="label-selector-options ph-mask">
        {Object.values(labels)
          .filter((label) =>
            label.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((labelOption) => (
            <Label
              key={labelOption.id}
              labelOption={labelOption}
              onLabelSelect={onLabelSelect}
              setLabelPickerActive={setLabelPickerActive}
            />
          ))}
      </div>
    </div>
  );
}

function Label({ labelOption, onLabelSelect, setLabelPickerActive }) {
  return (
    <div
      onClick={() => {
        onLabelSelect(labelOption.id);
        setLabelPickerActive(false);
      }}
      className="label-selector-option"
    >
      <div
        className="label-box ph-mask"
        style={{ backgroundColor: labelOption.color }}
      />
      <span> {labelOption.name}</span>
    </div>
  );
}

// import {
//   floatingTimerBackgroundCategories,
//   floatingTimerBackgroundImages,
// } from "../../utils";

import { FaPause } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentUser } from "../../redux/appSlice";

export const floatingTimerBackgroundImages = {
  color_black: {
    background: "#000000",
    color: "#ffffff",
    type: "color",
  },
  white: {
    background: "#ffffff",
    color: "#000000",
    type: "color",
  },
  red: {
    background: "#EF4444",
    color: "#ffffff",
    type: "color",
  },
  orange: {
    background: "#F59E0B",
    color: "#ffffff",
    type: "color",
  },
  yellow: {
    background: "#FCD34D",
    color: "#000000",
    type: "color",
  },
  green: {
    background: "#10B981",
    color: "#ffffff",
    type: "color",
  },
  blue: {
    background: "#3B82F6",
    color: "#ffffff",
    type: "color",
  },
  purple: {
    background: "#8B5CF6",
    color: "#ffffff",
    type: "color",
  },
  clouds: {
    image:
      "https://images.unsplash.com/photo-1529697216570-f48ef8f6b2dd?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Sky, Earth",
    type: "image",
  },
  kolkata: {
    image:
      "https://images.unsplash.com/photo-1496372412473-e8548ffd82bc?q=80&w=3114&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Kolkata, India",
    type: "image",
  },
  nyc: {
    image:
      "https://images.unsplash.com/photo-1568515387631-8b650bbcdb90?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Central Park, NYC",
    type: "image",
  },
  kyoto: {
    image:
      "https://images.unsplash.com/photo-1545569341-9eb8b30979d9?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Kyoto, Japan",
    type: "image",
  },
  menton: {
    image:
      "https://plus.unsplash.com/premium_photo-1688137881227-91b6322c5fe3?q=80&w=3126&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Menton, France",
    type: "image",
  },
  seoul: {
    image:
      "https://images.unsplash.com/photo-1595185515922-9b79af1ef52d?q=80&w=2972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Seoul, South Korea",
    type: "image",
  },
  el_nido: {
    image:
      "https://images.unsplash.com/photo-1531761535209-180857e963b9?q=80&w=2583&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "El Nido, Philippines",
    type: "image",
  },
  jelly_fish: {
    image:
      "https://images.unsplash.com/photo-1520634996521-d0985c84316a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MjB8fHxlbnwwfHx8fHw%3D",
    color: "#ffffff",
    name: "Jelly Fish",
    type: "image",
  },
  wolf: {
    image:
      "https://images.unsplash.com/photo-1474511320723-9a56873867b5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YW5pbWFsfGVufDB8fDB8fHww",
    color: "#ffffff",
    name: "Wolf",
    type: "image",
  },
  shiba: {
    image:
      "https://images.unsplash.com/photo-1622435242389-8f6561672b23?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Shiba Inu",
    type: "image",
  },
  gold_fish: {
    image:
      "https://images.unsplash.com/photo-1535591273668-578e31182c4f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fGFuaW1hbHxlbnwwfDB8MHx8fDA%3D",
    color: "#ffffff",
    name: "Gold Fish",
    type: "image",
  },
  corgi: {
    image:
      "https://images.unsplash.com/photo-1554692844-6627ca340264?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D",
    color: "#ffffff",
    name: "Corgi",
    type: "image",
  },
  luna_1: {
    image: "https://ellie-public-files.s3.amazonaws.com/luna2.jpeg",
    color: "#ffffff",
    name: "Sleepy Luna",
    type: "image",
  },
  pug: {
    image:
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?q=80&w=3024&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Pug",
    type: "image",
  },
  kitten_1: {
    image:
      "https://images.unsplash.com/photo-1607242792481-37f27e1d74e1?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Kitten (walking)",
    type: "image",
  },
  kitten_2: {
    image:
      "https://images.unsplash.com/photo-1581300134629-4c3a06a31948?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Kitten (green)",
    type: "image",
  },
  cherry_blossoms: {
    image:
      "https://images.unsplash.com/photo-1462275646964-a0e3386b89fa?q=80&w=3028&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Cherry Blossoms",
    type: "image",
  },
  tulips: {
    image:
      "https://images.unsplash.com/photo-1709836726851-e1eed2756fb5?q=80&w=3086&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Tulips",
    type: "image",
  },
  jungle: {
    image:
      "https://images.unsplash.com/photo-1470058869958-2a77ade41c02?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Jungle",
    type: "image",
  },
  geranium: {
    image:
      "https://images.unsplash.com/photo-1669007341539-08fa1d49f46e?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Geranium",
    type: "image",
  },
  lavender: {
    image:
      "https://images.unsplash.com/photo-1533162606129-460b494b3e47?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Lavender",
    type: "image",
  },
  neon_space: {
    image:
      "https://images.unsplash.com/photo-1563089145-599997674d42?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Neon Space",
    type: "image",
  },
  cooling_fire: {
    image:
      "https://images.unsplash.com/photo-1561212044-bac5ef688a07?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Cooling Fire",
    type: "image",
  },
  molten_sea: {
    image:
      "https://images.unsplash.com/photo-1604871000636-074fa5117945?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Molten Sea",
    type: "image",
  },
  liquid_sand: {
    image:
      "https://images.unsplash.com/photo-1583444012262-00185bf33cc6?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Liquid Sand",
    type: "image",
  },
  neon_water: {
    image:
      "https://images.unsplash.com/photo-1597177216409-a474906dfea4?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ffffff",
    name: "Neon Water",
    type: "image",
  },
};

export const floatingTimerBackgroundCategories = [
  {
    name: "Solid colors",
    images: [
      "color_black",
      "white",
      "red",
      "orange",
      "yellow",
      "green",
      "blue",
      "purple",
    ],
    id: "colors",
  },
  {
    name: "Places",
    images: ["clouds", "kolkata", "nyc", "kyoto", "menton", "seoul", "el_nido"],
    id: "places",
  },
  {
    name: "Animals",
    images: [
      "luna_1",
      "corgi",
      "pug",
      "kitten_1",
      "kitten_2",
      "shiba",
      "jelly_fish",
      "wolf",
      "gold_fish",
    ],
    id: "animals",
  },
  {
    name: "Plants",
    images: ["cherry_blossoms", "tulips", "jungle", "geranium", "lavender"],
    id: "flowers",
  },
  {
    name: "Abstract",
    images: [
      "neon_space",
      "cooling_fire",
      "molten_sea",
      "liquid_sand",
      "neon_water",
    ],
    id: "abstract",
  },
];

export default function TimerBackgroundGalery() {
  const { floating_timer_background = "color_black" } = useSelector(
    (state) => state.app.currentUser
  );

  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-2 items-start w-full overflow-hidden pb-[15px]">
      <div className="flex flex-col gap-2 items-start w-full px-[30px] pb-[25px]">
        <div className="text-base font-medium">Timer Preview</div>
        <div className="rounded-xl overflow-hidden shadow-md">
          <div
            className={`relative h-[65px] w-[200px] bg-cover bg-center overflow-hidden font-mono font-medium`}
            style={{
              backgroundImage: `${
                floatingTimerBackgroundImages[floating_timer_background].image
                  ? `url('${floatingTimerBackgroundImages[floating_timer_background].image}')`
                  : ""
              }`,
              WebkitAppRegion: "drag",
              backgroundColor: `${
                floatingTimerBackgroundImages[floating_timer_background]
                  .background || "#202020"
              }`,
              color: `${
                floatingTimerBackgroundImages[floating_timer_background]
                  .color || "#ffffff"
              }`,
            }}
          >
            <div
              className={`absolute inset-0  ${
                floatingTimerBackgroundImages[floating_timer_background].type === "color"
                  ? ""
                  : "bg-black"
              }
            bg-opacity-45`}
            ></div>
            <div className="flex items-center absolute inset-0 px-3">
              <div className="cursor-pointer flex flex-row gap-2  items-center absolute right-3 z-40">
                <div
                  className={`rounded-full cursor-pointer bg-white text-neutral-600 shadow-md p-[7px] text-[13px] hover:shadow-lg hover:bg-neutral-200 transition duration-100 ease-in-out ${
                    floating_timer_background === "color_black"
                      ? "dark:border-neutral-300 dark:border"
                      : ""
                  }`}
                >
                  <FaPause />
                </div>
              </div>
              <div className="w-full flex flex-col gap-0 items-start">
                <div className="flex items-center justify-between w-full">
                  <span className="text-[22px]">01:14:58</span>
                </div>
                <div
                  className={`text-xs w-full overflow-hidden whitespace-nowrap font-sans overflow-ellipsis`}
                >
                  Respond to emails
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Border */}
      <div className="border-t border-neutral-200 w-full mb-2 dark:border-neutral-600" />

      {floatingTimerBackgroundCategories.map((category) => {
        return (
          <div
            className="flex flex-col gap-2 items-start w-full"
            key={category.id}
          >
            <div className="text-base font-medium px-[30px]">
              {category.name}
            </div>
            <div className="flex w-full overflow-x-scroll flex-row gap-4 pb-3 pl-[30px]">
              {category.images.map((id, index) => {
                const background = floatingTimerBackgroundImages[id];

                if (!background) {
                  return null;
                }

                // if background.background, then it's a color
                if (background.background) {
                  return (
                    <div
                      onClick={() => {
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              floating_timer_background: id,
                            },
                            previousValues: {
                              floating_timer_background,
                            },
                          })
                        );
                      }}
                      key={id}
                      style={{ backgroundColor: background.background }}
                      className={`rounded-full w-[40px] h-[40px] cursor-pointer 
                          ${
                            floating_timer_background === id
                              ? "border-[3px] !border-purple-600"
                              : "border-2 border-transparent"
                          } 

                          ${
                            id === "white" ? "border border-black" : ""
                          }
                          hover:shadow-lg hover:border-[3px] hover:border-purple-400 transition duration-50 ease-in-out`}
                    />
                  );
                }

                return (
                  <div
                    onClick={() => {
                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            floating_timer_background: id,
                          },
                          previousValues: {
                            floating_timer_background,
                          },
                        })
                      );
                    }}
                    className="flex flex-col gap-2 items-center"
                  >
                    <img
                      key={id}
                      src={background.image}
                      alt={category.name}
                      className={`rounded-xl w-[150px] min-w-[150px] max-w-[150px] h-[60px] object-cover cursor-pointer
                      ${
                        floating_timer_background === id
                          ? "border-[3px] border-purple-600 p-0.5"
                          : "border-2 border-transparent"
                      }
                      hover:shadow-lg hover:border-[3px] hover:p-0.5 hover:border-purple-400 transition duration-50 ease-in-out`}
                    />
                    <div className="text-xs font-medium text-neutral-600 dark:text-neutral-300">
                      {background.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

import { Modal } from "antd";
import axios from "axios";
import { get, set } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icloudLogo from "../../images/iCloud_logo.png";
import { Button, message } from "antd";
import { toast } from "sonner";

import CryptoJS from "crypto-js";
import { th } from "date-fns/locale";
import { toggleSettings } from "../../redux/appSlice";
import { getApp } from "firebase/app";

import { analytics, googleServerUrl } from "../../utils";

export default function AddAppleCalModal({
  open,
  setOpen,
  isInSettings,
  setParentModalActive,
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [calendarAdded, setCalendarAdded] = useState(false);

  const userId = useSelector((state) => state.app.uid);

  const dispatch = useDispatch();

  const { ota_secret_key } = useSelector((state) => state.app.currentUser);

  function hexToBytes(hex) {
    const bytes = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
      bytes[i / 2] = parseInt(hex.substring(i, i + 2), 16);
    }
    return bytes;
  }

  useEffect(() => {
    if (ota_secret_key && ota_secret_key.length != "" && calendarAdded) {
      // Get the Uint8Array representation
      const byteArray = hexToBytes(ota_secret_key);

      // Convert the Uint8Array to a WordArray
      const wordArray = CryptoJS.lib.WordArray.create(byteArray);

      const secretKey = wordArray;

      toast.promise(getAppleCalendarAccount(secretKey), {
        loading: "Connecting to iCloud server...",
        success: `Successfully connected Apple Calendars!`,
        error: "Unable to connect to iCloud. Please try again.",
      });
    }
  }, [ota_secret_key, password, calendarAdded]);

  const encrypt = (text, secretKey) => {
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const cipher = CryptoJS.AES.encrypt(text, secretKey, { iv: iv });

    return (
      iv.toString(CryptoJS.enc.Hex) +
      ":" +
      // convert the cipher text to base64 instead of hex
      cipher.ciphertext.toString(CryptoJS.enc.Base64)
    );
  };

  // Async function to add a temporary OTA key to the user
  const addTempOTAKeyToUser = async () => {
    try {
      const res = await axios.post(
        `${googleServerUrl}/addTempOTAKeyToUser?userId=${userId}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  /// Async function to get the Apple Calendar account
  const getAppleCalendarAccount = async (secretKey) => {
    try {
      const encryptedPassword = encrypt(password, secretKey);

      const data = {
        userId: userId,
        username: username,
        password: encryptedPassword,
      };

      const res = await axios.post(
        `${googleServerUrl}/getAppleCalendarAccount`,
        data
      );

      analytics("Calendar linked", {
        source: "Web",
        type: "Apple",
      });

      setCalendarAdded(false);
      setLoading(false);
      setOpen(false);
      if (setParentModalActive) {
        setParentModalActive(false);
      }
      // toast.success(`Successfully connected Apple Calendars!`);
      if (isInSettings) {
        dispatch(toggleSettings());
      }
    } catch (error) {
      setCalendarAdded(false);
      console.log("ERROR");
      // toast.error(`Unable to connect to iCloud. Please try again.`);
      setLoading(false);
      throw error;
    }
  };

  const handleAppleCalConnect = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading at the beginning
    try {
      await addTempOTAKeyToUser(); // Wait for the promise to resolve
      setCalendarAdded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        title={null}
        footer={null}
        closable={false}
        maskClosable={true}
        zIndex={10002}
        onCancel={() => setOpen(false)}
        centered={true}
      >
        <div className="send-updates-modal">
          <div className="w-full flex justify-center">
            <img
              src={icloudLogo}
              alt="icloud logo"
              className="object-contain w-[2.5rem] h-[2.5rem] my-1"
            />
          </div>
          <div className="title text-center">iCloud</div>
          <div className="description text-center flex flex-col gap-1">
            <div>
              Apple requires an app-specific password to connect to iCloud.
            </div>
            <div>
              Generate an app-specific password at{" "}
              <span>
                <a
                  href="https://appleid.apple.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  https://appleid.apple.com/
                </a>
              </span>
            </div>
            <div>
              Check out our{" "}
              <span className="text-blue-500">
                <a
                  href="https://support.apple.com/en-us/HT204397"
                  target="_blank"
                  rel="noreferrer"
                >
                  tutorial about iCloud accounts
                </a>
              </span>{" "}
              for more information
            </div>
          </div>
          <form className="flex flex-col gap-8 px-2 my-10">
            <div className="flex flex-col gap-2">
              <label className="font-semibold">Email (Apple-ID)</label>
              <input
                type="text"
                className="px-1 py-1 border outline-none rounded-lg border-[#E1E1E1] dark:border-[#444444]  dark:bg-[#282828] dark:text-white"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-semibold">App-specific Password</label>
              <input
                type="password"
                className="px-1 py-1 border outline-none rounded-lg border-[#E1E1E1] dark:border-[#444444]  dark:bg-[#282828] dark:text-white"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </form>
          <div className="footer-buttons">
            <button
              onClick={() => {
                setOpen(false);
              }}
              className="button"
            >
              Cancel
            </button>
            <div className="right-side">
              <button
                onClick={(event) => {
                  handleAppleCalConnect(event);
                }}
                disabled={loading}
                className={`button ${loading ? "disabled" : "primary"}`}
              >
                Connect
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  formatDuration,
  generatePieChartOfLabelsAndEstimatedTimePerLabel,
  getNumberOfTasksCompletedThisPeriodAndLast,
  getTotalEstimatedTime,
} from "../AnalyticsFunctions";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { RiCactusFill, RiCactusLine } from "react-icons/ri";
import { Drawer } from "antd";
import TaskTable from "../TaskTable";
import { analytics } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);

export default function TimeSpentPieChartByEstimate({
  taskData,
  dateRange,
  userTheme,
}) {
  const { data: labels } = useSelector((state) => state.labels);

  const [totalEstimatedTimeThisPeriod, setTotalEstimatedTimeThisPeriod] =
    useState(0);
  const [labelBreakdownPieData, setLabelBreakdownPieData] = useState(null);

  useEffect(() => {
    const totalTime = getTotalEstimatedTime(taskData, dateRange.dates);

    const labelBreakdownPieData =
      generatePieChartOfLabelsAndEstimatedTimePerLabel(
        taskData,
        dateRange.dates,
        labels
      );

    setTotalEstimatedTimeThisPeriod(totalTime);
    setLabelBreakdownPieData(labelBreakdownPieData);
  }, [taskData, dateRange, labels]);

  const [selectedTasks, setSelectedTasks] = useState(null);

  return (
    <div
      className="chart"
      style={{
        gridArea: "pie-chart",
      }}
    >
      <Drawer
        title="Tasks"
        placement="right"
        closable={true}
        onClose={() => {
          setSelectedTasks(null);
        }}
        open={selectedTasks !== null}
        width={900}
        zIndex={10}
      >
        <TaskTable
          tasks={selectedTasks}
          labels={labels}
          showCompletionDate={true}
          showTimes={true}
        />
      </Drawer>

      <div className="chart-title">
        How you spent your time (by estimated time)
      </div>
      {labelBreakdownPieData && totalEstimatedTimeThisPeriod > 0 && (
        <Pie
          plugins={[ChartDataLabels]}
          options={{
            plugins: {
              title: {
                display: false,
                text: "How you spend your time each day",
              },
              tooltip: {
                filter: function (tooltipItem) {
                  return tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0;
                },
                callbacks: {
                  label: function (tooltipItems) {
                    // Let's get the value and convert it to hours and minutes

                    const rawSeconds =
                      tooltipItems.dataset.rawSecondsData[
                        tooltipItems.dataIndex
                      ];

                    // Use moment, convert seconds to a string formatted like "1 hour 30 minutes", hiding the minutes if there are none and the hours if there are none
                    return `${
                      tooltipItems.dataset.label[tooltipItems.dataIndex]
                    }: ${formatDuration(rawSeconds)}`;
                  },
                },
              },
              // Hide legend,
              legend: {
                display: true,
                // Set legend to the right side
                position: "bottom",
                // Change color
                labels: {
                  color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
                },
              },
              // Show the percentage of each pie slice
              datalabels: {
                display: true,
                formatter: function (value, context) {
                  // Return the percentage of each pie slice as a percentage

                  return (
                    Math.round((value / totalEstimatedTimeThisPeriod) * 100) +
                      "%" || ""
                  );
                },
                color: "white",
              },
            },
            responsive: true,
            interaction: {
              mode: "point",
              intersect: false,
            },
            onClick: (event, elements, chart) => {
              if (elements[0]) {
                // Get datasetindex
                const index = elements[0].index;

                // Get label
                const tasks = labelBreakdownPieData.datasetTaskData[index];

                analytics("Analytics drilldown", {
                  type: "time-spent-pie-chart",
                });

                setSelectedTasks(tasks);
              }
            },
          }}
          data={labelBreakdownPieData}
        />
      )}

      {totalEstimatedTimeThisPeriod === 0 && (
        <div className="no-data">
          <RiCactusLine className="icon" />
          <span>You have no tasks completed in this period.</span>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { toast } from "sonner";
import { set } from "lodash";
import { slackServerUrl } from "../../utils";
import PermissionModal from "./PermissionModal";

function Slack() {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.app.uid);
  const [modalStatus, setModalStatus] = useState("default");
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [title, setTitle] = useState(
    "Slack by Ellie would like permission to:"
  );
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setTitle("Authorization Cancelled ✋");
    setModalStatus("cancel");
    setTimeout(() => {
      navigate("/");
    }, 5000);
  };

  const handleAuthorizationClick = () => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      const state = queryParams.get("state");

      if (!code) {
        setError("No code provided");
        setModalStatus("error");
        setLoading(false);
        return;
      }

      fetch(
        `${slackServerUrl}/getAccessToken?code=${code}&userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setModalStatus("success");
          return response.json();
        } else {
          setLoading(false);
          setModalStatus("error");
          setError("Error in fetching access token. Please contact support");
          throw new Error(
            "Error in fetching access token. Please contact support"
          );
        }
      });
    } catch (error) {
      console.log("error", error);
      setError(error.message);
      setModalStatus("error");
      setLoading(false);
    }
  };

  var modalContent = null;

  if (modalStatus === "error") {
    modalContent = (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="text-xl font-semibold">Error 😵</div>
        <div>{error}</div>
      </div>
    );
  } else if (modalStatus === "success") {
    modalContent = (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="text-xl font-semibold text-center">
          Successfully connected slack workspace 🙌
        </div>
        <div>You can close out this window now!</div>
      </div>
    );
  } else if (modalStatus === "cancel") {
    modalContent = (
      <div className="w-full h-full flex flex-col gap-2 items-center justify-center">
        <div className="text-xl font-semibold">Authorization Cancelled ✋</div>
        <div>Redirecting you in Ellie homepage in 5 seconds</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center w-full h-screen ">
      <PermissionModal
        logo={require("../../images/Slack-mark-RGB.png")}
        name={"Slack"}
        scopes={[
          "Access Ellie data such as lists, labels and tasks",
          "Create and modify Ellie tasks",
        ]}
        modalContent={modalContent}
        authorizeAction={handleAuthorizationClick}
        cancelAction={handleCancel}
      />
    </div>
  );
}

export default Slack;

import "./Settings.css";
import React from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleSettings,
  toggleLabelManager,
  showPersonalizationSettings,
} from "../../redux/appSlice";

import SettingsContent from "./SettingsContent";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export default function Settings() {
  const { settingsVisible } = useSelector((state) => state.app);
  const { labelManagerVisible } = useSelector((state) => state.app);
  const { personalizationSettingsVisible } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [toolTipActiveForTab, setToolTipActiveForTab] = useState(null);

  // Hot key for "command + ," or "ctrl + ,
  useHotkeys(
    "command+,, ctrl+,",
    (event) => {
      event.preventDefault();

      if (toolTipActiveForTab != null) {
        setToolTipActiveForTab(null);
      }

      dispatch(toggleSettings());
    },
    []
  );

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={900}
      open={
        settingsVisible || labelManagerVisible || personalizationSettingsVisible
      }
      zIndex={1001}
      onOk={() => {
        if (toolTipActiveForTab != null) {
          setToolTipActiveForTab(null);
        }

        if (settingsVisible) {
          dispatch(toggleSettings());
        }

        if (labelManagerVisible) {
          dispatch(toggleLabelManager());
        }

        if (personalizationSettingsVisible) {
          dispatch(showPersonalizationSettings());
        }
      }}
      onCancel={() => {
        if (toolTipActiveForTab != null) {
          setToolTipActiveForTab(null);
        }

        if (settingsVisible) {
          dispatch(toggleSettings());
        }

        if (labelManagerVisible) {
          dispatch(toggleLabelManager());
        }

        if (personalizationSettingsVisible) {
          dispatch(showPersonalizationSettings());
        }
      }}
      destroyOnClose={true}
    >
      <SettingsContent
        toolTipActiveForTab={toolTipActiveForTab}
        setToolTipActiveForTab={setToolTipActiveForTab}
      />
    </Modal>
  );
}

import { Modal } from "antd";
import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { analytics } from "../../utils";
import { updateCurrentUser } from "../../redux/appSlice";

export default function DiscountModal({
  studentDiscountModalActive,
  setStudentDiscountModalActive,
}) {
  const [step, setStep] = useState(0);
  const [type, setType] = useState(null);

  const [email, setEmail] = useState(null);

  const [discountCode, setDiscountCode] = useState(null);

  const userId = useSelector((state) => state.app.uid);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(discountCode);
    toast.success(`Discount code copied to clipboard!`);
  };

  const dispatch = useDispatch();

  return (
    <Modal
      closable={false}
      footer={null}
      bodyStyle={{ padding: "0px" }}
      open={studentDiscountModalActive}
      onChange={() => {
        setStep(0);
        setEmail(null);
        setStudentDiscountModalActive(false);
      }}
      zIndex={10000}
      width={500}
      onCancel={() => {
        setStep(0);
        setEmail(null);
        setStudentDiscountModalActive(false);
      }}
    >
      <div className="p-6 flex flex-col gap-4">
        {step == 0 && (
          <>
            <div className="text-xl font-medium w-3/4">
              🤠 Are you a student, teacher or work at a non profit?
            </div>
            <div className="flex flex-col gap-2 items-start">
              <>
                <ChoiceButton
                  title="📚 Student"
                  action={() => {
                    setType("student");
                    setStep(1);
                  }}
                />
                <ChoiceButton
                  title="🍎 Teacher"
                  action={() => {
                    setType("teacher");
                    setStep(1);
                  }}
                />
                <ChoiceButton
                  title="🫶 Non profit"
                  action={() => {
                    setType("non_profit");
                    setStep(1);
                  }}
                />
              </>
            </div>
          </>
        )}

        {step == 1 && (
          <>
            <div
              className="text-base flex flex-row gap-1 items-center py-2 cursor-pointer hover:text-purple-600"
              onClick={() => setStep(0)}
            >
              <IoArrowBackOutline />
              <span>Back</span>
            </div>
            <>
              <div className="text-base font-medium">
                😎 Please provide{" "}
                {type == "student"
                  ? "your school email address"
                  : type == "teacher"
                  ? "your school email address"
                  : "the name of the non-profit"}
              </div>
              <div className="flex flex-row gap-2 items-center">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={
                    type == "student"
                      ? "School email address"
                      : type == "teacher"
                      ? "School email address"
                      : "Name of the non-profit"
                  }
                  className="border border-neutral-300 rounded-lg px-3 py-2 w-full"
                />
                <button
                  onClick={() => {
                    // Check if email is blank
                    if (!email) {
                      toast.error("Email cannot be blank");
                      return;
                    }

                    // Set discount code based on type
                    if (type == "student") {
                      setDiscountCode("AJF275JVB");
                    } else if (type == "teacher") {
                      setDiscountCode("AJF275JVB");
                    } else if (type == "non_profit") {
                      setDiscountCode("FJ25AJG92");
                    }

                    dispatch(
                      updateCurrentUser({
                        newValues: {
                          discount: {
                            type: type,
                            email: email,
                          },
                        },
                        previousValues: {},
                      })
                    );

                    analytics("discount_code_generated", {
                      type: type,
                      email: email,
                      user: userId,
                    });

                    setStep(2);
                  }}
                  className="bg-purple-600 text-white rounded-lg px-3 py-2"
                >
                  Submit
                </button>
              </div>
            </>
          </>
        )}

        {step == 2 && (
          <>
            <>
              <div className="text-base font-medium">
                🥳 I trust you, here is your discount code that you can use at
                checkout for any plan:
              </div>
              <div className="flex flex-row gap-2 items-center">
                <input
                  value={discountCode}
                  className="p-2 rounded-lg border w-15 dark:bg-neutral-800 dark:border-neutral-700"
                />
                <div className="button primary" onClick={copyToClipboard}>
                  Copy code
                </div>
              </div>
            </>
          </>
        )}
      </div>
    </Modal>
  );
}

function ChoiceButton({ title, action }) {
  return (
    <div
      className="cursor-pointer w-full items-center flex flex-row justify-between px-3 rounded-lg border border-neutral-300 shadow-sm text-base py-2 border-0.5 hover:border-purple-600"
      onClick={action}
    >
      {title}
      <FiChevronRight className="text-neutral-600" />
    </div>
  );
}

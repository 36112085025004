/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import "./Kanban.css";

import Labels from "../Labels";

import { useDispatch, useSelector } from "react-redux";
import { Switch, Popover, Tooltip } from "antd";

import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";

import { getOSPlatform } from "@todesktop/client-core/platform/os";

import {
  loadDatesFromStartDate,
  refreshKabanCursor,
  updateCurrentUser,
  toggleSettings,
  setUpgradeVisible,
  loadPreviousDates,
  loadNextDates,
  setSearchActive,
  setColumnSelected,
} from "../../redux/appSlice";

import { changeCalendarDate } from "../../redux/tasksSlice";
import { showPersonalizationSettings } from "../../redux/appSlice";

import moment from "moment";

import { analytics, getInitials, isElectron } from "../../utils";

import { useSpring, animated as a } from "react-spring";

import {
  ArrowRightIcon,
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/outline";
import { BiCheck } from "react-icons/bi";
import { RxCaretRight } from "react-icons/rx";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { TbCalendarEvent, TbLayoutKanban } from "react-icons/tb";

import MiniToolbar from "../Calendar/MiniToolbar";
import { BiGift, BiSearch } from "react-icons/bi";
import { CheckIcon } from "@heroicons/react/24/solid";
import SettingsMenu from "../Generics/SettingsMenu";
import { useHotkeys } from "react-hotkeys-hook";
export default function TopbarActions({}) {
  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");

  const [showLabels, setShowLabels] = useState(false);

  const date = useSelector((state) => state.tasks.calendarDate);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const {
    label_filters: activeLabels,
    hide_complete,
    hide_timeboxed,
    hide_sidebar,
    hide_calendar_sidebar,
    avatar_url,
    name,
    pro_meta,
    calendar_show_weekends = true,
    active_calendar_type = "week",
    show_declined_events = true,
    calendar_zoom_level = 100,
  } = useSelector((state) => state.app.currentUser);

  const { data: labels, loading } = useSelector((state) => state.labels);

  const dispatch = useDispatch();

  function isWindows() {
    if (isDesktopApp() && getOSPlatform() === "win32") return true;

    return false;
  }

  const goToNearestMonday = () => {
    const now = moment();
    const dayOfWeek = now.day();

    if (dayOfWeek === 0) {
      // Today is Sunday; go forward 1 day to the next Monday
      return now.add(1, "days").format("YYYY-MM-DD");
    } else if (dayOfWeek === 6) {
      // Today is Saturday; go forward 2 days to the next Monday
      return now.add(2, "days").format("YYYY-MM-DD");
    } else {
      // Today is already a weekday (Monday to Friday)
      return now.format("YYYY-MM-DD");
    }
  };

  function updateZoomLevel(delta) {
    analytics("Zoom level updated", {
      delta: delta,
    });

    var newZoomLevel = Math.min(Math.max(calendar_zoom_level + delta, 70), 140);
    dispatch(
      updateCurrentUser({
        newValues: { calendar_zoom_level: newZoomLevel },
        previousValues: { calendar_zoom_level: calendar_zoom_level },
      })
    );
  }

  const getNextDateIncrement = (calendarType) => {
    const increments = {
      month: { unit: "months", value: 1 },
      day: { unit: "days", value: 1 },
      timeGridTwoDay: { unit: "days", value: 2 },
      timeGridThreeDay: { unit: "days", value: 3 },
      timeGridFourDay: { unit: "days", value: 4 },
      timeGridFiveDay: { unit: "days", value: 5 },
      timeGridSixDay: { unit: "days", value: 6 },
    };

    return increments[calendarType] || { unit: "days", value: 7 };
  };

  const getPreviousDateDecrement = (calendarType) => {
    const decrements = {
      month: { unit: "months", value: 1 },
      day: { unit: "days", value: 1 },
      timeGridTwoDay: { unit: "days", value: 2 },
      timeGridThreeDay: { unit: "days", value: 3 },
      timeGridFourDay: { unit: "days", value: 4 },
      timeGridFiveDay: { unit: "days", value: 5 },
      timeGridSixDay: { unit: "days", value: 6 },
    };

    return decrements[calendarType] || { unit: "days", value: 7 };
  };

  useEffect(() => {
    if (
      labels &&
      activeLabels &&
      Object.keys(labels).length < activeLabels.length
    ) {
      const validActiveLabels = activeLabels.filter((labelId) =>
        Object.keys(labels).includes(labelId)
      );

      dispatch(
        updateCurrentUser({
          newValues: {
            label_filters: validActiveLabels,
          },
          previousValues: {
            label_filters: activeLabels,
          },
        })
      );
    }
  }, [labels]);

  function moveToToday() {
    if (mode == "kanban") {
      var newDate = calendar_show_weekends
        ? moment().format("YYYY-MM-DD")
        : goToNearestMonday();

      dispatch(
        changeCalendarDate({
          date: newDate,
        })
      );

      dispatch(
        loadDatesFromStartDate({
          date: Date(newDate).toString(),
          type: active_calendar_type,
          mode: mode,
        })
      );

      dispatch(refreshKabanCursor(newDate));
    } else if (mode == "calendar") {
      var newDate = calendar_show_weekends
        ? moment().format("YYYY-MM-DD")
        : goToNearestMonday();

      dispatch(
        changeCalendarDate({
          date: newDate,
        })
      );

      dispatch(
        loadDatesFromStartDate({
          date: Date(newDate).toString(),
          type: active_calendar_type,
          mode: mode,
        })
      );
    }
  }

  function handleCalendarTypeChange(type) {
    return (e) => {
      e.preventDefault();

      analytics("Calendar type changed", {
        type: type,
      });

      dispatch(
        updateCurrentUser({
          newValues: {
            active_calendar_type: type,
          },
          previousValues: {
            active_calendar_type: active_calendar_type,
          },
        })
      );
    };
  }

  // Keyboard shortcuts "D, W, M", shortcut
  useHotkeys(
    "d",
    handleCalendarTypeChange("day"),
    {
      enabled: mode === "calendar",
      enableOnTags: [],
    },
    []
  );

  useHotkeys(
    "w",
    handleCalendarTypeChange("week"),
    {
      enabled: mode === "calendar",
      enableOnTags: [],
    },
    []
  );

  useHotkeys(
    "m",
    handleCalendarTypeChange("month"),
    {
      enabled: mode === "calendar",
      enableOnTags: [],
    },
    []
  );

  useHotkeys(
    "shift+t",
    (e) => {
      e.preventDefault();

      moveToToday();

      // ... set up our own saving dialog.
    },
    {
      enabled: true,
    },
    []
  );

  return (
    <div
      className={`kanban-topbar ${
        (isElectron() || isDesktopApp()) && !isWindows() && hide_sidebar
          ? "electron"
          : ""
      }`}
    >
      <div className="topbar-left">
        {hide_sidebar ? (
          <ArrowSmallRightIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_sidebar: false,
                  },
                  previousValues: {
                    hide_sidebar: true,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        ) : (
          <ArrowSmallLeftIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_sidebar: true,
                  },
                  previousValues: {
                    hide_sidebar: false,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        )}

        {mode == "kanban" && (
          <>
            <div
              onClick={() => {
                moveToToday();
              }}
              className="topbar-button"
            >
              Today
            </div>
          </>
        )}

        {mode == "calendar" && (
          <>
            <div className="calendar-month-header">
              <span>{moment(date).format("MMM YYYY")}</span>
            </div>

            <div
              onClick={() => {
                moveToToday();
              }}
              className="topbar-button"
            >
              Today
            </div>

            <div className="week-navigation-buttons">
              <FiChevronLeft
                className="topbar-button nav-button"
                onClick={() => {
                  dispatch(
                    loadPreviousDates({
                      type: active_calendar_type,
                      mode: mode,
                    })
                  );

                  const { unit, value } =
                    getPreviousDateDecrement(active_calendar_type);
                  const previousDate = moment(date, "YYYY-MM-DD")
                    .subtract(value, unit)
                    .format("YYYY-MM-DD");
                  dispatch(changeCalendarDate({ date: previousDate }));
                }}
              />

              <FiChevronRight
                className="topbar-button nav-button"
                onClick={() => {
                  dispatch(
                    loadNextDates({
                      type: active_calendar_type,
                      mode: mode,
                    })
                  );

                  const { unit, value } =
                    getNextDateIncrement(active_calendar_type);
                  const nextDate = moment(date, "YYYY-MM-DD")
                    .add(value, unit)
                    .format("YYYY-MM-DD");
                  dispatch(changeCalendarDate({ date: nextDate }));
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className="topbar-right">
        <div className="topbar-actions">
          {subscriptionActive && pro_meta?.pro_status === "trial" && (
            <div
              onClick={() => {
                dispatch(setUpgradeVisible(true));
              }}
              className="topbar-button trial-button"
            >
              Free Trial (
              {moment(
                pro_meta?.pro_expiration_date?.toDate
                  ? pro_meta?.pro_expiration_date.toDate()
                  : pro_meta?.pro_expiration_date
              ).fromNow(true)}{" "}
              left)
            </div>
          )}

          {mode == "calendar" && <MiniToolbar />}

          <BiSearch
            className="collapse-icon"
            onClick={() => {
              dispatch(setSearchActive(true));
            }}
          />
          <Popover
            placement="bottom"
            trigger="click"
            zIndex={900}
            content={
              <div
                className="p-1 rounded-lg flex flex-col bg-[var(--modal-background-color)] shadow-md"
                style={{ minWidth: "220px" }}
              >
                <div
                  className={
                    "flex items-center " +
                    " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 dark:hover:bg-neutral-700 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                  }
                  onClick={() => {
                    analytics("Calendar type changed", {
                      type: "day",
                    });

                    dispatch(
                      updateCurrentUser({
                        newValues: {
                          active_calendar_type: "day",
                        },
                        previousValues: {
                          active_calendar_type: active_calendar_type,
                        },
                      })
                    );
                  }}
                >
                  Day
                  {active_calendar_type == "day" && (
                    <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                  )}
                </div>
                <div
                  className={
                    "flex items-center " +
                    " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 dark:hover:bg-neutral-700 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                  }
                  onClick={() => {
                    analytics("Calendar type changed", {
                      type: "week",
                    });

                    dispatch(
                      updateCurrentUser({
                        newValues: {
                          active_calendar_type: "week",
                        },
                        previousValues: {
                          active_calendar_type: active_calendar_type,
                        },
                      })
                    );
                  }}
                >
                  Week
                  {active_calendar_type == "week" && (
                    <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                  )}
                </div>
                <div
                  className={
                    "flex items-center " +
                    " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 dark:hover:bg-neutral-700 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                  }
                  onClick={() => {
                    analytics("Calendar type changed", {
                      type: "month",
                    });

                    dispatch(
                      updateCurrentUser({
                        newValues: {
                          active_calendar_type: "month",
                        },
                        previousValues: {
                          active_calendar_type: active_calendar_type,
                        },
                      })
                    );
                  }}
                >
                  Month
                  {active_calendar_type == "month" && (
                    <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                  )}
                </div>
                <hr class="border-t border-gray-200 my-1 dark:border-gray-700" />
                <div>
                  <Popover
                    placement="right"
                    trigger="click"
                    zIndex={900}
                    content={
                      <div
                        className="p-1 rounded-lg flex flex-col bg-[var(--modal-background-color)] shadow-md"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className={
                            "flex items-center dark:hover:bg-neutral-700 " +
                            (active_calendar_type == "timeGridTwoDay"
                              ? "bg-neutral-100 dark:bg-neutral-700"
                              : "") +
                            " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                          }
                          onClick={() => {
                            analytics("Calendar type changed", {
                              type: "timeGridTwoDay",
                            });

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  active_calendar_type: "timeGridTwoDay",
                                },
                                previousValues: {
                                  active_calendar_type: active_calendar_type,
                                },
                              })
                            );
                          }}
                        >
                          2 Days
                          {active_calendar_type == "timeGridTwoDay" && (
                            <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                          )}
                        </div>
                        <div
                          className={
                            "flex items-center dark:hover:bg-neutral-700 " +
                            (active_calendar_type ==
                            "timeGridThreeDay dark:bg-neutral-700"
                              ? "bg-neutral-100"
                              : "") +
                            " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                          }
                          onClick={() => {
                            analytics("Calendar type changed", {
                              type: "timeGridThreeDay",
                            });

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  active_calendar_type: "timeGridThreeDay",
                                },
                                previousValues: {
                                  active_calendar_type: active_calendar_type,
                                },
                              })
                            );
                          }}
                        >
                          3 Days
                          {active_calendar_type == "timeGridThreeDay" && (
                            <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                          )}
                        </div>
                        <div
                          className={
                            "flex items-center dark:hover:bg-neutral-700 " +
                            (active_calendar_type == "timeGridFourDay"
                              ? "bg-neutral-100 dark:bg-neutral-700"
                              : "") +
                            " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                          }
                          onClick={() => {
                            analytics("Calendar type changed", {
                              type: "timeGridFourDay",
                            });

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  active_calendar_type: "timeGridFourDay",
                                },
                                previousValues: {
                                  active_calendar_type: active_calendar_type,
                                },
                              })
                            );
                          }}
                        >
                          4 Days
                          {active_calendar_type == "timeGridFourDay" && (
                            <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                          )}
                        </div>
                        <div
                          className={
                            "flex items-center dark:hover:bg-neutral-700 " +
                            (active_calendar_type == "timeGridFiveDay"
                              ? "bg-neutral-100 dark:bg-neutral-700"
                              : "") +
                            " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                          }
                          onClick={() => {
                            analytics("Calendar type changed", {
                              type: "timeGridFiveDay",
                            });

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  active_calendar_type: "timeGridFiveDay",
                                },
                                previousValues: {
                                  active_calendar_type: active_calendar_type,
                                },
                              })
                            );
                          }}
                        >
                          5 Days (Hide weekends)
                          {active_calendar_type == "timeGridFiveDay" && (
                            <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                          )}
                        </div>
                        <div
                          className={
                            "flex items-center dark:hover:bg-neutral-700 " +
                            (active_calendar_type == "timeGridSixDay"
                              ? "bg-neutral-100 dark:bg-neutral-700"
                              : "") +
                            " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                          }
                          onClick={() => {
                            analytics("Calendar type changed", {
                              type: "timeGridSixDay",
                            });

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  active_calendar_type: "timeGridSixDay",
                                },
                                previousValues: {
                                  active_calendar_type: active_calendar_type,
                                },
                              })
                            );
                          }}
                        >
                          6 Days (Hide Sunday)
                          {active_calendar_type == "timeGridSixDay" && (
                            <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                          )}
                        </div>
                        <div
                          className={
                            "flex items-center dark:hover:bg-neutral-700 " +
                            (active_calendar_type == "week"
                              ? "bg-neutral-100 dark:bg-neutral-700"
                              : "") +
                            " p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                          }
                          onClick={() => {
                            analytics("Calendar type changed", {
                              type: "week (7 day)",
                            });

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  active_calendar_type: "week",
                                },
                                previousValues: {
                                  active_calendar_type: active_calendar_type,
                                },
                              })
                            );
                          }}
                        >
                          7 Days
                          {active_calendar_type == "week" && (
                            <BiCheck className="cursor-pointer text-gray-400 text-xl" />
                          )}
                        </div>
                      </div>
                    }
                  >
                    <div className="flex items-center p-1.5 rounded-md cursor-pointer justify-between hover:bg-neutral-100 dark:hover:bg-neutral-700 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark">
                      <span className="text-[13px]">Number of days</span>
                      <RxCaretRight className="cursor-pointer text-gray-400 text-xl" />
                    </div>
                  </Popover>
                </div>
                <div className="flex items-center p-1.5 rounded-md justify-between text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark">
                  Show declined events
                  {/* <Switch size="small" defaultChecked /> */}
                  <Switch
                    size="small"
                    style={{ marginLeft: "10px" }}
                    checked={show_declined_events}
                    onChange={(checked) => {
                      analytics("Show declined events toggled", {
                        location: "month-view",
                      });

                      dispatch(
                        updateCurrentUser({
                          newValues: { show_declined_events: checked },
                          previousValues: {
                            show_declined_events: show_declined_events,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <hr class="border-t border-gray-200 my-1 dark:border-gray-700" />
                <div className="flex items-center p-1.5 rounded-md justify-between text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark">
                  <span className="flex items-center gap-1">
                    Zoom
                    <span className="text-[12px] font-small leading-none align-baseline text-neutral-500 dark:text-textPrimaryDark">
                      {calendar_zoom_level}%
                    </span>
                  </span>
                  <span className="flex items-center gap-1">
                    <HiMinusSm
                      onClick={() => updateZoomLevel(-10)}
                      className="cursor-pointer text-gray-400 hover:text-neutral-800 text-xl"
                    />
                    <HiPlusSm
                      onClick={() => updateZoomLevel(10)}
                      className="cursor-pointer text-gray-400 hover:text-neutral-800 text-lg"
                    />
                  </span>
                </div>
                <hr class="border-t border-gray-200 my-1 dark:border-gray-700" />
                <div
                  onClick={() => {
                    analytics("Calendar settings clicked", {
                      location: "month-view",
                    });

                    dispatch(showPersonalizationSettings());
                  }}
                  className="dark:hover:bg-neutral-700 flex items-center p-1.5 mb-1 rounded-md cursor-pointer justify-between hover:bg-neutral-100 text-[13px] font-medium text-textPrimaryLight dark:text-textPrimaryDark"
                >
                  <span> Settings</span>
                  <RxCaretRight className="cursor-pointer text-gray-400 text-xl" />
                </div>
              </div>
            }
          >
            {mode === "calendar" && (
              <div
                onClick={() => {
                  setShowLabels(!showLabels);
                }}
                className={`topbar-button filter`}
              >
                {active_calendar_type == "month"
                  ? "Month"
                  : active_calendar_type == "day"
                  ? "Day"
                  : "Week"}
              </div>
            )}
          </Popover>
          <Popover
            placement="bottom"
            trigger="click"
            zIndex={900}
            content={
              <div className="w-[250px]">
                <Labels />

                <div className="flex flex-row gap-2 px-2 py-2">
                  <div
                    className={`flex-1 py-1 px-3 rounded-md font-medium text-[12px] flex flex-row gap-2 bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-600 shadow-sm items-center justify-center hover:bg-neutral-100/50 dark:hover:bg-neutral-800/40 dark:hover:text-white cursor-pointer`}
                    onClick={() => {
                      // If all labels are already active, remove them all
                      if (Object.keys(labels).length === activeLabels.length) {
                        // Update active labels for current user
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              label_filters: [],
                            },
                            previousValues: {
                              label_filters: activeLabels,
                            },
                          })
                        );
                      } else {
                        // Otherwise, add all labels
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              label_filters: Object.keys(labels),
                            },
                            previousValues: {
                              label_filters: activeLabels,
                            },
                          })
                        );
                      }
                    }}
                  >
                    Select all
                  </div>
                  <div
                    className={`flex-1 py-1 px-3 rounded-md font-medium text-[12px] flex flex-row gap-2 bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-600 shadow-sm items-center justify-center hover:bg-neutral-100/50 dark:hover:bg-neutral-800/40 dark:hover:text-white cursor-pointer`}
                    onClick={() => {
                      // If all labels are already active, remove them all
                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            label_filters: [],
                          },
                          previousValues: {
                            label_filters: activeLabels,
                          },
                        })
                      );
                    }}
                  >
                    Clear
                  </div>
                </div>
                <div className="pt-1 pb-1 px-3 font-medium text-[13px] flex flex-row gap-2 items-center justify-between">
                  Show Complete{" "}
                  <Switch
                    size="small"
                    checked={!hide_complete}
                    defaultChecked
                    onClick={() => {
                      // Update the current user's hide_complete value

                      analytics("Hide complete toggled", {
                        active: !hide_complete,
                      });

                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            hide_complete: !hide_complete,
                          },
                          previousValues: {
                            hide_complete: hide_complete,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="pt-1 pb-3 px-3 font-medium text-[13px] flex flex-row gap-2 items-center justify-between">
                  Show Timeboxed{" "}
                  <Switch
                    size="small"
                    checked={!hide_timeboxed}
                    defaultChecked
                    onClick={() => {
                      // Update the current user's hide_complete value

                      analytics("Hide timeboxed", {
                        active: !hide_timeboxed,
                      });

                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            hide_timeboxed: !hide_timeboxed,
                          },
                          previousValues: {
                            hide_timeboxed: hide_timeboxed,
                          },
                        })
                      );
                    }}
                  />
                </div>
              </div>
            }
          >
            <div
              onClick={() => {
                setShowLabels(!showLabels);
              }}
              className={`topbar-button filter 
                ${activeLabels?.length > 0 ? `active` : ""}`}
            >
              Filter
              {activeLabels?.length > 0 ? `(${activeLabels?.length})` : null}
            </div>
          </Popover>

          <div
            onClick={() => {
              analytics("View mode changed", {
                source: "Web",
                mode: mode == "calendar" ? "kanban" : "calendar",
              });

              dispatch(
                updateCurrentUser({
                  newValues: {
                    mode: mode == "calendar" ? "kanban" : "calendar",
                  },
                  previousValues: {
                    mode: mode,
                  },
                })
              );

              dispatch(refreshKabanCursor(moment().format("YYYY-MM-DD")));
            }}
            className="topbar-toggle"
          >
            <div className={mode == "calendar" ? "option active" : "option"}>
              <TbCalendarEvent className="topbar-button-icon" />
              <span>Calendar</span>
            </div>

            <div className={mode == "kanban" ? "option active" : "option"}>
              <TbLayoutKanban className="topbar-button-icon" />
              <span>Tasks</span>
            </div>
          </div>

          <SettingsMenu />
        </div>

        {hide_calendar_sidebar ? (
          <ArrowSmallLeftIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_calendar_sidebar: false,
                  },
                  previousValues: {
                    hide_calendar_sidebar: true,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        ) : (
          <ArrowRightIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_calendar_sidebar: true,
                  },
                  previousValues: {
                    hide_calendar_sidebar: false,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        )}
      </div>
    </div>
  );
}

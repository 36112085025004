import moment from "moment";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import MiniDayView from "../Calendar/MiniDayView";
import { useEffect, useState } from "react";
import { changeCalendarDate, updateTask } from "../../redux/tasksSlice";
import { useDispatch, useSelector } from "react-redux";
import PlannerContainer, { BackButton, NextButton } from "./PlannerContainer";
import { aiServerUrl } from "../../utils";
import { setStagedLabelSuggestions } from "../../redux/appSlice";
import { VscSparkleFilled } from "react-icons/vsc";
import { FaMagic } from "react-icons/fa";
import { FaWandMagicSparkles } from "react-icons/fa6";

export default function FinalizeDay({
  taskOrderEditable,
  setStep,
  skipStep2Active,
}) {
  const navigate = useNavigate();

  var todayDateKey = moment().format("YYYY-MM-DD");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeCalendarDate({
        date: todayDateKey,
      })
    );
  }, []);

  return (
    <PlannerContainer
      sidebarContent={
        <div
          className="flex flex-col gap-5 justify-start"
          style={{ WebkitAppRegion: "no-drag" }}
        >
          <div className="flex flex-col gap-2 items-start">
            <div className="inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline bg-violet-500/15 text-violet-700 group-data-[hover]:bg-violet-500/25 dark:text-violet-400 dark:group-data-[hover]:bg-violet-500/20">
              Step {skipStep2Active ? 3 : 4}
            </div>
            <div className="text-xl font-semibold">Let's finalize your day</div>
            <div>
              Great job! If you're somebody who likes to timebox, drag tasks
              from your task list into the timebox on the right.
            </div>
          </div>
        </div>
      }
      mainContent={
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-0">
            <MiniKanbanView
              key={todayDateKey}
              orderEditable={taskOrderEditable?.[todayDateKey]?.order}
              date={todayDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">Today</div>
                  <div className="text-sm text-neutral-500">Add some tasks</div>
                </div>
              }
              maxWidth={350}
            />
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1 pt-[10px]">
              <div className="text-xl font-semibold">Timebox</div>
              <div className="text-sm text-neutral-500">
                Drag tasks into the timebox
              </div>
            </div>
            <MiniDayView todayMode={true} ritualMode={true} />
          </div>
        </div>
      }
      backButton={
        <BackButton
          onClick={() => {
            if (skipStep2Active) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
        />
      }
      nextButton={
        <NextButton
          onClick={() => {
            // Take the user back to "/" route
            navigate("/");
          }}
          final={true}
        />
      }
    />
  );
}

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Modal, Switch } from "antd";

import {
  BellIcon,
  UserGroupIcon,
  ViewGridAddIcon,
  ShieldCheckIcon,
  ChatIcon,
  EmojiHappyIcon,
  TagIcon,
  ClipboardDocumentListIcon,
  ChartPieIcon,
  CalendarIcon,
  MoonIcon,
  RefreshIcon,
  ClockIcon,
  ArrowPathIcon,
  FaceSmileIcon,
  RectangleStackIcon,
  CalendarDaysIcon,
  ListBulletIcon,
  ArrowPathRoundedSquareIcon,
  HeartIcon,
  InboxIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";

import { XMarkIcon } from "@heroicons/react/24/outline";

import "./Upgrade.css";

import { serverUrl, isDev, analytics } from "../../utils";

import { useMediaQuery } from "react-responsive";

import { setUpgradeVisible } from "../../redux/appSlice";
import { getAuth } from "firebase/auth";

import { FaTasks } from "react-icons/fa";
import {
  BsCheck,
  BsCheckCircle,
  BsClock,
  BsDash,
  BsGoogle,
  BsCheck2Square,
  BsCheck2Circle,
  BsApple,
  BsClockFill,
  BsCheckSquareFill,
  BsCheckCircleFill,
  BsHeartFill,
} from "react-icons/bs";

import { BiBox, BiCheckbox } from "react-icons/bi";

import { SiGoogle } from "react-icons/si";

import { FiRepeat } from "react-icons/fi";

import { HiTag } from "react-icons/hi";
import DiscountModal from "./DiscountModal";

const UpgradeModal = ({
  value,
  setValue,
  removeEnabled = false,
  yearEnabled = true,
  inlineButton = false,
  onDatePickerClose,
}) => {
  const isVisible = useSelector((state) => state.app.upgradeVisible);

  const currentUser = useSelector((state) => state.app.currentUser);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [selectedPlan, setSelectedPlan] = useState("monthly");

  const [studentDiscountModalActive, setStudentDiscountModalActive] =
    useState(false);

  const auth = getAuth();
  const user = auth.currentUser;
  const prices = {
    yearly: {
      price_id: "price_1KLxz0J8MnUoTOQQexCyp12s",
    },
    monthly: {
      price_id: "price_1KLxz0J8MnUoTOQQGw45Hhh9",
    },
    lifetime: {
      price_id: "price_1KLxz0J8MnUoTOQQakq8gBtB",
    },
  };

  // Function to get tolt id if there is one, based on this script
  /*
  window.tolt_referral
*/

  function isStudentEmail(email) {
    const studentEmailEndings = [
      ".edu",
      ".ac.uk",
      ".edu.au",
      ".edu.sg",
      ".ac.in",
    ];

    return studentEmailEndings.some((ending) => email.endsWith(ending));
  }

  const getToltId = () => {
    var toltId = null;
    try {
      toltId = window.tolt_referral;
    } catch (err) {
      console.log("No tolt id found");
    }

    console.log("Tolt id is", toltId);
    return toltId;
  };

  const prodPrices = {
    yearly: {
      price_id: "price_1OHFqrJ8MnUoTOQQqngXAVfe",
    },
    monthly: {
      price_id: "price_1OHFqTJ8MnUoTOQQYslTeX4n",
    },
    lifetime: {
      price_id: "price_1OHFrQJ8MnUoTOQQGqeulh3m",
    },
  };

  const studentDiscounts = {
    dev: {
      coupon: "EWUuaykp",
    },
    prod: {
      coupon: "8nE07fsf",
    },
  };

  const [yearly, setYearly] = useState(false);

  var discount = null;

  const dispatch = useDispatch();

  function createCheckoutSession(stripeCustomer) {
    fetch(`${serverUrl}/create-checkout-session`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: isDev
          ? prices[selectedPlan].price_id
          : prodPrices[selectedPlan].price_id,
        client_reference_id: user.uid,
        customer: stripeCustomer,
        customer_email: user.email,
        discount: isStudentEmail(user.email)
          ? studentDiscounts[isDev ? "dev" : "prod"]
          : null,
        mode: selectedPlan === "lifetime" ? "payment" : "subscription",
        tolt_referral: getToltId(),
      }),
    }).then((response) => {
      // Examine the text in the response
      response.json().then(function (data) {
        window.location.assign(data.session.url);
      });
    });
  }

  return (
    <Modal
      closable={false}
      footer={null}
      style={{ top: isMobile ? "20px" : "30px" }}
      bodyStyle={{ padding: "0px" }}
      className={"light-theme"}
      open={isVisible}
      width={850}
      zIndex={10000}
      onCancel={() => {
        analytics("Web upgrade flow dismissed");
        dispatch(setUpgradeVisible(false));
      }}
    >
      <div className="upgrade-container">
        <div className="top-half">
          <div className="upgrade-modal-header">
            <div
              onClick={() => {
                analytics("Web upgrade flow dismissed");
                dispatch(setUpgradeVisible(false));
              }}
              className="close-button-container"
            >
              <XMarkIcon className="close-upgrade-modal-button" />
            </div>
            <div className="upgrade-h1">✨ Ready for an upgrade? ✨</div>
            <div className="upgrade-h2">
              Increase your productivity with powerful features
            </div>
          </div>

          {isStudentEmail(user.email) && (
            <div className="student-card mb-3 text-center">
              👋 Looks like you're using a student email! A 50% discount has
              been automatically applied to all plans
            </div>
          )}

          <div className="pricing-table">
            <div className="pricing-table-row upgrade-header">
              <div className="pricing-table-cell">
                <div className="pro-feature-cell"></div>
              </div>
              <div className="pricing-table-cell upgrade-header">
                <div className="name">Basic</div>
                <div className="price">Free</div>
                <div className="plan-button">
                  <BsCheck className="check" />
                  Your Plan
                </div>
                <div className="billing-switch"></div>
              </div>
              <div className="pricing-table-cell upgrade-header">
                <div className="name">Pro</div>
                {isStudentEmail(user.email) ? (
                  <>
                    <div className="flex flex-row gap-2 items-center">
                      {yearly ? (
                        <div className="price">
                          <del>99.99/yr</del> 49.99/yr 🥳
                        </div>
                      ) : (
                        <div className="price">
                          <del>9.99/mo</del> 4.99/mo 🥳
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {yearly ? (
                      <div className="price">99.99/yr</div>
                    ) : (
                      <div className="price">9.99/mo</div>
                    )}
                  </>
                )}
                <div
                  onClick={() => {
                    if (!currentUser.stripe_meta?.stripe_customer) {
                      // Call create-customer endpoint if stripe_customer does not exist
                      fetch(`${serverUrl}/create-customer`, {
                        method: "post",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          email: user.email,
                          userId: user.uid,
                        }),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          // Use the returned stripe_customer for the create-checkout-session call
                          console.log("Created customer", data.id);
                          createCheckoutSession(data.id);
                        });
                    } else {
                      // If stripe_customer exists, directly call createCheckoutSession
                      createCheckoutSession(
                        currentUser.stripe_meta.stripe_customer
                      );
                    }
                  }}
                  className="upgrade-plan-button"
                >
                  Upgrade
                </div>
                <div className="billing-switch">
                  <Switch
                    size="small"
                    checked={yearly}
                    onChange={(checked) => {
                      if (checked) {
                        setSelectedPlan("yearly");
                      } else {
                        setSelectedPlan("monthly");
                      }
                      setYearly(checked);
                    }}
                  />
                  Billed Yearly <span className="save-chip">Save 20%</span>
                </div>
              </div>
            </div>
            <FeatureRow
              icon={
                <RectangleStackIcon
                  className="feature-icon"
                  style={{
                    // Blue
                    color: "#3B82F6",
                  }}
                />
              }
              description="Unlimited tasks"
              basic={true}
              pro={true}
            />

            <FeatureRow
              icon={
                <InboxIcon
                  className="feature-icon"
                  style={{
                    // Purple
                    color: "#8B5CF6",
                  }}
                />
              }
              description="Braindump"
              basic={true}
              pro={true}
            />

            <FeatureRow
              icon={
                <DevicePhoneMobileIcon
                  className="feature-icon"
                  style={{
                    // Green
                    color: "#10B981",
                  }}
                />
              }
              description="iPhone & iPad App"
              basic={true}
              pro={true}
            />

            <FeatureRow
              icon={
                <ClockIcon
                  className="feature-icon"
                  style={{
                    // Yellow
                    color: "#F59E0B",
                  }}
                />
              }
              description="Timebox mode"
              basic={false}
              pro={true}
            />

            <FeatureRow
              icon={
                <CalendarIcon
                  className="feature-icon"
                  style={{
                    // Green
                    color: "#10B981",
                  }}
                />
              }
              description="Google Calendar Integration"
              basic={false}
              pro={true}
            />

            <FeatureRow
              icon={
                <CalendarDaysIcon
                  className="feature-icon"
                  style={{
                    // Black
                    color: "#000000",
                  }}
                />
              }
              description="Apple Calendar Integration"
              basic={false}
              pro={true}
            />

            <FeatureRow
              icon={
                <TagIcon
                  className="feature-icon"
                  style={{
                    // Red
                    color: "#EF4444",
                  }}
                />
              }
              description="Labels & Filters"
              basic={false}
              pro={true}
            />
            <FeatureRow
              icon={
                <ListBulletIcon
                  className="feature-icon"
                  style={{
                    // Orange
                    color: "#F97316",
                  }}
                />
              }
              description="Subtasks"
              basic={false}
              pro={true}
            />
            <FeatureRow
              icon={
                <ArrowPathRoundedSquareIcon
                  className="feature-icon"
                  style={{
                    // Blue
                    color: "#3B82F6",
                  }}
                />
              }
              description="Recurring Tasks"
              basic={false}
              pro={true}
            />
            <FeatureRow
              icon={
                <HeartIcon
                  className="feature-icon"
                  style={{
                    // Red
                    color: "#EF4444",
                  }}
                />
              }
              description="Support an independent developer"
              basic={false}
              pro={true}
            />
          </div>

          {!isStudentEmail(user.email) && (
            <>
              <DiscountModal
                studentDiscountModalActive={studentDiscountModalActive}
                setStudentDiscountModalActive={setStudentDiscountModalActive}
              />

              <div className="student-card mb-3">
                <span>
                  🤓 Student or non profit?{" "}
                  <span
                    style={{
                      // Purple
                      color: "#8B5CF6",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setStudentDiscountModalActive(true);
                    }}
                  >
                    Click here
                  </span>{" "}
                  for 50% off any plan
                </span>
              </div>
            </>
          )}

          <div className="student-card mb-14">
            <span>
              😴 Tired of subscriptions?{" "}
              <span
                style={{
                  // Purple
                  color: "#8B5CF6",
                  cursor: "pointer",
                }}
                onClick={() => {
                  fetch(`${serverUrl}/create-checkout-session`, {
                    method: "post",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      priceId: isDev
                        ? prices["lifetime"].price_id
                        : prodPrices["lifetime"].price_id,
                      client_reference_id: user.uid,
                      customer: currentUser.stripe_meta?.stripe_customer,
                      customer_email: user.email,
                      discount: isStudentEmail(user.email)
                        ? studentDiscounts[isDev ? "dev" : "prod"]
                        : null,
                      mode: "payment",
                    }),
                  }).then((response) => {
                    // Examine the text in the response
                    response.json().then(function (data) {
                      window.location.assign(data.session.url);
                    });
                  });
                }}
              >
                Click here
              </span>{" "}
              to purchase a lifetime license{" "}
              {isStudentEmail(user.email) ? (
                <span>
                  (<del>$300</del> $150 🥳)
                </span>
              ) : (
                <span>($300)</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;

function FeatureRow({ icon, description, basic, pro }) {
  return (
    <div className="pricing-table-row">
      <div className="pricing-table-cell">
        <div className="pro-feature-cell">
          {icon}
          {description == "Support an independent developer" ? (
            <a
              href="https://ellieplanner.com/about"
              target="_blank"
              className="pro-feature-text"
              style={{
                // Purple
                color: "#8B5CF6",
              }}
            >
              Support an Independent Developer
            </a>
          ) : (
            <div className="pro-feature-text">{description}</div>
          )}
        </div>
      </div>
      <div className="pricing-table-cell">
        {basic ? <BsCheck className="check" /> : <BsDash className="check" />}
      </div>
      <div className="pricing-table-cell">
        {pro ? <BsCheck className="check" /> : <BsDash className="check" />}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
  setReminderNotificationsEnabled,
} from "../../../redux/appSlice";
import dayjs from "dayjs";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Button, Input, Select, Switch } from "antd";
import CustomTimePicker from "../../Sidebar/DatePicker/CustomTimePicker";
import { GoArrowRight } from "react-icons/go";
import { CgExternal } from "react-icons/cg";
import { notificationsServerUrl } from "../../../utils";
import axios from "axios";
import { platform } from "@todesktop/client-core";
import { getToken } from "firebase/messaging";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";

const { Option } = Select;

const notificationMetas = {
  same_time: {
    stringValue: "same_time",
    notification_before_mins: 0,
    notification_before_days: 0,
    title: "At the due date",
  },
  "5_min": {
    stringValue: "5_min",
    notification_before_mins: 5,
    notification_before_days: 0,
    title: "5 mins before",
  },
  "10_min": {
    stringValue: "10_min",
    notification_before_mins: 10,
    notification_before_days: 0,
    title: "10 mins before",
  },
  "0_day": {
    stringValue: "0_day",
    notification_before_days: 0,
    notification_before_mins: 0,
    title: "The day of",
  },
  "1_day": {
    stringValue: "1_day",
    notification_before_days: 1,
    notification_before_mins: 0,
    title: "The day before",
  },
  "2_day": {
    stringValue: "2_day",
    notification_before_days: 2,
    notification_before_mins: 0,
    title: "2 days before",
  },
  "1_week": {
    stringValue: "1_week",
    notification_before_days: 7,
    notification_before_mins: 0,
    title: "A week before",
  },
};

export default function DueDateSettings() {
  const { time_format = "12_hour" } = useSelector(
    (state) => state.app.currentUser
  );

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
        notifications_enabled: false,
        notification_meta: null,
        notification_time: null,
      }
  );

  const dispatch = useDispatch();

  const [daysIndicator, setDaysIndicator] = useState(
    due_date_settings.number_of_days_to_show_indicator || 7
  );

  const reminderNotificationsEnabled = useSelector(
    (state) => state.app.reminderNotificationsEnabled || false
  );

  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        due_dates: {
          enabled: false,
          number_of_days_to_show_indicator: 7,
        },
      }
  );

  useEffect(() => {
    setDaysIndicator(due_date_settings.number_of_days_to_show_indicator);
  }, [due_date_settings.number_of_days_to_show_indicator]);

  useEffect(() => {
    setDueDateNotificationTime(due_date_settings.notification_time);
  }, [due_date_settings.notification_time]);

  const [dueDateNotificationTime, setDueDateNotificationTime] = useState(
    due_date_settings.notification_time
  );

  function shouldShowTimeSelection() {
    return (
      dueDateNotificationTime &&
      due_date_settings?.notification_meta &&
      due_date_settings?.notification_meta?.stringValue !== "same_time" &&
      (due_date_settings?.notification_meta.notification_before_days > 0 ||
        due_date_settings?.notification_meta.stringValue === "0_day")
    );
  }

  return (
    <div>
      <div className="settings personal">
        <div className="settings-item">
          <div className="settings-item-title">Due date indicator</div>
          <div className="settings-item-value">
            <div className="flex flex-row gap-2 items-center">
              <span> Show indicator on task card if due date is within</span>
              <input
                className="settings-item-input"
                type="number"
                style={{ width: "60px", minWidth: "60px" }}
                value={daysIndicator}
                onChange={(e) => {
                  setDaysIndicator(e.target.value);
                }}
                onBlur={() => {
                  let validatedValue = Number(daysIndicator);

                  // If the value is NaN or lesser than 0, reset it to 7
                  if (isNaN(validatedValue) || validatedValue <= 0) {
                    validatedValue = 7;
                    setDaysIndicator(validatedValue);
                  }

                  dispatch(
                    updateCurrentUser({
                      newValues: {
                        power_feature_settings: {
                          due_dates: {
                            number_of_days_to_show_indicator: validatedValue,
                          },
                        },
                      },
                      previousValues: {
                        power_feature_settings: power_feature_settings,
                      },
                    })
                  );
                }}
              />
              <span>days</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

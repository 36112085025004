import { Drawer } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { RiCactusLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  formatDuration,
  generateEstimatedTimeTasksPerDayPerLabel,
  transformDataForActualVsEstimated,
} from "../AnalyticsFunctions";
import TaskTable from "../TaskTable";
import { analytics } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DailyCompletedBarChartByEstimate({
  taskData,
  dateRange,
  userTheme,
}) {
  const { data: labels } = useSelector((state) => state.labels);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [barChartData, setBarChartData] = useState(null);

  useEffect(() => {
    const { finalData, labelsUsed } = generateEstimatedTimeTasksPerDayPerLabel(
      taskData,
      dateRange.dates,
      labels
    );

    const dateArray = dateRange.dates.map((date) => {
      return moment(date).format("MMM D");
    });

    const barChartData = {
      labels: dateArray,
      datasets: finalData,
    };

    setBarChartData(barChartData);
  }, [taskData, dateRange, labels]);

  return (
    <div
      className="chart"
      style={{
        gridArea: "bar-chart-2",
      }}
    >
      <Drawer
        title="Tasks"
        placement="right"
        closable={true}
        onClose={() => {
          setSelectedTasks(null);
        }}
        open={selectedTasks !== null}
        width={700}
        zIndex={10}
      >
        <TaskTable tasks={selectedTasks} labels={labels} showTimes={true} />
      </Drawer>

      <div className="chart-title">
        How you spend your time each day (by estimated time)
      </div>
      {barChartData && barChartData?.datasets?.length > 0 && (
        <Bar
          options={{
            plugins: {
              title: {
                display: false,
                text: "Estimated time (hours)",
              },
              tooltip: {
                filter: function (tooltipItem) {
                  return tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0;
                },
                // Format tooltip as a percentage
                callbacks: {
                  label: function (tooltipItems) {
                    // Let's get the value and convert it to hours and minutes

                    const rawSeconds =
                      tooltipItems.dataset.rawSecondsData[
                        tooltipItems.dataIndex
                      ];

                    // Use moment, convert seconds to a string formatted like "1 hour 30 minutes", hiding the minutes if there are none and the hours if there are none
                    return `${tooltipItems.dataset.label}: ${formatDuration(
                      rawSeconds
                    )}`;
                  },
                },
              },
              // Hide legend,
              legend: {
                display: false,
              },
            },
            responsive: true,
            interaction: {
              mode: "point",
              intersect: false,
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
                },
              },
              y: {
                stacked: true,
                grid: {
                  // Very light gray color
                  color:
                    userTheme == "dark" ? "#474747" : "rgba(0, 0, 0, 0.05)",
                },
                title: {
                  display: true,
                  text: "Estimated time (hours)",
                  color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
                },
                ticks: {
                  color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
                },
              },
            },
            onClick: (event, elements, chart) => {
              // Get activeElement

              if (elements[0]) {
                // Get datasetindex
                var datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;

                // Get tasks for this index
                const tasks = barChartData.datasets[datasetIndex].tasks[index];

                analytics("Analytics drilldown", {
                  type: "daily-completed-bar-chart",
                });

                setSelectedTasks(tasks);
              }
            },
          }}
          data={barChartData}
        />
      )}

      {barChartData?.datasets && barChartData?.datasets?.length === 0 && (
        <div className="no-data">
          <RiCactusLine className="icon" />
          <div>No data for this period</div>
        </div>
      )}
    </div>
  );
}

import { Select, Button } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";
import {
  bulkUpdateTasks,
  bulkUpdateTaskOrder,
  bulkAddToTaskOrder,
  bulkAddToTasks,
} from "../../redux/tasksSlice";
import { groupBy, set } from "lodash";
import moment from "moment";
import { bulkCreateLabels } from "../../redux/labelsSlice";
import {
  incrementManualTriggerCounter,
  toggleSettings,
} from "../../redux/appSlice";
import { toast } from "sonner";
import { analytics } from "../../utils";

const DO_NOT_IMPORT = "do-not-import";
const CREATE_LABEL = "create-label";

export default function UploadSunsama({ setMapSunsamaActive, sunsamaData }) {
  const { data: labels, loading } = useSelector((state) => state.labels);

  const dispatch = useDispatch();

  let uniqueChannels = [];

  if (Array.isArray(sunsamaData)) {
    uniqueChannels = [
      ...new Set(
        sunsamaData.map((row) => row?.channel?.replace(/^#/, "").trim())
      ),
    ].filter(Boolean); // This filters out any empty strings
  } else {
    console.error("sunsamaData is not an array or is undefined");
  }
  const [channelLabelMapping, setChannelLabelMapping] = useState(
    uniqueChannels.reduce(
      (acc, curr) => ({ ...acc, [curr]: DO_NOT_IMPORT }),
      {}
    )
  );

  const handleSelectionChange = (channel, selectedOption) => {
    // If the selected option is CREATE_LABEL, create a new label and add it to the labelsToCreate array

    setChannelLabelMapping((prevState) => ({
      ...prevState,
      [channel]: selectedOption || DO_NOT_IMPORT,
    }));
  };

  const convertToEllie = (sunsamaTasks, channelLabelMapping) => {
    // Check if sunsamaTasks is an array and channelLabelMapping is an object
    if (!Array.isArray(sunsamaTasks)) {
      throw new Error("Invalid arguments");
    }
    const labelsToCreate = [];
    var ellieTasks = sunsamaTasks.reduce((tasks, sunsamaTask) => {
      const id = uuid4();

      let channelName = sunsamaTask?.channel?.replace(/^#/, "").trim();

      var label =
        channelLabelMapping[channelName] === DO_NOT_IMPORT
          ? null
          : channelLabelMapping[channelName];

      if (channelLabelMapping[channelName] === CREATE_LABEL) {
        // Create a new label
        // Add the label to the labelsToCreate array, only if it doesn't already exist
        if (!labelsToCreate.find((label) => label.name === channelName)) {
          label = uuid4();
          labelsToCreate.push({
            id: label,
            name: channelName,
            color: "#e91e63",
          });
        } else {
          // The label already exists
          label = labelsToCreate.find((label) =>
            label.name === channelName ? label.id : null
          );
        }
      }

      let task = {
        id: id,
        description: sunsamaTask?.text ?? null,
        notes: sunsamaTask?.notes ?? null,
        created_at: new Date(),
        date: sunsamaTask?.startDate
          ? moment(sunsamaTask?.startDate, "YYYY-MM-DD").toDate()
          : null,
        subtasks: null,
        label: label || null,
        listId: null,
        estimated_time: sunsamaTask?.timeEstimate
          ? parseInt(sunsamaTask.timeEstimate * 60)
          : null,
        complete: !!sunsamaTask?.completedDate ?? null,
        recurring_id: null,
      };

      if (sunsamaTask.subtasks) {
        try {
          let subtaskArray = JSON.parse(sunsamaTask.subtasks);

          if (Array.isArray(subtaskArray) && subtaskArray.length > 0) {
            task.subtasks = subtaskArray.map((subtask) => {
              const subtaskId = uuid4();

              return {
                id: subtaskId,
                description: subtask?.title ?? null,
                complete: !!subtask.completedDate,
              };
            });
          }
        } catch (e) {
          console.error("Error parsing subtasks: ", e);
        }
      }

      tasks[id] = task;

      return tasks;
    }, {});

    return { ellieTasks, labelsToCreate };
  };

  const handleUpload = () => {
    const { ellieTasks: convertedTasks, labelsToCreate } = convertToEllie(
      sunsamaData,
      channelLabelMapping
    );

    const tasksArray = Object.values(convertedTasks);
    dispatch(bulkAddToTasks({ tasksToAdd: tasksArray }));

    dispatch(bulkCreateLabels(labelsToCreate));

    // Group tasks by date
    const tasksByDate = groupBy(tasksArray, (task) =>
      moment(task.date).format("YYYY-MM-DD")
    );

    try {
      // Prepare newOrder payload for bulkAddToTaskOrder action
      // {date: [taskIds]} where date is YYYY-MM-DD
      var orderToAdd = Object.keys(tasksByDate).reduce((acc, curr) => {
        acc[curr] = tasksByDate[curr].map((task) => task.id);
        return acc;
      }, {});

      // Then in incrementManualTriggerCounter
      dispatch(bulkAddToTaskOrder({ taskOrdersToAdd: orderToAdd }))
        .then(() => {
          // This will trigger us to manually re-call firestore in App.js to load the new data
          dispatch(incrementManualTriggerCounter());
          dispatch(toggleSettings());
        })
        .catch((e) => {
          console.error("Error incrementing manual trigger counter: ", e);
        });

      // Get the total count of tasks being imported
      const totalTasks = Object.values(tasksByDate).reduce(
        (acc, curr) => acc + curr.length,
        0
      );

      analytics("Sunsama import complete", {
        count: totalTasks,
      });

      toast.success(`Successfully imported (${totalTasks}) tasks 🥳`);
    } catch (e) {
      console.error("Error updating task order: ", e);
    }
  };

  return (
    <div>
      <div className="flex justify-between"></div>
      <div className="flex flex-col items-center gap-4 my-4 mb-6">
        <h1 className="font-semibold text-[1rem]">
          Match corresponding labels
        </h1>
        <p className="">
          Choose the channel attribute you wish to match with the corresponding
          label or create a new label if it doesn’t exist yet.
        </p>
      </div>
      <div className="my-4 flex flex-col gap-4">
        {uniqueChannels.map((channel) => (
          <div key={channel} className="flex justify-between">
            <div>{channel}</div>
            <Select
              key={channel}
              style={{ width: 150 }}
              defaultValue={DO_NOT_IMPORT}
              onChange={(value) => handleSelectionChange(channel, value)}
              options={[
                { label: "Do not import", value: DO_NOT_IMPORT },
                ...Object.values(labels).map((label) => ({
                  label: label.name,
                  value: label.id,
                })),
                { label: "Create label", value: CREATE_LABEL },
              ]}
            />
          </div>
        ))}
      </div>
      <div className="w-full py-[0.1px] my-8 bg-[#CACACA] dark:bg-[#373737]"></div>
      <div className="flex flex-row-reverse">
        <div className="flex gap-3">
          <div
            onClick={() => {
              setMapSunsamaActive(false);
            }}
            className="button w-[6rem] text-center"
          >
            Back
          </div>
          <button
            onClick={handleUpload}
            className="button primary w-[6rem] text-center"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../../Components/Kanban/Card";

import moment from "moment";

function DeadlineLists({
  indexSelected,
  manuallySelectColumn,
  navigatedViaKeyboard,
  setIndexSelected,
}) {
  const [pastDueOrder, setPastDueOrder] = useState([]);
  const [upcomingOrder, setUpcomingOrder] = useState([]);
  const [dueTodayOrder, setDueTodayOrder] = useState([]);
  const { data: taskData } = useSelector((state) => state.tasks);

  // Let's go through all taskData and pull out any tasks where due_date is not null and complete is not true
  // Then add them to the pastDue or upcoming array
  useEffect(() => {
    let pastDue = [];
    let upcoming = [];
    let dueToday = [];

    const sortedTaskIds = Object.keys(taskData)
      .filter((id) => taskData[id].due_date && !taskData[id].complete)
      .sort((a, b) => {
        const taskADueDate = taskData[a].due_date?.toDate
          ? taskData[a].due_date.toDate()
          : taskData[a].due_date;
        const taskBDueDate = taskData[b].due_date?.toDate
          ? taskData[b].due_date.toDate()
          : taskData[b].due_date;

        return moment(taskADueDate).diff(moment(taskBDueDate));
      });

    sortedTaskIds.forEach((taskId) => {
      const task = taskData[taskId];

      if (task.due_date && !task.complete) {
        var dueDate = task.due_date.toDate
          ? task.due_date.toDate()
          : task.due_date;

        if (moment(dueDate).isBefore(moment(), "day")) {
          pastDue.push(taskId);
        }

        if (moment(dueDate).isSame(moment(), "day")) {
          dueToday.push(taskId);
        }

        if (moment(dueDate).isAfter(moment(), "day")) {
          upcoming.push(taskId);
        }
      }
    });

    setPastDueOrder(pastDue);

    setUpcomingOrder(upcoming);

    setDueTodayOrder(dueToday);
  }, [taskData]);

  function isNothingThere() {
    return (
      pastDueOrder.length === 0 &&
      dueTodayOrder.length === 0 &&
      upcomingOrder.length === 0
    );
  }

  return (
    <div className="gap-2 p-4 overflow-y-auto">
      <div className="flex flex-col gap-0 mb-0">
        <div className="flex flex-col gap-2 text-neutral-700 dark:text-neutral-100">
          {pastDueOrder.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className="text-base font-medium">🫢 Overdue</div>
              {pastDueOrder.map((taskId, index) => {
                if (taskData[taskId]) {
                  return (
                    <Card
                      key={taskId + "_card"}
                      index={index}
                      item={taskData[taskId]}
                      activelySelected={indexSelected === index}
                      manuallySelectColumn={manuallySelectColumn}
                      navigatedViaKeyboard={navigatedViaKeyboard}
                      columnId={"past_due"}
                      setIndexSelected={setIndexSelected}
                      mini={false}
                      alwaysShowDueDate={true}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
          {dueTodayOrder.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className="text-base font-medium">👀 Due today</div>
              {dueTodayOrder.map((taskId, index) => {
                if (taskData[taskId]) {
                  return (
                    <Card
                      key={taskId + "_card"}
                      index={index}
                      item={taskData[taskId]}
                      activelySelected={indexSelected === index}
                      manuallySelectColumn={manuallySelectColumn}
                      navigatedViaKeyboard={navigatedViaKeyboard}
                      columnId={"past_due"}
                      setIndexSelected={setIndexSelected}
                      mini={false}
                      alwaysShowDueDate={true}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}

          {upcomingOrder.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className="text-base font-medium">🎯 Upcoming</div>
              {upcomingOrder.map((taskId, index) => {
                if (taskData[taskId]) {
                  return (
                    <Card
                      key={taskId + "_card"}
                      index={index}
                      item={taskData[taskId]}
                      activelySelected={indexSelected === index}
                      manuallySelectColumn={manuallySelectColumn}
                      navigatedViaKeyboard={navigatedViaKeyboard}
                      columnId={"upcoming"}
                      setIndexSelected={setIndexSelected}
                      mini={false}
                      alwaysShowDueDate={true}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}

          {isNothingThere() && (
            <div className="flex flex-col items-center justify-center h-full w-full mt-20 gap-4">
              <img
                className="w-2/4 h-1/2 object-contain opacity-80 dark:opacity-100"
                src={require("../../../images/empty_1.png")}
                alt="Empty State"
              />
              <p className="text-center w-3/4 font-medium text-xs text-neutral-600 dark:text-neutral-200">
                Nothing due. Tasks with due dates will appear here
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  return (
    prev.hide_sidebar === next.hide_sidebar &&
    prev.indexSelected === next.indexSelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(DeadlineLists, areEqual);

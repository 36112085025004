import { Popover } from "antd";
import React, { useState } from "react";
import { AiFillApple } from "react-icons/ai";
import { EventModal } from "./EventModal";
import moment from "moment";
import { useSelector } from "react-redux";

function AppleCalendarEvent({
  appleEventId,
  eventObject,
  textColor,
  calendarView,
}) {
  const [eventPopupActive, setEventPopupActive] = useState(null);

  const appleEvent = useSelector(
    (state) => state.calendar.appleEvents?.[appleEventId]
  );

  if (!appleEvent) return null;

  const durationLessThan15Minutes =
    moment(appleEvent.end).diff(moment(appleEvent.start), "minutes") <= 15;

  const isAllDayEvent =
    moment(appleEvent.start).isSame(
      moment(appleEvent.end).subtract(1, "day"),
      "day"
    ) || moment(appleEvent.end).diff(moment(appleEvent.start), "hours") > 24;

  function customTimeFormat(date) {
    const momentDate = moment(date);
    if (momentDate.minutes() === 0) {
      return momentDate.format("ha");
    } else {
      return momentDate.format("h:mma");
    }
  }

  return (
    <Popover
      content={
        <EventModal
          appleEventId={appleEvent.id}
          setEventPopupActive={setEventPopupActive}
        />
      }
      placement="left"
      title={null}
      trigger="click"
      open={eventPopupActive === appleEvent.id && eventPopupActive !== null}
      onOpenChange={(open) => {
        if (open) {
          setEventPopupActive(appleEvent.id);
        } else {
          setEventPopupActive(null);
        }
      }}
    >
      {calendarView !== "month" ? (
        <div
          className={`event-content ${
            durationLessThan15Minutes ? "small-text" : ""
          }`}
        >
          <div
            className="event-meta"
            style={{
              color: textColor,
            }}
          >
            <div className={`event-title`}>{appleEvent.title}</div>
            <div className="event-time">{eventObject.timeText}</div>
          </div>

          <AiFillApple
            className={`event-complete-check ${
              durationLessThan15Minutes ? "small" : ""
            }`}
            style={{
              color: eventObject.textColor,
            }}
          />
        </div>
      ) : (
        <div
          className={`overflow-hidden event-content w-full ${
            durationLessThan15Minutes ? "small-text" : ""
          }`}
        >
          <div className="flex flex-row items-center gap-1 overflow-hidden text-neutral-700 dark:text-neutral-300 px-1">
            {!isAllDayEvent && (
              <div class="flex justify-center items-center h-1 px-0.5">
                <div
                  class="h-2.5 w-1 rounded-full"
                  style={{
                    backgroundColor: textColor,
                  }}
                ></div>
              </div>
            )}
            {!isAllDayEvent && (
              <div className="event-time">
                {eventObject.timeText ||
                  (appleEvent.start && customTimeFormat(appleEvent.start))}
              </div>
            )}
            <div className="event-title ph-mask truncate pl-0.5">
              {appleEvent.title}
            </div>
          </div>
        </div>
      )}
    </Popover>
  );
}

export default AppleCalendarEvent;

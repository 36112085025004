import { useEffect, useState } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  generatePieChartOfLabelsAndNumberOfTasksPerLabel,
  getNumberOfTasksCompletedThisPeriodAndLast,
} from "../AnalyticsFunctions";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { RiCactusFill, RiCactusLine } from "react-icons/ri";
import { Drawer } from "antd";
import TaskTable from "../TaskTable";
import { analytics } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);

export default function TimeSpentPieChart({ taskData, dateRange, userTheme }) {
  const { data: labels } = useSelector((state) => state.labels);

  const [numberOfTasksThisPeriod, setNumberOfTasksThisPeriod] = useState(0);
  const [labelBreakdownPieData, setLabelBreakdownPieData] = useState(null);

  useEffect(() => {
    const { thisPeriod, lastPeriod } =
      getNumberOfTasksCompletedThisPeriodAndLast(
        taskData,
        dateRange.dates,
        dateRange.lastPeriodDates
      );

    const labelBreakdownPieData =
      generatePieChartOfLabelsAndNumberOfTasksPerLabel(
        taskData,
        dateRange.dates,
        labels
      );

    setNumberOfTasksThisPeriod(thisPeriod.length);
    setLabelBreakdownPieData(labelBreakdownPieData);
  }, [taskData, dateRange, labels]);

  const [selectedTasks, setSelectedTasks] = useState(null);

  return (
    <div
      className="chart"
      style={{
        gridArea: "pie-chart",
      }}
    >
      <Drawer
        title="Tasks"
        placement="right"
        closable={true}
        onClose={() => {
          setSelectedTasks(null);
        }}
        open={selectedTasks !== null}
        width={700}
        zIndex={10}
      >
        <TaskTable
          tasks={selectedTasks}
          labels={labels}
          showCompletionDate={true}
        />
      </Drawer>

      <div className="chart-title">
        How you spent your time (# of tasks by label)
      </div>
      <div className="flex flex-row gap-2">
        {labelBreakdownPieData && numberOfTasksThisPeriod > 0 && (
          <div className="w-44 h-44">
            <Doughnut
              plugins={[ChartDataLabels]}
              options={{
                cutout: "80%",
                plugins: {
                  title: {
                    display: false,
                    text: "How you spend your time each day",
                  },
                  tooltip: {
                    filter: function (tooltipItem) {
                      return (
                        tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0
                      );
                    },
                  },
                  // Hide legend,
                  legend: {
                    display: false,
                    // Set legend to the right side
                    position: "bottom",
                    // Change color
                    labels: {
                      color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
                    },
                  },
                  // Show the percentage of each pie slice
                  datalabels: {
                    display: false,
                    formatter: function (value, context) {
                      // Return the percentage of each pie slice as a percentage

                      var totalTasks = numberOfTasksThisPeriod;

                      return Math.round((value / totalTasks) * 100) + "%" || "";
                    },
                    color: "white",
                  },
                },
                responsive: true,
                interaction: {
                  mode: "point",
                  intersect: false,
                },
                onClick: (event, elements, chart) => {
                  if (elements[0]) {
                    // Get datasetindex
                    const index = elements[0].index;

                    // Get label
                    const tasks = labelBreakdownPieData.datasetTaskData[index];

                    analytics("Analytics drilldown", {
                      type: "time-spent-pie-chart",
                    });

                    setSelectedTasks(tasks);
                  }
                },
              }}
              data={labelBreakdownPieData}
            />
          </div>
        )}

        {/* Custom legend table */}

        <div className="w-full max-w-md mt-6">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left font-normal text-gray-500">
                  Category
                </th>
                <th className="text-right font-normal text-gray-500">
                  Amount / Share
                </th>
              </tr>
            </thead>
            <tbody>
              {labelBreakdownPieData &&
                labelBreakdownPieData.labels.map((label, index) => (
                  <tr key={index} className="h-5">
                    <td className="flex items-center">
                      <div
                        className="w-4 h-4 rounded mr-3"
                        style={{
                          backgroundColor:
                            labelBreakdownPieData.datasets[0].backgroundColor[
                              index
                            ],
                        }}
                      />
                      <span>{label}</span>
                    </td>
                    <td className="text-right">
                      <span className="font-semibold">
                        $
                        {labelBreakdownPieData.datasets[0].data[
                          index
                        ].toLocaleString()}
                      </span>
                      <span className="text-gray-500 ml-2">
                        {(
                          (labelBreakdownPieData.datasets[0].data[index] /
                            numberOfTasksThisPeriod) *
                          100
                        ).toFixed(1)}
                        %
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {numberOfTasksThisPeriod === 0 && (
          <div className="no-data">
            <RiCactusLine className="icon" />
            <span> You have no tasks completed in this period.</span>
          </div>
        )}
      </div>
    </div>
  );
}

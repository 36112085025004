import React from "react";
import { HiMiniArrowsRightLeft } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { getInitials } from "../../utils";
import { FaCheck } from "react-icons/fa";

function PermissionModal({
  logo = null,
  name = null,
  scopes = [],
  userPicture = null,
  userName = null,
  modalContent = null,
  authorizeAction = null,
  cancelAction = null,
  learnMoreAction = null,
}) {
  const { currentUser } = useSelector((state) => state.app);
  return (
    <div className="gap-10 w-[30rem] p-10 bg-white border-neutral-200 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-xl shadow-lg flex flex-col items-center">
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="flex gap-4 items-center">
          <img src={logo} alt="logo" className="w-10 h-10 object-contain" />
          <HiMiniArrowsRightLeft className="w-5 h-5 text-neutral-600" />
          <img
            src={require("../../images/logo3.png")}
            alt="logo"
            className="w-10 h-10 object-contain"
          />
        </div>
        <div className="font-semibold text-lg">
          Connect {name} to your Ellie account
        </div>
        <div className="bg-neutral-100 dark:bg-neutral-700 rounded-full px-4 py-2 flex gap-1 items-center">
          {currentUser.avatar_url ? (
            <img
              className="rounded-full object-contain w-6 h-6 mr-2"
              src={currentUser.avatar_url}
              alt="avatar"
            />
          ) : (
            <div className="settings-sidebar-avatar-initials !w-5 !h-5">
              {getInitials(currentUser.name)}
            </div>
          )}
          <div className="text-base font-medium">{currentUser.name}</div>
        </div>
      </div>

      {modalContent ? (
        modalContent
      ) : (
        <div className="w-full flex flex-col justify-around gap-4">
          <div className=" flex-col flex gap-4 ">
            <div className="text-sm font-semibold">{name} would like to:</div>
            {scopes.map((scope) => (
              <div className="flex gap-4 items-center">
                <FaCheck className="w-3 h-3 text-green-500" />
                <div className="text-sm text-">{scope}</div>
              </div>
            ))}
          </div>
          <div className="text-sm text-neutral-400 dark:text-neutral-400 font-medium">
            You can revoke access from {name || "this app"} at any time on the
            Integrations page in your Ellie settings.
          </div>
          <div className="w-full flex justify-end gap-2 pt-6">
            <button
              onClick={cancelAction}
              className="bg-transparent text-blue-500 rounded-lg px-4 py-2 font-semibold hover:bg-blue-50 dark:hover:bg-blue-100/10 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={authorizeAction}
              className="bg-blue-500 dark:bg-blue-600 text-white rounded-lg px-4 py-3 font-semibold hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors"
            >
              Allow Access
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PermissionModal;

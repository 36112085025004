import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Calendar.css";

import CalendarSettings from "./CalendarSettings";
import AddCalendar from "./AddCalendar";

import { IoSync } from "react-icons/io5";

import Moment from "react-moment";
import { Tooltip } from "antd";
import { last } from "lodash";
import { fetchBothCalendarEvents } from "../../redux/calendarSlice";
import { setUpgradeVisible } from "../../redux/appSlice";
import { HiMiniSparkles } from "react-icons/hi2";

export default function MiniToolbar({ type = "mini" }) {
  const {
    hide_calendar_sidebar,
    move_task_on_complete_disabled = false,
    active_calendars = {},
    calendar_accounts,
  } = useSelector((state) => state.app.currentUser);

  const lastSync = useSelector((state) => state.calendar.lastSync);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const dispatch = useDispatch();

  return (
    <div
      className={`mini-toolbar-calendar ${type === "mini" ? "" : "expanded"}`}
    >
      {calendar_accounts && (
        <div>
          <div
            onClick={(e) => {
              if (!subscriptionActive) {
                dispatch(setUpgradeVisible(true));
              } else {
                dispatch(fetchBothCalendarEvents());
              }
            }}
            className={`last-sync-icon-container ${
              type == "mini" ? "" : "expanded"
            }`}
          >
            {type == "mini" && (
              <Tooltip
                title={
                  subscriptionActive ? (
                    lastSync ? (
                      <span>
                        Last synced{" "}
                        <Moment interval={1000} fromNow>
                          {lastSync}
                        </Moment>{" "}
                      </span>
                    ) : (
                      <span>Syncing...</span>
                    )
                  ) : (
                    <span>Upgrade to use</span>
                  )
                }
              >
                {subscriptionActive ? (
                  <IoSync
                    className={
                      lastSync ? "last-sync-icon" : "last-sync-icon animated"
                    }
                  />
                ) : (
                  <HiMiniSparkles className="last-sync-icon" />
                )}
              </Tooltip>
            )}

            {type == "expanded" && (
              <div className="last-sync-expanded">
                {subscriptionActive ? (
                  <IoSync
                    className={`${
                      lastSync ? "last-sync-icon" : "last-sync-icon animated"
                    } ${type == "mini" ? "" : "expanded"}`}
                  />
                ) : (
                  <HiMiniSparkles
                    className={`last-sync-icon ${
                      type == "mini" ? "" : "expanded"
                    }`}
                  />
                )}
                <div className="last-sync-text">
                  {subscriptionActive ? (
                    lastSync ? (
                      <span>
                        Last synced{" "}
                        <Moment interval={1000} fromNow>
                          {lastSync}
                        </Moment>{" "}
                      </span>
                    ) : (
                      <span>Syncing...</span>
                    )
                  ) : (
                    <span>Upgrade to use</span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <CalendarSettings />
    </div>
  );
}

import { nativeWindow, object, webContents } from "@todesktop/client-core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function FloatingTimerHelper() {
  const { active_timer = null, floating_timer_enabled = false } = useSelector(
    (state) => state.app.currentUser
  );

  const [shouldShowTimer, setShouldShowTimer] = useState(false);

  useEffect(() => {
    if (active_timer && floating_timer_enabled) {
      setShouldShowTimer(true);
    } else {
      setShouldShowTimer(false);
    }
  }, [active_timer, floating_timer_enabled]);

  useEffect(() => {
    async function createTimerWindow() {
      // Get window fiBLz5kmcQbuYiFnQNQvx and show it
      const windowRef = await object.retrieve({ id: "fiBLz5kmcQbuYiFnQNQvx" });

      await nativeWindow.show({ ref: windowRef });
    }

    async function destroyAllTimerWindows() {
      const windowRef = await object.retrieve({ id: "fiBLz5kmcQbuYiFnQNQvx" });

      await nativeWindow.hide({ ref: windowRef });
    }

    if (shouldShowTimer) {
      createTimerWindow();
    } else {
      destroyAllTimerWindows();
    }
  }, [shouldShowTimer]);

  return <div />;
}

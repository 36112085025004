import "./Labels.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import {
  CheckIcon,
  SearchIcon,
  ArrowSmRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { updateCurrentUser } from "../../redux/appSlice";

import { toggleLabelManager } from "../../redux/appSlice";
import { Default, Mobile } from "../../mediaUtils";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
export default function Labels() {
  const { data: labels } = useSelector((state) => state.labels);

  const { label_filters: activeLabels } = useSelector(
    (state) => state.app?.currentUser
  );

  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="labels-filter-container !shadow-none border-b-neutral-100 border-b-2 dark:border-b-neutral-700 dark:border-b">
      <div className="label-selector-search">
        <MagnifyingGlassIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
        />
      </div>

      <div className="labels-container">
        
        {Object.values(labels)
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .filter((label) => {
            if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            } else {
              return false;
            }
          })
          .map((label) => {
            return (
              <div
                key={label.id}
                onClick={() => {
                  // If label is already active, remove it from activeLabels
                  if (activeLabels?.includes(label.id)) {
                    const newActiveLabels =
                      activeLabels?.filter((id) => id !== label.id) || [];
                    // Update active labels for current user

                    dispatch(
                      updateCurrentUser({
                        newValues: { label_filters: newActiveLabels },
                        previousValues: { label_filters: activeLabels },
                      })
                    );
                  } else {
                    if (activeLabels) {
                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            label_filters: [...activeLabels, label.id],
                          },
                          previousValues: { label_filters: activeLabels },
                        })
                      );
                    } else {
                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            label_filters: [label.id],
                          },
                          previousValues: { label_filters: [] },
                        })
                      );
                    }
                  }
                }}
                className={`label-container ${
                  activeLabels?.includes(label.id) ? "selected" : ""
                }`}
              >
                <div className="label ph-mask">
                  <div
                    className="label-box"
                    style={{ backgroundColor: label.color }}
                  />{" "}
                  {label.name}
                </div>

                {activeLabels?.includes(label.id) && (
                  <CheckIcon className="label-selected-icon" />
                )}
              </div>
            );
          })}

        <div
          className="label-container edit"
          onClick={() => {
            dispatch(toggleLabelManager());
          }}
        >
          <div className="label">
            Edit Labels
            <ArrowSmallRightIcon className="edit-labels-arrow-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

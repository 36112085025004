import "./Auth.css";

import { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import logo from "../../images/logo.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (email) => {
    const auth = getAuth();
    setLoading(true);
  
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // ...
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", error);
        setLoading(false);
  
        let errorDescription = "";
  
        switch (errorCode) {
          case "auth/invalid-email":
            errorDescription = "Invalid email format.";
            break;
          case "auth/user-disabled":
            errorDescription = "This account has been disabled.";
            break;
          case "auth/user-not-found":
            errorDescription = "No account found with this email.";
            break;
          case "auth/wrong-password":
            errorDescription = "Incorrect password.";
            break;
          default:
            errorDescription = errorMessage || errorCode;
        }
  
        toast.error(`Oops! Something went wrong.`, {
          description: `${errorDescription}`,
        });
      });
  };
  

  const handlePassReset = async () => {
    setLoading(true);
  
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setLoading(false);
        setForgotPasswordActive(false);
  
        toast.success(`We just sent an email to reset your password 🙌`);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setLoading(false);
        setForgotPasswordActive(false);
  
        let customMessage = '';
  
        switch (errorCode) {
          case 'auth/invalid-email':
            customMessage = 'The email address is not valid.';
            break;
          case 'auth/user-not-found':
            customMessage = 'There is no user corresponding to the given email.';
            break;
          case 'auth/wrong-password':
            customMessage = 'The password is invalid for the given email.';
            break;
          case 'auth/user-disabled':
            customMessage = 'The user account has been disabled.';
            break;
          default:
            customMessage = errorMessage || errorCode;
        }
  
        toast.error(`Oops! Something went wrong.`, {
          description: customMessage,
        });
      });
  };
  

  function keyPress(e) {
    if (e.keyCode === 13) {
      if (forgotPasswordActive) {
        handlePassReset();
      } else {
        handleLogin(email);
      }
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-left">
        <div className="auth-left-container">
          <img src={logo} className="auth-logo" alt="Ellie Logo" />
          <div className="auth-modal-container">
            {forgotPasswordActive ? (
              <h1 className="header">Reset Password 🤫</h1>
            ) : (
              <h1 className="header">Welcome back 👋</h1>
            )}

            {forgotPasswordActive ? (
              <>
                <p className="description">
                  Forgot your password? No worries, we'll send you an email with
                  a link to reset it!
                </p>
                <div>
                  <input
                    className="auth-input"
                    type="email"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={keyPress}
                  />
                </div>

                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handlePassReset();
                    }}
                    className="auth-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>Loading</span>
                    ) : (
                      <span>Reset Password</span>
                    )}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setForgotPasswordActive(false)}
                    className="auth-button-thin"
                  >
                    Back to Login
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="description">
                  Sign in via your email and password to continue
                </p>
                <div>
                  <input
                    className="auth-input"
                    type="email"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={keyPress}
                  />
                </div>
                <div>
                  <input
                    className="auth-input"
                    type="password"
                    placeholder="Your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={keyPress}
                  />
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogin(email);
                    }}
                    className="auth-button"
                    disabled={loading}
                  >
                    {loading ? <span>Loading</span> : <span>Log in</span>}
                  </button>
                </div>
                <div className="auth-meta">
                  <button
                    onClick={() => setForgotPasswordActive(true)}
                    className="auth-button-thin"
                  >
                    Forgot password?
                  </button>
                  <span className="or-spacer">or</span>

                  <button
                    onClick={() => navigate("/signup")}
                    className="auth-button-thin"
                    style={{ marginTop: "0px" }}
                  >
                    Create Account
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="auth-right"></div>
    </div>
  );
}

import "./Settings.css";
import { useState } from "react";

import { ArrowCircleLeftIcon, ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

import {
  getAuth,
  updateEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default function ChangeEmail({ setChangeEmailActive }) {
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");

  async function changeEmail() {
    if (newEmail === confirmEmail) {
      const auth = getAuth();
      const user = auth.currentUser;

      signInWithEmailAndPassword(auth, user.email, currentPassword)
        .then((userCredential) => {
          // Signed in

          updateEmail(auth.currentUser, newEmail)
            .then(() => {
              // Email updated!
              // ...
              alert("Email changed successfully");
              setChangeEmailActive(false);
            })
            .catch((error) => {
              // An error occurred
              // ...
              alert(`${error.message}`);
            });
        })
        .catch((error) => {
          alert(`Current password is incorrect`);
        });
    } else {
      alert("Emails do not match");
    }
  }

  return (
    <div>
      <div
        onClick={() => {
          setChangeEmailActive(false);
        }}
        className="back-button"
      >
        <ArrowLeftCircleIcon className="back-button-icon" />
        Back
      </div>

      <div className="settings-title">Change Email</div>

      <div className="settings-item">
        <div className="settings-item-title">Current password</div>
        <input
          className="settings-item-input"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          type="password"
        />
      </div>

      <div className="settings-item">
        <div className="settings-item-title">New email</div>
        <input
          className="settings-item-input"
          value={newEmail}
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
        />
      </div>

      <div className="settings-item">
        <div className="settings-item-title">Confirm email</div>
        <input
          className="settings-item-input"
          value={confirmEmail}
          onChange={(e) => {
            setConfirmEmail(e.target.value);
          }}
        />
      </div>

      <div
        onClick={() => changeEmail()}
        style={{ marginTop: "10px" }}
        className="button"
      >
        Change Email
      </div>
    </div>
  );
}

import { Popover } from "antd";
import moment from "moment";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { TbCalendarEvent } from "react-icons/tb";
import { useSelector } from "react-redux";
import { EventModal } from "../Calendar/EventModal";

export default function GoogleCalendarEventDetail({ taskCalendarEvent }) {
  const event = taskCalendarEvent.meta;

  const [eventPopupActive, setEventPopupActive] = useState(null);

  if (event === undefined) {
    return null;
  }

  return (
    <div
      className="calendar-event"
      onClick={() => {
        if (taskCalendarEvent.type === "outlook") {
          window.open(event?.webLink, "_blank");
        } else {
          // Let's open the google event in a new tab
          window.open(event.htmlLink, "_blank");
        }
      }}
    >
      <div
        className="left-stick"
        style={{
          backgroundColor: event?.backgroundColor || event?.color || "#000000",
        }}
      />
      {taskCalendarEvent.type === "outlook" ? (
        <img
          src={require("../../images/icons8-outlook-calendar-480.png")}
          alt="outlook icon"
          className="gcal-icon"
        />
      ) : (
        <FcGoogle className="icon" />
      )}
      {taskCalendarEvent.type === "outlook" ? (
        <div className="meta ph-mask">
          <div className="title">
            {" "}
            {`${moment(event?.start).format("dddd, MMMM Do")}`}
          </div>
          <div className="subtitle">
            {`${moment(event?.start).format("h:mma")} -
            ${moment(event?.end).format("h:mma")}`}
          </div>
          <div>
            {event?.calendarTitle && (
              <div className="property">
                <div className="title">
                  <TbCalendarEvent className="icon" />
                </div>
                <div className="value">{event.calendarTitle}</div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="meta ph-mask">
          <div className="title">
            {" "}
            {`${moment(event?.start?.dateTime).format("dddd, MMMM Do")}`}
          </div>
          <div className="subtitle">
            {`${moment(event?.start?.dateTime).format("h:mma")} -
    ${moment(event?.end?.dateTime).format("h:mma")}`}
          </div>
          <div>
            {event?.organizer && (
              <div className="property">
                <div className="title">
                  <TbCalendarEvent className="icon" />
                </div>
                <div className="value">
                  {taskCalendarEvent.type === "google"
                    ? event?.organizer?.displayName ||
                      event?.organizer?.email ||
                      "Error"
                    : event?.organizer?.emailAddress?.name ||
                      event?.organizer?.emailAddress?.address ||
                      "Error"}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import { Checkbox } from "antd";
import moment from "moment";
import { useState } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { RiCactusLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { setCardModalActive } from "../../redux/appSlice";
import {
  bulkDeleteTasks,
  bulkUpdateTaskOrder,
  bulkUpdateTasks,
  updateTaskOrder,
} from "../../redux/tasksSlice";

export default function BraindumpCleanup({ setMode, braindumpSortedByAge }) {
  const dispatch = useDispatch();

  const { data: labels, loading } = useSelector((state) => state.labels);

  const [selectedTasks, setSelectedTasks] = useState([]);

  const taskOrder = useSelector((state) => state.tasks.order);

  function markAllTasksAsComplete() {
    // Go through all the tasks and mark them as complete
    // Use bulkUpdateTasks to update

    // Then set the selectedTasks to an empty array

    const tasksToUpdate = braindumpSortedByAge.map((task) => {
      return {
        ...task,
        complete: true,
        completed_at: new Date(),
        date: moment().format("YYYY-MM-DD"),
      };
    });

    dispatch(
      bulkUpdateTasks({
        newData: tasksToUpdate,
        previousData: braindumpSortedByAge,
      })
    );

    // Also we need to update order

    const updatedBrainDumpOrder = taskOrder["brain_dump"].order.filter(
      (taskId) => !selectedTasks.includes(taskId)
    );

    var bulkUpdatedOrder = {
      newOrder: [
        { date: "brain_dump", order: updatedBrainDumpOrder },
        {
          date: moment().toDate(),
          order: [
            ...taskOrder[moment().format("YYYY-MM-DD")].order,
            ...selectedTasks,
          ],
        },
      ],
      previousOrder: [
        {
          date: "brain_dump",
          order: taskOrder["brain_dump"].order,
        },
        {
          date: moment().toDate(),
          order: taskOrder[moment().format("YYYY-MM-DD")].order,
        },
      ],
    };

    dispatch(bulkUpdateTaskOrder(bulkUpdatedOrder));

    setSelectedTasks([]);
  }

  function deleteTasks() {
    // Go through all the tasks and delete them
    // Use bulkDeleteTasks to delete

    // Then set the selectedTasks to an empty array

    const tasksToDelete = braindumpSortedByAge.filter((task) =>
      selectedTasks.includes(task.id)
    );

    dispatch(
      bulkDeleteTasks({
        tasksToDelete: tasksToDelete,
        previousOrder: taskOrder["brain_dump"].order,
      })
    );

    setSelectedTasks([]);
  }

  return (
    <div>
      <div className="anlaytics-subarea-header">
        <div
          onClick={() => {
            setMode("all-charts");
          }}
          className="back"
        >
          <FiArrowLeftCircle /> Back
        </div>
      </div>

      <div className="chart">
        <div className="cleanup-title">
          <div
            className="chart-title"
            style={{
              fontSize: "25px",
            }}
          >
            🧹 Braindump cleanup
          </div>
          {selectedTasks.length > 0 && (
            <div className="cleanup-toolbar">
              <div className="chart-subtitle">
                {selectedTasks.length} tasks selected
              </div>
              <div
                onClick={() => {
                  markAllTasksAsComplete();
                }}
                className="button"
              >
                Mark all as complete
              </div>
              <div
                onClick={() => {
                  deleteTasks();
                }}
                className="button"
              >
                Delete tasks
              </div>
            </div>
          )}
        </div>
        {braindumpSortedByAge.length > 0 ? (
          <div className="table-container">
            <table className="task-table">
              <thead className="braindump-oldest-header">
                <tr>
                  <th className="braindump-oldest-header-checkbox">
                    <Checkbox
                      checked={
                        selectedTasks.length === braindumpSortedByAge.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTasks(
                            braindumpSortedByAge.map((task) => task.id)
                          );
                        } else {
                          setSelectedTasks([]);
                        }
                      }}
                    />
                  </th>
                  <th className="braindump-oldest-header-title">Task</th>
                  <th className="braindump-oldest-header-label">Label</th>
                  <th className="braindump-oldest-header-age">Age</th>
                </tr>
              </thead>
              <tbody className="braindump-oldest-body">
                {
                  /* only get 5 */
                  braindumpSortedByAge.map((task) => (
                    <tr className="braindump-oldest-task">
                      <td className="braindump-oldest-task-checkbox">
                        <Checkbox
                          checked={selectedTasks.includes(task.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTasks([...selectedTasks, task.id]);
                            } else {
                              setSelectedTasks(
                                selectedTasks.filter((id) => id !== task.id)
                              );
                            }
                          }}
                        />
                      </td>
                      <td
                        onClick={() => {
                          dispatch(setCardModalActive(task.id));
                        }}
                        className="braindump-oldest-task-title"
                      >
                        {task.description}
                      </td>
                      <td>
                        {task.label && labels[task.label] && (
                          <div className="braindump-oldest-task-label">
                            <div
                              className="braindump-oldest-task-label-color"
                              style={{
                                backgroundColor: labels[task.label].color,
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            />
                            {labels[task.label].name}
                          </div>
                        )}
                      </td>
                      <td className="braindump-oldest-task-age">
                        {/* From now but show in days */}
                        {
                          /* Get the difference in days between created_at and now */
                          moment().diff(
                            moment(
                              task.created_at.toDate
                                ? task.created_at.toDate()
                                : task.created_at
                            ),
                            "days"
                          )
                        }{" "}
                        days old
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-data">
            <RiCactusLine className="icon" />
            <span> No tasks in the Braindump</span>
          </div>
        )}
      </div>
    </div>
  );
}

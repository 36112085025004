import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DatePicker.css";
import "./DatePickerDropdown.css";

import { Popover } from "antd";
import {
  calculateDaysToDueDateText,
  getIndicatorColorForDueDate,
} from "../../../utils";
import { useSelector } from "react-redux";
import { useState } from "react";
import CustomTimePicker from "./CustomTimePicker";

export default function DatePickerDropdown({
  isVisible,
  setIsVisible,
  dateSelected,
  onDateChange,
  children,
  timeDisabled = false,
  isDueDate = false,
  complete = false,
}) {
  // Check if start_date is instanceof firebase timestamp, if so, convert to date
  if (dateSelected && dateSelected.toDate) {
    dateSelected = dateSelected.toDate();
  }

  // Get time format from user
  const { time_format = "12_hour", calendar_first_day_of_week = 0 } = useSelector(
    (state) => state.app.currentUser
  );

  const timeFormat = time_format === "24_hour" ? "HH:mm" : "h:mma";

  // If dateSelected is today, set text to "Today", if tomorrow, set text to "Tomorrow", if yesterday, set text to "Yesterday", else set text to dateSelected
  const dateTextToDisplay = moment(dateSelected).isSame(moment(), "day")
    ? `Today, ${moment(dateSelected).format(timeFormat)}`
    : moment(dateSelected).isSame(moment().add(1, "day"), "day")
    ? `Tomorrow, ${moment(dateSelected).format(timeFormat)}`
    : moment(dateSelected).isSame(moment().subtract(1, "day"), "day")
    ? `Yesterday, ${moment(dateSelected).format(timeFormat)}`
    : moment(dateSelected).format(`MMM D, ${timeFormat}`);

  const ExampleCustomTimeInput = ({ date, value, onChange }) => {
    const [localValue, setLocalValue] = useState(value);

    const isValidTime = (time) => {
      const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
      return timePattern.test(time);
    };

    const handleInputChange = (e) => {
      setLocalValue(e.target.value);
    };

    const processTimeChange = () => {
      if (isValidTime(localValue)) {
        onChange(localValue);
      } else {
        setLocalValue(value); // resetting to the last valid time
      }
    };

    const handleBlur = () => {
      processTimeChange();
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        processTimeChange();
      }
    };

    return (
      <input
        value={localValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyPress} // Add the key press listener here
        className="border-neutral-300 !w-full border rounded py-2 px-3 leading-tight focus:outline-none focus:border-neutral-700 shadow-sm font-medium dark:bg-transparent dark:border-neutral-600 dark:text-neutral-100 dark:focus:border-neutral-500"
        placeholder="HH:mm"
      />
    );
  };

  return (
    <Popover
      open={isVisible}
      onOpenChange={(visible) => {
        if (isDueDate && complete) return;
        setIsVisible(visible);
      }}
      destroyTooltipOnHide={false}
      title={null}
      content={
        <div className="flex flex-col gap-2">
          {isVisible && (
            <div>
              <DatePicker
                selected={moment(
                  dateSelected || new Date(),
                  "YYYY-MM-DD"
                ).toDate()}
                onChange={onDateChange}
                selectsRange={false}
                inline
                timeInputLabel={null}
                showTimeInput
                // showTimeSelect
                timeFormat={time_format === "12_hour" ? "hh:mm aa" : "HH:mm"}
                customTimeInput={<CustomTimePicker time_format={time_format} />}
                calendarStartDay={calendar_first_day_of_week}
              />
              {isDueDate && dateSelected && (
                <div className="px-[10px] pb-4 w-full">
                  <button
                    className="button border-neutral-300 w-full border rounded py-2 px-3 leading-tight focus:outline-none "
                    onClick={() => onDateChange(null)}
                  >
                    Remove due date
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      }
      placement="bottom"
      trigger="click"
    >
      {children ? (
        <div
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          {children}
        </div>
      ) : (
        <div
          onClick={() => {
            if (isDueDate && complete) return;
            setIsVisible(!isVisible);
          }}
          className={`mcf-button${dateSelected == null ? " empty" : ""} ${
            isDueDate && complete ? "!cursor-not-allowed" : ""
          }`}
        >
          {dateSelected != null ? (
            isDueDate ? (
              complete ? (
                <div
                  className="flex flex-row items-center gap-1 text-[#8e8e8e] font-medium"
                  style={{
                    color: "#8e8e8e",
                  }}
                >
                  Already complete 👍
                </div>
              ) : (
                <div
                  className="flex flex-row items-center gap-1 text-[#8e8e8e] font-medium"
                  style={{
                    color: getIndicatorColorForDueDate(dateSelected),
                  }}
                >
                  {calculateDaysToDueDateText(dateSelected)}
                </div>
              )
            ) : (
              dateTextToDisplay
            )
          ) : (
            "Click to add"
          )}
        </div>
      )}
    </Popover>
  );
}

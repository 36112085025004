import { useRef, useEffect, useState } from "react";
import moment from "moment";

import OutsideClickHandler from "react-outside-click-handler";

export default function DurationPickerInput({
  hours,
  minutes,
  setHours,
  setMinutes,
  visible,
  setVisible,
  time,
  inline = false,
  inlineActive = false,
  setInlineActive,
  title,
}) {
  const [value, setValue] = useState(null);

  const minutesRef = useRef(null);
  const hoursRef = useRef(null);

  function updateMinutesAndHours() {
    // Get hours
    const newHours =
      (getDigit(value, 4)?.toString() || "0") +
      (getDigit(value, 3)?.toString() || "0");
    const newMinutes =
      (getDigit(value, 2)?.toString() || "0") +
      (getDigit(value, 1)?.toString() || "0");

    setHours(parseInt(!isNaN(newHours) ? newHours : ""));
    setMinutes(parseInt(!isNaN(newMinutes) ? newMinutes : ""));
  }

  useEffect(() => {
    if (!inline && visible) {
      minutesRef.current.focus();
    }

    if (inline && inlineActive) {
      minutesRef.current.focus();
    }
  }, [visible, inline, inlineActive]);

  useEffect(() => {
    // convert hours and minutes to strings and combine them
    const hoursString = hours?.toString().padStart(2, "0") ?? "00";
    const minutesString = minutes?.toString().padStart(2, "0") ?? "00";

    // Set value to the combined hours and minutes strings converted to a number
    const newValue = parseInt(hoursString + minutesString);

    if (value === null) {
      if (newValue === 0) {
        setValue(null);
      } else {
        setValue(newValue);
      }
    }
  }, []);

  useEffect(() => {
    if (value === null) {
      setHours(null);
      setMinutes(null);
    } else {
      updateMinutesAndHours();
    }
  }, [value]);

  return (
    <div className="duration-input-container">
      <div className="title"> {title}:</div>
      <div>
        {inline && !inlineActive && (
          <div
            className="inline-dpi-content"
            onClick={() => {
              setInlineActive(true);
            }}
          >
            {!isNaN(time) ? moment.utc(time * 1000).format("H:mm") : "0:00"}
          </div>
        )}

        {(!inline || inlineActive) && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (inline && inlineActive) {
                setInlineActive(false);
              }
            }}
            disabled={!inline}
          >
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div
                className="time-input-container"
                onKeyDown={(e) => {
                  const isNumber = /^[0-9]$/i.test(e.key);

                  if (e.key === "Enter" || e.key === "Escape") {
                    e.stopPropagation();
                    setVisible(false);

                    return;
                  }

                  // If left arrow key is pressed, and we are at the beginning of the minutesInput, focus the hoursInput
                  if (e.key === "ArrowLeft" && e.target.selectionStart === 0) {
                    hoursRef.current.focus();
                  }

                  // IF backspace is pressed, and we are at the beginning of the minutesInput and it is empty, focus the hoursInput
                  if (
                    e.key === "Backspace" &&
                    e.target.selectionStart === 0 &&
                    e.target.value === ""
                  ) {
                    hoursRef.current.focus();
                  }

                  // If right arrow key is pressed, and we are at the end of the hoursInput, focus the minutesInput
                  if (
                    e.key === "ArrowRight" &&
                    e.target.selectionStart === e.target.value.length
                  ) {
                    minutesRef.current.focus();
                  }
                }}
              >
                <input
                  type="text"
                  className="hour-input"
                  value={hours}
                  ref={hoursRef}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);

                    if (isNaN(newValue)) {
                      setHours("");
                    } else {
                      // Don't let users type anything if it is greater than 9
                      if (newValue > 9) {
                        setHours(hours);
                      } else {
                        setHours(newValue);
                        // Then set the input to the minutes input
                        minutesRef.current.focus();
                      }
                    }
                  }}
                  placeholder="0"
                />

                <div className="colon">:</div>

                <input
                  type="text"
                  className="minute-input"
                  value={minutes}
                  inputmode="numeric" pattern="[0-9]*"
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);

                    if (isNaN(newValue)) {
                      setMinutes("");
                    } else {
                      // If the new value is 3 digits long and the hour is 0 or null, set the minutes to the last 2 digits and set the hours to the first digit
                      if (
                        newValue.toString().length === 3 &&
                        (hours === null || hours === 0 || hours === "")
                      ) {
                        setMinutes(parseInt(newValue.toString().slice(1)));
                        setHours(parseInt(newValue.toString().slice(0, 1)));
                      } else if (
                        newValue.toString().length === 3 &&
                        hours !== null &&
                        hours !== 0 &&
                        hours !== ""
                      ) {
                        setMinutes(minutes);
                      } else if (
                        newValue.toString().length < 3 &&
                        (hours === null || hours === 0 || hours === "")
                      ) {
                        setMinutes(newValue);
                      } else {
                        // Don't let users type anything if it is greater than 59
                        if (newValue > 59) {
                          setMinutes(minutes);
                        } else {
                          setMinutes(newValue);
                        }
                      }
                    }
                  }}
                  ref={minutesRef}
                  placeholder="00"
                />
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
}

function getDigitCount(number) {
  return Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;
}
function getDigit(number, n, fromLeft) {
  const location = fromLeft ? getDigitCount(number) + 1 - n : n;

  // If n is 1 and number is null, return null
  if (n === 1 && (number === null || number === "")) {
    return null;
  }

  // If n is greater than the number of digits in the number, return null
  if (location > getDigitCount(number)) return null;

  return Math.floor((number / Math.pow(10, location - 1)) % 10);
}

import moment from "moment";
import { useDispatch } from "react-redux";
import { setCardModalActive } from "../../redux/appSlice";

export default function TaskTable({
  tasks,
  labels,
  showTimes = false,
  showCompletionDate = false,
}) {
  const dispatch = useDispatch();

  return (
    <table className="task-table">
      <thead className="braindump-oldest-header">
        <tr>
          <th className="braindump-oldest-header-title">Task</th>
          <th className="braindump-oldest-header-label">Label</th>
          {showTimes && (
            <>
              <th className="braindump-oldest-header-label">Estimated time</th>
              <th className="braindump-oldest-header-label">Actual time</th>
            </>
          )}
          {showCompletionDate && (
            <th className="braindump-oldest-header-label">Completed at</th>
          )}
        </tr>
      </thead>
      <tbody className="braindump-oldest-body">
        {
          /* only get 5 */
          tasks &&
            tasks.map((task, index) => (
              <tr className="braindump-oldest-task">
                <td
                  onClick={() => {
                    dispatch(setCardModalActive(task.id));
                  }}
                  className="braindump-oldest-task-title"
                  style={{
                    cursor: "pointer",
                    borderBottom:
                      index === tasks.length - 1
                        ? "none"
                        : "1px solid var(--big-button-border)",
                  }}
                >
                  {task.description}
                </td>
                <td
                  style={{
                    borderBottom:
                      index === tasks.length - 1
                        ? "none"
                        : "1px solid var(--big-button-border)",
                  }}
                >
                  {task.label && labels[task.label] && (
                    <div className="braindump-oldest-task-label">
                      <div
                        className="braindump-oldest-task-label-color"
                        style={{
                          backgroundColor: labels[task.label].color,
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                        }}
                      />
                      {labels[task.label].name}
                    </div>
                  )}
                </td>
                {showTimes && (
                  <>
                    <td
                      style={{
                        borderBottom:
                          index === tasks.length - 1
                            ? "none"
                            : "1px solid var(--big-button-border)",
                      }}
                    >
                      {!isNaN(task.estimated_time)
                        ? moment.utc(task.estimated_time * 1000).format("H:mm")
                        : "0:00"}
                    </td>
                    <td
                      style={{
                        borderBottom:
                          index === tasks.length - 1
                            ? "none"
                            : "1px solid var(--big-button-border)",
                      }}
                    >
                      {" "}
                      {!isNaN(task.actual_time)
                        ? moment.utc(task.actual_time * 1000).format("H:mm")
                        : "0:00"}
                    </td>
                  </>
                )}
                {showCompletionDate && (
                  <td
                    style={{
                      borderBottom:
                        index === tasks.length - 1
                          ? "none"
                          : "1px solid var(--big-button-border)",
                    }}
                  >
                    {task.completed_at &&
                      moment(
                        task.completed_at.toDate
                          ? task.completed_at.toDate()
                          : task.completed_at
                      ).format("ddd MMM DD, h:mm a")}
                  </td>
                )}
              </tr>
            ))
        }
      </tbody>
    </table>
  );
}

import { useSelector, useDispatch } from "react-redux";

import { Popover } from "antd";
import { HiCheck, HiPlus } from "react-icons/hi";
import { useMemo, useState } from "react";
import CreateList from "./CreateList";

export default function ListPickerDropdown({
  isVisible,
  setIsVisible,
  listSelected,
  onListChange,
  children,
}) {
  const lists = useSelector((state) => state.tasks.lists);
  const [listCreateActive, setListCreateActive] = useState(false);

  const list_order = useSelector(
    (state) => state.app.currentUser?.list_order || []
  );

  const sortedLists = useMemo(() => {
    const orderedLists = [];
    const unorderedLists = [];

    // First, add lists that are in the list_order
    list_order.forEach((id) => {
      if (lists[id]) {
        orderedLists.push(lists[id]);
      }
    });

    // Then, add any remaining lists that are not in list_order
    Object.values(lists).forEach((list) => {
      if (!list_order.includes(list.id)) {
        unorderedLists.push(list);
      }
    });

    // Combine ordered and unordered lists
    return [...orderedLists, ...unorderedLists];
  }, [lists, list_order]);

  return (
    <>
      <CreateList
        active={listCreateActive}
        setActive={setListCreateActive}
        mode={"create"}
        listToEdit={null}
        callback={(id) => {
          onListChange(id);
        }}
      />
      <Popover
        open={isVisible}
        onOpenChange={(visible) => {
          setIsVisible(visible);
        }}
        destroyTooltipOnHide={false}
        title={null}
        content={
          <div className="flex flex-col gap-2 w-64">
            <div className="flex flex-col gap-1 pt-2">
              <ListItem
                title="Brain Dump"
                icon={"🧠"}
                selected={listSelected == "brain_dump"}
                onClick={() => {
                  onListChange("brain_dump");
                }}
              />

              {lists &&
                sortedLists.map((list) => {
                  return (
                    <ListItem
                      title={list.title}
                      icon={list.icon}
                      selected={listSelected == list.id}
                      onClick={() => {
                        onListChange(list.id);
                      }}
                    />
                  );
                })}
            </div>
            <div
              onClick={() => {
                setListCreateActive(true);
                setIsVisible(false);
              }}
              className="flex flex-col gap-0 border-t py-1 dark:border-t-neutral-700"
            >
              <div className="flex flex-row gap-2 items-center text-sm text-neutral-700 dark:text-neutral-400 font-medium rounded-lg cursor-pointer hover:bg-neutral-100 hover:dark:bg-neutral-600 px-3 py-2">
                <HiPlus className="text-lg text-neutral-400" />
                <div>New List</div>
              </div>
            </div>
          </div>
        }
        placement="bottom"
        trigger="click"
      >
        {children ? (
          <div
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            {children}
          </div>
        ) : (
          <div
            onClick={() => {
              setIsVisible(!isVisible);
            }}
            className={`mcf-button ph-mask ${
              listSelected == null ? " empty" : ""
            }`}
          >
            {listSelected != null
              ? `${lists[listSelected]?.icon || "🧠"} ${
                  lists[listSelected]?.title || "Braindump"
                }`
              : "Click to add"}
          </div>
        )}
      </Popover>
    </>
  );
}

export function ListItem({ title, icon, selected, onClick }) {
  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className="flex flex-col gap-2 cursor-pointer hover:bg-neutral-100 hover:dark:bg-neutral-600 px-3 py-1"
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <div className="bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] from-teal-900 via-fuchsia-200 to-slate-200 rounded-md w-7 h-7 flex items-center justify-center">
            <div className="text-base">{icon}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-semibold">{title}</div>
          </div>
        </div>
        {selected && <HiCheck />}
      </div>
    </div>
  );
}

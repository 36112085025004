import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { updateCurrentUser } from "../../redux/appSlice";
import { Default, Mobile } from "../../mediaUtils";
import { Select, Switch } from "antd";
import { setUpgradeVisible } from "../../redux/appSlice";
import { serverUrl } from "../../utils";

import moment from "moment";

const { Option } = Select;

export default function ManageSubscription() {
  const { subscriptionActive, currentUser } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  return (
    <div>
      <div className="settings">
        {subscriptionActive ? (
          <div className="settings-item">
            <div className="settings-item-title">Membership</div>
            <div className="settings-item-value">
              {currentUser.pro_meta?.pro_status === "lifetime" ? (
                <div className="settings-item-value-subtitle">
                  Pro Membership (Lifetime) 🥳
                </div>
              ) : currentUser.pro_meta?.pro_status === "trial" ? (
                <div className="settings-item-value-subtitle">
                  Free Trial 🥳 (ends{" "}
                  {moment(
                    currentUser?.pro_meta?.pro_expiration_date?.toDate
                      ? currentUser?.pro_meta?.pro_expiration_date?.toDate()
                      : currentUser?.pro_meta?.pro_expiration_date
                  ).fromNow()}
                  )
                </div>
              ) : (
                <div className="settings-item-value-subtitle">
                  Pro Member 🥳
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="settings-item">
            <div className="settings-item-title">Membership</div>
            <div className="settings-item-value">
              <div className="settings-item-value-subtitle">Free Plan</div>
            </div>
          </div>
        )}

        {subscriptionActive &&
          currentUser.pro_meta?.pro_status != "lifetime" &&
          currentUser.pro_meta?.pro_status != "trial" && (
            <div className="settings-item">
              <div className="settings-item-title">Manage membership</div>
              <div className="settings-item-value">
                <div
                  onClick={() => {
                    fetch(`${serverUrl}/customer-portal`, {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        customerId: currentUser.stripe_meta.stripe_customer,
                      }),
                    }).then((response) => {
                      // Examine the text in the response
                      response.json().then(function (data) {
                        window.location.assign(data.session.url);
                      });
                    });
                  }}
                  className="settings-item-link"
                >
                  View invoices
                  <ArrowRightIcon className="settings-item-link-icon" />{" "}
                </div>
              </div>
              <div className="settings-item-value">
                <div
                  onClick={() => {
                    fetch(`${serverUrl}/customer-portal`, {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        customerId: currentUser.stripe_meta.stripe_customer,
                      }),
                    }).then((response) => {
                      // Examine the text in the response
                      response.json().then(function (data) {
                        window.location.assign(data.session.url);
                      });
                    });
                  }}
                  className="settings-item-link"
                >
                  Change Plan{" "}
                  <ArrowRightIcon className="settings-item-link-icon" />{" "}
                </div>
              </div>
              <div className="settings-item-value">
                <div
                  onClick={() => {
                    fetch(`${serverUrl}/customer-portal`, {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        customerId: currentUser.stripe_meta.stripe_customer,
                      }),
                    }).then((response) => {
                      // Examine the text in the response
                      response.json().then(function (data) {
                        window.location.assign(data.session.url);
                      });
                    });
                  }}
                  className="settings-item-link"
                >
                  Cancel Membership{" "}
                  <ArrowRightIcon className="settings-item-link-icon" />{" "}
                </div>
              </div>
            </div>
          )}

        {(!subscriptionActive ||
          currentUser.pro_meta?.pro_status == "trial") && (
          <div className="settings-item">
            <div className="settings-item-title">Upgrade to Pro</div>
            <div className="settings-item-value">
              <div
                onClick={() => {
                  dispatch(setUpgradeVisible(true));
                }}
                className="settings-item-link"
              >
                Upgrade to Pro{" "}
                <ArrowRightIcon className="settings-item-link-icon" />{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { FaCheck } from "react-icons/fa";
import { TbTag, TbTrash } from "react-icons/tb";
import { HiMiniTag, HiMiniTrash } from "react-icons/hi2";
import { clearBulkSelect } from "../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import {
  bulkDeleteTasks,
  moveTaskToBottom,
  moveTaskToBottomFromList,
  softDelete,
  updateTask,
} from "../../redux/tasksSlice";
import moment from "moment";
import { toast } from "sonner";
import LabelSelectorMini from "../Kanban/Card/Components/LabelSelector/LabelSelectorMini";
import { useState } from "react";
import { IoClose } from "react-icons/io5";

export default function BulkEditBar({}) {
  const dispatch = useDispatch();

  const tasks = useSelector((state) => state.tasks.data);
  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const bulkSelectedTasks = useSelector(
    (state) => state.app.bulkSelectedTasks || []
  );

  const {
    move_task_on_complete_disabled = false,
    auto_fill_actual_time_disabled = false,
  } = useSelector((state) => state.app.currentUser);

  function markTaskAsComplete(task) {
    var newActualTime = task.actual_time || 0;

    if (!auto_fill_actual_time_disabled) {
      // If auto_fill_actual_time_disabled is false, then let's fill in the actual time if estimated_time is set and actual_time is not
      if (task.estimated_time && (!task.actual_time || task.actual_time == 0)) {
        newActualTime = task.estimated_time;
      }
    }

    // If there is no date, then we are marking something from the braindump as complete
    // So let's set the date to today
    // Then move it out of the brain dump and at the bottom of today's order
    if (!task.date) {
      // This is a brain dump task
      dispatch(
        updateTask({
          taskId: task.id,
          currentTask: task,
          newData: {
            complete: true,
            completed_at: new Date(),
            date: new Date(),
            actual_time: newActualTime,
            listId: null,
          },
        })
      );

      // Move task to bottom from brain dup
      dispatch(
        moveTaskToBottomFromList({
          taskId: task.id,
          listId: task?.listId || "brain_dump",
        })
      );
    } else {
      dispatch(
        updateTask({
          taskId: task.id,
          currentTask: task,
          newData: {
            completed_at: new Date(),
            complete: true,
            actual_time: newActualTime,
          },
        })
      );

      if (!move_task_on_complete_disabled) {
        // If we are marking as complete, move it to the bottom of the order

        // Get date in format YYYY-MM-DD
        const dateString = moment(task.date).format("YYYY-MM-DD");

        dispatch(
          moveTaskToBottom({
            taskId: task.id,
            date: dateString,
          })
        );
      }
    }
  }

  return (
    <div
      id="bulkSelectContainer"
      className="fixed inset-x-0 bottom-10 flex items-center justify-center z-[1000]"
    >
      <div className="bg-white dark:bg-neutral-800 px-4 py-3 flex flex-row items-center gap-2 rounded-xl shadow-xl border border-neutral-200 dark:border-neutral-700 font-sans text-s text-neutral-700 dark:text-neutral-300">
        <div
          onClick={() => {
            dispatch(clearBulkSelect());
          }}
          className="flex flex-row gap-2 items-center p-[6px] border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-700 rounded-lg cursor-pointer hover:shadow-md hover:border-neutral-500 hover:dark:bg-neutral-800"
        >
          <IoClose className="cursor-pointer h-[14px] w-[14px] font-medium" />
        </div>
        <div className="w-[1px] h-6 bg-neutral-300 dark:bg-neutral-600 ml-1 mr-1" />
        <div className="text-xs">{bulkSelectedTasks.length} selected</div>
        <div
          onClick={() => {
            bulkSelectedTasks.forEach((taskId) => {
              const task = tasks && tasks[taskId];
              if (task) {
                markTaskAsComplete(task);
              }
            });

            toast.success(
              `${bulkSelectedTasks.length} task${
                bulkSelectedTasks.length > 1 ? "s" : ""
              } marked as complete`
            );
            dispatch(clearBulkSelect());
          }}
          className="flex flex-row gap-2 items-center border border-neutral-300 dark:border-neutral-600 rounded-lg py-1 px-3 cursor-pointer hover:shadow-md hover:border-neutral-300 dark:hover:border-neutral-500"
        >
          <FaCheck className="h-2 w-2 text-green-600" />
          <div>Done</div>
        </div>
        <LabelSelectorMini
          labelPickerActive={labelPickerActive}
          setLabelPickerActive={setLabelPickerActive}
          onLabelSelect={(labelId) => {
            console.log("labelId", labelId);
            bulkSelectedTasks.forEach((taskId) => {
              const task = tasks && tasks[taskId];
              if (task) {
                dispatch(
                  updateTask({
                    taskId: task.id,
                    currentTask: task,
                    newData: {
                      label: labelId,
                    },
                  })
                );
              }
            });

            toast.success(
              `${bulkSelectedTasks.length} task${
                bulkSelectedTasks.length > 1 ? "s" : ""
              } updated`
            );
            dispatch(clearBulkSelect());
          }}
        >
          <div
            onClick={() => {
              setLabelPickerActive(!labelPickerActive);
            }}
            className="flex flex-row gap-1 items-center border border-neutral-300 dark:border-neutral-600 rounded-lg py-1 px-3 cursor-pointer hover:shadow-md hover:border-neutral-300 dark:hover:border-neutral-500"
          >
            <HiMiniTag className="h-3 w-3 text-blue-500" />
            <div>Change label</div>
          </div>
        </LabelSelectorMini>

        <div
          onClick={() => {
            var tasksToDelete = [];
            bulkSelectedTasks.forEach((taskId) => {
              const task = tasks && tasks[taskId];

              if (task) {
                dispatch(softDelete(task));
                tasksToDelete.push(task);
              }
            });

            dispatch(clearBulkSelect());
          }}
          className="flex flex-row gap-1 items-center border border-neutral-300 dark:border-neutral-600 rounded-lg py-1 px-3 cursor-pointer hover:shadow-md hover:border-neutral-300 dark:hover:border-neutral-500"
        >
          <HiMiniTrash className="h-3 w-3 text-red-600" />
          <div>Delete</div>
        </div>
      </div>
    </div>
  );
}

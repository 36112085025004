import { useState } from "react";
import LabelLibrary from "../Components/Labels/LabelLibrary";
import { Divider } from "antd";
import {
  AiFillApple,
  AiFillWindows,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { FaAppStoreIos } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import AddCalendar from "../Components/Calendar/AddCalendar";
import { FiExternalLink } from "react-icons/fi";
import { MdLabelOutline } from "react-icons/md";
import { analytics } from "../utils";

const CustomLabelContent = () => {
  const [labelLibraryOpen, setLabelLibraryOpen] = useState(false);

  return (
    <div className="my-2">
      <button
        onClick={() => {
          setLabelLibraryOpen(true);
          analytics("Walkthrough link clicked", {
            source: "web",
            link: "labels",
          });
        }}
        className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-full gap-2 font-medium mt-6"
      >
        View common labels <AiOutlineArrowRight />
      </button>
      <LabelLibrary open={labelLibraryOpen} setOpen={setLabelLibraryOpen} />
    </div>
  );
};

const IphoneQR = () => {
  return (
    <div className="mt-2">
      <div className="w-full flex items-center justify-center md:justify-start h-48 gap-6">
        <div>
          <a
            href="https://apps.apple.com/us/app/ellie-daily-planner/id1602196200"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              analytics("Walkthrough link clicked", {
                source: "web",
                link: "https://apps.apple.com/us/app/ellie-daily-planner/id1602196200",
              });
            }}
          >
            <button className="border border-gray-300 rounded-xl py-2 px-4 flex items-center justify-center w-full gap-2 font-medium">
              <span>
                <FaAppStoreIos />
              </span>
              Download for iOS
            </button>
          </a>
        </div>
        or
        <img
          src={require("../images/ellie-ios-QR.png")}
          alt="Ellie ios QR code"
          className="w-28 rounded-xl"
        />
      </div>
    </div>
  );
};

const AddCalendars = () => {
  const Button = ({ children, icon }) => {
    return (
      <button className="border border-gray-300 rounded-xl px-1 py-2 text-lg flex items-center justify-center w-full gap-2 font-medium">
        <span>{icon}</span>
        {children}
      </button>
    );
  };
  return (
    <div className="flex gap-2 my-2">
      <AddCalendar>
        {" "}
        <button className="border border-gray-300 rounded-lg p-2 flex items-center justify-center gap-2 font-medium text-sm">
          Add Calendar{" "}
        </button>
      </AddCalendar>
    </div>
  );
};

const ExtendedFeatures = () => {
  const features = [
    {
      icon: "🧘",
      title: "Focus mode",
      link: "https://guide.ellieplanner.com/features/the-calendar-kanban/focus-on-today-mode",
    },
    {
      icon: "⏰",
      title: "Timer",
      link: "https://guide.ellieplanner.com/features/tasks/task-timer",
    },
    {
      icon: "📧",
      title: "Email forwarding",
      link: "https://guide.ellieplanner.com/features/tasks/email-greater-than-task",
    },
    {
      icon: "♾️",
      title: " Recurring task",
      link: "https://guide.ellieplanner.com/features/tasks/recurring-tasks",
    },
    {
      icon: "👀",
      title: "Menu bar",
      link: "https://guide.ellieplanner.com/features/mac-app/mac-calendar-menu-bar",
    },
    {
      icon: "🗓️",
      title: "Month/Week view",
      link: "https://guide.ellieplanner.com/features/the-timebox#week-view",
    },
    {
      icon: "🎯",
      title: "Due dates",
      link: "https://guide.ellieplanner.com/features/tasks/due-dates",
    },
    {
      icon: "🔌",
      title: "Zapier",
      link: "https://guide.ellieplanner.com/features/zapier-integration",
    },
    {
      icon: "📱",
      title: "Siri integration",
      link: "https://guide.ellieplanner.com/features/iphone-ipad-app/siri-integration",
    },
    {
      icon: "📊",
      title: "Analytics",
      link: "https://guide.ellieplanner.com/features/analytics",
    },
  ];
  return (
    <div className="flex justify-center items-center">
      <div className="text-lg md:text-base gap-4 grid md:grid-cols-2 w-full md:w-auto px-4 items-center">
        {features.map(
          ({ title = null, icon = null, description = null, link = null }) => {
            return (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  analytics("Walkthrough link clicked", {
                    source: "web",
                    link: link,
                  });
                }}
              >
                <div className="border p-3 rounded-lg border-neutral-300 flex items-center justify-between w-full md:w-72 group hover:border-purple-500">
                  <div className="flex flex-col gap-2 ">
                    {title && (
                      <div className="flex gap-2">
                        <span>{icon}</span> {title}
                      </div>
                    )}
                  </div>
                  <div className="text-center px-1 group-hover:text-purple-700 duration-0">
                    <FiExternalLink />
                  </div>
                </div>
              </a>
            );
          }
        )}
      </div>
    </div>
  );
};

export const stepData = [
  {
    tag: { text: "core feature", color: "text-blue-400" },
    title: "Tasks ✨",
    description: (
      <div className="flex flex-col gap-1">
        <div className="text-gray-500 dark:text-gray-400">
          Tasks are pretty straight forward, but here are some tips:
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <span className="font-bold">Tip 1</span>: You can add{" "}
            <span className="font-medium">subtasks</span>,{" "}
            <span className="font-medium">labels</span>,{" "}
            <span className="font-medium">notes</span> and even{" "}
            <span className="font-medium">emails 💌</span> to your tasks
          </div>
          <div>
            <span className="font-bold">Tip 2</span>: Tasks can be recurring 🔁,
            great for things like morning routes or preparing for a weekly
            meeting
          </div>
        </div>

        <a
          href="https://guide.ellieplanner.com/features/tasks"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://guide.ellieplanner.com/features/tasks",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-full gap-2 font-medium mt-6">
            Learn more about tasks
            <FiExternalLink />
          </button>
        </a>
      </div>
    ),
    asset: { mp4: { light: "tasks.mp4", dark: "tasks_dark.MP4" } },
    darkModeAsset: "tasks_dark.gif",
  },
  {
    tag: { text: "core feature", color: "text-blue-400" },
    title: "Labels",
    description: (
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div>
            Labels are a powerful tool for sorting and organizing your tasks.
            They work with the{" "}
            <span className="font-bold">Filter feature 🏷️</span> and{" "}
            <span className="font-bold">Analytics feature 📊</span>
          </div>
        </div>

        <div>Click the button below to view commonly used labels 👇</div>
      </div>
    ),
    asset: { mp4: { light: "labels.MP4", dark: "labels_dark.MP4" } },
    darkModeAsset: "labels_dark.gif",
    content: <CustomLabelContent />,
  },
  {
    tag: { text: "core feature", color: "text-blue-400" },
    title: "The Braindump (and lists)",
    description: (
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div>
            The Braindump is a special list to capture tasks once they come up.
            You can create other lists to further organize your tasks.
          </div>
        </div>

        <a
          href="https://guide.ellieplanner.com/features/lists"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://guide.ellieplanner.com/features/lists",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-full gap-2 font-medium mt-6">
            Learn more about lists
            <FiExternalLink />
          </button>
        </a>
      </div>
    ),
    asset: { mp4: { light: "lists.MP4", dark: "lists_dark.MP4" } },
    darkModeAsset: "lists_dark.gif",
  },
  {
    tag: { text: "core feature", color: "text-blue-400" },
    title: "Timebox",
    description: (
      <div>
        <div className="flex flex-col gap-2">
          <div>
            You can drag tasks from either the braindump or the kanban to the
            calendar to schedule them. This is called timeboxing.
          </div>
        </div>

        <a
          href="https://guide.ellieplanner.com/features/the-timebox"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://guide.ellieplanner.com/features/tasks",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-full gap-2 font-medium mt-6">
            Learn more about timeboxing
            <FiExternalLink />
          </button>
        </a>
      </div>
    ),
    asset: { mp4: { light: "timebox_light.MP4", dark: "timebox_dark.MP4" } },
    darkModeAsset: "timebox_dark.gif",
  },
  {
    tag: { text: "integrations", color: "text-green-400" },
    title: "Calendar integrations",
    description: (
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div>
            Want to see your Google, Apple or Outlook calendar events in Ellie?
            No problem! Just add your calendar and we'll overlay your events on
            top of the timebox.
          </div>
        </div>
      </div>
    ),
    asset: { mp4: { light: "timebox_light.MP4", dark: "timebox_dark.MP4" } },
    darkModeAsset: "timebox_dark.gif",
    content: (
      <div className="flex w-full flex-wrap py-6 gap-4">
        <a
          href="https://guide.ellieplanner.com/features/the-timebox/google-calendar-integration"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://guide.ellieplanner.com/features/the-timebox/google-calendar-integration",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-48 gap-2 font-medium">
            Google calendar
            <FiExternalLink />
          </button>
        </a>
        <a
          href="https://guide.ellieplanner.com/features/the-timebox/apple-calendar-integration"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://guide.ellieplanner.com/features/the-timebox/apple-calendar-integration",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-48 gap-2 font-medium">
            Apple calendar
            <FiExternalLink />
          </button>
        </a>
        <a
          href="https://guide.ellieplanner.com/features/the-timebox/outlook-calendar-integration"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://guide.ellieplanner.com/features/the-timebox/outlook-calendar-integration",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-48 gap-2 font-medium">
            Outlook calendar
            <FiExternalLink />
          </button>
        </a>
      </div>
    ),
  },
  {
    tag: { text: "feedback", color: "text-purple-400" },
    title: "The Ellie feedback board",
    description: (
      <div>
        <div className="flex flex-col gap-2">
          <div>
            We're fortunate enough to have a community of users who are actively
            sharing ideas and upvoting features they want to see. Would love to
            hear your thoughts 🙏
          </div>
        </div>

        <a
          href="https://feedback.ellieplanner.com"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://feedback.ellieplanner.com",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-4 text-md flex items-center justify-center w-full gap-2 font-medium mt-6">
            View our feedback board
            <FiExternalLink />
          </button>
        </a>
      </div>
    ),
    asset: { gif: { light: "canny_screenshot.png" } },
  },
  {
    tag: { text: "apps", color: "text-red-400" },
    title: "iPhone & iPad",
    description: (
      <div className="flex flex-col gap-2">
        <div>
          Access Ellie on the go with our iPhone and iPad apps. Quickly timebox
          and even add tasks with Siri 👀
        </div>
      </div>
    ),
    asset: {
      gif: { light: "iphone_ipad2.png", dark: "iphone_ipad2_dark.png" },
    },
    darkModeAsset: "iphone_ipad2_dark.png",
    content: <IphoneQR />,
    darkModeBorder: false,
  },
  {
    tag: { text: "apps", color: "text-red-400" },
    title: "Desktop app",
    description:
      "Download the Ellie app for quick access and desktop-only features (like our calendar menu bar for Mac)",
    asset: { mp4: { light: "desktop.MP4" } },
    content: (
      <div className="flex gap-4 my-3 w-full mt-8">
        <a
          href="https://download.ellieplanner.com/"
          className=" w-6/12"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://download.ellieplanner.com/",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 p-1 text-lg flex items-center justify-center w-full gap-2 font-medium">
            <span>
              <AiFillApple />
            </span>
            Get for Mac
          </button>
        </a>
        <a
          href="https://download.ellieplanner.com/windows/nsis/x64"
          className="w-6/12"
          onClick={() => {
            analytics("Walkthrough link clicked", {
              source: "web",
              link: "https://download.ellieplanner.com/windows/nsis/x64",
            });
          }}
        >
          <button className="border border-gray-300 rounded-xl py-2 px-1 text-lg flex items-center justify-center w-full gap-2 font-medium">
            <span>
              <AiFillWindows />
            </span>
            Get for Windows
          </button>
        </a>
      </div>
    ),
  },
  {
    title: <div className="flex md:pl-64 md:mb-2">And a lot more...</div>,
    description: <ExtendedFeatures />,
  },
];

import React, { useEffect, useState } from "react";
import { Segmented } from "antd";
import { RRule } from "rrule";
import { areRRulesStringsEquivalent } from "../../../../../utils";

const getOrdinalSuffix = (n) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const MonthSegmentedControl = ({ date, onUpdate, rrule }) => {
 
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const inputDate = date ? new Date(date) : new Date();
    const dayOfMonth = inputDate.getDate();
    const weekday = inputDate.getDay();
  
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const ordinalWeek = Math.ceil(dayOfMonth / 7);
    const lastDayOfMonth = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth() + 1,
      0
    );
    const isLastWeekdayOfMonth = dayOfMonth > lastDayOfMonth.getDate() - 7;
  
    const weekdayLookup = {
      SUN: RRule.SU,
      MON: RRule.MO,
      TUE: RRule.TU,
      WED: RRule.WE,
      THU: RRule.TH,
      FRI: RRule.FR,
      SAT: RRule.SA,
    };

    
    const rruleOptions = rrule ? RRule.fromString(rrule).origOptions : {};
    const newOptions = [
      {
        label: `the ${getOrdinalSuffix(dayOfMonth)}`,
        value: { bymonthday: [dayOfMonth], byweekday: null, bysetpos: null },
        rruleString: new RRule({
          ...rruleOptions,
          bymonthday: dayOfMonth,
          byweekday: null,
          bysetpos: null,
        }).toString(),
      },
      {
        label: `the ${getOrdinalSuffix(ordinalWeek)} ${weekdays[weekday]}`,
        value: {
          byweekday: [weekdayLookup[weekdays[weekday].toUpperCase()]],
          bysetpos: [ordinalWeek],
          bymonthday: null,
        },
        rruleString: new RRule({
          ...rruleOptions,
          byweekday: weekdayLookup[weekdays[weekday].toUpperCase()],
          bysetpos: ordinalWeek,
          bymonthday: null,
        }).toString(),
      },
    ];

    if (isLastWeekdayOfMonth) {
      newOptions.push({
        label: `the last ${weekdays[weekday]}`,
        value: {
          byweekday: [weekdayLookup[weekdays[weekday].toUpperCase()]],
          bysetpos: [-1],
          bymonthday: null,
        },
        rruleString: new RRule({
          ...rruleOptions,
          byweekday: weekdayLookup[weekdays[weekday].toUpperCase()],
          bysetpos: [-1],
          bymonthday: null,
        }).toString(),
      });
    }

    // If its the last day of the month in general, let's add this as an option
    if (dayOfMonth === lastDayOfMonth.getDate()) {
      newOptions.push({
        label: `the last day of the month`,
        value: {
          byweekday: null,
          bysetpos: null,
          bymonthday: [-1],
        },
        rruleString: new RRule({
          ...rruleOptions,
          byweekday: null,
          bysetpos: null,
          bymonthday: [-1],
        }).toString(),
      });
    }

    setOptions(newOptions);
  }, [
    rrule,
    date
  ]);

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    try {
      const matchedOption = options.find((option) => {
        const rruleBySetPos = RRule.fromString(rrule).options.bysetpos;
  
        if (
          rruleBySetPos &&
          rruleBySetPos.length > 0 &&
          rruleBySetPos[0] === -1
        ) {
          if (option.value.bysetpos === [-1]) {
            return true;
          }
        }
  
        return areRRulesStringsEquivalent(option.rruleString, rrule);
      });
      setSelectedValue(matchedOption ? matchedOption.label : null);
    } catch (error) {
      console.error('Error processing rrule:', error);
      // Handle the error as needed
    }
  }, [rrule]);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption.label);
    if (onUpdate) {
      onUpdate(selectedOption);
    }
  };

  return (
    <Segmented
      options={options.map((option) => option.label)}
      value={selectedValue}
      onChange={(selectedLabel) => {
        const selectedOption = options.find(
          (option) => option.label === selectedLabel
        );
        handleChange(selectedOption);
      }}
    />
  );
};

export default MonthSegmentedControl;

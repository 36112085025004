import { Menu, Modal, Popover } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { createList, updateList } from "../../../redux/tasksSlice";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";
import LabelSelector from "../../Kanban/Card/Components/LabelSelector";
import { analytics } from "../../../utils";

export default function CreateList({
  mode = "create",
  active = false,
  setActive,
  listToEdit,
  callback,
}) {
  const [title, setTitle] = useState("");
  const [emoji, setEmoji] = useState("");
  const [emojiPickerActive, setEmojiPickerActive] = useState(false);

  const user_theme = useSelector(
    (state) => state.app.currentUser.user_theme || "system"
  );

  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const [selectedLabel, setSelectedLabel] = useState(null);

  // Whenever the auto_label_id changes, update the selected label
  useEffect(() => {
    setSelectedLabel(listToEdit?.auto_label_id || null);
  }, [listToEdit?.auto_label_id]);

  function getRandomEmoji() {
    // Array of random cool emojis
    const coolEmojis = [
      "🫠",
      "🐙",
      "😈",
      "👻",
      "👽",
      "🤖",
      "👾",
      "👺",
      "🥹",
      "🍌",
      "🤠",
    ];

    // Get a random emoji from the array
    const randomEmoji =
      coolEmojis[Math.floor(Math.random() * coolEmojis.length)];

    return randomEmoji;
  }

  const inputRef = useRef(null);

  useEffect(() => {
    if (active && mode == "create") {
      setTitle("");

      // Set a random emoji
      setEmoji(getRandomEmoji());

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    if (active && mode == "edit" && listToEdit) {
      setTitle(listToEdit.title);
      setEmoji(listToEdit.icon);
    }
  }, [active, listToEdit]);

  const dispatch = useDispatch();

  return (
    <Modal
      title={null}
      open={active}
      footer={null}
      closable={false}
      width={450}
      destroyOnClose={true}
    >
      <div className="py-4">
        <div className="text-lg font-semibold px-4 mb-1">
          {mode == "create" ? "Create" : "Edit"} list
        </div>
        <div className="flex flex-col gap-2 py-2 px-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-3 w-full">
                <Popover
                  content={
                    emojiPickerActive ? (
                      <Picker
                        data={data}
                        onEmojiSelect={(emoji) => {
                          setEmoji(emoji.native);
                          setEmojiPickerActive(false);
                        }}
                        theme={
                          user_theme == "system"
                            ? window.matchMedia("(prefers-color-scheme: dark)")
                                .matches
                              ? "dark"
                              : "light"
                            : user_theme
                        }
                      />
                    ) : null
                  }
                  trigger="click"
                  open={emojiPickerActive}
                  onOpenChange={(open) => {
                    setEmojiPickerActive(open);
                  }}
                >
                  <div
                    onClick={() => {
                      setEmojiPickerActive(!emojiPickerActive);
                    }}
                    className="bg-neutral-100 dark:bg-neutral-700 border border-neutral-200 dark:border-neutral-600 from-teal-900 via-fuchsia-200 to-slate-200 rounded-md w-8 h-8 flex items-center justify-center cursor-pointer hover:border-neutral-400 dark:hover:border-neutral-200"
                  >
                    <div className="text-xl">{emoji}</div>
                  </div>
                </Popover>
                <input
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  ref={inputRef}
                  placeholder="ex: Work"
                  className="text-base font-medium bg-white-100 border py-1 px-2 outline-none rounded-lg flex-1 dark:bg-neutral-700 dark:border-neutral-500"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-4 items-center px-4 py-2">
            <div className="font-medium">Auto add label</div>
            <LabelSelector
              label={selectedLabel}
              updateLabelLocal={(newValue) => {
                analytics("Auto label added");
                setSelectedLabel(newValue);
                setLabelPickerActive(false);
              }}
              labelPickerActive={labelPickerActive}
              setLabelPickerActive={setLabelPickerActive}
              buttonMode={true}
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 justify-end border-t dark:border-t-neutral-700 pt-4 px-4 mt-3">
          <div
            onClick={() => {
              setTitle("");
              setEmoji("");
              setEmojiPickerActive(false);
              setLabelPickerActive(false);
              setActive(false);
            }}
            className="button"
          >
            Cancel
          </div>
          <div
            onClick={() => {
              if (title == "") {
                toast.error("List name cannot be blank 🥶", "error");
                return;
              }

              if (mode == "create") {
                // Create a new list
                var id = uuidv4();

                // Then callback
                dispatch(
                  createList({
                    list: {
                      title,
                      icon: emoji,
                      id: id,
                      auto_label_id: selectedLabel,
                    },
                  })
                ).then(() => {
                  if (callback) {
                    callback(id);
                  }
                });
              } else {
                // Update the list
                dispatch(
                  updateList({
                    currentList: listToEdit,
                    newData: {
                      title,
                      icon: emoji,
                      auto_label_id: selectedLabel,
                    },
                  })
                );
              }

              // Reset the form
              setTitle("");
              setEmoji(getRandomEmoji());
              setEmojiPickerActive(false);
              setSelectedLabel(null);
              setLabelPickerActive(false);
              setActive(false);
            }}
            className="button primary"
          >
            {mode == "create" ? "Create" : "Save"}
          </div>
        </div>
      </div>
    </Modal>
  );
}

import "./Subtasks.css";
import { useState, useRef, useEffect, useMemo } from "react";

import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { CSS } from "@dnd-kit/utilities";
import { Menu, Dropdown } from "antd";

import DurationPicker from "../DurationPicker";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";

import {
  createTask,
  moveTaskToBottom,
  updateTask,
  moveTaskToBottomFromList,
} from "../../../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../../../redux/appSlice";
import moment from "moment";

import FocusModePicker from "../../../../Mobile/Task/FocusModeDurationPicker";

import DraggerIcon from "../../../../../images/dragger.svg";

import TextareaAutosize from "react-textarea-autosize";

import { setUpgradeVisible } from "../../../../../redux/appSlice";
import _ from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import SortableSubtask from "./SortableSubtask";
import {
  DndContext,
  useSensors,
  MouseSensor,
  TouchSensor,
  useSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { BsCheck } from "react-icons/bs";
import { MdOutlineDragIndicator } from "react-icons/md";
import { FiMoreVertical } from "react-icons/fi";
import { BiPlus } from "react-icons/bi";

// Generate random id
const generateId = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

export default function Subtasks({
  item,
  updateSubtasksLocal,
  activelySelected,
  focusMode = false,
  subtasksActive,
  setSubtasksActive,
  modalMode = false,
}) {
  const dispatch = useDispatch();

  const [subtasks, setSubtasks] = useState(item.subtasks || []);

  useEffect(() => {
    // If subtask and subtasks are not the same, update subtasks
    // Use lodash to compare objects
    if (!_.isEqual(subtasks, item?.subtasks)) {
      setSubtasks(item.subtasks);
    }
  }, [item?.subtasks]);

  const [focusedSubtask, setFocusedSubtask] = useState(null);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const {
    move_task_on_complete_disabled = false,
    auto_complete_on_subtasks_disabled = false,
    auto_fill_actual_time_disabled = false,
  } = useSelector((state) => state.app.currentUser);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onHover(sourceIndex, destinationIndex) {
    const newSubtasks = reorder(subtasks, sourceIndex, destinationIndex);

    setSubtasks(newSubtasks);
  }

  function saveOrder() {
    if (updateSubtasksLocal) {
      updateSubtasksLocal(subtasks);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            subtasks: subtasks,
          },
        })
      );
    }
  }

  function updateSubtask(key, value, index) {
    var subtasksNew = [...subtasks];
    var subtask = _.cloneDeep(subtasksNew[index]);
    subtask[key] = value;

    subtasksNew[index] = subtask;

    if (
      key === "complete" &&
      value === true &&
      move_task_on_complete_disabled === false
    ) {
      subtasksNew.splice(index, 1);
      subtasksNew.push(subtask);
    }

    var newData = {
      subtasks: subtasksNew,
    };

    if (updateSubtasksLocal) {
      updateSubtasksLocal(subtasksNew);
    } else {
      // If key is complete and value is true, check if all subtasks are complete
      // If they are, then let's mark the entire task as complete
      if (key === "complete" && value === true) {
        var allComplete = true;
        subtasksNew.forEach((subtask) => {
          if (!subtask.complete) {
            allComplete = false;
          }
        });
        if (allComplete) {
          if (!auto_complete_on_subtasks_disabled) {
            newData.complete = true;
            newData.completed_at = new Date();

            // If auto_fill_actual_time_disabled is false, then let's fill in the actual time if estimated_time is set and actual_time is not
            if (!auto_fill_actual_time_disabled) {
              if (
                item.estimated_time &&
                (!item.actual_time || item.actual_time === 0)
              ) {
                newData.actual_time = item.estimated_time;
              }
            }

            // This is a braindump item, lets move it out
            if (!item.date) {
              newData.date = new Date();
              dispatch(
                moveTaskToBottomFromList({
                  taskId: item.id,
                  listId: item.list_id || "brain_dump",
                })
              );
            } else {
              if (!move_task_on_complete_disabled) {
                const dateString = moment(item.date).format("YYYY-MM-DD");
                dispatch(
                  moveTaskToBottom({
                    taskId: item.id,
                    date: dateString,
                  })
                );
              }
            }
          }
        }
      }

      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: newData,
        })
      );
    }
  }

  function deleteSubtask(index) {
    var subtasksNew = [...subtasks];
    subtasksNew.splice(index, 1);

    setSubtasks(subtasksNew);
    if (updateSubtasksLocal) {
      updateSubtasksLocal(subtasksNew);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            subtasks: subtasksNew,
          },
        })
      );
    }
  }

  function duplicateSubtask(index) {
    var subtasksNew = [...subtasks];
    var subtask = _.cloneDeep(subtasksNew[index]);

    subtask.id = generateId();
    subtask.complete = false;

    subtasksNew.splice(index + 1, 0, subtask);
    if (updateSubtasksLocal) {
      updateSubtasksLocal(subtasksNew);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            subtasks: subtasksNew,
          },
        })
      );
    }
  }

  function createSubtaskAt(index) {
    var subtasksNew = subtasks ? [...subtasks] : [];
    var subtask = {
      id: generateId(),
      description: "",
      complete: false,
    };

    subtasksNew.splice(index + 1, 0, subtask);

    setSubtasks(subtasksNew);

    setFocusedSubtask(subtask.id);
    if (updateSubtasksLocal) {
      updateSubtasksLocal(subtasksNew);
    } else {
      /*
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            subtasks: subtasksNew,
          },
        })
      );
       */
    }
  }

  function setFocusedSubtaskIndex(index) {
    if (index === null) {
      setFocusedSubtask(null);
    }
    if (index >= 0 && index < subtasks.length) {
      var subtask = subtasks[index];

      setFocusedSubtask(subtask.id);
    }
  }

  useHotkeys(
    "cmd+s, ctrl+s",
    (e) => {
      e.preventDefault();

      createSubtaskAt(subtasks?.length || 0);

      if (setSubtasksActive) {
        setSubtasksActive(true);
      }
    },
    {
      enabled: activelySelected ? true : false,
      enableOnTags: ["INPUT", "TEXTAREA"],
    },
    [activelySelected, item]
  );

  const subtaskIds = useMemo(() => {
    return subtasks?.map((subtask) => subtask.id);
  }, [subtasks]);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, useSensor(TouchSensor));

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = subtaskIds.indexOf(active.id);
      const newIndex = subtaskIds.indexOf(over.id);
      const newSubtasks = reorder(subtasks, oldIndex, newIndex);

      if (updateSubtasksLocal) {
        updateSubtasksLocal(newSubtasks);
      } else {
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              subtasks: newSubtasks,
            },
          })
        );
      }

      setSubtasks(newSubtasks);
    }
  }

  if (!subtasksActive) {
    return null;
  }

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors} autoScroll={false}>
      <div
        className={
          focusMode
            ? "fm-task-card"
            : modalMode
            ? "modal-subcontent"
            : "card-time-content"
        }
        data-no-dnd="true"
      >
        <div className="time-section">
          <div className="subtasks">
            <div className="subtasks-container">
              <SortableContext
                items={subtaskIds || []}
                id="subtasks"
                strategy={verticalListSortingStrategy}
              >
                {subtasks &&
                  subtasks.map((subtask, index) => (
                    <Subtask
                      key={subtask.id}
                      item={subtask}
                      index={index}
                      updateSubtask={updateSubtask}
                      deleteSubtask={deleteSubtask}
                      duplicateSubtask={duplicateSubtask}
                      createSubtask={createSubtaskAt}
                      focused={focusedSubtask === subtask.id}
                      setFocusedSubtaskIndex={setFocusedSubtaskIndex}
                      onHover={onHover}
                      saveOrder={saveOrder}
                      taskId={item.id}
                      modalMode={modalMode}
                    />
                  ))}
              </SortableContext>
            </div>

            <div className="add-subtask-container">
              <div
                className={`add-subtask-button${
                  !modalMode ? " inline-mode" : ""
                }`}
                onClick={() => {
                  if (!subscriptionActive) {
                    dispatch(setUpgradeVisible(true));
                  } else {
                    createSubtaskAt(subtasks?.length || 0);
                  }
                }}
              >
                <BiPlus className="add-subtask-icon" /> Add subtask
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndContext>
  );
}

export function Subtask({
  updateSubtask,
  index,
  item,
  deleteSubtask,
  duplicateSubtask,
  createSubtask,
  focused = false,
  setFocusedSubtaskIndex,
  onHover,
  saveOrder,
  taskId,
  modalMode,
}) {
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const taskInputRef = useRef(null);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
    opacity: isDragging ? 0.5 : 1,
  };

  useEffect(() => {
    if (focused) {
      taskInputRef.current.focus();
    } else {
      if (taskInputRef.current.contains(document.activeElement)) {
        taskInputRef.current.blur();
      }
    }
  }, [focused, taskInputRef]);

  const [descriptionEditable, setDescriptionEditable] = useState(
    item?.description || ""
  );

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <span
          onClick={() => {
            deleteSubtask(index);
          }}
        >
          Delete
        </span>
      </Menu.Item>
      <Menu.Item key="1">
        <span
          onClick={() => {
            duplicateSubtask(index);
          }}
        >
          Duplicate
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`subtask ${item.complete ? "complete" : ""}${
        false ? " preview" : ""
      }${!modalMode ? " inline-mode" : ""}`}
      key={item.id}
      ref={setNodeRef}
      style={style}
    >
      <div className="left">
        <MdOutlineDragIndicator
          className="dragger"
          {...listeners}
          {...attributes}
        />
        {item.complete && (
          <BsCheck
            onClick={(event) => {
              event.stopPropagation();
              updateSubtask("complete", false, index);
            }}
            className="card-complete-button complete subtask-check-icon"
          />
        )}

        {!item.complete && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              updateSubtask("complete", true, index);
            }}
            className="card-complete-button incomplete subtask-check-icon"
          />
        )}
      </div>

      <TextareaAutosize
        placeholder="Subtask description"
        spellCheck={false}
        onBlur={() => {
          // Check if the description is empty and delete the task if it is

          if (!descriptionEditable || descriptionEditable === "") {
            deleteSubtask(index);
          } else {
            // Only update the description if it has changed
            if (descriptionEditable !== item.description) {
              updateSubtask("description", descriptionEditable, index);
            }
          }
        }}
        ref={taskInputRef}
        className="subtask-description ph-mask"
        value={descriptionEditable}
        onChange={(e) => {
          setDescriptionEditable(e.target.value);
        }}
        onKeyDown={(e) => {
          // Detect if enter is pressed and save the task
          if (e.key === "Enter") {
            e.preventDefault();

            createSubtask(index);
          }

          if (e.code === "Backspace") {
            if (!e.target.value || descriptionEditable === "") {
              e.preventDefault();

              deleteSubtask(index);
              setFocusedSubtaskIndex(index - 1);
            }
          }

          if (e.code === "ArrowUp") {
            e.preventDefault();

            setFocusedSubtaskIndex(index - 1);
          }

          if (e.code === "ArrowDown") {
            e.preventDefault();

            setFocusedSubtaskIndex(index + 1);
          }

          if (e.code === "Escape") {
            setFocusedSubtaskIndex(null);
          }
        }}
      />

      <Dropdown overlay={menu} trigger={["click"]}>
        <FiMoreVertical className="subtask-dropdown" />
      </Dropdown>
    </div>
  );
}

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BiBrain, BiCheckCircle, BiTime } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { FiArrowDown, FiArrowRight, FiArrowUp } from "react-icons/fi";
import { TiWarningOutline } from "react-icons/ti";
import { useSelector } from "react-redux";
import {
  generateCompletedTasksPerDayPerLabel,
  getAverageBraindumpTaskAge,
  getAverageEstimateAndActualTimesThisPeriodAndLast,
  getNumberOfTasksCompletedThisPeriodAndLast,
  transformDataForActualVsEstimated,
} from "../AnalyticsFunctions";
import { analytics } from "../../../utils";

export default function BraindumpAverageAgeCard({
  taskData,
  dateRange,
  brainDumpTaskData,
  setMode,
}) {
  const { data: labels } = useSelector((state) => state.labels);

  const [averageBrainDumpTaskAge, setAverageBrainDumpTaskAge] = useState(0);

  const taskOrder = useSelector((state) => state.tasks.order);

  useEffect(() => {
    const averageBrainDumpTaskAge = getAverageBraindumpTaskAge(
      {
        ...taskData,
        ...brainDumpTaskData,
      },
      taskOrder?.["brain_dump"]?.order || []
    );

    setAverageBrainDumpTaskAge(averageBrainDumpTaskAge);
  }, [taskData, dateRange, labels]);

  return (
    <div
      className="chart sl"
      style={{
        gridArea: "average-task-age-small",
      }}
    >
      <div
        onClick={() => {
          analytics("Analytics drilldown", {
            type: "brain-dump-average-card",
            source: "title",
          });
          setMode("braindump");
        }}
        className="title"
      >
        <BiBrain className="title-icon" />
        <span>Braindump average task age</span>
      </div>
      <div className="value">
        {
          // to 2 decimal places
          averageBrainDumpTaskAge.toFixed(0)
        }{" "}
        days
      </div>
      {averageBrainDumpTaskAge > 50 ? (
        <div
          onClick={() => {
            analytics("Analytics drilldown", {
              type: "brain-dump-average-card",
              source: "chip",
            });
            setMode("braindump");
          }}
          className="change-chip orange"
        >
          <TiWarningOutline className="up" /> Needs attention <FiArrowRight />
        </div>
      ) : averageBrainDumpTaskAge == 0 ? (
        <div className="change-chip">
          <BsCheckCircle className="up" /> No action needed
        </div>
      ) : (
        <div
          onClick={() => {
            setMode("braindump");
          }}
          className="change-chip"
        >
          <BsCheckCircle className="up" /> Average
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
  setReminderNotificationsEnabled,
  setTimeboxNotificationsEnabled,
  setTimeboxNotificationsBefore,
} from "../../redux/appSlice";
import dayjs from "dayjs";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Button, Input, Select, Switch } from "antd";
import CustomTimePicker from "../Sidebar/DatePicker/CustomTimePicker";
import { GoArrowRight } from "react-icons/go";
import { CgExternal } from "react-icons/cg";
import axios from "axios";
import { platform } from "@todesktop/client-core";
import { getToken } from "firebase/messaging";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import moment from "moment";
import { analytics } from "../../utils";

const { Option } = Select;

const notificationMetas = {
  same_time: {
    stringValue: "same_time",
    notification_before_mins: 0,
    notification_before_days: 0,
    title: "At the due date",
  },
  "5_min": {
    stringValue: "5_min",
    notification_before_mins: 5,
    notification_before_days: 0,
    title: "5 mins before",
  },
  "10_min": {
    stringValue: "10_min",
    notification_before_mins: 10,
    notification_before_days: 0,
    title: "10 mins before",
  },
  "0_day": {
    stringValue: "0_day",
    notification_before_days: 0,
    notification_before_mins: 0,
    title: "The day of",
  },
  "1_day": {
    stringValue: "1_day",
    notification_before_days: 1,
    notification_before_mins: 0,
    title: "The day before",
  },
  "2_day": {
    stringValue: "2_day",
    notification_before_days: 2,
    notification_before_mins: 0,
    title: "2 days before",
  },
  "1_week": {
    stringValue: "1_week",
    notification_before_days: 7,
    notification_before_mins: 0,
    title: "A week before",
  },
};

const timeboxNotificationBeforeOptions = [
  {
    label: "At start of event",
    value: 0, // seconds before
  },
  {
    label: "5 minutes before",
    value: 5 * 60, // seconds before
  },
  {
    label: "10 minutes before",
    value: 10 * 60, // seconds before
  },
  {
    label: "15 minutes before",
    value: 15 * 60, // seconds before
  },
  {
    label: "30 minutes before",
    value: 30 * 60, // seconds before
  },
  {
    label: "1 hour before",
    value: 60 * 60, // seconds before
  },
];

export default function Notifications() {
  const { time_format = "12_hour" } = useSelector(
    (state) => state.app.currentUser
  );

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
        notifications_enabled: false,
        notification_meta: null,
        notification_time: null,
      }
  );

  const dispatch = useDispatch();

  const daily_planning_enabled = useSelector(
    (state) =>
      state.app.currentUser?.power_feature_settings?.daily_planning_enabled
  );

  const reminderNotificationsEnabled = useSelector(
    (state) => state.app.reminderNotificationsEnabled || false
  );

  const timeboxNotificationsEnabled = useSelector(
    (state) => state.app.timeboxNotificationsEnabled || false
  );

  const timeboxNotificationBefore = useSelector(
    (state) => state.app.timeboxNotificationBefore ?? 10 * 60
  );

  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        due_dates: {
          enabled: false,
          number_of_days_to_show_indicator: 7,
        },
      }
  );

  useEffect(() => {
    setDueDateNotificationTime(due_date_settings.notification_time);
  }, [due_date_settings.notification_time]);

  const [dueDateNotificationTime, setDueDateNotificationTime] = useState(
    due_date_settings.notification_time
  );

  const {
    dailyPlanningTimeEnabled = false,
    dailyPlanningTime = "09:00",
    dailyShutDownTimeEnabled = false,
    dailyShutDownTime = "18:00",
  } = useSelector((state) => state.app.currentUser || {});

  function shouldShowTimeSelection() {
    return (
      dueDateNotificationTime &&
      due_date_settings?.notification_meta &&
      due_date_settings?.notification_meta?.stringValue !== "same_time" &&
      (due_date_settings?.notification_meta.notification_before_days > 0 ||
        due_date_settings?.notification_meta.stringValue === "0_day")
    );
  }

  const timeRange = Array.from(
    { length: 25 },
    (_, i) => i.toString().padStart(2, "0") + ":00"
  );

  return (
    <div>
      <div className="settings personal">
        <div className="settings-item">
          <div className="settings-item-title">Timebox notifications</div>

          <div className="settings-item-value w-full">
            <div className="flex flex-row gap-2 items-center w-full">
              <span>Timebox notifications</span>
              <Switch
                size="small"
                checked={timeboxNotificationsEnabled}
                onChange={(value) => {
                  localStorage.setItem(
                    "ellie_timebox_notifications_enabled",
                    value ? "true" : "false"
                  );

                  analytics("Notifications toggled", {
                    enabled: value,
                    type: "timebox",
                    source: "web",
                  });

                  dispatch(setTimeboxNotificationsEnabled(value));
                }}
              />
              {!timeboxNotificationsEnabled && (
                <div
                  className="pl-2 flex items-center text-xs gap-1 text-purple-500 cursor-pointer hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-500"
                  onClick={() => {
                    // Open window here https://guide.ellieplanner.com/features/tasks/due-dates#reminder-notifications
                    window.open(
                      "https://guide.ellieplanner.com/features/notifications#timebox-notifications",
                      "_blank"
                    );
                  }}
                >
                  Learn more <CgExternal className="w-3 h-3" />
                </div>
              )}
            </div>
          </div>
          {timeboxNotificationsEnabled && (
            <div className="settings-item-value w-full">
              <div className="flex flex-col items-start gap-4 w-full">
                <div className="flex flex-row gap-2 items-center w-full">
                  <span>Remind me</span>
                  <Select
                    className="w-48"
                    defaultValue={
                      10 * 60 // default to 10 mins before
                    } // default to 10 mins before
                    value={
                      timeboxNotificationBefore || 10 * 60 // default to 10 mins before
                    }
                    onChange={(value) => {
                      dispatch(setTimeboxNotificationsBefore(value));

                      // Also set ellie_timebox_notifications_before_seconds in localStorage
                      localStorage.setItem(
                        "ellie_timebox_notifications_before_seconds",
                        value
                      );
                    }}
                    options={
                      timeboxNotificationBeforeOptions.map((option) => {
                        return {
                          value: option.value,
                          label: option.label,
                        };
                      }) || []
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {daily_planning_enabled && (
          <>
            <div className="settings-rowdivider" />
            <div className="settings-item">
              <div className="settings-item-title">
                Daily Planning notifications
              </div>

              <div className="settings-item-value w-full flex-col gap-4">
                <div className="flex flex-col gap-6 w-full">
                  <div className="flex flex-row items-center gap-2">
                    <span>Daily Planning notifications</span>
                    <Switch
                      size="small"
                      checked={dailyPlanningTimeEnabled}
                      onChange={(value) => {
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              dailyPlanningTimeEnabled: value,
                            },
                            previousValues: {
                              dailyPlanningTimeEnabled:
                                dailyPlanningTimeEnabled,
                            },
                          })
                        );
                      }}
                    />
                  </div>
                  {dailyPlanningTimeEnabled && (
                    <div className="settings-item-value w-full">
                      <div className="flex flex-col items-start gap-4 w-full">
                        <div className="flex flex-row gap-2 items-center w-full">
                          <span>Remind me</span>
                          <Select
                            className="min-w-fit"
                            defaultValue={"09:00"}
                            value={
                              time_format === "12_hour"
                                ? moment(dailyPlanningTime, "HH:mm").format(
                                    "h:mm A"
                                  )
                                : dailyPlanningTime
                            }
                            onChange={(value) => {
                              dispatch(
                                updateCurrentUser({
                                  newValues: {
                                    dailyPlanningTime: value,
                                  },
                                  previousValues: {
                                    dailyPlanningTime: dailyPlanningTime,
                                  },
                                })
                              );
                            }}
                            options={
                              timeRange.map((time) => {
                                return {
                                  value: time,
                                  label:
                                    time_format === "12_hour"
                                      ? moment(time, "HH:mm").format("h:mm A")
                                      : time,
                                };
                              }) || []
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-6 w-full">
                <div className="flex flex-row items-center gap-2">
                  <span>Daily Shutdown notifications</span>
                  <Switch
                    size="small"
                    checked={dailyShutDownTimeEnabled}
                    onChange={(value) => {
                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            dailyShutDownTimeEnabled: value,
                          },
                          previousValues: {
                            dailyShutDownTimeEnabled: dailyShutDownTimeEnabled,
                          },
                        })
                      );
                    }}
                  />
                </div>
                {dailyShutDownTimeEnabled && (
                  <div className="settings-item-value w-full">
                    <div className="flex flex-col items-start gap-4 w-full">
                      <div className="flex flex-row gap-2 items-center w-full">
                        <span>Remind me</span>
                        <Select
                          className="min-w-fit"
                          defaultValue={
                            time_format === "12_hour" ? "09:00 PM" : "21:00"
                          }
                          value={
                            time_format === "12_hour"
                              ? moment(dailyShutDownTime, "HH:mm").format(
                                  "h:mm A"
                                )
                              : dailyShutDownTime
                          }
                          onChange={(value) => {
                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  dailyShutDownTime: value,
                                },
                                previousValues: {
                                  dailyShutDownTime: dailyShutDownTime,
                                },
                              })
                            );
                          }}
                          options={
                            timeRange.map((time) => {
                              return {
                                value: time,
                                label:
                                  time_format === "12_hour"
                                    ? moment(time, "HH:mm").format("h:mm A")
                                    : time,
                              };
                            }) || []
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="settings-rowdivider" />

        {power_feature_settings &&
          power_feature_settings.due_dates?.enabled && (
            <>
              <div className="settings-item">
                <div className="settings-item-title">
                  Due date notifications
                </div>

                <div className="settings-item-value w-full">
                  <div className="flex flex-row gap-2 items-center w-full">
                    <span> Allow reminder notifications</span>
                    <Switch
                      size="small"
                      checked={due_date_settings?.notifications_enabled}
                      onChange={() => {
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              power_feature_settings: {
                                due_dates: {
                                  notifications_enabled:
                                    due_date_settings?.notifications_enabled
                                      ? !due_date_settings.notifications_enabled
                                      : true,
                                },
                              },
                            },
                            previousValues: {
                              power_feature_settings: power_feature_settings,
                            },
                          })
                        );

                        analytics("Notifications toggled", {
                          enabled: !due_date_settings?.notifications_enabled,
                          type: "due_date",
                          source: "web",
                        });
                      }}
                    />
                    {!due_date_settings?.notifications_enabled && (
                      <div
                        className="pl-2 flex items-center text-xs gap-1 text-purple-500 cursor-pointer hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-500"
                        onClick={() => {
                          // Open window here https://guide.ellieplanner.com/features/tasks/due-dates#reminder-notifications
                          window.open(
                            "https://guide.ellieplanner.com/features/tasks/due-dates#reminder-notifications",
                            "_blank"
                          );
                        }}
                      >
                        Learn more <CgExternal className="w-3 h-3" />
                      </div>
                    )}
                  </div>
                </div>
                {due_date_settings?.notifications_enabled && (
                  <div className="settings-item-value w-full">
                    <div className="flex flex-col items-start gap-4 w-full">
                      <div className="flex flex-row gap-2 items-center w-full">
                        <span>Send notification</span>
                        <Select
                          className="w-36"
                          defaultValue={"0_day"}
                          value={
                            due_date_settings?.notification_meta?.stringValue ||
                            "0_day"
                          }
                          onChange={(value) => {
                            // The value will be the string value

                            // If the value is "0_day", "1_day", "2_day", "1_week",
                            // Set notification_time to 9AM
                            var notificationTime = null;
                            if (
                              ["0_day", "1_day", "2_day", "1_week"].includes(
                                value
                              )
                            ) {
                              setDueDateNotificationTime(
                                dayjs().hour(9).minute(0).second(0).toDate()
                              );
                              notificationTime = dayjs()
                                .hour(9)
                                .minute(0)
                                .second(0)
                                .toDate();
                            } else {
                              setDueDateNotificationTime(null);
                            }

                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  power_feature_settings: {
                                    due_dates: {
                                      notification_meta:
                                        notificationMetas[value],
                                      notification_time: notificationTime,
                                    },
                                  },
                                },
                                previousValues: {
                                  power_feature_settings:
                                    power_feature_settings,
                                },
                              })
                            );
                          }}
                          options={
                            Object.keys(notificationMetas).map((key) => {
                              return {
                                value: notificationMetas[key].stringValue,
                                label: notificationMetas[key].title,
                              };
                            }) || []
                          }
                        />
                      </div>
                      {shouldShowTimeSelection() && (
                        <div className="flex flex-row gap-2 items-center w-full">
                          <div>At</div>
                          <div className="w-[120px] text-sm">
                            <CustomTimePicker
                              time_format={time_format}
                              value={dayjs(
                                dueDateNotificationTime.toDate
                                  ? dueDateNotificationTime.toDate()
                                  : dueDateNotificationTime
                              ).format(
                                time_format === "12_hour" ? "hh:mm A" : "HH:mm"
                              )}
                              onChange={(timeString) => {
                                const timeParts = timeString.split(/[: ]/);
                                let hours = parseInt(timeParts[0], 10);
                                const minutes = parseInt(timeParts[1], 10);
                                const ampm = timeParts[2];

                                // Convert 12-hour format to 24-hour format
                                if (ampm === "PM" && hours < 12) hours += 12;
                                if (ampm === "AM" && hours === 12) hours = 0;

                                // Get the current date or task's start date
                                var date = dueDateNotificationTime
                                  ? new Date(
                                      dueDateNotificationTime.toDate
                                        ? dueDateNotificationTime.toDate()
                                        : dueDateNotificationTime
                                    )
                                  : new Date();

                                // Set the selected time to the date
                                date.setHours(hours);
                                date.setMinutes(minutes);
                                date.setSeconds(0);

                                setDueDateNotificationTime(date);

                                dispatch(
                                  updateCurrentUser({
                                    newValues: {
                                      power_feature_settings: {
                                        due_dates: {
                                          notification_time: date,
                                        },
                                      },
                                    },
                                    previousValues: {
                                      power_feature_settings:
                                        power_feature_settings,
                                    },
                                  })
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="text-neutral-500 text-[13px]">
                        (note: changing these settings only impact future tasks.
                        You can also modify the notification time on a per-task
                        basis.)
                      </div>
                    </div>
                  </div>
                )}

                {!isDesktopApp() && (
                  <div className="w-full text-blue-600 bg-blue-100/40 dark:bg-blue-500/20 dark:text-blue-500 py-2 px-3 text-center rounded-lg text-sm mb-1">
                    Notifications are only available on the desktop and iOS apps
                    (at this time) 😓
                  </div>
                )}

                {due_date_settings?.notifications_enabled && (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-2 items-center w-full">
                      <span>Enabled for this device</span>
                      <Switch
                        size="small"
                        checked={reminderNotificationsEnabled}
                        onChange={(value) => {
                          localStorage.setItem(
                            "ellie_reminder_notifications_enabled",
                            value ? "true" : "false"
                          );

                          dispatch(setReminderNotificationsEnabled(value));
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="settings-rowdivider" />
            </>
          )}

        <div className="settings-item">
          <div className="settings-item-title">Troubleshoot notifications</div>
          <div className="flex flex-row gap-4">
            <div
              className="button"
              onClick={() => {
                // Just send a test notification
                // Using the HTML5 Push API
                // That says "Test notification" and "This is a test notification"

                new Notification("Test notification", {
                  body: "This is a test notification",
                });
              }}
            >
              Send test notification
            </div>
            <div
              className="flex items-center text-xs gap-1 text-purple-500 cursor-pointer hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-500"
              onClick={() => {
                // Open an email to chris@aloa.co
                window.open("mailto:chris@aloa.co", "_blank");
              }}
            >
              Don't see the test notification?{" "}
              <CgExternal className="w-3 h-3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
  toggleSettings,
} from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Modal, Popover, Select, Switch } from "antd";
import { FcGoogle } from "react-icons/fc";
import { TbPlus } from "react-icons/tb";

import axios from "axios";
import { analytics, googleServerUrl, isDev, outlookServerUrl } from "../../utils";
import { AiOutlineApple } from "react-icons/ai";
import { FaAppStoreIos } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

import calenderImageDark from "../../images/cal_integration_dark.png";
import calenderImageLight from "../../images/cal_integration.png";
import { BiCalendar, BiChevronDown, BiChevronRight } from "react-icons/bi";
import { FiCalendar, FiCheck, FiCheckCircle, FiCircle } from "react-icons/fi";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import _, { set } from "lodash";
import { openDB } from "idb";
import { auth, db } from "../../firebase";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import AddAppleCalModal from "../Calendar/AddAppleCalModal";
import { fetchBothCalendarEvents } from "../../redux/calendarSlice";
import { IoSettingsOutline } from "react-icons/io5";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { MdOutlineSyncAlt } from "react-icons/md";
import { toast } from "sonner";
import { FaChevronRight } from "react-icons/fa";
import UpgradeBanner from "../Upgrade/UpgradeBanner";

const { Option } = Select;

export default function Calendars() {
  const [calendars, setCalendars] = useState({});
  const [appleCalendars, setAppleCalendars] = useState({});
  const [outlookCalendars, setOutlookCalendars] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [loadedAppleCalendars, setLoadedAppleCalendars] = useState(false);
  const [loadedOutlookCalendars, setLoadedOutlookCalendars] = useState(false);
  const [connectAppleCalModal, setConnectAppleCalModal] = useState(false);
  const [showExtendedSettings, setShowExtendedSettings] = useState(false);
  const [extendedSettingsData, setExtendedSettingsData] = useState({});

  const { currentUser } = useSelector((state) => state.app);
  const { active_calendars = {}, calendar_accounts = {} } = useSelector(
    (state) => state.app.currentUser
  );

  const [errors, setErrors] = useState({});

  const {
    calendar_integration = "separate",
    default_google_calendar,
    outlook_calendar_integration = "separate",
    default_outlook_calendar,
  } = useSelector((state) => state.app.currentUser);

  const userId = useSelector((state) => state.app.uid);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const dispatch = useDispatch();

  const { user_theme = "system" } = useSelector(
    (state) => state.app.currentUser
  );

  function getThemeFromUser(user_theme) {
    if (user_theme == "system") {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    } else {
      return user_theme;
    }
  }

  function disconnectCalendar(accountId) {
    console.log("disconnecting google calendar");
    axios
      .get(`${googleServerUrl}/removeGoogleAccount`, {
        params: {
          userId: userId,
          accountId: accountId,
        },
      })
      .then((response) => {
        dispatch(
          setToastVisible({
            toastType: "success",
            message: "Google account disconnected",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          setToastVisible({
            toastType: "success",
            message: "Error disconnecting account, please contact support",
          })
        );
      });
  }

  const disconnectAppleCalendar = async (accountId) => {
    console.log("disconnecting apple calendar");
    //map through active_calendars & calendar_accounts and delete the one with the accountId
    try {
      const activeCalendarsClone = _.cloneDeep(active_calendars);

      const calendarAccountsClone = _.cloneDeep(calendar_accounts);

      if (calendarAccountsClone[accountId]) {
        delete calendarAccountsClone[accountId];
      }

      if (activeCalendarsClone[accountId]) {
        delete activeCalendarsClone[accountId];
      }

      // Use updated firebase syntax
      await updateDoc(doc(db, "users", userId), {
        calendar_accounts: calendarAccountsClone,
        active_calendars: activeCalendarsClone,
      });

      console.log("updated user");
      dispatch(
        setToastVisible({
          toastType: "success",
          message: "Apple account disconnected",
        })
      );
      dispatch(fetchBothCalendarEvents());
    } catch (err) {
      console.log(err);
      dispatch(
        setToastVisible({
          toastType: "success",
          message: "Error disconnecting account, please contact support",
        })
      );
    }
  };

  async function disconnectOutlookCalendar(accountId) {
    console.log("disconnecting outlook calendar");
    const idToken = await auth.currentUser.getIdToken(true);
    axios
      .get(`${outlookServerUrl}/disconnectOutlookUser`, {
        params: {
          idToken: idToken,
          accountId: accountId,
        },
      })
      .then((response) => {
        dispatch(
          setToastVisible({
            toastType: "success",
            message: "Outlook account disconnected",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          setToastVisible({
            toastType: "error",
            message: "Error disconnecting account, please contact support",
          })
        );
      });
  }

  const handleCalendarDisconnect = async (type, accountId) => {
    try {
      switch (type) {
        case "Google":
          await disconnectCalendar(accountId);
          break;
        case "Apple":
          await disconnectAppleCalendar(accountId);
          break;
        case "Outlook":
          await disconnectOutlookCalendar(accountId);
          break;
        default:
          console.log("error disconnecting calendar");
          toast.error("Error disconnecting account, please contact support");
          break;
      }
      setShowExtendedSettings(false);
      setExtendedSettingsData({});
      console.log("success");
    } catch (e) {
      console.log("error disconnect error", e);
      toast.error("Error disconnecting account, please contact support");
    }
  };

  useEffect(() => {
    if (userId) {
      axios
        .get(`${googleServerUrl}/getCalendars`, {
          params: {
            userId: userId,
          },
        })
        .then((response) => {
          if (response.data) {
            setCalendars(response.data.calendars);
            setLoaded(true);
          } else {
            setLoaded(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoaded(true);
        });
    }
  }, [userId, Object.keys(currentUser.calendar_accounts || {}).length]);

  useEffect(() => {
    if (userId) {
      // Get apple calendars
      axios
        .get(`${googleServerUrl}/getAppleCalendars`, {
          params: {
            userId: userId,
          },
        })
        .then((response) => {
          if (response.data) {
            setAppleCalendars(response.data);
            setLoadedAppleCalendars(true);
          } else {
            setLoadedAppleCalendars(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadedAppleCalendars(true);
        });
    }
  }, [userId, Object.keys(currentUser.calendar_accounts || {}).length]);

  useEffect(() => {
    if (userId) {
      const fetchOutlookCalendars = async () => {
        const idToken = await auth.currentUser.getIdToken(true);
        axios
          .get(`${outlookServerUrl}/getOutlookCalendars`, {
            params: {
              idToken: idToken,
            },
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          })
          .then((response) => {
            if (response.data) {
              setOutlookCalendars(response.data.calendars);
              setLoadedOutlookCalendars(true);
            } else {
              setLoadedOutlookCalendars(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadedOutlookCalendars(true);
          });
      };

      fetchOutlookCalendars();
    }
  }, [userId, Object.keys(currentUser.calendar_accounts || {}).length]);

  const integrationOptions = [
    {
      key: "public",
      type: "google",
      title: "Add Ellie tasks to Google calendar",
      image:
        getThemeFromUser(user_theme) == "dark"
          ? require("../../images/cal_int_2_dark.png")
          : require("../../images/cal_int_2.png"),
    },
    {
      key: "private",
      type: "google",
      title: "Add Ellie tasks, but keep them private",
      image:
        getThemeFromUser(user_theme) == "dark"
          ? require("../../images/cal_int_3_dark.png")
          : require("../../images/cal_int_3.png"),
    },
    {
      key: "public",
      type: "outlook",
      title: "Add Ellie tasks to Outlook calendar",
      image:
        getThemeFromUser(user_theme) == "dark"
          ? require("../../images/cal_int_2_dark.png")
          : require("../../images/cal_int_2.png"),
    },
    {
      key: "private",
      type: "outlook",
      title: "Add Ellie tasks, but keep them private",
      image:
        getThemeFromUser(user_theme) == "dark"
          ? require("../../images/cal_int_3_dark.png")
          : require("../../images/cal_int_3.png"),
    },
  ];

  function getPrimaryCalendar(account) {
    if (account?.calendars) {
      for (const calendar of account?.calendars) {
        if (calendar?.primary || calendar?.isDefaultCalendar) {
          return { firstAccount: account, primaryCalendar: calendar };
        }
      }
    }

    return { firstAccount: null, primaryCalendar: null };
  }

  async function createPushNotificationChannel({
    accountId, // Google account id
    calendarId, // Google calendar id
    type,
  }) {
    if (type === "Outlook") {
      const idToken = await auth.currentUser.getIdToken(true);
      axios
        .post(
          `${outlookServerUrl}/createSubscription`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "true",
            },
            params: {
              accountId: accountId,
              calendarId: calendarId,
              idToken: idToken,
              url: isDev
                ? "https://abc4-99-49-245-131.ngrok-free.app"
                : "https://valhalla.ellieplanner.com",
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }

    if (type === "Google") {
      axios
        .post(
          `${googleServerUrl}/createPushNotificationChannelRequest`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              userId: userId,
              accountId: accountId,
              calendarId: calendarId,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log("error creating push notification channel", error);
        });
    }
  }

  function checkIfUserHasCorrectCalendarAccount(type) {
    if (currentUser.calendar_accounts) {
      for (const account of Object.values(currentUser.calendar_accounts)) {
        if (account.type === type) {
          return true;
        }
      }
    }

    return false;
  }

  const sortAccounts = (a, b) => {
    const order = { google: 1, apple: 2, outlook: 3 };
    return order[a.type] - order[b.type];
  };

  return (
    <div className="h-full w-full">
        <UpgradeBanner featureName="Calendar integrations" plural={true} />

      <AddAppleCalModal
        open={connectAppleCalModal}
        setOpen={setConnectAppleCalModal}
      />
      {showExtendedSettings && (
        <ExtendedSettings
          setShowExtendedSettings={setShowExtendedSettings}
          setExtendedSettingsData={setExtendedSettingsData}
          extendedSettingsData={extendedSettingsData}
          handleCalendarDisconnect={handleCalendarDisconnect}
          default_google_calendar={default_google_calendar}
          calendar_integration={calendar_integration}
          outlook_calendar_integration={outlook_calendar_integration}
          currentUser={currentUser}
          dispatch={dispatch}
          getPrimaryCalendar={getPrimaryCalendar}
          calendars={calendars}
          createPushNotificationChannel={createPushNotificationChannel}
          integrationOptions={integrationOptions}
          loaded={loaded}
          default_outlook_calendar={default_outlook_calendar}
          outlookCalendars={outlookCalendars}
          loadedOutlookCalendars={loadedOutlookCalendars}
        />
      )}
      {!showExtendedSettings && (
        <>
          {currentUser?.calendar_accounts &&
            Object.values(currentUser?.calendar_accounts).length > 0 && (
              <div>
                <div className="flex-col">
                  <div className="text-2xl font-semibold mb-2">
                    Connected calendars
                  </div>
                  <div className="text-neutral-500 font-medium">
                    Show calendar events in Ellie and optionally add tasks to
                    Google calendar
                  </div>
                </div>
                <div className="flex flex-col gap-2 my-4">
                  {currentUser.calendar_accounts &&
                    Object.values(currentUser.calendar_accounts)
                      .sort(sortAccounts)
                      .map((account) => {
                        return (
                          <>
                            {account.type === "google" && (
                              <CalendarAccountCard
                                icon={<FcGoogle className="w-7 h-7 m-2" />}
                                email={account.email || account.id}
                                accountId={account.id}
                                type="Google"
                                setShowExtendedSettings={
                                  setShowExtendedSettings
                                }
                                setExtendedSettingsData={
                                  setExtendedSettingsData
                                }
                                calendarDisconnectHandler={disconnectCalendar}
                                isPrimaryCalendar={
                                  account.id ===
                                  default_google_calendar?.accountId
                                }
                                twoWaySyncEnabled={
                                  calendar_integration !== "separate" &&
                                  account.id ===
                                    default_google_calendar?.accountId
                                }
                              />
                            )}
                            {account.type === "apple" && (
                              <CalendarAccountCard
                                icon={
                                  <AiOutlineApple className="w-7 h-7 m-2" />
                                }
                                email={account.email || account.id}
                                accountId={account.id}
                                type="Apple"
                                setShowExtendedSettings={
                                  setShowExtendedSettings
                                }
                                setExtendedSettingsData={
                                  setExtendedSettingsData
                                }
                                calendarDisconnectHandler={
                                  disconnectAppleCalendar
                                }
                              />
                            )}
                            {account.type === "outlook" && (
                              <CalendarAccountCard
                                icon={
                                  <img
                                    src={require("../../images/icons8-outlook-calendar-480.png")}
                                    alt="outlook icon"
                                    className="w-7 h-7 m-2"
                                  />
                                }
                                email={account.email || account.id}
                                accountId={account.id}
                                type="Outlook"
                                setShowExtendedSettings={
                                  setShowExtendedSettings
                                }
                                setExtendedSettingsData={
                                  setExtendedSettingsData
                                }
                                calendarDisconnectHandler={
                                  disconnectOutlookCalendar
                                }
                                twoWaySyncEnabled={
                                  outlook_calendar_integration !== "separate" &&
                                  account.id ===
                                    default_outlook_calendar?.accountId
                                }
                              />
                            )}
                          </>
                        );
                      })}
                </div>
                <div
                  class="settings-rowdivider"
                  style={{ margin: "25px 0px" }}
                ></div>
              </div>
            )}
          <div className="font-medium mb-4 text-base">Link a new calendar</div>
          <div className="flex justify-evenly gap-2">
            <AddCalendarButtons
              type={"Google"}
              icon={<FcGoogle className="w-5 h-5" />}
              onClick={() => {
                // Use axios to call google login in a new window
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  window.open(
                    googleServerUrl + "/googleLogin?userId=" + userId
                  );
                }
              }}
            />
            <AddCalendarButtons
              type={"Apple"}
              icon={<AiOutlineApple className="w-5 h-5" />}
              onClick={() => {
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  setConnectAppleCalModal(true);
                }
              }}
            />

            <AddCalendarButtons
              type={"Outlook"}
              icon={
                <img
                  src={require("../../images/icons8-outlook-calendar-480.png")}
                  alt="outlook icon"
                  className="w-5 h-5"
                />
              }
              onClick={() => {
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  window.open(
                    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=66012393-dd4e-4e12-96cc-6a83c295b460&response_type=code&redirect_uri=https://app.ellieplanner.com/outlook-oauth&response_mode=query&scope=offline_access%20Calendars.ReadWrite%20User.Read"
                  );
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function CalIntegrationOption({ option, isSelected, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`integration-option ${isSelected ? "selected" : ""} my-4 `}
      key={option.key}
    >
      <img src={option.image} />

      <div className="meta ph-mask">
        {isSelected && <BsCheckCircleFill />}
        {!isSelected && <BsCircle />}
        <div className="title">{option.title}</div>
      </div>
    </div>
  );
}

const CalendarAccountCard = ({
  icon,
  email,
  accountId,
  type,
  setShowExtendedSettings,
  setExtendedSettingsData,
  twoWaySyncEnabled = false,
}) => {
  const handleShowExtendedSettings = () => {
    setShowExtendedSettings(true);
    setExtendedSettingsData({
      type,
      email,
      icon,
      accountId,
    });
  };

  return (
    <div className=" flex flex-col py-4 px-4 border border-neutral-200 dark:border-neutral-700 rounded-xl gap-4 group">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {icon}
          <div className="flex flex-col gap-1">
            <div className="font-medium">{email}</div>
            {twoWaySyncEnabled && (
              <div className="bg-green-100 text-green-700 dark:bg-green-800/70 dark:text-green-300 font-semibold rounded-md px-2 py-1 max-w-fit text-xs flex gap-1 items-center">
                2-way sync enabled
              </div>
            )}
          </div>
        </div>
        <div
          className="button border border-[#CACACA] dark:border-[#373737] p-1 rounded-lg text-xs font-medium"
          onClick={handleShowExtendedSettings}
        >
          <div className="flex flex-row gap-0 items-center">
            Manage <BiChevronRight className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

const ExtendedSettings = ({
  setShowExtendedSettings,
  setExtendedSettingsData,
  extendedSettingsData,
  handleCalendarDisconnect,
  default_google_calendar,
  calendar_integration,
  outlook_calendar_integration,
  currentUser,
  dispatch,
  getPrimaryCalendar,
  calendars,
  createPushNotificationChannel,
  integrationOptions,
  loaded,
  default_outlook_calendar,
  outlookCalendars,
  loadedOutlookCalendars,
}) => {
  const { type, email, icon, accountId = null } = extendedSettingsData;
  const enabledAccountEmail =
    calendars[default_google_calendar?.accountId]?.email;

  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div
        onClick={() => {
          setShowExtendedSettings(false);
          setExtendedSettingsData(null);
        }}
        className="back-button"
      >
        <ArrowLeftCircleIcon className="back-button-icon" />
        Back
      </div>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center">
          <div>{icon}</div>
          <div className="text-2xl font-medium">{type} Calendar</div>
        </div>
        <div>
          <div className="text-sm text-neutral-500">{email}</div>
        </div>
      </div>
      <div className="w-full bg-neutral-200 h-[0.5px]" />
      {type === "Apple" && (
        <InfoMessage
          message={
            "Apple's calendar system doesn't current support 2 way syncing 🥲"
          }
          bgColor={"bg-orange-50"}
          darkBgColor={"bg-orange-600"}
          textColor={"text-orange-800"}
          darkTextColor={"text-white"}
        />
      )}
      {((type === "Google" &&
        calendar_integration !== "separate" &&
        accountId !== default_google_calendar?.accountId) ||
        (type === "Outlook" &&
          outlook_calendar_integration !== "separate" &&
          accountId !== default_outlook_calendar?.accountId)) && (
        <InfoMessage
          message={
            <div>
              It's looks like you already have 2 way syncing enabled for{" "}
              {enabledAccountEmail}. Disable it first to enable it for this
              account.
            </div>
          }
          bgColor={"bg-orange-50"}
          darkBgColor={"bg-orange-600"}
          textColor={"text-orange-800"}
          darkTextColor={"text-white"}
        />
      )}
      <div
        className={`flex items-center justify-between mt-2 rounded-lg ${
          (type === "Apple" ||
            (default_google_calendar?.accountId != null &&
              default_google_calendar?.accountId !== accountId) ||
            (default_outlook_calendar?.accountId != null &&
              default_outlook_calendar?.accountId !== accountId)) &&
          "opacity-30 cursor-not-allowed"
        }`}
      >
        <div className="flex flex-col gap-2 w-9/12">
          <div className="flex items-center gap-2 text-base font-medium">
            Add tasks to {type} Calendar
          </div>
          <div className="">
            Choose to add Ellie tasks to your {type} calendar, or keep them
            separate{" "}
            <span className="font-medium">
              Note: this only applies to new tasks, and existing tasks will not
              be updated
            </span>
          </div>
        </div>
        {(type === "Google" || type === "Outlook") && (
          <SyncSwitch
            calendar_integration={calendar_integration}
            outlook_calendar_integration={outlook_calendar_integration}
            currentUser={currentUser}
            dispatch={dispatch}
            getPrimaryCalendar={getPrimaryCalendar}
            calendars={calendars}
            default_google_calendar={default_google_calendar}
            createPushNotificationChannel={createPushNotificationChannel}
            localAccountId={accountId}
            type={type}
            default_outlook_calendar={default_outlook_calendar}
            outlookCalendars={outlookCalendars}
          />
        )}
        {type === "Apple" && (
          <>
            <Switch disabled={true} />
          </>
        )}
      </div>

      {(type === "Google" || type === "Outlook") &&
        (default_google_calendar?.accountId === accountId ||
          default_outlook_calendar?.accountId === accountId) && (
          <>
            <SyncOptions
              calendar_integration={calendar_integration}
              integrationOptions={integrationOptions}
              dispatch={dispatch}
              currentUser={currentUser}
              loaded={loaded}
              calendars={calendars}
              createPushNotificationChannel={createPushNotificationChannel}
              localAccountId={accountId}
              outlook_calendar_integration={outlook_calendar_integration}
              outlookCalendars={outlookCalendars}
              type={type}
              loadedOutlookCalendars={loadedOutlookCalendars}
            />
          </>
        )}
      <div className="w-full flex justify-end my-10">
        <div
          className="bg-red-500 text-white rounded-lg px-2 py-2 font-medium text-sm cursor-pointer hover:bg-red-600 ease-in duration-100"
          onClick={() => handleCalendarDisconnect(type, accountId)}
        >
          Disconnect
        </div>
      </div>
    </div>
  );
};

const AddCalendarButtons = ({ type, onClick, icon }) => {
  return (
    <div
      className=" border border-neutral-200 dark:border-neutral-700 rounded-full flex items-center gap-2 justify-center px-4 py-2 cursor-pointer hover:border-purple-500 ease-in duration-100 w-full"
      onClick={onClick}
    >
      <div>{icon}</div>
      <div>Add {type}</div>
    </div>
  );
};

const SyncSwitch = ({
  calendar_integration,
  outlook_calendar_integration,
  currentUser,
  dispatch,
  getPrimaryCalendar,
  calendars,
  default_google_calendar,
  createPushNotificationChannel,
  localAccountId,
  type,
  default_outlook_calendar,
  outlookCalendars,
}) => {
  const userId = useSelector((state) => state.app.uid);
  const updateGoogleIntegration = async (checked) => {
    try {
      if (
        outlook_calendar_integration === "public" ||
        outlook_calendar_integration === "private"
      ) {
        dispatch(
          updateCurrentUser({
            newValues: {
              outlook_calendar_integration: "separate",
            },
            previousValues: {
              outlook_calendar_integration:
                currentUser.outlook_calendar_integration,
            },
          })
        );
      }

      const { firstAccount, primaryCalendar } = getPrimaryCalendar(
        calendars[localAccountId]
      );

      var accountId = firstAccount ? firstAccount.id : null;
      var calendarId = primaryCalendar ? primaryCalendar.id : null;

      var updates = {
        newValues: {
          calendar_integration: checked ? "public" : "separate",
        },
        previousValues: {
          calendar_integration: currentUser.calendar_integration,
        },
      };

      if (!checked) {
        updates.newValues.default_google_calendar = null;
      }

      // If there is no default_google_calendar set, we need to set it
      if (checked && !default_google_calendar) {
        updates.newValues.default_google_calendar = {
          accountId: accountId,
          calendarId: calendarId,
        };

        createPushNotificationChannel({
          accountId: accountId,
          calendarId: calendarId,
          type: "Google",
        });
      }

      dispatch(updateCurrentUser(updates));
    } catch (error) {
      console.log(error);
    }
  };

  const updateOutlookIntegration = async (checked) => {
    if (
      calendar_integration === "public" ||
      calendar_integration === "private"
    ) {
      dispatch(
        updateCurrentUser({
          newValues: {
            calendar_integration: "separate",
          },
          previousValues: {
            calendar_integration: currentUser.calendar_integration,
          },
        })
      );
    }

    const { firstAccount, primaryCalendar } = getPrimaryCalendar(
      outlookCalendars[localAccountId]
    );

    var accountId = firstAccount ? firstAccount.id : null;
    var calendarId = primaryCalendar ? primaryCalendar.id : null;

    var updates = {
      newValues: {
        outlook_calendar_integration: checked ? "public" : "separate",
      },
      previousValues: {
        outlook_calendar_integration: currentUser.outlook_calendar_integration,
      },
    };

    if (!checked) {
      updates.newValues.default_outlook_calendar = null;
      try {
        const outlookCalendarChannelsSnapshot = await getDocs(
          collection(db, "users", userId, "outlookCalendarChannels")
        );
        var subscriptionIds = [];
        outlookCalendarChannelsSnapshot.forEach((doc) => {
          subscriptionIds.push(doc.id);
        });

        if (subscriptionIds.length > 0) {
          subscriptionIds.forEach(async (subscriptionId) => {
            try {
              const response = await axios.post(
                `${outlookServerUrl}/deleteSubscription`,
                {
                  subscriptionId: subscriptionId,
                  userId: userId,
                  accountId: accountId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              console.log(response.data.message);
            } catch (err) {
              console.log(err);
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    // If there is no default_google_calendar set, we need to set it
    if (checked && !default_outlook_calendar) {
      updates.newValues.default_outlook_calendar = {
        accountId: accountId,
        calendarId: calendarId,
      };

      createPushNotificationChannel({
        accountId: accountId,
        calendarId: calendarId,
        type: type,
      });
    }

    // Only save the user if there is an accountId, calendarId, and type
    if (accountId && calendarId && type) {
      dispatch(updateCurrentUser(updates));
    }
  };

  return (
    <Switch
      // size="medium"
      style={{ marginLeft: "10px" }}
      checked={
        (type === "Outlook" &&
          (outlook_calendar_integration === "public" ||
            outlook_calendar_integration === "private") &&
          default_outlook_calendar?.accountId === localAccountId) ||
        (type === "Google" &&
          (calendar_integration === "public" ||
            calendar_integration === "private") &&
          default_google_calendar?.accountId === localAccountId)
      }
      onChange={(checked) => {
        if (type === "Google") {
          updateGoogleIntegration(checked, type);
        }

        if (type === "Outlook") {
          updateOutlookIntegration(checked, type);
        }

        analytics("Calendar 2-way sync updated", {
          source: "Web",
          type: type === "Google" ? "Google" : "Outlook",
          enabled: checked,
        });
      }}
      disabled={
        (default_google_calendar?.accountId != null &&
          default_google_calendar?.accountId !== localAccountId) ||
        (default_outlook_calendar?.accountId != null &&
          default_outlook_calendar?.accountId !== localAccountId)
      }
    />
  );
};

const SyncOptions = ({
  calendar_integration,
  integrationOptions,
  dispatch,
  currentUser,
  loaded,
  calendars,
  createPushNotificationChannel,
  localAccountId,
  outlook_calendar_integration,
  outlookCalendars,
  type,
  loadedOutlookCalendars,
}) => {
  var account;

  if (type === "Google") {
    account = calendars[localAccountId];
  } else if (type === "Outlook") {
    account = outlookCalendars[localAccountId];
  } else {
    account = null;
  }

  return (
    <>
      {type === "Google" && (
        <div className="flex flex-col gap-2">
          {calendar_integration !== "separate" && (
            <div className="integration-options">
              {integrationOptions.map((option) => {
                if (option.type === "google") {
                  const isSelected = calendar_integration === option.key;

                  return (
                    <CalIntegrationOption
                      option={option}
                      isSelected={isSelected}
                      onClick={() => {
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              calendar_integration: option.key,
                            },
                            previousValues: {
                              calendar_integration:
                                currentUser.calendar_integration,
                            },
                          })
                        );
                      }}
                    />
                  );
                }

                return null;
              })}
            </div>
          )}

          {(calendar_integration !== "separate" ||
            outlook_calendar_integration !== "separate") &&
            currentUser.calendar_accounts &&
            loaded && (
              <>
                <div
                  class="settings-rowdivider"
                  style={{ margin: "25px 0px" }}
                ></div>
                <div className="default-calendar pl-2">
                  <div className="meta">
                    <div className="title font-medium">Default calendar</div>
                    <div className="subtitle">
                      Timeboxed tasks will be added to this calendar by default
                    </div>
                  </div>
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    content={
                      <div className="calendar-settings-inner">
                        <div className="calendar-settings-title">
                          Active calendars
                        </div>
                        <div className="calendar-settings-content">
                          <div className="calendar-settings-item">
                            <div className="calendar-account">
                              {account?.email}
                            </div>
                            <div className="claendar-list">
                              {account &&
                                account.calendars.map((calendar) => {
                                  const isSelected =
                                    currentUser &&
                                    currentUser.default_google_calendar &&
                                    currentUser.default_google_calendar
                                      .calendarId === calendar.id;

                                  return (
                                    <div
                                      onClick={() => {
                                        // Let's set the default_calendar on the user (it needs to have accountId and calendarId)

                                        createPushNotificationChannel({
                                          accountId: localAccountId,
                                          calendarId: calendar.id,
                                          type: type,
                                        });

                                        dispatch(
                                          updateCurrentUser({
                                            newValues: {
                                              default_google_calendar: {
                                                accountId: localAccountId,
                                                calendarId: calendar.id,
                                              },
                                            },
                                            previousValues: {
                                              default_google_calendar:
                                                currentUser.default_google_calendar,
                                            },
                                          })
                                        );
                                      }}
                                      className="calendar-list-item"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="info">
                                        <FiCalendar
                                          className="calendar-icon"
                                          style={{
                                            color: calendar.backgroundColor,
                                          }}
                                        />
                                        <span> {calendar.summary}</span>
                                      </div>

                                      {isSelected && <FiCheck />}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className="calendar-dropdown">
                      <div className="info">
                        <FiCalendar />
                        {currentUser && currentUser.default_google_calendar
                          ? calendars?.[
                              currentUser.default_google_calendar.accountId
                            ]?.calendars?.find(
                              (calendar) =>
                                calendar.id ==
                                currentUser.default_google_calendar.calendarId
                            )?.summary || "Select a calendar"
                          : "Select a calendar"}
                      </div>
                      <BiChevronDown />
                    </div>
                  </Popover>
                </div>
              </>
            )}
        </div>
      )}
      {type === "Outlook" && (
        <div className="flex flex-col gap-2">
          {outlook_calendar_integration !== "separate" && (
            <div className="integration-options">
              {integrationOptions.map((option) => {
                if (option.type === "outlook") {
                  const isSelected =
                    outlook_calendar_integration === option.key;

                  return (
                    <CalIntegrationOption
                      option={option}
                      isSelected={isSelected}
                      onClick={() => {
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              outlook_calendar_integration: option.key,
                            },
                            previousValues: {
                              outlook_calendar_integration:
                                currentUser.outlook_calendar_integration,
                            },
                          })
                        );
                      }}
                    />
                  );
                }

                return null;
              })}
            </div>
          )}

          {outlook_calendar_integration !== "separate" &&
            currentUser.calendar_accounts &&
            loadedOutlookCalendars && (
              <>
                <div
                  class="settings-rowdivider"
                  style={{ margin: "25px 0px" }}
                ></div>
                <div className="default-calendar !pl-2">
                  <div className="meta">
                    <div className="title font-medium">Default calendar</div>
                    <div className="subtitle">
                      Timeboxed tasks will be added to this calendar by default
                    </div>
                  </div>
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    content={
                      <div className="calendar-settings-inner">
                        <div className="calendar-settings-title">
                          Active calendars
                        </div>
                        <div className="calendar-settings-content">
                          <div className="calendar-settings-item">
                            <div className="calendar-account">
                              {account?.email}
                            </div>
                            <div className="claendar-list">
                              {account?.calendars.map((calendar) => {
                                const isSelected =
                                  currentUser &&
                                  currentUser.default_outlook_calendar &&
                                  currentUser.default_outlook_calendar
                                    .calendarId === calendar.id;

                                return (
                                  <div
                                    onClick={() => {
                                      // Let's set the default_calendar on the user (it needs to have accountId and calendarId)

                                      createPushNotificationChannel({
                                        accountId: localAccountId,
                                        calendarId: calendar.id,
                                        type: type,
                                      });

                                      dispatch(
                                        updateCurrentUser({
                                          newValues: {
                                            default_outlook_calendar: {
                                              accountId: localAccountId,
                                              calendarId: calendar.id,
                                            },
                                          },
                                          previousValues: {
                                            default_outlook_calendar:
                                              currentUser.default_outlook_calendar,
                                          },
                                        })
                                      );
                                    }}
                                    className="calendar-list-item"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="info">
                                      <FiCalendar
                                        className="calendar-icon"
                                        style={{
                                          color: calendar.backgroundColor,
                                        }}
                                      />
                                      <span> {calendar?.name}</span>
                                    </div>

                                    {isSelected && <FiCheck />}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className="calendar-dropdown">
                      <div className="info">
                        <FiCalendar />
                        {currentUser && currentUser.default_outlook_calendar
                          ? outlookCalendars?.[
                              currentUser.default_outlook_calendar.accountId
                            ]?.calendars?.find(
                              (calendar) =>
                                calendar.id ===
                                currentUser.default_outlook_calendar.calendarId
                            )?.name || "Select a calendar"
                          : "Select a calendar"}
                      </div>
                      <BiChevronDown />
                    </div>
                  </Popover>
                </div>
              </>
            )}
        </div>
      )}
    </>
  );
};

const InfoMessage = ({
  message,
  bgColor = "bg-blue-50",
  textColor = "text-blue-800",
  darkBgColor = "bg-blue-600",
  darkTextColor = "text-white",
}) => {
  return (
    <div
      className={`text-sm ${textColor} ${bgColor} ${darkBgColor} ${darkTextColor} py-2 px-4 rounded-lg my-4`}
    >
      {message}
    </div>
  );
};

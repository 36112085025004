import { IoMdClose } from "react-icons/io";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function PlannerContainer({
  sidebarContent,
  mainContent,
  backButton,
  nextButton,
  step,
}) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white dark:bg-[#060606] dark:text-neutral-200 flex flex-row gap-4 items-center h-screen w-full"
      style={{ WebkitAppRegion: "drag" }}
    >
      <div className="w-72 pl-6 h-screen pt-10 pb-6 flex flex-col justify-between">
        {sidebarContent}

        <div className="flex flex-row gap-2 items-center"
             style={{ WebkitAppRegion: "no-drag" }}
        >
          {backButton}
          {nextButton}
        </div>
      </div>

      <div className="flex-grow p-2 h-screen relative">
        <div className="bg-[#FAFBFD] dark:bg-[#141415] border border-neutral-200 dark:border-none rounded-xl w-full h-full p-4 shadow-sm overflow-hidden dark:text-white text-neutral-800">
          {mainContent}
          <div
            onClick={() => {
              navigate("/");
            }}
            className="absolute top-6 right-6 bg-neutral-200 text-neutral-500 hover:bg-neutral-300 dark:bg-neutral-600 dark:text-white dark:hover:bg-neutral-700 p-2 rounded-full cursor-pointer"
            style={{ WebkitAppRegion: "no-drag" }}
          >
            <IoMdClose className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function NextButton({ onClick, final = false }) {
  return (
    <div
      onClick={onClick}
      className="h-10 bg-appPrimaryLight text-white rounded-xl cursor-pointer hover:bg-appPrimaryLightHover border border-neutral-200 dark:border-none shadow-md px-4 flex flex-row justify-between items-center flex-grow"
    >
      <div>{final ? "Finish" : "Next step"}</div>
      <IoArrowForward />
    </div>
  );
}

export function BackButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="h-10 bg-white dark:bg-neutral-600 text-neutral-700 dark:text-white rounded-xl cursor-pointer border border-neutral-200 dark:border-none hover:bg-neutral-100 dark:hover:bg-neutral-700 shadow-md px-4 flex flex-row justify-between items-center"
    >
      <IoArrowBack />
    </div>
  );
}

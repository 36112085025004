import { appMenu, nativeWindow, object } from "@todesktop/client-core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function DesktopFunctions() {
  const { float_ellie_on_top = false } = useSelector(
    (state) => state.app.currentUser
  );

  const toggleAlwaysOnTop = async (floatToTop) => {
    const winIdFromToDesktopBuilder = "sTozdF9t5eSE2zzH_57W8";

    const winRef = await object.retrieve({ id: winIdFromToDesktopBuilder });

    nativeWindow.setAlwaysOnTop({ ref: winRef }, floatToTop);
  };

  useEffect(() => {
    toggleAlwaysOnTop(float_ellie_on_top);
  }, [float_ellie_on_top]);

  return <div />;
}

import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { addSeconds, format } from "date-fns";
import { nativeWindow } from "@todesktop/client-core";

function DailyPlanningNotification() {
  const scheduledNotifications = useRef(new Map());

  const {
    dailyPlanningTime = "09:00",
    dailyShutDownTime = "18:00",
    dailyPlanningTimeEnabled = false,
    dailyShutDownTimeEnabled = false,
  } = useSelector((state) => state.app.currentUser || {});

  const daily_planning_enabled = useSelector(
    (state) =>
      state.app.currentUser?.power_feature_settings?.daily_planning_enabled
  );

  const clearScheduledNotifications = () => {
    scheduledNotifications.current.forEach((intervalId) => {
      clearInterval(intervalId);
    });
    scheduledNotifications.current.clear();
  };

  const scheduleNotification = (title, time) => {
    const scheduleForNextOccurrence = () => {
      const now = new Date();
      const [hours, minutes] = time.split(":");
      let notificationTime = new Date(now);
      notificationTime.setHours(
        parseInt(hours, 10),
        parseInt(minutes, 10),
        0,
        0
      );

      if (notificationTime <= now) {
        notificationTime.setDate(notificationTime.getDate() + 1);
      }

      const delay = notificationTime.getTime() - now.getTime();

      return setTimeout(() => {
        new Notification(title, {
          body: `It's time for your ${title}.`,
        }).onclick = () => {
          nativeWindow.focus();
        };

        // Schedule the next occurrence
        const nextIntervalId = scheduleForNextOccurrence();
        scheduledNotifications.current.set(title, nextIntervalId);
      }, delay);
    };

    const initialIntervalId = scheduleForNextOccurrence();
    scheduledNotifications.current.set(title, initialIntervalId);
  };

  useEffect(() => {
    if (daily_planning_enabled) {
      clearScheduledNotifications();

      if (dailyPlanningTimeEnabled) {
        scheduleNotification("Daily Planning", dailyPlanningTime);
      }

      if (dailyShutDownTimeEnabled) {
        scheduleNotification("Daily Shutdown", dailyShutDownTime);
      }
    } else {
      clearScheduledNotifications();
    }

    return () => {
      clearScheduledNotifications();
    };
  }, [
    dailyPlanningTime,
    dailyPlanningTimeEnabled,
    dailyShutDownTimeEnabled,
    dailyShutDownTime,
    daily_planning_enabled,
  ]);

  return null;
}

export default DailyPlanningNotification;

import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function KeyboardShortcutNavListener() {
  const { settingsVisible } = useSelector((state) => state.app);

  const navigate = useNavigate();

  const location = useLocation();

  const { daily_planning_enabled } = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        daily_planning_enabled: true,
      }
  );

  useHotkeys(
    "cmd+shift+p, ctrl+shift+p",
    (e) => {
      e.preventDefault();

      // if we are already at /daily-planning, go to /
      if (location.pathname === "/daily-planning") {
        navigate("/");
        return;
      }
      navigate("/daily-planning");
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA", "SELECT"],
      enabled: daily_planning_enabled,
    },
    [location]
  );

  return null;
}

import moment from "moment";
import React, { useState } from "react";

import { TbTargetArrow } from "react-icons/tb";
import { useSelector } from "react-redux";
import {
  calculateDaysToDueDate,
  calculateDaysToDueDateText,
  getIndicatorColorForDueDate,
} from "../../../../utils";

export default function DueDateIndiciator({
  item,
  action,
  preview = false,
  alwaysVisible = false,
}) {
  // If there is no due date, let's not even show this

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
      }
  );

  if(item.complete) {
    return null;
  }

  if (!item.due_date) return null;

  // Function to spit out color indicator based on due date

  if (!due_date_settings.enabled) return null;

  if (
    !alwaysVisible &&
    calculateDaysToDueDate(
      item.due_date.toDate ? item.due_date.toDate() : item.due_date
    ) > due_date_settings.number_of_days_to_show_indicator
  )
    return null;

  return (
    <div
      onClick={(event) => {
        action();
      }}
      data-no-dnd="true"
      className="flex flex-row items-center gap-1 text-[11px] text-[#8e8e8e] font-medium"
      style={{
        color: getIndicatorColorForDueDate(
          item.due_date.toDate ? item.due_date.toDate() : item.due_date
        ),
      }}
    >
      <TbTargetArrow className="w-3 h-3" />
      {calculateDaysToDueDateText(
        item.due_date.toDate ? item.due_date.toDate() : item.due_date
      )}
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";

import "./CardModal.css";
import _ from "lodash";
import TextareaAutosize from "react-textarea-autosize";

import CompleteInput from "../Kanban/Card/Components/CompleteInput";
import LabelSelector from "../Kanban/Card/Components/LabelSelector";
import Subtasks from "../Kanban/Card/Components/Subtasks";
import SubtasksIcon from "../Kanban/Card/Components/Subtasks/SubtaskIcon";

import { createTask } from "../../redux/tasksSlice";
import { createRecurringTask } from "../../redux/recurringTasksSlice";

import { useDispatch, useSelector } from "react-redux";

import OutsideClickHandler from "react-outside-click-handler";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { RiCloseLine, RiNotionFill, RiTimeLine } from "react-icons/ri";

import {
  setToastVisible,
  setColumnSelected,
  setCreateTaskModalActive,
} from "../../redux/appSlice";

import TimeSection from "../Kanban/Card/Components//TimeSection";
import { useHotkeys } from "react-hotkeys-hook";
import RecurringInput from "../Kanban/Card/Components//RecurringInput";
import DurationPicker from "../Kanban/Card/Components/DurationPicker";
import {
  generateTemplateFromTask,
  getClickupLinkForTask,
  getCraftDocsLinkForTask,
  getFigmaLinkForTask,
  getGoogleDocsLinkForTask,
  getLinearLinkForTask,
  getNotionLinkForTask,
  getSlackLinkForTask,
  isMac,
} from "../../utils";
import { Modal } from "antd";
import {
  TbCalendar,
  TbExternalLink,
  TbFlag,
  TbTag,
  TbTargetArrow,
} from "react-icons/tb";
import DatePickerDropdown from "../Sidebar/DatePicker/DatePickerDropdown";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { consoleSandbox } from "@sentry/utils";
import AutoGrowingTextAreaWithLinks from "./AutoGrowingTextAreaWithLinks";
import { AiOutlineConsoleSql } from "react-icons/ai";
import PrioritySelector from "../Generics/PrioritySelector";
import { PiSlackLogo } from "react-icons/pi";
import { FaFigma } from "react-icons/fa";
import { SiGoogledocs, SiLinear } from "react-icons/si";

export default function CreateTaskModal({}) {
  const { active, date, start_date, duration, listId } = useSelector(
    (state) => state.app.createTaskModalActive || {}
  );

  const priority_settings_enabled = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.priority_enabled || false
  );

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
      }
  );

  const { label_filters: activeLabels, rich_links = {} } = useSelector(
    (state) => state.app?.currentUser
  );

  const dispatch = useDispatch();
  const [itemEditable, setItemEditable] = useState({
    date: start_date ? start_date : null,
    id: uuidv4(),
    complete: false,
    start: start_date,
    estimated_time: duration,
    listId: listId ? listId : null,
  });

  useEffect(() => {
    if (active) {
      setItemEditable({
        ...itemEditable,
        date: start_date ? start_date : null,
        start: start_date,
        estimated_time: duration,
        recurring_id: null,
        listId: listId ? listId : null,
      });

      setRecurringTask(null);
    }
  }, [active, date, start_date, duration]);

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);
  const [recurringTask, setRecurringTask] = useState(null);

  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [dueDatePickerVisible, setDueDatePickerVisible] = useState(false);
  const [labelPickerActive, setLabelPickerActive] = useState(false);
  const [prioritySelectorVisible, setPrioritySelectorVisible] = useState(false);

  const [estimatedTimeActive, setEstimatedTimeActive] = useState(false);
  const [actualTimeActive, setActualTimeActive] = useState(false);
  const taskInputRef = useRef(null);
  async function updateItemEditableValue(key, value, save = false) {
    setItemEditable((tempItemEditable) => {
      return {
        ...tempItemEditable,
        [key]: value,
      };
    });
  }

  const [subtasksActive, setSubtasksActive] = useState(false);

  useHotkeys(
    "cmd+l, ctrl+l",
    (e) => {
      e.preventDefault();

      if (prioritySelectorVisible) {
        setPrioritySelectorVisible(false);
      }

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active, labelPickerActive, prioritySelectorVisible]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      dispatch(setCreateTaskModalActive(null));

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active, taskInputRef]
  );

  useEffect(() => {
    // If there is no label selected, but there is 1 label in activeLabels, select it
    if (!itemEditable.label && activeLabels && activeLabels.length === 1) {
      updateItemEditableValue("label", activeLabels[0]);
    }
  }, [activeLabels?.length, itemEditable.label]);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  useEffect(() => {
    if (itemEditable.recurring && recurringTask) {
      updateItemEditableValue("recurring_id", recurringTask.id, true);
    } else {
      updateItemEditableValue("recurring_id", null, true);
    }
  }, [itemEditable.recurring, recurringTask]);

  function createTaskFunction() {
    // If task description is not blank
    if (!isBlank(itemEditable.description)) {
      // If no labels are selected for this item, and there is more than 1 label filter active,
      // then notify the user that the item was hidden

      // Also create the recurring task, if there is one
      if (recurringTask) {
        const template = generateTemplateFromTask(itemEditable);
        const recurringTaskTemp = _.cloneDeep(recurringTask);
        recurringTaskTemp.task_template = template;

        dispatch(createRecurringTask({ recurringTask: recurringTaskTemp }));
      }

      // Clonse itemEditable and if there is no duration or start_date, remove them
      const itemEditableTemp = _.cloneDeep(itemEditable);
      if (!itemEditableTemp.estimated_time) {
        delete itemEditableTemp.estimated_time;
      }

      if (!itemEditableTemp.start) {
        delete itemEditableTemp.start;
      }

      // Let's go ahead and create
      dispatch(createTask(itemEditableTemp));

      setRecurringTask(null);

      setItemEditable({
        date: date ? moment(date, "YYYY-MM-DD").toDate() : null,
        description: "",
        estimated_time: null,
        label: null,
        id: uuidv4(),
        complete: false,
        listId: null,
      });
      dispatch(setCreateTaskModalActive(null));
    } else {
      dispatch(
        setToastVisible({
          toastType: "error",
          message: "Description cannot be blank. Please enter a description.",
        })
      );
    }
  }

  useHotkeys(
    "cmd+e, ctrl+e",
    (e) => {
      e.preventDefault();

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        setEstimatedTimeActive(true);
      }

      if (actualTimeActive) {
        setActualTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active, estimatedTimeActive, actualTimeActive]
  );

  useHotkeys(
    "cmd+b, ctrl+b",
    (e) => {
      e.preventDefault();

      if (actualTimeActive) {
        setActualTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        setActualTimeActive(true);
      }

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active, actualTimeActive, estimatedTimeActive]
  );

  // Use CMD+Enter, or CTRL+Enter to create task
  useHotkeys(
    "cmd+enter, ctrl+enter",
    (e) => {
      e.preventDefault();

      createTaskFunction();

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active]
  );

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={600}
      open={active}
      destroyOnClose={true}
      onOk={() => {
        dispatch(setCreateTaskModalActive(null));
      }}
      onCancel={() => {
        setItemEditable({
          date: start_date ? start_date : new Date(),
          id: uuidv4(),
          complete: false,
          start: start_date,
          estimated_time: duration,
          listId: listId ? listId : null,
        });

        dispatch(setCreateTaskModalActive(null));
      }}
      className="amazing-modal"
    >
      <div className="create-task-modal">
        <div className="card-modal-header editing">
          <ModalTextArea
            taskInputRef={taskInputRef}
            itemEditable={itemEditable}
            updateItemEditableValue={updateItemEditableValue}
            active={active}
          />
        </div>

        <div className="card-modal-body">
          <div className="modal-card-properties">
            <div className="modal-property">
              <div className="title">
                <TbCalendar className="icon" />
                Task date
              </div>
              <div className="value">
                <DatePickerDropdown
                  dateSelected={itemEditable.date}
                  isVisible={datePickerVisible}
                  setIsVisible={setDatePickerVisible}
                  onDateChange={(date) => {
                    updateItemEditableValue("date", date, true);
                    updateItemEditableValue("start", date, true);
                    //   setDatePickerVisible(false);
                  }}
                />
              </div>
            </div>

            {due_date_settings.enabled && (
              <div className="modal-property">
                <div className="title">
                  <TbTargetArrow className="icon" />
                  Due date
                </div>
                <div className="value">
                  <DatePickerDropdown
                    dateSelected={itemEditable.due_date}
                    isVisible={dueDatePickerVisible}
                    setIsVisible={setDueDatePickerVisible}
                    onDateChange={(date) => {
                      updateItemEditableValue("due_date", date, true);
                    }}
                    timeDisabled={true}
                    isDueDate={true}
                    complete={itemEditable.complete}
                  />
                </div>
              </div>
            )}

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Estimated time
              </div>
              <div className="value">
                <DurationPicker
                  time={
                    !isNaN(itemEditable.estimated_time)
                      ? itemEditable.estimated_time
                      : null
                  }
                  item={itemEditable}
                  title="Estimated"
                  updateTimeLocal={(newValue) => {
                    updateItemEditableValue("estimated_time", newValue, true);
                  }}
                  buttonMode={true}
                  durationPickerActive={estimatedTimeActive}
                  setDurationPickerActive={setEstimatedTimeActive}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Actual time
              </div>
              <div className="value">
                <DurationPicker
                  time={
                    !isNaN(itemEditable.actual_time)
                      ? itemEditable.actual_time
                      : null
                  }
                  item={itemEditable}
                  title="Actual"
                  updateTimeLocal={(newValue) => {
                    updateItemEditableValue("actual_time", newValue, true);
                  }}
                  buttonMode={true}
                  durationPickerActive={actualTimeActive}
                  setDurationPickerActive={setActualTimeActive}
                />
              </div>
            </div>

            {priority_settings_enabled && (
              <div className="modal-property">
                <div className="title">
                  <TbFlag className="icon" />
                  Priority
                </div>
                <div className="value">
                  {itemEditable && (
                    <PrioritySelector
                      priority={itemEditable?.priority}
                      item={itemEditable}
                      modal={true}
                      creation={true}
                      setPrioritySelectorVisible={setPrioritySelectorVisible}
                      prioritySelectorVisible={prioritySelectorVisible}
                      action={(newValue) => {
                        updateItemEditableValue("priority", newValue, true);
                      }}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="modal-property">
              <div className="title">
                <MdOutlineArrowDropDownCircle className="icon" />
                Label
              </div>
              <div className="value">
                <LabelSelector
                  label={itemEditable.label}
                  updateLabelLocal={(newValue) => {
                    updateItemEditableValue("label", newValue, true);
                  }}
                  labelPickerActive={labelPickerActive}
                  setLabelPickerActive={setLabelPickerActive}
                  buttonMode={true}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <MdOutlineArrowDropDownCircle className="icon" />
                Repeats
              </div>
              <div className="value">
                <RecurringInput
                  item={itemEditable}
                  recurringTaskLocal={recurringTask}
                  updateRecurringLocal={(newValue) => {
                    if (newValue) {
                      // There is a recurring task, let's set the itemEditable
                      updateItemEditableValue("recurring", true, true);
                    } else {
                      // There is no recurring task, let's set the itemEditable
                      updateItemEditableValue("recurring", false, true);
                    }
                    setRecurringTask(newValue);
                  }}
                  creation={true}
                  recurringTaskMenuOpen={recurringTaskMenuOpen}
                  setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
                  modalMode={true}
                  updateLocalTask={(newValue) => {
                    setItemEditable((prevState) => {
                      return {
                        ...prevState,
                        ...newValue,
                      };
                    });
                  }}
                />
              </div>
            </div>

            {!rich_links["slack"]?.disabled &&
              getSlackLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Slack
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getSlackLinkForTask(
                          itemEditable,
                          rich_links["slack"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/Slack-mark-RGB.png")}
                        alt="Slack Logo"
                        className="w-3.5 h-3.5 object-contain"
                      />
                      Open in Slack
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["notion"]?.disabled &&
              getNotionLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Notion
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getNotionLinkForTask(
                          itemEditable,
                          rich_links["notion"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <RiNotionFill className="w-5 h-5 object-contain" />
                      Open in Notion
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["linear"]?.disabled &&
              getLinearLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Linear
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getLinearLinkForTask(
                          itemEditable,
                          rich_links["linear"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/linear-logo.png")}
                        alt="Linear Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Linear
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["figma"]?.disabled &&
              getFigmaLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Figma
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getFigmaLinkForTask(
                          itemEditable,
                          rich_links["figma"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/figma-logo.png")}
                        alt="Figma Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Figma
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["craft"]?.disabled &&
              getCraftDocsLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Craft
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getCraftDocsLinkForTask(
                          itemEditable,
                          rich_links["craft"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/craft-logo.png")}
                        alt="Craft Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Craft
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["googleDocs"]?.disabled &&
              getGoogleDocsLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Google Docs
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getGoogleDocsLinkForTask(
                          itemEditable,
                          rich_links["googleDocs"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/google-docs-logo.png")}
                        className="w-5 h-5 object-cover"
                        alt="Google Docs Logo"
                      />
                      Open in Google Docs
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["clickUp"]?.disabled &&
              getClickupLinkForTask(itemEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    ClickUp
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getClickupLinkForTask(
                          itemEditable,
                          rich_links["clickUp"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/clickup-logo.png")}
                        className="w-5 h-5 object-contain"
                        alt="ClickUp Logo"
                      />
                      Open in ClickUp
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div className="modal-card-body-notes">
            <div className="modal-card-body-notes-header">Notes</div>

            <AutoGrowingTextAreaWithLinks
              placeholder="Add any notes to the task"
              value={itemEditable?.notes}
              setValue={(newValue) => {
                updateItemEditableValue("notes", newValue);
              }}
            />
          </div>

          <div
            className="modal-card-body-notes subtasks"
            style={{
              paddingBottom: "0px",
              borderTop: "none",
            }}
          >
            <div
              className="modal-card-body-notes-header"
              style={{ marginBottom: "0px" }}
            >
              Subtasks
            </div>
            <Subtasks
              item={itemEditable}
              updateSubtasksLocal={(newValue) => {
                updateItemEditableValue("subtasks", newValue, true);
              }}
              subtasksActive={true}
              setSubtasksActive={null}
              activelySelected={true}
              modalMode={true}
            />
          </div>

          <div className="mcf-footer">
            <div
              onClick={() => {
                dispatch(setCreateTaskModalActive(null));

                setItemEditable({
                  date: start_date ? start_date : new Date(),
                  id: uuidv4(),
                  complete: false,
                  start: start_date,
                  estimated_time: duration,
                  listId: listId ? listId : null,
                });
              }}
              className="cancel-button"
            >
              Cancel
            </div>
            <div
              onClick={() => {
                createTaskFunction();
              }}
              className="button primary"
            >
              <span>Create task</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function ModalTextArea({
  taskInputRef,
  itemEditable,
  updateItemEditableValue,
  active,
}) {
  // focus taskInputRef on mount
  useEffect(() => {
    if (active && taskInputRef.current) {
      taskInputRef.current.focus();
    }
  }, [active, taskInputRef]);

  return (
    <TextareaAutosize
      className="card-modal-description create-textarea ph-mask"
      ref={taskInputRef}
      value={itemEditable.description}
      onChange={(e) => {
        updateItemEditableValue("description", e.target.value);
      }}
      placeholder="Enter a description"
      autoFocus
      onKeyPress={(e) => {
        // Detect if enter is pressed and save the task
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      style={{}}
    />
  );
}

import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import { updateCurrentUser } from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Select, Switch } from "antd";
import { app_version } from "../../utils";

import { getVersion } from "@todesktop/client-core/app";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";

export default function Support() {
  const { currentUser } = useSelector((state) => state.app);
  const userId = useSelector((state) => state.app.uid);
  const dispatch = useDispatch();

  /*
  // Let's receive a message from electron "message-from-electron", using ipcRenderer
  useEffect(() => {
    if (window) {
      const { ipcRenderer } = window;

      ipcRenderer.on("message-from-electron", (event, arg) => {
        console.log("message-from-electron", arg);
      });

      // Let's send a message to electron "message-from-react"
      ipcRenderer.send("react-check-for-update", "Hello from React!");

      return () => {
        ipcRenderer.removeAllListeners("message-from-electron");
      };
    }
  }, []); */

  return (
    <div>
      <div className="settings">
        <div className="settings-item">
          <div className="settings-item-title">Changelog</div>
          <div className="settings-item-value">
            <a
              href="https://feedback.ellieplanner.com/"
              className="settings-item-link"
            >
              What's new + What's upcoming{" "}
              <ArrowRightIcon className="settings-item-link-icon" />{" "}
            </a>
          </div>
        </div>

        <div className="settings-rowdivider" />

        <div className="settings-item">
          <div className="settings-item-title">Contact Support</div>
          <a href="mailto:chris@aloa.co" className="settings-item-link">
            chris@aloa.co <ArrowRightIcon className="settings-item-link-icon" />{" "}
          </a>
        </div>

        <div className="settings-rowdivider" />

        <div onClick={() => {}} className="settings-item">
          <div className="settings-item-title">User ID (for support)</div>
          <div className="settings-item-value">{userId}</div>
        </div>

        <div className="settings-rowdivider" />

        <div className="settings-item">
          <div className="settings-item-title">App Version</div>
          <div className="settings-item-value">
            {isDesktopApp() ? getVersion() : app_version}
          </div>
        </div>
      </div>
    </div>
  );
}

import { Popover } from "antd";
import React, { useState } from "react";
import { TbFlag3Filled, TbFlagFilled } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { updateTask } from "../../redux/tasksSlice";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { PiFlag, PiFlagFill } from "react-icons/pi";

export const priorityData = {
  0: {
    color: "#F0543F",
    title: "High",
    id: "0",
  },
  1: {
    color: "#FF914A",
    title: "Medium",
    id: "1",
  },
  2: {
    color: "#024ADF",
    title: "Low",
    id: "2",
  },
  none: {
    color: "#49C31D",
    title: "None",
    id: "none",
  },
};

export const priorityOrder = ["0", "1", "2", "none"];

function PrioritySelector({
  priority,
  item,
  modal = false,
  alwaysVisible = false,
  creation = false,
  action,
  setPrioritySelectorVisible,
  prioritySelectorVisible,
}) {
  const dispatch = useDispatch();
  const updatePriority = (value) => {
    if (creation && action) {
      action(value.id);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: { priority: value.id },
        })
      );
    }

    setPrioritySelectorVisible(false);
  };
  return (
    <Popover
      trigger={"click"}
      placement="bottom"
      open={prioritySelectorVisible}
      onOpenChange={(visible) => {
        setPrioritySelectorVisible(visible);
      }}
      content={
        <div className="p-1">
          {priorityOrder.map((priority) => (
            <div key={priority} className="">
              <PrioritySelectorItem
                priority={priorityData[priority]}
                updatePriority={updatePriority}
                selected={item.priority === priorityData[priority].id}
              />
            </div>
          ))}
        </div>
      }
    >
      {modal ? (
        <>
          {(!priority || !priorityData[priority]) && (
            <div className="mcf-button empty">Click to add</div>
          )}
          {priority && priorityData[priority] && (
            <div className="flex gap-1 items-center mcf-button">
              {priority == "none" ? (
                <PiFlag />
              ) : (
                <PiFlagFill color={`${priorityData[priority]?.color}`} />
              )}
              {priorityData[priority]?.title}
            </div>
          )}
        </>
      ) : (
        <div
          data-no-dnd="true"
          className={`subtask-button-container ${
            prioritySelectorVisible || alwaysVisible || (item.priority && item.priority != "none")
              ? "visible"
              : ""
          } flex flex-row items-center gap-1 text-[11px] text-[#8e8e8e] font-medium`}
        >
          {!item.priority || item.priority == "none" ? (
            <PiFlag className="w-3 h-3" />
          ) : (
            <PiFlagFill
              className="w-3 h-3"
              color={`${priorityData[item.priority]?.color}`}
            />
          )}
        </div>
      )}
    </Popover>
  );
}

const PrioritySelectorItem = ({ priority, updatePriority, selected }) => {
  return (
    <div
      className="px-1 hover:bg-neutral-100 dark:hover:bg-neutral-500 dark:text-white rounded-md cursor-pointer flex gap-1 items-center font-normal py-1 px-1 min-w-[150px] justify-between"
      onClick={(e) => {
        e.stopPropagation();
        updatePriority(priority);
      }}
    >
      <div className="flex flex-row gap-2 items-center">
        {priority?.id == "none" ? (
          <PiFlag />
        ) : (
          <PiFlagFill color={`${priority?.color}`} />
        )}
        {priority?.title}
      </div>
      {selected && <BsCheck className="w-5 h-5" />}{" "}
      {/* Render checkmark if this priority is selected */}
    </div>
  );
};

export default PrioritySelector;

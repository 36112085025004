import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setCalendarEventEditModalActiveFor } from "../../redux/calendarSlice";
import EventEditorContent from "./EventEditorContent";

export default function EventEditorModal({}) {
  const calendarEventEditModalActiveFor = useSelector(
    (state) => state.calendar.calendarEventEditModalActiveFor
  );

  const dispatch = useDispatch();

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={600}
      open={calendarEventEditModalActiveFor !== null}
      onOk={() => {
        dispatch(setCalendarEventEditModalActiveFor(null));
      }}
      onCancel={() => {
        dispatch(setCalendarEventEditModalActiveFor(null));
      }}
      className="amazing-modal"
    >
      <EventEditorContent originalEvent={calendarEventEditModalActiveFor} />
    </Modal>
  );
}

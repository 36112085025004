import React from "react";
import { useNavigate } from "react-router-dom";
import { stepData } from "../../../data/walkthroughSteps";
import { useSelector } from "react-redux";
import { analytics, getThemeFromUser } from "../../../utils";

const Step = ({
  title,
  tag,
  description,
  asset,
  content,
  darkModeBorder = true,
  user_theme,
}) => {
  return (
    <div className="rounded-md flex flex-col-reverse md:flex-row md:justify-center gap-4 md:gap-20 h-full w-full px-4">
      <div
        className={`flex flex-col w-full ${
          !asset ? "md:w-full" : "md:w-5/12"
        } justify-center items-center`}
      >
        <div className="gap-2 flex flex-col w-full">
          <div className="flex flex-col">
            {tag && (
              <span className={`${tag.color} font-bold text-xs`}>
                {tag.text.toUpperCase()}
              </span>
            )}
            {title && <h1 className="text-2xl font-semibold">{title}</h1>}
          </div>
          {description && <div className="w-full">{description}</div>}
        </div>
        {content && <div className="w-full">{content}</div>}
      </div>
      {asset && (
        <div className="md:w-7/12 w-full rounded-lg">
          {asset ? (
            <div className="">
              {asset?.mp4 ? (
                <div className="relative overflow-hidden w-full h-96 rounded-lg">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full w-auto h-auto z-[20] object-contain rounded-lg"
                  >
                    <source
                      type="video/mp4"
                      src={require(`../../../images/${
                        user_theme === "dark" && asset.mp4.dark
                          ? asset.mp4.dark
                          : asset.mp4.light
                      }`)}
                    />
                  </video>
                </div>
              ) : (
                <img
                  className={`object-contain rounded-lg ${
                    darkModeBorder
                      ? "dark:border-neutral-800 dark:border-2"
                      : ""
                  }`}
                  src={require(`../../../images/${
                    user_theme === "dark" && asset.gif.dark
                      ? asset.gif.dark
                      : asset.gif.light
                  }`)}
                  alt=""
                />
              )}
            </div>
          ) : (
            <div className="rounded-lg bg-gray-300 min-h-full min-w-full"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default function Walkthrough() {
  const [step, setStep] = React.useState(0);

  let { user_theme = "system" } = useSelector((state) => state.app.currentUser);

  user_theme = getThemeFromUser(user_theme);

  const navigate = useNavigate();

  if (step === 9) {
    navigate("/");
  }

  return (
    <div className="h-full min-h-screen flex flex-col md:justify-center items-center w-full dark:text-white dark:bg-neutral-900 bg-neutral-50 py-4 relative">
      <div className="w-full md:w-[70rem]">
        {stepData.map(
          (
            {
              tag = null,
              title = null,
              description = null,
              asset = null,
              darkModeAsset = null,
              content = null,
              darkModeBorder = true,
            },
            index
          ) =>
            step === index && (
              <Step
                tag={tag}
                title={title}
                description={description}
                asset={asset}
                content={content}
                darkModeBorder={darkModeBorder}
                user_theme={user_theme}
              />
            )
        )}
        <div className="flex gap-6 w-full justify-end px-4 md:px-0 pt-10 sticky bottom-4">
          {step !== 0 && (
            <button
              onClick={() => {
                analytics("Walkthrough step back", {
                  source: "web",
                  step: step,
                  stepTitle: stepData[step].title || "undefined",
                });

                setStep((prev) => prev - 1);
              }}
            >
              Back
            </button>
          )}
          <button
            onClick={() => {
              analytics("Walkthrough step completed", {
                source: "web",
                step: step,
                stepTitle: stepData[step].title || "undefined",
              });

              setStep((prev) => prev + 1);
            }}
            className="px-3 py-2 rounded-lg text-white bg-purple-600 hover:bg-purple-700 text-lg"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

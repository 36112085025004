import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Tooltip } from "antd";
import Card from "../Kanban/Card";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import { changeCalendarDate } from "../../redux/tasksSlice";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa";
import { IoArrowForward } from "react-icons/io5";
import YesterdayReview from "./ReviewYesterday";
import TodayReview from "./ReviewToday";
import ReviewTodayCapacity from "./ReviewTodayCapacity";
import FinalizeDay from "./FinalizeDay";
import { useMediaQuery } from "react-responsive";
import { analytics } from "../../utils";

export default function DailyPlanning({
  taskOrderEditable,
  activelyReorderingSidebar,
  sortedLists,
}) {
  const [step, setStep] = useState(0);
  const [skipStep2Active, setSkipStep2Active] = useState(false);

  const {
    work_threshold = 8,
    enable_work_threshold = true,
    work_threshold_excluded_labels = [],
  } = useSelector((state) => state.app?.currentUser);
  const { label_filters: activeLabels = [], hide_complete = false } =
    useSelector((state) => state.app?.currentUser);

  const [totalEstimatedTime, setTotalEstimatedTime] = useState(0);

  const tasks = useSelector((state) => state.tasks.data);

  const taskOrder = useSelector((state) => state.tasks.order);

  useEffect(() => {
    // Whenever task order updates, let's re-calculate the total time
    // spent on tasks for this column.
    var totalEstimatedTimeTemp = 0;
    var todayDateKey = moment().format("YYYY-MM-DD");

    if (taskOrder[todayDateKey]?.order) {
      // If there is a date, get all tasks for a given date and sum their time
      const tempTasks = taskOrder[todayDateKey]?.order
        .map((taskId) => {
          let task = tasks[taskId];

          if (
            task &&
            (!hide_complete || !task.complete) &&
            (activeLabels?.length === 0 ||
              activeLabels?.includes(task.label)) &&
            (!enable_work_threshold ||
              !work_threshold_excluded_labels.includes(task.label))
          ) {
            return task;
          }
        })
        .filter((task) => task);

      totalEstimatedTimeTemp = tempTasks.reduce(
        (acc, task) =>
          acc + (!isNaN(task.estimated_time) ? task.estimated_time : 0),
        0
      );
    }

    setTotalEstimatedTime(totalEstimatedTimeTemp);
  }, [
    taskOrder,
    tasks,
    activeLabels,
    hide_complete,
    work_threshold_excluded_labels,
    enable_work_threshold,
  ]);

  useEffect(() => {
    analytics("Daily planning activated");
  }, []);

  // Analytics for the steps
  useEffect(() => {
    analytics("Daily planning step change", { step: step });
  }, [step]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (isMobile) {
    return (
      <div className="p-6 text-black dark:text-white">
        😓 Daily planning is not (yet) supported on mobile. Please reach out to
        Chris to let him know you'd like to see it on mobile!
      </div>
    );
  }

  if (step == 0) {
    return (
      <YesterdayReview
        taskOrderEditable={taskOrderEditable}
        setStep={setStep}
      />
    );
  }

  if (step == 1) {
    return (
      <TodayReview
        taskOrderEditable={taskOrderEditable}
        setStep={setStep}
        activelyReorderingSidebar={activelyReorderingSidebar}
        sortedLists={sortedLists}
        setSkipStep2Active={setSkipStep2Active}
        totalEstimatedTime={totalEstimatedTime}
        work_threshold={work_threshold}
      />
    );
  }

  if (step == 2) {
    return (
      <ReviewTodayCapacity
        taskOrderEditable={taskOrderEditable}
        setStep={setStep}
        totalEstimatedTime={totalEstimatedTime}
        work_threshold={work_threshold}
        skipStep2Active={skipStep2Active}
      />
    );
  }

  if (step == 3) {
    return (
      <FinalizeDay
        taskOrderEditable={taskOrderEditable}
        setStep={setStep}
        activelyReorderingSidebar={activelyReorderingSidebar}
        sortedLists={sortedLists}
        skipStep2Active={skipStep2Active}
      />
    );
  }
}

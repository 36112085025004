import { Popover } from "antd";
import { IoCloseCircle } from "react-icons/io5";
import TaskCalendarEvent from "../TaskCalendarEvent";
import GoogleCalendarEvent from "../GoogleCalendarEvent";
import { closestColorSet, colorSets, darkColorSets } from "../../../utils";
import { useEffect, useState } from "react";
import { setCardModalActive } from "../../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import AppleCalendarEvent from "../AppleCalendarEvent";
import "../Calendar.css";
import moment from "moment";
import OutlookCalendarEvent from "../OutlookCalendarEvent";

export default function MoreLink({
  allTasksForDay,
  dateForCurrentDay,
  num,
  calendar_view,
  user_theme,
  setMoreLinkActive,
}) {
  const appleEvents = useSelector((state) => state.calendar.appleEvents || {});

  const googleEvents = useSelector(
    (state) => state.calendar.googleEvents || {}
  );

  const outlookEvents = useSelector(
    (state) => state.calendar.outlookEvents || {}
  );

  const apple_cal_color = useSelector(
    (state) => state.app.currentUser.apple_cal_color || {}
  );

  const dispatch = useDispatch();
  const [eventPopupActive, setEventPopupActive] = useState(false);
  const content = (
    <div class="w-64 pl-3 pr-2 py-4">
      <div
        onClick={() => {
          setEventPopupActive(false);
        }}
        class="w-full text-sm font-semibold pl-1.5 pb-3 pr-2 flex flex-row items-center justify-between"
      >
        {/* only here for spacing purposes */}
        <IoCloseCircle className="invisible cursor-pointer text-gray-400 text-2xl" />
        <div className="flex flex-row gap-1.5 text-base text-gray-500 items-baseline pb-1 dark:text-gray-200">
          {dateForCurrentDay?.toLocaleString("default", { month: "short" })}
          <span className="text-gray-500 text-base dark:text-gray-200">
            {" "}
            {dateForCurrentDay?.getUTCDate
              ? dateForCurrentDay?.getUTCDate()
              : ""}
          </span>
        </div>
        <IoCloseCircle className="cursor-pointer text-gray-300 text-2xl hover:text-gray-500" />
      </div>
      <div class="w-full max-h-72 overflow-auto pr-1">
        {allTasksForDay.map((eventObject) => {
          const event = eventObject.event;
          const googleEvent = googleEvents[event.id];

          const appleEvent = appleEvents[event.id];

          const outlookEvent = outlookEvents[event.id];

          const appleColor = apple_cal_color[appleEvent?.calendar] || "#000000";

          const isAllDayEvent = googleEvent
          ? moment(googleEvent.start).isSame(
              moment(googleEvent.end).subtract(1, "day"),
              "day"
            ) ||
            moment(googleEvent.end).diff(moment(googleEvent.start), "hours") > 24
          : appleEvent
          ? moment(appleEvent.start).isSame(
              moment(appleEvent.end).subtract(1, "day"),
              "day"
            ) ||
            moment(appleEvent.end).diff(moment(appleEvent.start), "hours") > 24
          : outlookEvent
          ? moment(outlookEvent.start).isSame(
              moment(outlookEvent.end).subtract(1, "day"),
              "day"
            ) ||
            moment(outlookEvent.end).diff(moment(outlookEvent.start), "hours") > 24
          : event
          ? moment(event.start).isSame(
              moment(event.end).subtract(1, "day"),
              "day"
            ) || moment(event.end).diff(moment(event.start), "hours") > 24
          : false;

          return (
            <div class="w-full text-sm">
              {event.extendedProps?.type === "task" && (
                <div
                  onClick={() => {
                    setEventPopupActive(false);
                    dispatch(setCardModalActive(event.id));
                  }}
                  className="hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-md px-1 py-0.5 cursor-pointer"
                >
                  <TaskCalendarEvent
                    taskId={event.id}
                    event={event}
                    eventObject={eventObject}
                    textColor={event.backgroundColor}
                    calendarView={calendar_view}
                    moreLinkActive={false}
                    setMoreLinkActive={setEventPopupActive}
                  />
                </div>
              )}
              {googleEvent && (
                <div
                  className={`${
                    !isAllDayEvent ? "hover:bg-neutral-100 " : ""
                  } ${
                    !isAllDayEvent ? "dark:hover:bg-neutral-700 " : ""
                  } rounded-md px-1 py-0.5 cursor-pointer`}
                  style={{
                    backgroundColor: isAllDayEvent
                      ? closestColorSet(
                          googleEvent.backgroundColor,
                          user_theme === "dark" ? darkColorSets : colorSets
                        ).background
                      : "",
                  }}
                >
                  <GoogleCalendarEvent
                    googleEvent={googleEvent}
                    event={event}
                    eventObject={eventObject}
                    textColor={
                      closestColorSet(
                        googleEvent.backgroundColor,
                        user_theme === "dark" ? darkColorSets : colorSets
                      ).text
                    }
                    calendarView={calendar_view}
                  />
                </div>
              )}

              {outlookEvent && (
                <div
                  className={`${
                    !isAllDayEvent ? "hover:bg-neutral-100 " : ""
                  } ${
                    !isAllDayEvent ? "dark:hover:bg-neutral-700 " : ""
                  } rounded-md px-1 py-0.5 cursor-pointer`}
                  style={{
                    backgroundColor: isAllDayEvent
                      ? closestColorSet(
                        outlookEvent.extendedProps?.data?.color || "#0072C6",
                          user_theme === "dark" ? darkColorSets : colorSets
                        ).background
                      : "",
                  }}
                >
                  <OutlookCalendarEvent
                     outlookEvent={outlookEvent}
                    event={event}
                    eventObject={eventObject}
                    textColor={
                      closestColorSet(
                        outlookEvent.extendedProps?.data?.color || "#0072C6",
                        user_theme === "dark" ? darkColorSets : colorSets
                      ).text
                    }
                    calendarView={calendar_view}
                  />
                </div>
              )  
              }

              {appleEvent && (
                <div
                  className={`${
                    !isAllDayEvent ? "hover:bg-neutral-100 " : ""
                  } ${
                    !isAllDayEvent ? "dark:hover:bg-neutral-700 " : ""
                  } rounded-md px-1 py-0.5 cursor-pointer`}
                  style={{
                    backgroundColor: isAllDayEvent
                      ? closestColorSet(
                          appleColor,
                          user_theme === "dark" ? darkColorSets : colorSets
                        ).background
                      : "",
                  }}
                >
                  <AppleCalendarEvent
                    appleEventId={appleEvent.id}
                    eventObject={eventObject}
                    textColor={
                      closestColorSet(
                        appleColor,
                        user_theme === "dark" ? darkColorSets : colorSets
                      ).text
                    }
                    calendarView={calendar_view}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  useEffect(() => {
    if (!eventPopupActive) {
      setMoreLinkActive(false);
    }
  }, [eventPopupActive]);

  return (
    <Popover
      content={content}
      title=""
      open={eventPopupActive === true}
      onOpenChange={(open) => {
        if (open) {
          setEventPopupActive(true);
        } else {
          setEventPopupActive(null);
        }
      }}
      trigger={["click"]}
    >
      <div
        onClick={() => {
          setEventPopupActive(!eventPopupActive);
        }}
        className="event-title ph-mask w-full text-slate-700 font-semibold dark:text-slate-400 "
      >
        {`+ ${num} more`}
      </div>
    </Popover>
  );
}

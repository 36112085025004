import "./TabBar.css";
import React from "react";

import calendarIcon from "../../../images/calendar_icon.svg";
import brainIcon from "../../../images/brain_icon.svg";
import moreIcon from "../../../images/more_icon.svg";

// Import calendar icon from hero icons

import { setMobilePageActive } from "../../../redux/appSlice";

import { useSelector, useDispatch } from "react-redux";
export default function TabBar() {
  const { mobilePageActive } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  return (
    <div className="tabbar">
      <div
        onClick={() => {
          dispatch(setMobilePageActive("tasks"));
        }}
        className={`tab ${mobilePageActive === "tasks" ? "active" : ""}`}
      >
        <div src={calendarIcon} className="tab-icon">
          📆
        </div>
        <div>Tasks</div>
      </div>
      <div
        onClick={() => dispatch(setMobilePageActive("braindump"))}
        className={`tab ${mobilePageActive === "braindump" ? "active" : ""}`}
      >
        <div alt="brain" className="tab-icon">
          🧠
        </div>
        <div>Braindump</div>
      </div>
      <div
        onClick={() => dispatch(setMobilePageActive("timebox"))}
        className={`tab ${mobilePageActive === "timebox" ? "active" : ""}`}
      >
        <div alt="timebox" className="tab-icon">
          🕐
        </div>
        <div>Timebox</div>
      </div>
      <div
        onClick={() => dispatch(setMobilePageActive("settings"))}
        className={`tab ${mobilePageActive === "settings" ? "active" : ""}`}
      >
        <div alt="more" className="tab-icon">
          🎨
        </div>
        <div>Settings</div>
      </div>
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { openDB } from "idb";
import {
  closestColorSet,
  colorSets,
  darkColorSets,
  googleServerUrl,
  outlookServerUrl,
} from "../utils";
import { setToastVisible } from "./appSlice";
import { updateTask } from "./tasksSlice";
import { auth } from "../firebase";

const initialState = {
  appleEvents: {},
  googleEvents: {},
  outlookEvents: {},
  taskEvents: {},
  googleEventData: {},
  lastSync: null,
  stagedGoogleEvent: null,
  contextMenuActiveForEvent: null,
  calendarEventEditModalActiveFor: null,
};

function convertGoogleEventsToEvents(googleEvents) {
  // Convert google events to events
  // Remove nulls
  return googleEvents
    .map((googleEvent) => convertGoogleCalendarEventToEvent(googleEvent))
    .filter((event) => event !== null);
}

function convertGoogleCalendarEventToEvent(googleEvent) {
  var isAllDay = false;

  if (googleEvent.start == null) {
    return null;
  }

  if (googleEvent.start.date) {
    isAllDay = true;
  }

  const event = {
    id: googleEvent.id,
    title:
      googleEvent.summary ||
      (googleEvent.visibility === "private"
        ? "Busy (private)"
        : "Untitled Event"),
    start: isAllDay ? googleEvent.start.date : googleEvent.start.dateTime,
    end: isAllDay ? googleEvent.end.date : googleEvent.end.dateTime,
    color: googleEvent.colorId,
    backgroundColor: googleEvent.backgroundColor,
    //  textColor: "var(--calendar-text-color)",
    //  borderColor: googleEvent.backgroundColor,
    allDay: isAllDay,
    googleEvent: true,
    editable: true,
    extendedProps: {
      type: "google",
      googleEventId: googleEvent.id,
      ellie_task_id: googleEvent.extendedProperties?.private?.ellie_task_id,
      declined:
        googleEvent.attendees?.find((attendee) => attendee.self)
          ?.responseStatus === "declined"
          ? true
          : false,
    },
  };

  return event;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result;
}

var colourIsLight = function (hex) {
  // Convert hex to RGB first (removing #)
  var rgb = hexToRgb(hex);

  if (rgb) {
    var r = parseInt(rgb[1], 16);
    var g = parseInt(rgb[2], 16);
    var b = parseInt(rgb[3], 16);

    // Counting the perceptive luminance
    // human eye favors green color...
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  return true;
};

function convertTasksToEvents(tasks, labels) {
  return tasks
    .map((task) => {
      // If there is no estimated time, make it 1 hour (in seconds)
      const estimated_time = task.estimated_time || 3600;

      var start_date = task.start;

      if (!start_date) {
        return null;
      }

      // Check if start_date is instanceof firebase timestamp, if so, convert to date
      if (start_date.toDate) {
        start_date = start_date.toDate();
      }

      // The end time is the start time plus the estimated time (using moment), then convert to date
      const end_date = moment(start_date)
        .add(estimated_time, "seconds")
        .toDate();

      return {
        title: task.description,
        start: start_date,
        end: end_date,
        allDay: false, // will make the time show
        editable: true,
        backgroundColor: labels?.[task.label]?.color || "#FFE3C9",
        textColor: colourIsLight(labels?.[task.label]?.color || "#FFE3C9")
          ? "black"
          : "white",
        borderColor: labels?.[task.label]?.color || "#FFE3C9",
        id: task.id,
        extendedProps: {
          type: "task",
          calendar_events: task.calendar_events,
        },
      };
    })
    .filter((event) => event !== null);
}

function convertTaskToEvent(task, labels) {
  // If there is no estimated time, make it 1 hour (in seconds)
  const estimated_time = task.estimated_time || 3600;

  var start_date = task.start;

  if (!start_date) {
    return null;
  }

  // Check if start_date is instanceof firebase timestamp, if so, convert to date
  if (start_date.toDate) {
    start_date = start_date.toDate();
  }

  // The end time is the start time plus the estimated time (using moment), then convert to date
  const end_date = moment(start_date).add(estimated_time, "seconds").toDate();

  return {
    title: task.description,
    start: start_date,
    end: end_date,
    allDay: false, // will make the time show
    editable: true,
    backgroundColor: labels?.[task.label]?.color || "#FFE3C9",
    textColor: colourIsLight(labels?.[task.label]?.color || "#FFE3C9")
      ? "black"
      : "white",
    borderColor: labels?.[task.label]?.color || "#FFE3C9",
    id: task.id,
    extendedProps: {
      type: "task",
    },
  };
}

export const fetchTaskEventsFromTasks = createAsyncThunk(
  "calendar/fetchTaskEventsFromTasks",
  async (args, { getState, rejectWithValue, fulfillWithValue }) => {
    const tasks = getState().tasks.data;
    const taskOrders = getState().tasks.order;

    const date = getState().tasks.calendarDate;

    const dates = getState().app.dates;

    const labels = getState().labels.data;

    // Filter taskOrders to only include the ones that are between the date and the date + 1 week
    const taskOrdersFromOrder = Object.values(taskOrders).filter(
      (taskOrder) => {
        if (taskOrder.date) {
          // Check if task date is within 1 week of the date (plus or minus 1 week)
          // const oneWeekBefore = moment(date, "YYYY-MM-DD").subtract(1, "week");
          // const oneWeekAfter = moment(date, "YYYY-MM-DD").add(1, "week");

          //should we do this conditionally???
          var firstDayOfMonth = moment(dates[0], "YYYY-MM-DD").startOf("month");
          var lastDayOfMonth = moment(
            dates[dates.length - 1],
            "YYYY-MM-DD"
          ).endOf("month");

          return moment(
            taskOrder.date.toDate ? taskOrder.date.toDate() : taskOrder.date
          ).isBetween(firstDayOfMonth, lastDayOfMonth, "day", "[]");
        } else {
          return false;
        }
      }
    );

    // Get the tasks from the taskOrders
    var tasksToConvert = [];

    taskOrdersFromOrder.forEach((taskOrder) => {
      taskOrder.order.forEach((taskId) => {
        if (tasks[taskId]) {
          tasksToConvert.push(tasks[taskId]);
        }
      });
    });

    const newEvents = convertTasksToEvents(tasksToConvert, labels);

    // Take the array and group by id
    const newTaskEvents = newEvents.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    return fulfillWithValue(newTaskEvents);
  }
);

export const updateTaskFromEvent = createAsyncThunk(
  "calendar/updateTaskFromEvent",
  async (event, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const tasks = getState().tasks.data;

    const start = event.start;
    const end = event.end;
    const taskId = event.id;

    // Get the duration in seconds from the start and end time
    let duration = moment(end).diff(moment(start), "seconds");

    // if duration is not a number, set it to 1 hour in seconds
    if (isNaN(duration)) {
      duration = 3600;
    }

    // Update the task
    const task = tasks[taskId];

    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          date: start,
          start: start,
          estimated_time: duration,
          listId: null,
        },
        currentTask: task,
        saveGhostOrder: false,
        updateOrder: true,
      })
    );

    return fulfillWithValue(event);
  }
);

export const fetchAppleCalendarEvents = createAsyncThunk(
  "calendar/fetchAppleCalendarEvents",
  async (params, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;
    const calendarDate = getState().tasks.calendarDate;

    //  var oneWeekBefore = moment(calendarDate, "YYYY-MM-DD").subtract(1, "week");
    //  var oneWeekAfter = moment(calendarDate, "YYYY-MM-DD").add(1, "week");

    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const activeCalendarsJson = JSON.stringify(active_calendars);

    try {
      var systemTimezone = moment.tz.guess();

      if (!systemTimezone) {
        systemTimezone = "America/Chicago";
      }

      return await axios
        .get(`${googleServerUrl}/getAppleCalendarEvents`, {
          params: {
            userId: getState().app.uid,
            startDate: timeMin,
            endDate: timeMax,
            systemTimezone: systemTimezone,
            active_calendars: activeCalendarsJson,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const allEvents = res.data.map((event) => {
            return {
              id: event.id,
              title: event.title,
              description: event.description,
              start: event.startDate,
              end: event.endDate,
              calendar: event.calendar,
              location: event.location,
              attendees: event.attendees,
              etag: event.etag,
              calendarAccount: event.calendarAccount,
              extendedProps: {
                type: "apple",
                url: event.url,
                data: event.data,
                recurring: event.recurring,
              },
            };
          });

          // convert the array into objects with the id as the key
          const allEventsObject = allEvents.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});

          return fulfillWithValue(allEventsObject);
        });
    } catch (err) {
      console.log(err);
      dispatch(
        setToastVisible({
          toastType: "error",
          message:
            "Something went wrong talking to Apple calendar. Please contact support",
        })
      );
      return rejectWithValue(err);
    }
  }
);

// Function to get timeMin and timeMax given active_calendar_type, calendarDate and mode (kanban or calendar)
function getTimeMinAndTimeMax(
  active_calendar_type,
  calendarDate,
  mode = "kanban"
) {
  var timeMin =
    active_calendar_type === "month" && mode !== "kanban"
      ? moment(calendarDate, "YYYY-MM-DD")
          .startOf("month")
          .add(-2, "weeks")
          .toISOString()
      : moment(calendarDate, "YYYY-MM-DD")
          .subtract(1, "week")
          .startOf("day")
          .toISOString();
  var timeMax =
    active_calendar_type === "month" && mode !== "kanban"
      ? moment(calendarDate, "YYYY-MM-DD")
          .endOf("month")
          .add(2, "weeks")
          .toISOString()
      : moment(calendarDate, "YYYY-MM-DD")
          .add(1, "week")
          .endOf("day")
          .toISOString();

  return { timeMin, timeMax };
}

export const fetchGoogleCalendarEvents = createAsyncThunk(
  "calendar/fetchGoogleCalendarEvents",
  async (args, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    // Get active_calendars from the app slice

    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;
    const userId = getState().app.uid;

    // Get the calendarDate from the calendar slice
    const calendarDate = getState().tasks.calendarDate;

    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const activeCalendarsJson = JSON.stringify(active_calendars);

    try {
      return axios
        .get(`${googleServerUrl}/getCalendarEvents`, {
          params: {
            userId: userId,
            // 1 week before date
            // timeMin: oneWeekBefore.startOf("day").toISOString(),
            // timeMax: oneWeekAfter.endOf("day").toISOString(),
            // Full month date
            timeMin: timeMin,
            timeMax: timeMax,
            active_calendars: activeCalendarsJson,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data && response.data.events?.length > 0) {
            const newEvents = convertGoogleEventsToEvents(response.data.events);

            // Take the array and group by id
            const newGoogleEvents = newEvents.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, {});

            const newGoogleEventData = response.data.events.reduce(
              (acc, cur) => {
                acc[cur.id] = cur;
                return acc;
              },
              {}
            );

            return fulfillWithValue({
              newEvents: newGoogleEvents,
              newGoogleEventData: newGoogleEventData,
            });
          } else {
            return fulfillWithValue({});
          }
        })
        .catch((error) => {
          console.log(error);

          dispatch(
            setToastVisible({
              toastType: "error",
              message:
                "Something went wrong talking to Google calendar. Please contact support",
            })
          );

          return rejectWithValue(error);
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOutlookCalendarEvents = createAsyncThunk(
  "calendar/fetchOutlookCalendarEvents",
  async (args, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    // Get active_calendars from the app slice
    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;

    // Get the calendarDate from the calendar slice
    const calendarDate = getState().tasks.calendarDate;

    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const activeCalendarsJson = JSON.stringify(active_calendars);

    const idToken = await auth.currentUser.getIdToken(true);

    try {
      return axios
        .get(`${outlookServerUrl}/getOutlookEvents`, {
          params: {
            timeMin: timeMin,
            timeMax: timeMax,
            active_calendars: activeCalendarsJson,
            idToken: idToken,
          },
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
        })
        .then((response) => {
          if (response.data && response.data.events?.length > 0) {
            // Convert the events to the same format as Google events
            const newEvents = response.data.events.map((event) => {
              return {
                id: event?.id,
                title: event?.title,
                description: event?.bodyPreview,
                start: event?.start,
                end: event?.end,
                calendar: event?.calendar,
                location: event?.location?.displayName,
                attendees: event?.attendees,
                etag: event?.etag,
                calendarAccount: event?.calendarAccount,
                calendarId: event?.calendarId,
                accountId: event?.accountId,
                allDay: event?.isAllDay,
                extendedProps: {
                  type: "outlook",
                  url: event?.webLink,
                  data: event,
                  recurring: event?.isRecurring,
                  ellie_task_id: event?.ellie_task_id,
                },
              };
            });

            // Take the array and group by id
            const newOutlookEvents = newEvents.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, {});

            return fulfillWithValue(newOutlookEvents);
          } else {
            return fulfillWithValue({});
          }
        })
        .catch((error) => {
          console.log(error);

          dispatch(
            setToastVisible({
              toastType: "error",
              message:
                "Something went wrong talking to Outlook calendar. Please contact support",
            })
          );

          return rejectWithValue(error);
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBothCalendarEvents = createAsyncThunk(
  "calendar/fetchBothCalendarEvents",
  async (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const results = {
      googleEvents: null,
      appleEvents: null,
      outlookEvents: null,
    };
    let errorMessages = [];

    try {
      const googleCalendarEvents = await dispatch(fetchGoogleCalendarEvents());
      if (fetchGoogleCalendarEvents.rejected.match(googleCalendarEvents)) {
        errorMessages.push("Google calendar fetch failed.");
      } else {
        results.googleEvents = googleCalendarEvents.payload;
      }
    } catch (error) {
      console.log("Google calendar error: ", error);
      errorMessages.push("Google calendar fetch failed.");
    }

    try {
      const appleCalendarEvents = await dispatch(fetchAppleCalendarEvents());
      if (fetchAppleCalendarEvents.rejected.match(appleCalendarEvents)) {
        errorMessages.push("Apple calendar fetch failed.");
      } else {
        results.appleEvents = appleCalendarEvents.payload;
      }
    } catch (error) {
      console.log("Apple calendar error: ", error);
      errorMessages.push("Apple calendar fetch failed.");
    }

    try {
      const outlookCalendarEvents = await dispatch(
        fetchOutlookCalendarEvents()
      );
      if (fetchOutlookCalendarEvents.rejected.match(outlookCalendarEvents)) {
        errorMessages.push("Outlook calendar fetch failed.");
      } else {
        results.outlookEvents = outlookCalendarEvents.payload;
      }
    } catch (error) {
      console.log("Outlook calendar error: ", error);
      errorMessages.push("Outlook calendar fetch failed.");
    }

    if (errorMessages.length > 0) {
      dispatch(
        setToastVisible({
          toastType: "error",
          message: errorMessages.join(" "),
        })
      );
      // return rejectWithValue(new Error(errorMessages.join(" ")));
    }

    return fulfillWithValue(results);
  }
);

export const deleteCalenderEvent = createAsyncThunk(
  "calendar/deleteCalenderEvent",
  async (
    { googleEvent },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice
    const googleEventId = googleEvent.id;

    const calendarEvent = getState().calendar.googleEvents[googleEventId];

    try {
      return axios
        .post(`${googleServerUrl}/deleteCalendarEvent`, calendarEvent, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            calendarId: googleEvent.calendarId,
            eventId: googleEventId,
          },
        })
        .then((response) => {
          return fulfillWithValue(googleEventId);
        })
        .catch((error) => {
          console.log(error);

          dispatch(
            setToastVisible({
              toastType: "error",
              message:
                "Something went wrong talking to Google calendar. Please contact support",
            })
          );

          return rejectWithValue(error);
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOutlookCalendarEvent = createAsyncThunk(
  "calendar/deleteOutlookCalendarEvent",
  async (
    { outlookEvent },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    console.log(" dispatch: Deleting outlook event");

    // Get the current event from the calendar slice
    const outlookEventId = outlookEvent.id;

    // const outlookEvents = getState().calendar.googleEvents[outlookEventId];

    try {
      const idToken = await auth.currentUser.getIdToken(true);
      return axios
        .post(`${outlookServerUrl}/deleteOutlookEvent`, outlookEvent, {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          params: {
            accountId: outlookEvent.accountId,
            eventId: outlookEventId,
            idToken: idToken,
          },
        })
        .then((response) => {
          console.log("Successfully deleted outlook event");
          return fulfillWithValue(outlookEventId);
        })
        .catch((error) => {
          console.log(error);

          dispatch(
            setToastVisible({
              toastType: "error",
              message:
                "Something went wrong talking to Outlook calendar. Please contact support",
            })
          );

          return rejectWithValue(error);
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAppleCalendarEvent = createAsyncThunk(
  "calendar/deleteAppleCalendarEvent",
  async (
    { appleEvent },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice

    try {
      return axios
        .post(`${googleServerUrl}/deleteAppleCalendarEvent`, appleEvent, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            url: appleEvent.extendedProps.url,
            etag: appleEvent.etag,
            calendarUser: appleEvent.calendarAccount,
          },
        })
        .then((response) => {
          return fulfillWithValue(appleEvent.id);
        })
        .catch((error) => {
          console.log(error);

          dispatch(
            setToastVisible({
              toastType: "error",
              message:
                "Something went wrong talking to iCal servers. Please contact support",
            })
          );

          return rejectWithValue(error);
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRecurringCalendarEvent = createAsyncThunk(
  "calendar/updateRecurringCalendarEvent",
  async (
    { eventId, newData, sendUpdates, recurringUpdateType = "all" },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice
    const googleEventId = getState().calendar.googleEvents[eventId].id;

    const calendarEvent = getState().calendar.googleEvents[eventId];
    const googleEvent = getState().calendar.googleEventData[googleEventId];

    const recurringEventId = googleEvent.recurringEventId;
    try {
      return axios
        .post(`${googleServerUrl}/updateRecurringCalendarEvent`, newData, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            eventId: googleEvent.id,
            calendarId: googleEvent.calendarId,
            sendUpdates: sendUpdates,
            recurringEventId: recurringEventId,
            type: recurringUpdateType,
          },
        })
        .then((res) => {
          if (res.data?.event?.id) {
            // Update the event in the googleEvents object

            var converted = convertGoogleCalendarEventToEvent({
              ...res.data.event,
            });

            if (converted) {
              return fulfillWithValue({
                newEvent: converted,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err != null) {
            return rejectWithValue(googleEvent);
          }
        });
    } catch (error) {
      return rejectWithValue(googleEvent);
    }
  }
);

export const updateGoogleCalendarEvent = createAsyncThunk(
  "calendar/updateGoogleCalendarEvent",
  async (
    { eventId, newData, sendUpdates },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice
    const googleEventId = getState().calendar.googleEvents[eventId].id;

    const calendarEvent = getState().calendar.googleEvents[eventId];
    const googleEvent = getState().calendar.googleEventData[googleEventId];

    try {
      return axios
        .post(`${googleServerUrl}/updateCalendarEvent`, newData, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            eventId: googleEvent.id,
            calendarId: googleEvent.calendarId,
            sendUpdates: sendUpdates,
          },
        })
        .then((res) => {
          if (res.data?.event?.id) {
            // Update the event in the googleEvents object

            var converted = convertGoogleCalendarEventToEvent({
              ...res.data.event,
            });

            if (converted) {
              return fulfillWithValue({
                newEvent: converted,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err != null) {
            return rejectWithValue(googleEvent);
          }
        });
    } catch (error) {
      return rejectWithValue(googleEvent);
    }
  }
);

export const updateOutlookCalendarEvent = createAsyncThunk(
  "calendar/updateOutlookCalendarEvent",
  async (
    { eventId, newData, sendUpdates },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice
    const outlookEvent = getState().calendar.outlookEvents[eventId];

    try {
      const idToken = await auth.currentUser.getIdToken(true);

      return axios
        .post(`${outlookServerUrl}/updateOutlookEvent`, newData, {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          params: {
            accountId: outlookEvent.accountId,
            eventId: outlookEvent.id,
            calendarId: outlookEvent.calendar,
            idToken: idToken,
            // sendUpdates: sendUpdates,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("Successfully updated outlook event");
          } else {
            console.log("Error updating outlook event");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err != null) {
            return rejectWithValue(outlookEvent);
          }
        });
    } catch (error) {
      return rejectWithValue(outlookEvent);
    }
  }
);

export const updateAppleCalendarEvent = createAsyncThunk(
  "calendar/updateAppleCalendarEvent",
  async (
    { appleEvent, newData },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;
    try {
      var systemTimezone = moment.tz.guess();

      if (!systemTimezone) {
        systemTimezone = "America/Chicago";
      }

      return axios
        .post(`${googleServerUrl}/updateAppleCalendarEvent`, newData, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            data: appleEvent.extendedProps.data,
            url: appleEvent.extendedProps.url,
            etag: appleEvent.etag,
            start: newData.start,
            end: newData.end,
            calendarUser: appleEvent.calendarAccount,
            systemTimezone: systemTimezone,
          },
        })
        .then((res) => {
          // Get the "newEtag" from the response
          return fulfillWithValue({
            newEvent: {
              ...appleEvent,
              start: newData.start,
              end: newData.end,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          if (err != null) {
            return rejectWithValue(appleEvent);
          }
        });
    } catch (error) {
      console.log(error);
      return rejectWithValue(appleEvent);
    }
  }
);

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setCalendarEventEditModalActiveFor: (state, action) => {
      state.calendarEventEditModalActiveFor = action.payload;
    },
    setTaskEvents: (state, action) => {
      state.taskEvents = action.payload;
    },
    updateTaskEvent: (state, action) => {
      const { id, newEventData } = action.payload;
      state.taskEvents[id] = {
        ...state.taskEvents[id],
        ...newEventData,
      };
    },
    updateTaskEventFromTaskChangeData: (state, action) => {
      const { id, newTask } = action.payload;

      state.taskEvents[id] = {
        ...state.taskEvents[id],
        ...convertTaskToEvent(newTask),
      };
    },
    setContextMenuActiveForEvent: (state, action) => {
      state.contextMenuActiveForEvent = action.payload;
    },
    stageGoogleEvent: (state, action) => {
      const { originalCalendarEvent, newGoogleEventData } = action.payload;

      var originalGoogleEvent = state.googleEventData[originalCalendarEvent.id];

      // The oriignal
      state.stagedGoogleEvent = {
        originalCalendarEvent: originalCalendarEvent,
        originalGoogleEvent: originalGoogleEvent,
        newGoogleEventData: newGoogleEventData,
      };

      var newGoogleEvent = {
        ...originalGoogleEvent,
        ...newGoogleEventData,
      };

      // Let's first update the googleEventData object
      state.googleEventData = {
        ...state.googleEventData,
        [originalGoogleEvent.id]: newGoogleEvent,
      };

      // Update the event in the googleEvents object
      const newEvents = {
        [originalCalendarEvent.id]:
          convertGoogleCalendarEventToEvent(newGoogleEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };
    },
    revertStagedGoogleEvent: (state, action) => {
      const { originalCalendarEvent, originalGoogleEvent } =
        state.stagedGoogleEvent;

      // Let's first update the googleEventData object
      state.googleEventData = {
        ...state.googleEventData,
        [originalGoogleEvent.id]: originalGoogleEvent,
      };

      // Update the event in the googleEvents object
      const newEvents = {
        [originalCalendarEvent.id]:
          convertGoogleCalendarEventToEvent(originalGoogleEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };

      state.stagedGoogleEvent = null;
    },
    clearStaging: (state, action) => {
      state.stagedGoogleEvent = null;
    },
  },
  extraReducers: {
    [deleteCalenderEvent.pending]: (state, action) => {
      const { googleEvent } = action.meta.arg;

      // Remove the event from the googleEvents object
      const newEvents = { ...state.googleEvents };

      delete newEvents[googleEvent.id];

      state.googleEvents = newEvents;

      // Remove the event from the googleEventData object
      const newEventData = { ...state.googleEventData };

      delete newEventData[googleEvent.id];

      state.googleEventData = newEventData;
    },
    [deleteAppleCalendarEvent.pending]: (state, action) => {
      const { appleEvent } = action.meta.arg;

      // Remove the event from the appleEvents object
      const newEvents = { ...state.appleEvents };

      delete newEvents[appleEvent.id];

      state.appleEvents = newEvents;
    },
    [deleteCalenderEvent.fulfilled]: (state, action) => {
      // In theory, do nothing
    },
    [deleteCalenderEvent.rejected]: (state, action) => {
      const { googleEvent } = action.meta.arg;

      // Add the event back to the googleEvents object
      const newEvents = {
        [googleEvent.id]: convertGoogleCalendarEventToEvent(googleEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };

      // Add the event back to the googleEventData object
      const newEventData = {
        [googleEvent.id]: googleEvent,
      };

      state.googleEventData = {
        ...state.googleEventData,
        ...newEventData,
      };
    },
    [deleteOutlookCalendarEvent.pending]: (state, action) => {
      const { outlookEvent } = action.meta.arg;

      // Remove the event from the outlookEvents object
      const newEvents = { ...state.outlookEvents };

      delete newEvents[outlookEvent.id];

      state.outlookEvents = newEvents;
    },
    [deleteOutlookCalendarEvent.rejected]: (state, action) => {
      const { outlookEvent } = action.meta.arg;

      // Add the event back to the outlookEvents object
      const newEvents = {
        [outlookEvent.id]: outlookEvent,
      };

      state.outlookEvents = {
        ...state.outlookEvents,
        ...newEvents,
      };
    },
    [deleteAppleCalendarEvent.rejected]: (state, action) => {
      const { appleEvent } = action.meta.arg;

      // Add the event back to the appleEvents object
      const newEvents = {
        [appleEvent.id]: appleEvent,
      };

      state.appleEvents = {
        ...state.appleEvents,
        ...newEvents,
      };
    },
    [updateTaskFromEvent.pending]: (state, action) => {
      const event = action.meta.arg;

      // Let's update the event in the taskEvents object
      const newEvents = {
        [event.id]: event,
      };

      state.taskEvents = {
        ...state.taskEvents,
        ...newEvents,
      };
    },
    [updateTaskFromEvent.fulfilled]: (state, action) => {
      const event = action.payload;

      // Nothing to do hopefully
    },
    [updateTaskFromEvent.rejected]: (state, action) => {
      const event = action.meta.arg;

      // Ideally we can fill this out to undo the event change if we need
    },
    [fetchTaskEventsFromTasks.fulfilled]: (state, action) => {
      state.taskEvents = action.payload;
    },
    [fetchBothCalendarEvents.pending]: (state, action) => {
      state.lastSync = null;
    },
    [fetchBothCalendarEvents.fulfilled]: (state, action) => {
      const { googleEvents, appleEvents, outlookEvents } = action.payload;

      // Combine or handle the events as needed
      state.googleEvents = googleEvents.newEvents;
      state.googleEventData = googleEvents.newGoogleEventData;
      state.appleEvents = appleEvents;
      state.outlookEvents = outlookEvents;

      state.lastSync = new Date();
    },
    [fetchGoogleCalendarEvents.pending]: (state, action) => {
      //  state.lastSync = null;
    },
    [fetchAppleCalendarEvents.pending]: (state, action) => {
      //  state.lastSync = null;
    },
    [fetchAppleCalendarEvents.fulfilled]: (state, action) => {
      const newEvents = action.payload;

      // When we do this, we wipe it completely
      //  state.appleEvents = newEvents;

      //  state.lastSync = new Date();
    },
    [fetchGoogleCalendarEvents.fulfilled]: (state, action) => {
      const { newEvents, newGoogleEventData } = action.payload;

      // When we do this, we wipe it completely
      //    state.googleEvents = newEvents;

      //    state.googleEventData = newGoogleEventData;

      //    state.lastSync = new Date();
    },
    [fetchGoogleCalendarEvents.rejected]: (state, action) => {
      console.log("Error fetching Google Calendar Events");
      //  state.lastSync = new Date();
    },
    [updateRecurringCalendarEvent.pending]: (state, action) => {
      // Let's optimistically update the google event
      const { eventId, newData } = action.meta.arg;

      // First, lets update the underlying googleEventData
      const googleEventId = state.googleEventData[eventId].id;

      state.googleEventData = {
        ...state.googleEventData,
        [googleEventId]: {
          ...state.googleEventData[googleEventId],
          ...newData,
        },
      };

      // Let's update the googleEvents object, which is what we use to render the calendar
      const newEvents = {
        [eventId]: convertGoogleCalendarEventToEvent(
          state.googleEventData[googleEventId]
        ),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };
    },
    [updateGoogleCalendarEvent.pending]: (state, action) => {
      // Let's optimistically update the google event
      const { eventId, newData } = action.meta.arg;

      // First, lets update the underlying googleEventData
      const googleEventId = state.googleEventData[eventId].id;

      state.googleEventData = {
        ...state.googleEventData,
        [googleEventId]: {
          ...state.googleEventData[googleEventId],
          ...newData,
        },
      };

      // Let's update the googleEvents object, which is what we use to render the calendar
      const newEvents = {
        [eventId]: convertGoogleCalendarEventToEvent(
          state.googleEventData[googleEventId]
        ),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };
    },
    [updateOutlookCalendarEvent.pending]: (state, action) => {
      // Let's optimistically update the outlook event

      const { eventId, newData } = action.meta.arg;

      // First, lets update the underlying outlookEventData
      const outlookEventId = state.outlookEvents[eventId].id;

      state.outlookEvents = {
        ...state.outlookEvents,
        [outlookEventId]: {
          ...state.outlookEvents[outlookEventId],
          ...newData,
        },
      };
    },
    [updateAppleCalendarEvent.pending]: (state, action) => {
      // Let's optimistically update the google event
      const { appleEvent, newData } = action.meta.arg;

      // First, lets update the underlying googleEventData
      const appleEventId = appleEvent.id;

      state.appleEvents = {
        ...state.appleEvents,
        [appleEventId]: {
          ...state.appleEvents[appleEventId],
          ...newData,
        },
      };
    },
    [updateAppleCalendarEvent.fulfilled]: (state, action) => {},
    // First, lets update the underlying googleEventData
    [updateRecurringCalendarEvent.fulfilled]: (state, action) => {
      // In theory, we don't have to do anything
      // If there are any staged events, we should clear them
      state.stagedGoogleEvent = null;
    },
    [updateRecurringCalendarEvent.rejected]: (state, action) => {
      console.log("Error updating Google Calendar Event: ", action);

      // Let's revert the optimistic update
      const originalEvent = action.payload;

      // First, lets update the underlying googleEventData
      const googleEventId = state.googleEventData[originalEvent.id].id;

      state.googleEventData = {
        ...state.googleEventData,
        [googleEventId]: originalEvent,
      };

      // Let's update the googleEvents object, which is what we use to render the calendar
      const newEvents = {
        [originalEvent.id]: convertGoogleCalendarEventToEvent(originalEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };
    },
    [updateAppleCalendarEvent.rejected]: (state, action) => {
      console.log("Error updating Apple Calendar Event: ", action);

      // Let's revert the optimistic update
      const originalEvent = action.payload;

      // First, lets update the underlying googleEventData
      const appleEventId = state.appleEvents[originalEvent.id].id;

      state.appleEvents = {
        ...state.appleEvents,
        [appleEventId]: originalEvent,
      };
    },
    [updateGoogleCalendarEvent.fulfilled]: (state, action) => {
      // In theory, we don't have to do anything
      // If there are any staged events, we should clear them
      state.stagedGoogleEvent = null;
    },
    [updateGoogleCalendarEvent.rejected]: (state, action) => {
      console.log("Error updating Google Calendar Event: ", action);

      // Let's revert the optimistic update
      const originalEvent = action.payload;

      // First, lets update the underlying googleEventData
      const googleEventId = state.googleEventData[originalEvent.id].id;

      state.googleEventData = {
        ...state.googleEventData,
        [googleEventId]: originalEvent,
      };

      // Let's update the googleEvents object, which is what we use to render the calendar
      const newEvents = {
        [originalEvent.id]: convertGoogleCalendarEventToEvent(originalEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };
    },
  },
});

export const {
  setContextMenuActiveForEvent,
  stageGoogleEvent,
  revertStagedGoogleEvent,
  clearStaging,
  setTaskEvents,
  updateTaskEvent,
  updateTaskEventFromTaskChangeData,
  setCalendarEventEditModalActiveFor,
} = calendarSlice.actions;

export default calendarSlice.reducer;

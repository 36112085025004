import React, { useEffect, useState } from "react";
import { Drawer, Select } from "antd";
import { Bar } from "react-chartjs-2";
import { RiCactusLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  generateCompletedTasksPerDayPerLabel,
  generateEstimatedTimeTasksPerDayPerLabel,
  generateActualTimeTasksPerDayPerLabel,
  formatDuration,
} from "../AnalyticsFunctions";
import TaskTable from "../TaskTable";
import { analytics } from "../../../utils";
import moment from "moment";

export default function DailyCompletedBarChart({
  taskData,
  dateRange,
  userTheme,
}) {
  const { data: labels } = useSelector((state) => state.labels);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [unit, setUnit] = useState("number");

  useEffect(() => {
    const generateData = () => {
      switch (unit) {
        case "number":
          return generateCompletedTasksPerDayPerLabel(
            taskData,
            dateRange.dates,
            labels
          ).finalData;
        case "estimated_time":
          return generateEstimatedTimeTasksPerDayPerLabel(
            taskData,
            dateRange.dates,
            labels
          ).finalData;
        case "actual_time":
          return generateActualTimeTasksPerDayPerLabel(
            taskData,
            dateRange.dates,
            labels
          ).finalData;
        default:
          return [];
      }
    };

    const dateArray = dateRange.dates.map((date) => {
      return moment(date).format("MMM D");
    });

    const finalData = generateData();

    const barChartData = {
      labels: dateArray,
      datasets: finalData,
    };

    setBarChartData(barChartData);
  }, [taskData, dateRange, labels, unit]);

  const chartOptions = {
    plugins: {
      title: {
        display: false,
        text: unit === "number" ? "# of Tasks" : unit === "estimated_time" ? "Estimated time" : "Actual time",
      },
      tooltip: {
        filter: function (tooltipItem) {
          return tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0;
        },
        callbacks: {
          label: function (tooltipItems) {
            if (unit === "number") {
              return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue}`;
            } else {
              const rawSeconds =
                tooltipItems.dataset.rawSecondsData[tooltipItems.dataIndex];
              return `${tooltipItems.dataset.label}: ${formatDuration(
                rawSeconds
              )}`;
            }
          },
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: "point",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
        },
      },
      y: {
        stacked: true,
        grid: {
          color: userTheme == "dark" ? "#474747" : "rgba(0, 0, 0, 0.05)",
        },
        title: {
          display: true,
          text: unit === "number" ? "# of tasks" : unit === "estimated_time" ? "Estimated time" : "Actual time",
          color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
        },
        ticks: {
          color: userTheme == "dark" ? "#d9d9d9" : "#7a7a7a",
        },
      },
    },
    onClick: (event, elements, chart) => {
      if (elements[0]) {
        var datasetIndex = elements[0].datasetIndex;
        const index = elements[0].index;
        const tasks = barChartData.datasets[datasetIndex].tasks[index];
        analytics("Analytics drilldown", {
          type: "daily-completed-bar-chart",
        });
        setSelectedTasks(tasks);
      }
    },
  };

  return (
    <div
      className="chart"
      style={{
        gridArea: "bar-chart-2",
      }}
    >
      <Drawer
        title="Tasks"
        placement="right"
        closable={true}
        onClose={() => {
          setSelectedTasks(null);
        }}
        open={selectedTasks !== null}
        width={500}
        zIndex={10}
      >
        <TaskTable tasks={selectedTasks} labels={labels} />
      </Drawer>

      <div className="flex justify-between items-center mb-4">
        <div className="chart-title">How you spend your time each day</div>
        <Select
          options={[
            { value: "number", label: "# of Tasks" },
            { value: "estimated_time", label: "Estimated time" },
            { value: "actual_time", label: "Actual time" },
          ]}
          onChange={(value) => {
            analytics("Analytics chart type changed", {
              type: value,
            });
            setUnit(value);
          }}
          value={unit}
          style={{ width: 150 }}
        />
      </div>

      {barChartData && barChartData?.datasets?.length > 0 && (
        <Bar options={chartOptions} data={barChartData} />
      )}

      {barChartData?.datasets && barChartData?.datasets?.length === 0 && (
        <div className="no-data">
          <RiCactusLine className="icon" />
          <div>No data for this period</div>
        </div>
      )}
    </div>
  );
}
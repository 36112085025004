import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={320}
    height={332}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={75} cy={75} r={67.5} stroke="#000" strokeWidth={15} />
    <circle cx={245} cy={257} r={67.5} stroke="#000" strokeWidth={15} />
    <path
      d="M71.5 139c-16.55 96.9 12.594 128.26 101.848 121.331"
      stroke="#000"
      strokeWidth={18}
    />
  </svg>
);

export default SvgComponent;

import "./Settings.css";
import { useState } from "react";

import { ArrowCircleLeftIcon, ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

import {
  getAuth,
  updatePassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default function ChangePassword({ setChangePasswordActive }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  async function changePassword() {
    if (password === confirmPassword) {
      const auth = getAuth();
      const user = auth.currentUser;

      signInWithEmailAndPassword(auth, user.email, currentPassword)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...

          updatePassword(user, password)
            .then(() => {
              // Update successful.
              alert("Password changed successfully");
              setChangePasswordActive(false);
            })
            .catch((error) => {
              // An error ocurred
              // ...
              alert(`${error.message}`);
            });
        })
        .catch((error) => {
          alert(`Current password is incorrect`);
        });
    } else {
      alert("Passwords do not match");
    }
  }

  return (
    <div>
      <div
        onClick={() => {
          setChangePasswordActive(false);
        }}
        className="back-button"
      >
        <ArrowLeftCircleIcon className="back-button-icon" />
        Back
      </div>
      <div className="settings-title">Change Password</div>

      <div className="settings-item">
        <div className="settings-item-title">Current password</div>
        <input
          className="settings-item-input"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          type="password"
        />
      </div>

      <div className="settings-item">
        <div className="settings-item-title">New password</div>
        <input
          className="settings-item-input"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
        />
      </div>

      <div className="settings-item">
        <div className="settings-item-title">Confirm password</div>
        <input
          className="settings-item-input"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          type="password"
        />
      </div>

      <div
        onClick={() => changePassword()}
        style={{ marginTop: "10px" }}
        className="button"
      >
        Change Password
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { Popover } from "antd";

import "./DurationPicker.css";

import DurationPickerInput from "./DurationPickerInput";
import moment from "moment";

import { useDispatch } from "react-redux";

import { updateTask } from "../../../../../redux/tasksSlice";

import Sheet from "react-modal-sheet";

import { Mobile, Default } from "../../../../../mediaUtils";

import { useMediaQuery } from "react-responsive";
import { RiTimeLine } from "react-icons/ri";
export default function DurationPicker({
  item,
  time,
  updateTimeLocal,
  title,
  durationPickerActive,
  setDurationPickerActive,
  buttonMode = false,
}) {
  const [hours, setHours] = useState(secondsToHms(time)?.hours);
  const [minutes, setMinutes] = useState(secondsToHms(time)?.minutes);

  const [timeEditable, setTimeEditable] = useState(time);

  const changesMade = useRef(false);

  useEffect(() => {
    if (timeEditable !== time) {
      setHours(secondsToHms(time)?.hours);
      setMinutes(secondsToHms(time)?.minutes);
      setTimeEditable(time);
    }
  }, [time]);

  const dispatch = useDispatch();

  function updateTime(newValue) {
    if (updateTimeLocal) {
      updateTimeLocal(newValue);
    } else {
      if (title === "Actual") {
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              actual_time: newValue,
            },
          })
        );
      } else {
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              estimated_time: newValue,
            },
          })
        );
      }
    }
  }

  useEffect(() => {
    // If the estimated time is updated, let's update the hours and minutes
    if (!durationPickerActive) {
      const totalSeconds =
        parseInt(hours != null && hours != "" ? hours : 0, 10) * 60 * 60 +
        parseInt(minutes != null && minutes != "" ? minutes : 0, 10) * 60;

      setTimeEditable(totalSeconds);
    }
  }, [durationPickerActive]);

  useEffect(() => {
    // If the estimated time is updated, let's update the hours and minutes
    const hmsObject = secondsToHms(time);

    setHours(hmsObject?.hours);
    setMinutes(hmsObject?.minutes);
  }, [time]);

  useEffect(() => {
    // When we close the duration picker input
    // If there are differences, save the change'

    if (changesMade.current) {
      if (time !== timeEditable) {
        if (isNaN(timeEditable) || timeEditable == 0) {
          updateTime(null);
        } else {
          updateTime(timeEditable);
        }

        changesMade.current = false;
      }
    }
  }, [timeEditable]);

  return (
    <>
      <Popover
        open={durationPickerActive}
        onOpenChange={(visible) => {
          changesMade.current = true;
          setDurationPickerActive(visible);
        }}
        destroyTooltipOnHide={false}
        title={null}
        content={
          <DurationPickerInput
            hours={hours}
            minutes={minutes}
            setHours={setHours}
            setMinutes={setMinutes}
            visible={durationPickerActive}
            setVisible={(visible) => {
              changesMade.current = true;
              setDurationPickerActive(visible);
            }}
            title={title}
          />
        }
        placement="bottom"
        trigger="click"
      >
        {buttonMode ? (
          <div
            className={`mcf-button${
              isNaN(timeEditable) || timeEditable == 0 ? " empty" : ""
            }`}
          >
            {!isNaN(timeEditable) && timeEditable != 0
              ? `${moment.utc(timeEditable * 1000).format("H")}h ${moment
                  .utc(timeEditable * 1000)
                  .format("m")}m`
              : "Click to edit"}
          </div>
        ) : (
          <div
            onClick={() => setDurationPickerActive(!durationPickerActive)}
            className="ts-content"
            style={title === "Actual" ? { marginRight: "20px" } : {}}
          >
            <div className="ts-title">{title}</div>
            <div className="ts-content">
              {" "}
              {!isNaN(timeEditable)
                ? `${moment.utc(timeEditable * 1000).format("H")}h ${moment
                    .utc(timeEditable * 1000)
                    .format("m")}m`
                : "0h 0m"}
            </div>
          </div>
        )}
      </Popover>
    </>
  );
}

function secondsToHms(d) {
  if (!d) {
    return null;
  }

  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}

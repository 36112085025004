import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import SignUp from "./SignUp";
import { isDev } from "../../utils";
import Valhalla from "./Valhalla";

export default function Auth() {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route path="signup" element={<SignUp />} />

      {isDev && <Route path="valhalla" element={<Valhalla />} />}
    </Routes>
  );
}

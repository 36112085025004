import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { db } from "../firebase";
import { RRule, RRuleSet, rrulestr } from "rrule";
import {
  doc,
  setDoc,
  query,
  where,
  collection,
  getDocs,
  writeBatch,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { updateCurrentUser } from "./appSlice";

import { toast } from "sonner";

import {
  analytics,
  generateTaskFromTemplateAndDate,
  googleServerUrl,
  handleInlineLabel,
  isGhostTask,
  shouldProcessCalendarEventsForUser,
  tasksServerUrl,
  v1TasksServerUrl,
} from "../utils";
import { createRecurringTask } from "./recurringTasksSlice";

import { addMinutes } from "date-fns";
import {
  updateTaskEvent,
  updateTaskEventFromTaskChangeData,
} from "./calendarSlice";
import axios from "axios";
import { generateTasksForSpecificDates } from "./recurringTaskUtils";

const initialState = {
  data: {},
  recurringTasks: {},
  order: {},
  loading: true,
  orderLoading: true,
  brainDumpLoading: false,
  ghostTasksLoaded: false,
  dateRangesLoaded: {
    taskOrders: [],
    ghostTasks: [],
  },
  calendarDate: moment().format("YYYY-MM-DD"),
  activeTimerTaskId: null,
  lists: {},
  tasksToBeDeleted: [],
  listsToBeDeleted: [],
};

// Create List
export const createList = createAsyncThunk(
  "tasks/createList",
  async ({ list }, { getState, rejectWithValue, dispatch }) => {
    const userId = getState().app.uid;

    try {
      const listId = list.id || uuidv4();

      await setDoc(doc(db, "users", userId, "lists", listId), {
        ...list,
        id: listId,
      });

      // LEt's also update the current user
      dispatch(
        updateCurrentUser({
          newValues: {
            selectedList: listId,
          },
          previousValues: {
            selectedList:
              getState().app.currentUser.selectedList || "brain_dump",
          },
        })
      );

      analytics("List created", {
        title: list.title,
        icon: list.icon,
      });

      return { listId, list };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update List
export const updateList = createAsyncThunk(
  "tasks/updateList",
  async ({ currentList, newData }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    try {
      await setDoc(
        doc(db, "users", userId, "lists", currentList.id),
        {
          ...newData,
        },
        { merge: true }
      );

      analytics("List updated");

      return { listId: currentList.id, newData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete List
export const deleteList = createAsyncThunk(
  "tasks/deleteList",
  async ({ list }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    try {
      await deleteDoc(doc(db, "users", userId, "lists", list.id));

      analytics("List deleted");

      return { listId: list.id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaskOrder = createAsyncThunk(
  "tasks/updateTaskOrder",
  async (
    { date, order, previousOrder },
    { dispatch, getState, rejectWithValue }
  ) => {
    const userId = getState().app.uid;

    const listKeys = getState().tasks.lists
      ? [...Object.keys(getState().tasks.lists), "brain_dump"]
      : ["brain_dump"];

    try {
      // We need to check if there are any "ghost" recurring tasks that need to be created
      // Let's iterate through the order and see if there are any recurring tasks

      if (!listKeys.includes(date)) {
        // First, let's get the recurring tasks
        const recurringTasks = getState().tasks.recurringTasks;

        // Let's get all tasks
        const tasks = getState().tasks.data;

        // Now, let's iterate through the order and see if there are any recurring tasks
        order.forEach((taskId) => {
          var currentTask = tasks[taskId];
          if (currentTask && currentTask.recurring) {
            var recurringTask = recurringTasks[currentTask.recurring_id];

            if (
              recurringTask &&
              (!recurringTask.branched_tasks ||
                !recurringTask.branched_tasks?.includes(taskId))
            ) {
              // This is a recurring task that has not been branched off yet
              // Lets branch it off

              dispatch(
                convertGhostTaskToTask({
                  ghostTask: currentTask,
                  saveOrder: false,
                })
              );
            }
          }
        });
      }

      await setDoc(
        doc(db, "users", userId, "task_order", date),
        {
          order: order,
          date: !listKeys.includes(date)
            ? moment(date, "YYYY-MM-DD").toDate()
            : null,
          list: listKeys.includes(date) ? true : false,
        },
        {
          merge: true,
        }
      );

      return { date, order, previousOrder };
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

// Format to taskOrdersToAdd is {date: [taskIds]} where date is YYYY-MM-DD
export const bulkAddToTaskOrder = createAsyncThunk(
  "tasks/bulkAddToTaskOrder",
  async ({ taskOrdersToAdd }, { dispatch, getState, rejectWithValue }) => {
    const userId = getState().app.uid;
    let listIds =
      (getState().tasks.lists && Object.keys(getState().tasks.lists)) || [];
    listIds.push("brain_dump");

    let operationCounter = 0;
    let batch = writeBatch(db);
    let omittedTasks = []; // To track tasks that were omitted due to errors

    try {
      const tasks = getState().tasks.data;

      Object.keys(taskOrdersToAdd).forEach((dateString) => {
        const taskOrderRef = doc(db, "users", userId, "task_order", dateString);
        let taskIdsToAdd = taskOrdersToAdd[dateString];

        taskIdsToAdd.forEach((taskId) => {
          try {
            // Check if the date is valid
            const momentDate = moment(dateString, "YYYY-MM-DD");
            if (!momentDate.isValid()) {
              console.error(`Invalid date for task ${taskId}`);
              omittedTasks.push(taskId);
              return;
            }

            // If you have more validations, add them here.

            batch.set(
              taskOrderRef,
              {
                order: arrayUnion(taskId),
                date: momentDate.toDate(),
                list: listIds.includes(dateString),
              },
              { merge: true }
            );

            operationCounter++;

            // Check if we've reached Firestore's limit
            if (operationCounter >= 500) {
              // Commit this batch and start a new one
              batch.commit();
              batch = writeBatch(db);
              operationCounter = 0;
            }
          } catch (error) {
            console.error(`Error adding task ${taskId} to batch: ${error}`);
            omittedTasks.push(taskId);
          }
        });
      });

      // Commit the last batch if it has any operations left
      if (operationCounter > 0) {
        await batch.commit();
      }

      return { taskOrdersToAdd, omittedTasks };
    } catch (error) {
      console.error("Overall error in bulkAddToTaskOrder:", error);
      return rejectWithValue(error);
    }
  }
);

export const bulkUpdateTaskOrder = createAsyncThunk(
  "tasks/bulkUpdateTaskOrder",
  async (
    { newOrder, previousOrder },
    { dispatch, getState, rejectWithValue }
  ) => {
    const userId = getState().app.uid;

    var listIds =
      (getState().tasks.lists && Object.keys(getState().tasks.lists)) || [];

    // Add "brain_dump" to listIds
    listIds.push("brain_dump");

    try {
      const batch = writeBatch(db);

      const recurringTasks = getState().tasks.recurringTasks;

      // Let's get all tasks
      const tasks = getState().tasks.data;

      newOrder.forEach((updatedOrder) => {
        // Get date in format YYYY-MM-DD

        // We need to check if there are any "ghost" recurring tasks that need to be materialized
        updatedOrder.order.forEach((taskId) => {
          var currentTask = tasks[taskId];
          if (currentTask && currentTask.recurring) {
            var recurringTask = recurringTasks[currentTask.recurring_id];

            if (
              recurringTask &&
              (!recurringTask.branched_tasks ||
                !recurringTask.branched_tasks?.includes(taskId))
            ) {
              // This is a recurring task that has not been branched off yet
              // Lets branch it off

              dispatch(
                convertGhostTaskToTask({
                  ghostTask: currentTask,
                  saveOrder: false,
                })
              );
            }
          }
        });

        const dateString = listIds.includes(updatedOrder.date)
          ? updatedOrder.date
          : moment(updatedOrder.date, "YYYY-MM-DD").format("YYYY-MM-DD");

        const taskOrderRef = doc(db, "users", userId, "task_order", dateString);

        if (listIds.includes(dateString)) {
          batch.set(
            taskOrderRef,
            {
              order: updatedOrder.order,
              date: null,
              list: true,
            },
            { merge: true }
          );
        } else {
          batch.set(
            taskOrderRef,
            {
              ...updatedOrder,
            },
            { merge: true }
          );
        }
      });

      await batch.commit();
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async (
    {
      taskId,
      currentTask,
      newData,
      saveGhostOrder = true,
      updateOrder = false,
      ignore_timer_settings = false,
    },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      // Might need to convert the date to timestamp
      // Also handle null case for braindump

      const userId = getState().app.uid;

      const labels = getState().labels.data;

      const {
        active_timer,
        auto_stop_timer = null,
        inline_label_enabled = true,
      } = getState().app.currentUser;

      const currentUser = getState().app.currentUser;

      // Let's also add newData to current task
      var currentTaskClone = { ...currentTask, ...newData };

      const listId =
        currentTaskClone.listId ||
        (!currentTaskClone.date ? "brain_dump" : null);

      // If this is a recurring task, lets check if it has been branched off yet
      if (currentTask.recurring) {
        var recurringTask =
          getState().tasks.recurringTasks[currentTask.recurring_id];

        // Check if the "date" key exists in newData and is null
        if (newData.date === null) {
          // This means we are removing the date and moving to brain dump
          // Let's go ahead and also remove the "recurring" and "recurring_id"
          // keys from the current task
          delete currentTaskClone.recurring;
          newData = {
            ...newData,
            recurring: null,
            recurring_id: null,
          };
        }

        if (
          recurringTask &&
          (!recurringTask.branched_tasks ||
            !recurringTask.branched_tasks?.includes(taskId))
        ) {
          // This is a recurring task that has not been branched off yet
          // Lets branch it off

          dispatch(
            convertGhostTaskToTask({
              ghostTask: currentTaskClone,
              saveOrder: saveGhostOrder,
              customExclusionDate:
                newData.date || newData.date === null ? currentTask.date : null,
            })
          );
        }
      }

      if (newData.description && inline_label_enabled) {
        const { label = null, newDescription = null } = handleInlineLabel(
          newData.description,
          labels
        );

        if (newDescription) {
          newData = {
            ...newData,
            description: newDescription,
          };
        }

        if (label) {
          newData = {
            ...newData,
            label,
          };
        }

        // update currentTaskClone
        currentTaskClone = { ...currentTaskClone, ...newData };
      }

      // whenever a task gets completed, we want to check if there are any subtasks
      // and if so, we want to complete them as well
      if (newData.complete === true && currentTaskClone.subtasks) {
        // Let's go through each subtask and complete it
        currentTaskClone.subtasks = currentTaskClone.subtasks.map((subtask) => {
          // Update the subtask object to be complete
          return {
            ...subtask,
            complete: true,
          };
        });

        // update newData
        newData = {
          ...newData,
          subtasks: currentTaskClone.subtasks,
        };
      }

      // If date is being changed and start is not, but the currentTask has a start date and the new date is not the same day as the start date, we need to update the start date to be null
      if (
        newData.date &&
        !newData.start &&
        currentTask.start &&
        moment(newData.date).isSame(currentTask.start, "day") === false
      ) {
        newData = {
          ...newData,
          start: null,
        };
        currentTaskClone = { ...currentTaskClone, ...newData };
      }

      // If the start date is being changed, lets update the order of the tasks
      var startChanged = false;

      try {
        // Check if newData.start is different from currentTask.start
        if (
          newData.start &&
          currentTask.start &&
          newData.start !== currentTask.start
        ) {
          startChanged = true;
        }

        if (newData.start && !currentTask.start) {
          startChanged = true;
        }
      } catch (error) {
        console.log("error", error);
      }

      // Let's check if we need to update the order
      if (startChanged || updateOrder) {
        // Get the previous date (date object) and convert to YYYY-MM-DD format
        const previousDateString = currentTask.date
          ? moment(currentTask.date).format("YYYY-MM-DD")
          : currentTask.listId || "brain_dump";

        // Get the new date (date object) and convert to YYYY-MM-DD format
        const newDateString = newData.date
          ? moment(newData.date).format("YYYY-MM-DD")
          : listId;

        let newOrder = _.cloneDeep(
          getState().tasks.order[newDateString]?.order || []
        );

        // If the previous date is different from the new date, we need to update the order
        if (previousDateString !== newDateString) {
          // Let's remove the task from the previous order

          let newOldOrder = _.cloneDeep(
            getState().tasks.order[previousDateString]?.order || []
          );

          newOldOrder = newOldOrder.filter((id) => id !== taskId);

          dispatch(
            updateTaskOrder({
              date: previousDateString,
              order: newOldOrder,
            })
          );
        } else {
          // Let's remove it from the new order so we can re-order
          newOrder = newOrder.filter((id) => id !== taskId);
        }

        // Let's add the task to the new order
        if (newDateString) {
          // Go through the new order and insert it between the tasks where start time is before and after it
          // start is a javascript date object
          var inserted = false;

          // Go through newOrder and remove any tasks that are null in getState().tasks.data
          newOrder = newOrder.filter((id) => getState().tasks.data[id]);

          // Go through each one and to find where to insert it
          for (var i = 0; i < newOrder.length; i++) {
            var taskToCompare = getState().tasks.data[newOrder[i]];

            // Get the start time of the task to compare
            var taskToCompareStartTime = taskToCompare.start;

            // If its null (or they do not have same completed status), we can skip it
            if (
              !taskToCompareStartTime ||
              taskToCompare.complete !== currentTaskClone.complete
            ) {
              continue;
            }

            // If date selected is a firestore timestamp, convert to date
            if (taskToCompareStartTime.toDate) {
              taskToCompareStartTime = taskToCompareStartTime.toDate();
            }

            // Get the start time of the new task
            var newTaskStartTime = newData.start;

            // If the new task start time is after the task to compare
            if (newTaskStartTime > taskToCompareStartTime) {
              // Let's check if the newTaskStartTime is before the start time of the next task with a valid start time
              // If it is, we can insert it here
              var nextTaskStartTime = null;

              // Go through the rest of the tasks and find the next one with a valid start time
              for (var j = i + 1; j < newOrder.length; j++) {
                var nextTaskToCompare = getState().tasks.data[newOrder[j]];

                // Get the start time of the task to compare
                nextTaskStartTime = nextTaskToCompare.start;

                // If date selected is a firestore timestamp, convert to date
                if (nextTaskStartTime && nextTaskStartTime.toDate) {
                  nextTaskStartTime = nextTaskStartTime.toDate();
                }

                // If its not null (or they do not have the same complete status), we can break out of the loop
                if (
                  nextTaskStartTime ||
                  nextTaskToCompare.complete !== currentTaskClone.complete
                ) {
                  break;
                }

                // If we get to the end of the loop and nextTaskStartTime is still null, we can insert it here
                if (j === newOrder.length - 1) {
                  nextTaskStartTime = null;
                }
              }

              // If the newTaskStartTime is null, we can insert it here
              if (!nextTaskStartTime) {
                newOrder.splice(i + 1, 0, taskId);
                inserted = true;
                break;
              }

              // If the newTaskStartTime is before the nextTaskStartTime, we can insert it after
              if (newTaskStartTime < nextTaskStartTime) {
                newOrder.splice(i + 1, 0, taskId);
                inserted = true;
                break;
              }
            }
          }

          if (!inserted) {
            newOrder.unshift(taskId);
          }

          // If the order has changed, let's update it
          if (
            !_.isEqual(
              newOrder,
              getState().tasks.order[newDateString]?.order || []
            )
          ) {
            dispatch(
              updateTaskOrder({
                date: newDateString,
                order: newOrder,
                previousOrder:
                  getState().tasks.order[newDateString]?.order || [],
              })
            );
          }
        }
      }

      // Let's check if a completed task still has a timer active
      if (newData.complete === true && active_timer) {
        // set auto_stop_timer state to true
        if (!ignore_timer_settings) {
          dispatch(
            updateCurrentUser({
              newValues: {
                auto_stop_timer: taskId,
              },
              previousValues: {
                auto_stop_timer: auto_stop_timer,
              },
            })
          );
        }
      }

      analytics("Task updated", {
        task_id: taskId,
        fields_updated: Object.keys(newData),
      });

      await setDoc(doc(db, "users", userId, "tasks", taskId), newData, {
        merge: true,
      });

      // Let's update the task in the calendar store
      dispatch(
        updateTaskEventFromTaskChangeData({
          id: taskId,
          newTask: currentTaskClone,
        })
      );

      const shouldProcessCalendarEvents =
        shouldProcessCalendarEventsForUser(currentUser);

      return fulfillWithValue({
        taskId,
        newTask: currentTaskClone,
        oldTask: currentTask,
        userId: userId,
        shouldProcessCalendarEvents,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const convertGhostTaskToTask = createAsyncThunk(
  "tasks/convertGhostTaskToTask",
  async (
    { ghostTask, saveOrder, customExclusionDate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const ghostTaskClone = _.cloneDeep(ghostTask);
      const userId = getState().app.uid;

      var recurringTask =
        getState().tasks.recurringTasks[ghostTaskClone.recurring_id];

      // So lets branch it off and append the taskId to the branched_tasks array
      const newBranchedTasks = (recurringTask.branched_tasks || []).concat(
        ghostTaskClone.id
      );

      // Let's update exclusions to include this
      // Get date in format YYYY-MM-DD
      const dateString = moment(
        customExclusionDate ? customExclusionDate : ghostTaskClone.date
      ).format("YYYY-MM-DD");

      const newExclusions = (recurringTask.exclusions || []).concat(dateString);

      dispatch(
        updateRecurringTask({
          recurringTaskId: recurringTask.id,
          currentRecurringTask: recurringTask,
          newData: {
            branched_tasks: newBranchedTasks,
            exclusions: newExclusions,
          },
        })
      );

      if (saveOrder) {
        // Let's also add it to the relevant task order by saving
        // Get date in format YYYY-MM-DD
        const dateString = moment(ghostTaskClone.date).format("YYYY-MM-DD");
        let newOrder = _.cloneDeep(
          getState().tasks.order[dateString]?.order || []
        );

        dispatch(
          updateTaskOrder({
            date: dateString,
            order: newOrder,
            previousOrder: newOrder,
          })
        );
      }

      await setDoc(
        doc(db, "users", userId, "tasks", ghostTaskClone.id),
        ghostTaskClone,
        {
          merge: true,
        }
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRecurringTask = createAsyncThunk(
  "tasks/updateRecurringTask",
  async (
    { recurringTaskId, currentRecurringTask, newData },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const userId = getState().app.uid;

      await setDoc(
        doc(db, "users", userId, "recurring_tasks", recurringTaskId),
        newData,
        {
          merge: true,
        }
      );

      const dates = getState().app.dates;
      return fulfillWithValue({ recurringTaskId, dates });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkAddToTasks = createAsyncThunk(
  "tasks/bulkAddToTasks",
  async ({ tasksToAdd }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;
    let operationCounter = 0;
    let batch = writeBatch(db);

    try {
      tasksToAdd.forEach((taskToAdd) => {
        const taskRef = doc(db, "users", userId, "tasks", taskToAdd.id);

        batch.set(
          taskRef,
          {
            ...taskToAdd,
          },
          { merge: true }
        );

        operationCounter += 1;

        if (operationCounter >= 500) {
          batch.commit();
          batch = writeBatch(db);
          operationCounter = 0;
        }
      });

      if (operationCounter > 0) {
        await batch.commit();
      }

      return { tasksToAdd };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkUpdateTasks = createAsyncThunk(
  "tasks/bulkUpdateTasks",
  async ({ newData, previousData }, { getState, rejectWithValue }) => {
    try {
      const batch = writeBatch(db);

      const userId = getState().app.uid;

      newData.forEach((updatedTask) => {
        const taskRef = doc(db, "users", userId, "tasks", updatedTask.id);
        batch.set(
          taskRef,
          {
            ...updatedTask,
          },
          { merge: true }
        );
      });

      await batch.commit();

      return { newData, previousData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkDeleteTasks = createAsyncThunk(
  "tasks/bulkDeleteTasks",
  async ({ tasksToDelete, previousData }, { getState, rejectWithValue }) => {
    try {
      const batch = writeBatch(db);

      const userId = getState().app.uid;

      tasksToDelete.forEach((taskToDelete) => {
        const taskRef = doc(db, "users", userId, "tasks", taskToDelete.id);
        batch.delete(taskRef);
      });

      await batch.commit();

      return { tasksToDelete, previousData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTask = createAsyncThunk(
  "tasks/createTask",
  async (
    newTask,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const {
        new_task_position = "top",
        inline_label_enabled = true,
        label_filters = [],
        default_estimated_time = 0,
      } = getState().app.currentUser;

      const currentUser = getState().app.currentUser;

      const userId = getState().app.uid;

      const labels = getState().labels.data;

      const selectedList =
        newTask.listId ||
        getState().app.currentUser.selectedList ||
        "brain_dump";

      // Let's check if we need to add a label
      if (newTask.description && inline_label_enabled) {
        const { label = null, newDescription = null } = handleInlineLabel(
          newTask.description,
          labels
        );

        if (newDescription) {
          newTask = {
            ...newTask,
            description: newDescription,
          };
        }

        if (label) {
          newTask = {
            ...newTask,
            label,
          };
        }
      }

      // Check if there is an estimated time
      if (!newTask.estimated_time) {
        newTask = {
          ...newTask,
          estimated_time: default_estimated_time,
        };
      }

      analytics("Task created", {
        task_id: newTask.id,
        added_to: !newTask.date ? "braindump" : "date",
        fields_updated: Object.keys(newTask),
        recurring: newTask.recurring,
      });

      // Get date in format YYYY-MM-DD
      const dateString = newTask.date
        ? moment(newTask.date).format("YYYY-MM-DD")
        : selectedList;

      let newOrder = _.cloneDeep(
        getState().tasks.order[dateString]?.order || []
      );

      if (new_task_position === "top") {
        newOrder.unshift(newTask.id);

        dispatch(
          updateTaskOrder({
            date: dateString,
            order: newOrder,
            previousOrder: getState().tasks.order[dateString]?.order || [],
          })
        );
      } else {
        dispatch(
          moveTaskToBottomOfIncomplete({
            taskId: newTask.id,
            date: dateString,
          })
        );
      }

      // If there are any label filters active, let's check if the label of the task is included,
      // If not, we need to warn the user
      if (label_filters && label_filters.length > 0) {
        if (!label_filters.includes(newTask.label)) {
          toast("Task created but hidden 👀", {
            description:
              "Task was hidden because it does not match any of your label filters.",
            duration: 10000,
            action: {
              label: "Clear",
              onClick: () => {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      label_filters: [],
                    },
                    previousValues: {
                      label_filters,
                    },
                  })
                );
              },
            },
          });
        }
      }

      await setDoc(
        doc(db, "users", userId, "tasks", newTask.id),
        {
          ...newTask,
          created_at: new Date(),
        },
        {
          merge: true,
        }
      );

      const shouldProcessCalendarEvents =
        shouldProcessCalendarEventsForUser(currentUser);

      return fulfillWithValue({
        newTask: newTask,
        userId: userId,
        shouldProcessCalendarEvents,
      });
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async ({ taskId, currentTask }, { dispatch, getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    const currentUser = getState().app.currentUser;

    try {
      // We need to check if this is a ghost task, if so, add an exclusion
      // If this is a recurring task, lets check if it has been branched off yet
      if (currentTask.recurring) {
        var recurringTask =
          getState().tasks.recurringTasks[currentTask.recurring_id];

        if (
          recurringTask &&
          (!recurringTask.branched_tasks ||
            !recurringTask.branched_tasks?.includes(taskId))
        ) {
          // No need to branch it off since it is going to be deleted
          // but let's add it to the exclusions

          let newExclusions = _.cloneDeep(recurringTask.exclusions || []);

          // Get date in format YYYY-MM-DD
          const dateString = moment(currentTask.date).format("YYYY-MM-DD");

          newExclusions.push(dateString);

          dispatch(
            updateRecurringTask({
              recurringTaskId: recurringTask.id,
              currentRecurringTask: recurringTask,
              newData: {
                exclusions: newExclusions,
              },
            })
          );

          return;
        }
      }

      analytics("Task deleted", {
        task_id: taskId,
      });

      await deleteDoc(doc(db, "users", userId, "tasks", taskId));

      const shouldProcessCalendarEvents =
        shouldProcessCalendarEventsForUser(currentUser);

      return {
        currentTask,
        userId,
        shouldProcessCalendarEvents,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const proceedDeletion = createAsyncThunk(
  "tasks/proceedDeletion",
  async (args, { dispatch, getState }) => {
    const tasksToBeDeleted = getState().tasks.tasksToBeDeleted;

    // If there are any tasks in the queue, process each one
    if (tasksToBeDeleted && tasksToBeDeleted.length) {
      tasksToBeDeleted.forEach((task) => {
        dispatch(deleteTask({ taskId: task.id, currentTask: task }));
      });
    }
  }
);

export const proceedListDeletion = createAsyncThunk(
  "tasks/proceedListDeletion",
  async ({ listId }, { dispatch, getState }) => {
    const listsToBeDeleted = getState().tasks.listsToBeDeleted;

    if (listsToBeDeleted && listsToBeDeleted.length) {
      listsToBeDeleted.forEach((list) => {
        dispatch(deleteList({ list }));
      });
    }
  }
);

export const duplicateTask = createAsyncThunk(
  "tasks/duplicateTask",
  async (
    { taskId, taskToDuplicate },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    const selectedList =
      taskToDuplicate.listId ||
      getState().app.currentUser.selectedList ||
      "brain_dump";

    analytics("Task duplicated", {
      task_id: taskId,
    });

    const clonedTask = _.cloneDeep(taskToDuplicate);
    // Let's change the id
    clonedTask.id = uuidv4();

    // If calendar_events exist, let's remove them
    if (clonedTask.calendar_events) {
      clonedTask.calendar_events = [];
    }

    try {
      // Get date in format YYYY-MM-DD
      const dateString = taskToDuplicate.date
        ? moment(taskToDuplicate.date).format("YYYY-MM-DD")
        : selectedList;

      let newOrder = _.cloneDeep(
        getState().tasks.order[dateString]?.order || []
      );

      // Add it right after the current task id
      const index = newOrder.indexOf(taskId);
      newOrder.splice(index + 1, 0, clonedTask.id);

      dispatch(
        updateTaskOrder({
          date: dateString,
          order: newOrder,
          previousOrder: getState().tasks.order[dateString]?.order || [],
        })
      );

      // Lets get the clonedTask and for any field that is undefined, we should remove it
      Object.keys(clonedTask).forEach((key) => {
        if (clonedTask[key] === undefined) {
          delete clonedTask[key];
        }
      });

      await setDoc(
        doc(db, "users", userId, "tasks", clonedTask.id),
        {
          ...clonedTask,
          created_at: new Date(),
        },
        {
          merge: true,
        }
      );

      if (taskToDuplicate.recurring) {
        var recurringTask =
          getState().tasks.recurringTasks[taskToDuplicate.recurring_id];

        if (recurringTask) {
          let newBranchedTasks = _.cloneDeep(
            recurringTask.branched_tasks || []
          );

          newBranchedTasks.push(clonedTask.id);

          dispatch(
            updateRecurringTask({
              recurringTaskId: recurringTask.id,
              currentRecurringTask: recurringTask,
              newData: {
                branched_tasks: newBranchedTasks,
              },
            })
          );
        }
      }

      return fulfillWithValue({
        newTask: clonedTask,
      });
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

// Move a task to the bottom of the date
export const moveTaskToBottom = createAsyncThunk(
  "tasks/moveTaskToBottom",
  async ({ taskId, date }, { dispatch, getState, rejectWithValue }) => {
    // Get the current order
    let currentOrder = _.cloneDeep(getState().tasks.order[date]?.order || []);

    // Remove the task from the current order
    currentOrder = currentOrder.filter((task) => task !== taskId);

    // Add the task to the bottom of the order
    currentOrder = [...currentOrder, taskId];

    // Update the task order
    dispatch(
      updateTaskOrder({
        date: date,
        order: currentOrder,
        previousOrder: getState().tasks.order[date]?.order || [],
      })
    );

    return;
  }
);

// Move a task to the bottom of the date
export const moveTaskToBottomOfIncomplete = createAsyncThunk(
  "tasks/moveTaskToBottomOfIncomplete",
  async ({ taskId, date }, { dispatch, getState, rejectWithValue }) => {
    // Get the current order
    let currentOrder = _.cloneDeep(getState().tasks.order[date]?.order || []);

    // Get all tasks
    const allTasks = getState().tasks.data;

    // Remove the task from the current order
    currentOrder = currentOrder.filter((task) => task !== taskId);

    // Iterate through the current order and find the first complete task index
    let firstCompleteTaskIndex = currentOrder.findIndex((task) => {
      return allTasks[task] && allTasks[task].complete;
    });

    // If there is no complete task, add the task to the bottom of the order
    if (firstCompleteTaskIndex === -1) {
      currentOrder = [...currentOrder, taskId];
    } else {
      // Otherwise, add the task before the first complete task
      currentOrder.splice(firstCompleteTaskIndex, 0, taskId);
    }

    // Update the task order
    dispatch(
      updateTaskOrder({
        date: date,
        order: currentOrder,
        previousOrder: getState().tasks.order[date]?.order || [],
      })
    );

    return;
  }
);

// Move a task from the brain dump to the bottom of the date
export const moveTaskToBottomFromList = createAsyncThunk(
  "tasks/moveTaskToBottomFromList",
  async (
    { taskId, listId = "brain_dump" },
    { dispatch, getState, rejectWithValue }
  ) => {
    // Get the current list

    var currentListOrder = _.cloneDeep(
      getState().tasks.order[listId]?.order || []
    );

    // Remove the task from the current braindump
    currentListOrder = currentListOrder.filter((task) => task !== taskId);

    // Get the current order for today
    let currentOrder = _.cloneDeep(
      getState().tasks.order[moment().format("YYYY-MM-DD")]?.order || []
    );

    // Add the task to the bottom of the order
    currentOrder = [...currentOrder, taskId];

    // Update the task order
    dispatch(
      updateTaskOrder({
        date: moment().format("YYYY-MM-DD"),
        order: currentOrder,
        previousOrder:
          getState().tasks.order[moment().format("YYYY-MM-DD")].order || [],
      })
    );

    dispatch(
      updateTaskOrder({
        date: listId,
        order: currentListOrder,
        previousOrder: getState().tasks.order[listId].order || [],
      })
    );
    return;
  }
);

export const processRecurringTasks = createAsyncThunk(
  "tasks/processRecurringTasks",
  async (
    { recurringTasks, dates, override },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      var recurringTasksToRegenerateGhosts = [];

      var recurringTasksToAdd = Object.values(recurringTasks)
        .filter((task) => task.task_template)
        .sort((a, b) => {
          try {
            // Float the recurring tasks that don't have a start date to the bottom
            var aStartDate = a.task_template.start?.toDate
              ? a.task_template.start.toDate()
              : a.task_template.start;
            var bStartDate = b.task_template.start?.toDate
              ? b.task_template.start.toDate()
              : b.task_template.start;

            // Convert both a and b to today's date while maintaining the time
            if (aStartDate) {
              aStartDate = moment(aStartDate)
                .set({
                  year: moment().year(),
                  month: moment().month(),
                  date: moment().date(),
                })
                .toDate();
            }

            if (bStartDate) {
              bStartDate = moment(bStartDate)
                .set({
                  year: moment().year(),
                  month: moment().month(),
                  date: moment().date(),
                })
                .toDate();
            }

            // Now compare
            if (aStartDate && bStartDate) {
              return aStartDate - bStartDate;
            }
          } catch (error) {
            console.error("Error sorting recurring tasks:", error);
            return 0; // Default sort return value for no difference.
          }
        });

      if (recurringTasksToAdd.length > 0) {
        recurringTasksToAdd.reverse();
      }

      recurringTasksToAdd.forEach((incomingRecurringTask) => {
        try {
          const existingRecurringTask =
            getState().tasks.recurringTasks[incomingRecurringTask.id];

          if (
            !existingRecurringTask ||
            !_.isEqual(
              incomingRecurringTask.task_template,
              existingRecurringTask.task_template
            ) ||
            !_.isEqual(
              incomingRecurringTask.rrule,
              existingRecurringTask.rrule
            ) ||
            override
          ) {
            recurringTasksToRegenerateGhosts.push(incomingRecurringTask);
          }
        } catch (error) {
          console.error("Error processing recurring task:", error);
        }
      });

      if (recurringTasksToRegenerateGhosts.length > 0) {
        recurringTasksToRegenerateGhosts.forEach((recurringTask) => {
          try {
            dispatch(
              reloadGhostTasksForDates({
                recurringTask,
                dates,
              })
            );
          } catch (error) {
            console.error("Error dispatching reloadGhostTasksForDates:", error);
          }
        });
      }

      // Remove any recurring tasks that are not in the list

      try {
        dispatch(
          addRecurringTasks({
            recurringTasks,
            dates,
          })
        );
      } catch (error) {
        console.error("Error dispatching addRecurringTasks:", error);
      }

      return;
    } catch (error) {
      console.error("Unexpected error in processRecurringTasks:", error);
      rejectWithValue(error.message);
    }
  }
);

// Move a task from the brain dump to the bottom of the date
export const processTaskOrders = createAsyncThunk(
  "tasks/processTaskOrders",
  async (
    { taskOrder, dates, override },
    { dispatch, getState, rejectWithValue }
  ) => {
    // Get current task order to process (clone it)
    var processedTaskOrder = _.cloneDeep({
      ...getState().tasks.order,
      ..._.keyBy(taskOrder, "id"),
    });

    const calendarDate = getState().tasks.calendarDate;

    const lists = getState().tasks.lists;
    const listIds = Object.keys(lists);

    var datesNotInFirestore = [];

    // 1. Remove any dates that are not in the taskOrder
    // Ignore the brain dump and calendare Date
    // Ignore any dates that are in the list array
    if (dates) {
      Object.keys(processedTaskOrder).forEach((date) => {
        if (
          !dates.includes(date) &&
          date !== "brain_dump" &&
          !listIds.includes(date) &&
          date !== calendarDate
        ) {
          delete processedTaskOrder[date];
        }
      });

      // Add dates that are in dates but not processedTaskOrder
      dates.forEach((date) => {
        if (!processedTaskOrder[date]) {
          processedTaskOrder[date] = {
            order: [],
            id: date,
            date: moment(date, "YYYY-MM-DD").toDate(),
          };

          datesNotInFirestore.push(date);
        }
      });
    }

    // 2. Let's filter out any duplicates
    var previouslySeenTasks = [];
    // We are going to filter out any duplicate tasks
    // First, get a list of orderToFilter keys and order them by date (descending)
    // We do this so that the duplicates are removed and we default to the most recent date

    var orderToFilterKeys = Object.keys(processedTaskOrder).sort(
      (a, b) =>
        moment(b, "YYYY-MM-DD").valueOf() - moment(a, "YYYY-MM-DD").valueOf()
    );

    orderToFilterKeys.forEach((date) => {
      if (processedTaskOrder[date] && processedTaskOrder[date].order) {
        processedTaskOrder[date].order = processedTaskOrder[date].order.filter(
          (task) => {
            if (previouslySeenTasks.includes(task)) {
              return false;
            }

            previouslySeenTasks.push(task);

            return true;
          }
        );
      }
    });

    // Get recurring tasks from the state
    const recurringTasks = getState().tasks.recurringTasks;

    // 3. Update the task order
    dispatch(
      addTaskOrder({
        taskOrders: processedTaskOrder,
        dates,
        taskOrdersToActuallyUpdate: [
          ...taskOrder.map((taskOrder) => taskOrder.id),
          ...datesNotInFirestore,
        ],
        recurringTasks,
      })
    );

    return;
  }
);

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    removeTaskFromOrder: (state, action) => {
      const { date, taskId } = action.payload;

      if (!state.order) {
        state.order = {};
      }

      if (!state.order[date]) {
        state.order[date] = {
          date: date,
        };
      }

      const currentOrder = _.cloneDeep(state.order[date]?.order || []);

      const newOrder = currentOrder.filter((task) => task !== taskId);

      state.order[date].order = newOrder;
    },
    addLists: (state, action) => {
      const { lists } = action.payload;

      // Go through the added lists and add them to the state
      // Remove the ones where deleted is true
      lists.forEach((list) => {
        if (list.deleted) {
          delete state.lists[list.id];
        } else {
          state.lists[list.id] = list;

          // If there is no order, add an empty order
          if (!state.order[list.id]) {
            state.order[list.id] = {
              order: [],
              id: list.id,
              date: null,
              list: true,
            };
          }
        }
      });
    },
    changeCalendarDate(state, action) {
      state.calendarDate = action.payload.date;
    },
    addTaskOrder: (state, action) => {
      // taskOrdersActuallyUpdated is an array of keys
      const { taskOrders, dates, taskOrdersToActuallyUpdate, recurringTasks } =
        action.payload;

      // 1. Get the current task order
      var taskOrdersEditable = _.cloneDeep(taskOrders);
      // These are the tasks we are going to add
      var tasksToAdd = [];

      // Clone tasks
      const tasks = _.cloneDeep(state.data);

      // 2. Go through each task order and remove any ghost tasks
      // But only do it if the task order is in taskOrdersActuallyUpdated
      if (dates) {
        Object.keys(taskOrdersEditable).forEach((date) => {
          if (!taskOrdersToActuallyUpdate.includes(date)) {
            return;
          }
          taskOrdersEditable[date].order = taskOrdersEditable[
            date
          ].order.filter((taskId) => {
            // TODO: REMOVE THIS TO ACCOUNT FOR CALENDAR DATES
            // If the task is a ghost task, remove it
            if (
              !isGhostTask({
                task: state.data[taskId],
                recurringTasks: state.recurringTasks,
              })
            ) {
              return true;
            } else {
              // Delete it from the tasks
              delete tasks[taskId];
              return false;
            }
          });
        });
      }

      // TODO: REMOVE THIS TO ACCOUNT FOR CALENDAR DATES
      if (dates) {
        // Go through recurring tasks and sort them based on the time of "start" of the task_template

        const {
          tasksToAdd: updatedTasksToAdd,
          taskOrdersEditable: updatedTaskOrdersEditable,
        } = generateTasksForSpecificDates(
          recurringTasks,
          dates,
          taskOrdersToActuallyUpdate,
          taskOrdersEditable
        );

        tasksToAdd.push(...updatedTasksToAdd);

        taskOrdersEditable = updatedTaskOrdersEditable;
      }

      // 4. Add the tasks to the state
      state.data = { ...tasks, ..._.keyBy(tasksToAdd, "id") };

      // 5. Update the task order
      state.order = taskOrdersEditable;
    },
    reloadGhostTasksForDates: (state, action) => {
      try {
        const { recurringTask, dates } = action.payload;

        // If no rrule, skip it
        if (!recurringTask.rrule) {
          return;
        }

        const rruleObject = rrulestr(recurringTask.rrule);
        var taskOrderEditable = _.cloneDeep(state.order);

        // These are the tasks we are going to add
        var tasksToAdd = [];

        // Go through the dates and let's remove the ghost tasks from the task order
        // That match the recurring task
        dates.forEach((date) => {
          if (taskOrderEditable[date]) {
            if (!taskOrderEditable[date].order) {
              taskOrderEditable[date].order = [];
            } else {
              var ordersToDelete = [];

              if (
                taskOrderEditable[date].order &&
                taskOrderEditable[date].order.length > 0
              ) {
                taskOrderEditable[date].order.forEach(function (taskId) {
                  const task = state.data[taskId];

                  if (task && task.recurring_id == recurringTask.id) {
                    if (
                      isGhostTask({
                        task: state.data[taskId],
                        recurringTasks: state.recurringTasks,
                      })
                    ) {
                      // Add taskId to the ordersToDelete array, without returning
                      ordersToDelete = [...ordersToDelete, taskId];
                    }
                  }
                });
              }

              ordersToDelete.forEach((taskId) => {
                // Delete the taskId from the task order, using splice
                taskOrderEditable[date].order.splice(
                  taskOrderEditable[date].order.indexOf(taskId),
                  1
                );
                // Also delete the task from the state

                delete state.data[taskId];
              });
            }
          }
        });

        // Lets print out the rruleObject to a readable string

        // Get the dstart of the recurring object
        const dstart = moment(recurringTask.dstart).toDate();

        // Change dstart to the utc timezone from the user's timezone
        // rruleObject.options.dtstart = dstartUTC
        const tzOffset = new Date().getTimezoneOffset();

        const fromRRuleOutput = (date) => {
          return addMinutes(date, tzOffset);
        };

        // Get all ocurrence dates between the date ranges
        // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
        const startDate = moment(dates[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate();

        // End date is the last day in dates
        const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate();

        // if interval has decimal, don't process
        if (rruleObject.options.interval % 1 !== 0) {
          return;
        }

        const ocurrenceDates = rruleObject
          .between(startDate, endDate)
          .map(fromRRuleOutput);

        // Assume that the ocurrenceDates are in UTC, convert them to the user's timezone
        const ocurrenceDatesFormatted2 = ocurrenceDates.map((date) => {
          var utcMomentDate = moment.utc(date);

          // Convert to the user's timezone
          var userTimezoneMomentDate = utcMomentDate.tz(moment.tz.guess());

          return userTimezoneMomentDate.format("YYYY-MM-DD");
        });

        // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
        const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
          return moment(date).format("YYYY-MM-DD");
        });

        ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
          // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
          if (recurringTask.exclusions?.includes(ocurrenceDate)) {
            return;
          }

          // Otherwise, create a new task
          const newTask = {
            ...generateTaskFromTemplateAndDate(
              recurringTask.task_template,
              moment(ocurrenceDate, "YYYY-MM-DD").toDate()
            ),
            id: uuidv4(),
            complete: false,
            recurring: true,
            recurring_id: recurringTask.id,
            created_at: new Date(),
          };

          // Add the new task to the tasksToAdd array
          tasksToAdd.push(newTask);

          if (!taskOrderEditable[ocurrenceDate]) {
            // Add the new task to the task order
            taskOrderEditable[ocurrenceDate] = {
              date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
              order: [newTask.id],
              id: ocurrenceDate,
            };
          } else {
            // Add the new task to the top of task order

            // WE NEED TO MAKE SURE THAT WHEN WE ADD THE TASK TO THE ORDER, IT IS IN THE CORRECT ORDER BASED ON START TIME
            taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
          }
        });

        state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };
        state.order = taskOrderEditable;
      } catch (error) {
        console.log("error reloading ghosts", error);
      }
    },
    addTaskToOrder: (state, action) => {
      const { date, taskId } = action.payload;

      if (!state.order) {
        state.order = {};
      }

      if (!state.order[date]) {
        state.order[date] = {
          date: date,
        };
      }

      const currentOrder = _.cloneDeep(state.order[date]?.order || []);

      const newOrder = [...currentOrder, taskId];

      state.order[date].order = newOrder;
    },
    addTasks: (state, action) => {
      const { tasks, dates, activeTimerTaskId } = action.payload;

      state.loading = false;
      if (activeTimerTaskId) {
        state.activeTimerTaskId = activeTimerTaskId;
      }

      const convertToDate = (field) => {
        if (field && field.seconds) {
          return new Date(field.seconds * 1000);
        } else if (typeof field === "string") {
          return new Date(field);
        }
        return field;
      };

      // Go through the tasks
      var convertedTasks = tasks.map((task) => {
        task.created_at = convertToDate(task.created_at);
        task.due_date = convertToDate(task.due_date);
        task.due_date_notification_time = convertToDate(
          task.due_date_notification_time
        );
        task.completed_at = convertToDate(task.completed_at);
        task.date = convertToDate(task.date);
        task.start = convertToDate(task.start);

        return task;
      });

      state.data = { ...state.data, ..._.keyBy(convertedTasks, "id") };

      // Some of the tasks have "deleted" set as true, we need to remove them
      Object.keys(state.data).forEach((taskId) => {
        const task = state.data[taskId];

        if (task && task.deleted) {
          delete state.data[taskId];
        }
      });

      // Remove any tasks that are not between the dates
      Object.keys(state.data).forEach((taskId) => {
        const task = state.data[taskId];

        if (
          dates &&
          dates.length > 0 &&
          task.date &&
          !dates?.includes(moment(task.date).format("YYYY-MM-DD")) &&
          moment(task.date).format("YYYY-MM-DD") !== state.calendarDate &&
          task.id !== state.activeTimerTaskId
        ) {
          delete state.data[taskId];
        }
      });
    },
    addRecurringTasks: (state, action) => {
      const { recurringTasks, dates } = action.payload;

      state.recurringTasks = {
        ...state.recurringTasks,
        ..._.keyBy(recurringTasks, "id"),
      };
    },
    removeTask: (state, action) => {
      const { taskId } = action.payload;

      // Delete the task from the data
      delete state.data[taskId];
    },
    removeRecurringTask: (state, action) => {
      const { recurringTask } = action.payload;

      // If it fulfilled, let's delete all the ghost tasks associated with it

      const recurringTaskId = recurringTask.id;

      // This is the taskOrder we are going to edit
      const taskOrderEditable = _.cloneDeep(state.order);

      // Go through each date and delete the ghost tasks
      Object.keys(taskOrderEditable).forEach((date) => {
        // Go through each task in the order
        taskOrderEditable[date].order.forEach((taskId, index) => {
          const task = state.data[taskId];

          // If the task is a ghost task, delete it from state
          if (task && task.recurring_id === recurringTaskId) {
            if (
              !recurringTask.branched_tasks ||
              !recurringTask.branched_tasks?.includes(task.id)
            ) {
              // Delete the task
              delete state.data[taskId];
              // Remove the task from the order
              taskOrderEditable[date].order.splice(index, 1);
            }
          }
        });
      });

      delete state.recurringTasks[recurringTask.id];
    },
    manuallySetOrderLoading: (state, action) => {
      const { loading, dates } = action.payload;

      const taskOrdersDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.taskOrders
      );

      const datesToLoad = dates.filter((date) => {
        return !taskOrdersDatesLoaded.includes(date);
      });

      state.dateRangesLoaded.taskOrders =
        taskOrdersDatesLoaded.concat(datesToLoad);

      state.orderLoading = loading;
    },
    removeDatesFromLoaded: (state, action) => {
      const { dates } = action.payload;

      const taskOrdersDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.taskOrders
      );

      const ghostOrdersDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.ghostTasks
      );

      if (taskOrdersDatesLoaded) {
        // Remove dates from the loaded dates
        state.dateRangesLoaded.taskOrders = taskOrdersDatesLoaded.filter(
          (date) => !dates.includes(date)
        );
      }

      if (ghostOrdersDatesLoaded) {
        state.dateRangesLoaded.ghostTasks = ghostOrdersDatesLoaded.filter(
          (date) => !dates.includes(date)
        );
      }
    },
    manuallySetTasksLoading: (state, action) => {
      const { loading } = action.payload;

      state.loading = loading;
    },
    addBraindumpOrder: (state, action) => {
      const { order } = action.payload;

      state.brainDumpOrder = false;
      state.order["brain_dump"] = {
        id: "brain_dump",
        order,
      };
    },
    addListsOrder: (state, action) => {
      const { orders } = action.payload;

      // Go through orders and add them to the state

      orders.forEach((order) => {
        if (order.deleted) {
          delete state.order[order.id];
        } else {
          var orderTemp = _.cloneDeep(order);

          // If order does not have an "order" array, let's create one
          if (!orderTemp.order) {
            orderTemp.order = [];
          }

          state.order[order.id] = orderTemp;
        }
      });
    },
    softDelete: (state, action) => {
      const taskToDelete = action.payload;

      // Append the task to the tasksToBeDeleted array
      state.tasksToBeDeleted.push(taskToDelete);

      // Delete the task from the main data
      delete state.data[taskToDelete.id];
    },
    cancelDeletion: (state, action) => {
      const taskToRestore = action.payload;

      // Restore the task to the main data
      state.data[taskToRestore.id] = taskToRestore;

      // Remove the task from the tasksToBeDeleted array
      state.tasksToBeDeleted = state.tasksToBeDeleted.filter(
        (task) => task.id !== taskToRestore.id
      );
    },
    softListDelete: (state, action) => {
      const { list } = action.payload;

      state.listsToBeDeleted.push(list);

      delete state.lists[list.id];
    },
    cancelListDeletion: (state, action) => {
      const listToRestore = action.payload;

      state.lists[listToRestore.id] = listToRestore;

      state.listsToBeDeleted = state.listsToBeDeleted.filter(
        (list) => list.id !== listToRestore.id
      );
    },
  },
  extraReducers: {
    [updateTask.pending]: (state, action) => {
      // Let's just update the store
      const { taskId, newData } = action.meta.arg;

      state.data[taskId] = { ...state.data[taskId], ...newData };
    },
    [updateTask.fulfilled]: (state, action) => {
      // Let's just update the store
      const { taskId, newTask, oldTask, userId, shouldProcessCalendarEvents } =
        action.payload;

      state.data[taskId] = newTask;

      if (shouldProcessCalendarEvents) {
        axios
          .post(
            `${v1TasksServerUrl}/processCalendarEventsForTask`,
            {
              userId: userId,
              newTask: newTask,
              oldTask: oldTask,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {},
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [updateTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { taskId, currentTask } = action.meta.arg;

      state.data[taskId] = currentTask;
    },
    [updateRecurringTask.pending]: (state, action) => {
      // Let's just update the store
      const { recurringTaskId, newData } = action.meta.arg;

      state.recurringTasks[recurringTaskId] = {
        ...state.recurringTasks[recurringTaskId],
        ...newData,
      };
    },
    [updateRecurringTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { recurringTaskId, currentRecurringTask } = action.meta.arg;

      state.recurringTasks[recurringTaskId] = currentRecurringTask;
    },
    [updateRecurringTask.fulfilled]: (state, action) => {
      const { recurringTaskId, currentRecurringTask, newData } =
        action.meta.arg;

      const { dates } = action.payload;

      var recurringTask = {
        ...state.recurringTasks[recurringTaskId],
        ...newData,
      };

      // If the new data includes a new rrule or task_template, then we need to update the ghosts
      if (newData.rrule || newData.task_template) {
        // Go through all the tasks and task orders and delete any ghosts
        // This is the taskOrder we are going to edit
        const taskOrderEditable = _.cloneDeep(state.order);

        Object.keys(taskOrderEditable).forEach((date) => {
          // Go through each task in the order
          taskOrderEditable[date].order.forEach((taskId, index) => {
            const task = state.data[taskId];

            // If the task is a ghost task, delete it from state
            if (task && task.recurring_id === recurringTaskId) {
              if (
                !recurringTask.branched_tasks ||
                !recurringTask.branched_tasks?.includes(task.id)
              ) {
                // Delete the task
                delete state.data[taskId];
                // Remove the task from the order
                taskOrderEditable[date].order.splice(index, 1);
              }
            }
          });
        });

        // Now we need to add the new ghosts

        var tasksToAdd = [];
        // From the recurringTask's rrule, get the RRule object
        const rruleObject = rrulestr(recurringTask.rrule);

        // Get all ocurrence dates between the date ranges
        // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
        const startDate = moment(dates[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate();

        // End date is the last day in dates
        const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate();
        const tzOffset = new Date().getTimezoneOffset();

        const fromRRuleOutput = (date) => {
          return addMinutes(date, tzOffset);
        };

        const ocurrenceDates = rruleObject
          .between(startDate, endDate)
          .map(fromRRuleOutput);

        // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
        const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
          return moment(date).format("YYYY-MM-DD");
        });

        ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
          // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
          if (recurringTask.exclusions?.includes(ocurrenceDate)) {
            return;
          }

          // Otherwise, create a new task
          const newTask = {
            ...generateTaskFromTemplateAndDate(
              recurringTask.task_template,
              moment(ocurrenceDate, "YYYY-MM-DD").toDate()
            ),
            id: uuidv4(),
            complete: false,
            recurring: true,
            recurring_id: recurringTask.id,
            created_at: new Date(),
          };

          // Add the new task to the tasksToAdd array
          tasksToAdd.push(newTask);

          if (!taskOrderEditable[ocurrenceDate]) {
            // Add the new task to the task order
            taskOrderEditable[ocurrenceDate] = {
              date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
              order: [newTask.id],
              id: ocurrenceDate,
            };
          } else {
            // Add the new task to the top of task order
            taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
          }
        });

        state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };
        state.order = taskOrderEditable;
      }
    },
    [bulkUpdateTasks.pending]: (state, action) => {
      // Let's just update the store
      const { newData } = action.meta.arg;

      newData.forEach((taskData) => {
        state.data[taskData.id] = { ...state.data[taskData.id], ...taskData };
      });
    },
    [bulkUpdateTasks.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousData } = action.meta.arg;

      previousData.forEach((task) => {
        state.data[task.id] = task;
      });
    },
    [bulkDeleteTasks.pending]: (state, action) => {
      // Let's just update the store
      const { tasksToDelete } = action.meta.arg;

      tasksToDelete.forEach((task) => {
        delete state.data[task.id];
      });
    },
    [bulkDeleteTasks.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousData } = action.meta.arg;

      previousData.forEach((task) => {
        state.data[task.id] = task;
      });
    },
    [updateTaskOrder.pending]: (state, action) => {
      // Let's just update the store
      const { date, order } = action.meta.arg;

      if (!state.order) {
        state.order = {};
      }

      if (!state.order[date]) {
        state.order[date] = {
          date: date,
        };
      }

      state.order[date].order = order;
    },
    [updateTaskOrder.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { date, previousOrder } = action.meta.arg;

      if (!previousOrder) {
        // There was no previous order, just set it to blank
        state.order[date] = {};
      } else {
        state.order[date].order = previousOrder;
      }
    },
    [bulkUpdateTaskOrder.pending]: (state, action) => {
      // Let's just update the store
      const { newOrder } = action.meta.arg;

      var listIds = Object.keys(state.lists || {});
      listIds.push("brain_dump");

      if (!state.order) {
        state.order = {};
      }

      newOrder.forEach((order) => {
        // Get date in format YYYY-MM-DD
        const dateString = listIds.includes(order.date)
          ? order.date
          : moment(order.date).format("YYYY-MM-DD");

        if (!state.order[dateString]) {
          state.order[dateString] = {};
        }

        state.order[dateString].order = order.order;
      });
    },
    [bulkUpdateTaskOrder.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousOrder } = action.meta.arg;

      var listIds = Object.keys(state.lists || {});
      listIds.push("brain_dump");

      if (!state.order) {
        state.order = {};
      }

      previousOrder.forEach((order) => {
        // Get date in format YYYY-MM-DD
        const dateString = listIds.includes(order.date)
          ? order.date
          : moment(order.date).format("YYYY-MM-DD");

        if (!state.order[dateString]) {
          state.order[dateString] = {};
        }

        state.order[dateString].order = order.order;
      });
    },
    [convertGhostTaskToTask.fulfilled]: (state, action) => {
      // Let's just update the store

      const { ghostTask } = action.meta.arg;

      state.data[ghostTask.id] = ghostTask;
    },
    [createTask.fulfilled]: (state, action) => {
      // Let's get the task from the payload
      const { newTask, userId, shouldProcessCalendarEvents } = action.payload;

      // Let's just update the store
      state.data[newTask.id] = newTask;

      if (shouldProcessCalendarEvents) {
        axios
          .post(
            `${v1TasksServerUrl}/processCalendarEventsForTask`,
            {
              userId: userId,
              newTask: newTask,
              oldTask: null,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {},
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [createTask.pending]: (state, action) => {
      // Let's just update the store

      const newTask = action.meta.arg;

      state.data[newTask.id] = newTask;
    },
    [createTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const newTask = action.meta.arg;

      delete state.data[newTask.id];
    },
    [createList.pending]: (state, action) => {
      // Let's just update the store

      const { list } = action.meta.arg;

      state.lists[list.id] = list;

      state.order[list.id] = {
        date: null,
        order: [],
        list: true,
        id: list.id,
      };
    },
    [createList.rejected]: (state, action) => {
      // Roll back the update if this failed

      const { list } = action.meta.arg;

      delete state.lists[list.id];
    },
    [updateList.pending]: (state, action) => {
      // Let's just update the store

      const { currentList, newData } = action.meta.arg;

      state.lists[currentList.id] = {
        ...currentList,
        ...newData,
      };
    },
    [updateList.rejected]: (state, action) => {
      // Roll back the update if this failed

      const { currentList } = action.meta.arg;

      state.lists[currentList.id] = currentList;
    },
    [deleteList.pending]: (state, action) => {
      // Let's just update the store

      const { list } = action.meta.arg;

      delete state.lists[list.id];
    },
    [deleteList.rejected]: (state, action) => {
      // Roll back the update if this failed

      const { list } = action.meta.arg;

      state.lists[list.id] = list;
    },
    [createRecurringTask.pending]: (state, action) => {
      // Let's just update the store

      const { recurringTask } = action.meta.arg;

      state.recurringTasks[recurringTask.id] = recurringTask;
    },
    [createRecurringTask.fulfilled]: (state, action) => {
      const { dates, recurringTask } = action.payload;

      // Now we need to add the new ghosts
      const taskOrderEditable = _.cloneDeep(state.order);

      // 2. Go through each task order and remove any ghost tasks
      Object.keys(taskOrderEditable).forEach((date) => {
        taskOrderEditable[date].order = taskOrderEditable[date].order.filter(
          (taskId) => {
            // If the task is a ghost task, remove it
            if (
              state.data[taskId] &&
              state.data[taskId].recurring_id === recurringTask.id &&
              isGhostTask({
                task: state.data[taskId],
                recurringTasks: state.recurringTasks,
              })
            ) {
              return false;
            } else {
              return true;
            }
          }
        );
      });

      var tasksToAdd = [];
      // From the recurringTask's rrule, get the RRule object
      const rruleObject = rrulestr(recurringTask.rrule);

      // Get all ocurrence dates between the date ranges
      // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
      const startDate = moment(dates[0], "YYYY-MM-DD").startOf("day").toDate();

      // End date is the last day in dates
      const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
        .endOf("day")
        .toDate();

      const tzOffset = new Date().getTimezoneOffset();

      const fromRRuleOutput = (date) => {
        return addMinutes(date, tzOffset);
      };

      const ocurrenceDates = rruleObject
        .between(startDate, endDate)
        .map(fromRRuleOutput);

      // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
      const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
        return moment(date).format("YYYY-MM-DD");
      });

      ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
        // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
        if (recurringTask.exclusions?.includes(ocurrenceDate)) {
          return;
        }

        // Otherwise, create a new task
        const newTask = {
          ...generateTaskFromTemplateAndDate(
            recurringTask.task_template,
            moment(ocurrenceDate, "YYYY-MM-DD").toDate()
          ),
          id: uuidv4(),
          complete: false,
          recurring: true,
          recurring_id: recurringTask.id,
          created_at: new Date(),
        };

        // Add the new task to the tasksToAdd array
        tasksToAdd.push(newTask);

        if (!taskOrderEditable[ocurrenceDate]) {
          // Add the new task to the task order
          taskOrderEditable[ocurrenceDate] = {
            date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
            order: [newTask.id],
            id: ocurrenceDate,
          };
        } else {
          // Add the new task to the top of task order
          taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
        }
      });

      state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };
      state.order = taskOrderEditable;
    },
    [createRecurringTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const recurringTask = action.meta.arg;

      delete state.recurringTasks[recurringTask.id];
    },
    [deleteTask.pending]: (state, action) => {
      const { taskId } = action.meta.arg;

      delete state.data[taskId];
    },
    [deleteTask.fulfilled]: (state, action) => {
      // Remove the deleted task from the tasksToBeDeleted array
      const { currentTask, userId, shouldProcessCalendarEvents } =
        action.payload;

      state.tasksToBeDeleted = state.tasksToBeDeleted.filter(
        (task) => task.id !== currentTask.id
      );

      if (shouldProcessCalendarEvents) {
        axios
          .post(
            `${v1TasksServerUrl}/processCalendarEventsForTask`,
            {
              userId: userId,
              newTask: null,
              oldTask: currentTask,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {},
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [deleteTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { taskId, currentTask } = action.meta.arg;

      state.data[taskId] = currentTask;
    },
    [duplicateTask.fulfilled]: (state, action) => {
      const { newTask } = action.payload;

      state.data[newTask.id] = newTask;
    },
  },
});

export const {
  removeTaskFromOrder,
  addTaskToOrder,
  addTasks,
  addRecurringTasks,
  removeTask,
  addTaskOrder,
  addBraindumpOrder,
  addListsOrder,
  manuallySetOrderLoading,
  manuallySetTasksLoading,
  removeDatesFromLoaded,
  reloadGhostTasksForDates,
  removeRecurringTask,
  changeCalendarDate,
  addLists,
  softDelete,
  cancelDeletion,
  softListDelete,
  cancelListDeletion,
} = tasksSlice.actions;

export default tasksSlice.reducer;

import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { ChevronRightIcon, ArrowSmLeftIcon } from "@heroicons/react/24/outline";

import AddTaskCard from "../Kanban/Column/AddTaskCard";

import Card from "../Kanban/Card";

import {
  updateCurrentUser,
  setColumnSelected,
  updatePowerFtux,
} from "../../redux/appSlice";

import { Resizable } from "re-resizable";
import { useSpring, animated as a } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import logo from "../../images/ellie-solid.svg";
import logoLight from "../../images/logo-light.svg";

import { analytics, isElectron } from "../../utils";
import { TbList, TbTargetArrow } from "react-icons/tb";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";

import { getOSPlatform } from "@todesktop/client-core/platform/os";

import SidebarContent from "./SidebarContent";
import { Tooltip } from "antd";

function Sidebar({
  onDragEnd,
  saveOrder,
  mobile = false,
  taskOrder = {},
  sortedLists = [],
  activelyReorderingSidebar = null,
  noContainer = false,
}) {
  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
      }
  );

  const columnSelected = useSelector((state) => state.app.columnSelected);

  const tasks = useSelector((state) => state.tasks.data);

  const lists = useSelector((state) => state.tasks.lists || {});

  const selectedList = useSelector(
    (state) => state.app.currentUser?.selectedList || "brain_dump"
  );

  const due_date_topbar = useSelector(
    (state) => state.app.power_ftux?.due_date_topbar || false
  );

  const {
    hide_sidebar,
    sidebar_width = 290,
    user_theme = "system",
    sidebar_layout = "single_list",
    sidebar_mode = "lists",
  } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  const [createActive, setCreateActive] = useState(null);

  useHotkeys(
    "b",
    (e) => {
      e.preventDefault();

      // Set create active to today's date
      setCreateActive(true);

      // ... set up our own saving dialog.
    },
    {
      enabled: sidebar_layout === "single_list" && !createActive,
    },
    [createActive, columnSelected]
  );

  const [sidebarVisible, setSidebarVisible] = useState(true);

  useEffect(() => {
    if (!hide_sidebar) {
      setSidebarVisible(true);
    }
  }, [hide_sidebar]);

  const sidebarSpringProps = useSpring({
    width: mobile ? "100%" : hide_sidebar ? 0 : sidebar_width || 290,
    overflow: mobile ? "visible" : hide_sidebar ? "hidden" : "visible",
    onRest: () => {
      // Set display to none when the sidebar is hidden
      if (hide_sidebar) {
        setSidebarVisible(false);
      }
    },
  });

  const [indexSelected, setIndexSelected] = useState(null);

  useEffect(() => {
    if (createActive) {
      setIndexSelected(null);
    }
  }, [indexSelected, createActive, columnSelected]);

  useHotkeys(
    "shift+b",
    (e) => {
      e.preventDefault();
      setCreateActive(false);
      dispatch(setColumnSelected("brain_dump"));

      if (sidebar_layout === "all") {
        // select the brain dump list and set index to 0
        setIndexSelected(0);
      } else {
        // // Set create active to today's date
        setIndexSelected(0);
        dispatch(setColumnSelected("brain_dump"));
      }

      // ... set up our own saving dialog.
    },
    [columnSelected, indexSelected]
  );

  const { label_filters: activeLabels, hide_complete } = useSelector(
    (state) => state.app?.currentUser
  );

  // Function that checks if a task should be visible
  function shouldShowTask(task) {
    // If the task is complete and hide_complete is true, don't show it
    if (task.complete && hide_complete) {
      return false;
    }

    // If there are no active labels, show the task
    if (!activeLabels || activeLabels.length === 0) {
      return true;
    }

    // If there are active labels, check if task.label exists and is in activeLabels
    if (task.label) {
      return activeLabels.includes(task.label);
    } else {
      return false;
    }
  }

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();

      if (sidebar_layout === "all") {
        var filteredOrder = taskOrder?.[columnSelected]?.order?.filter(
          (taskId) => {
            if (tasks[taskId] && shouldShowTask(tasks[taskId])) {
              return true;
            }
            return false;
          }
        );

        if (indexSelected === null) {
          setIndexSelected(0);
        } else if (indexSelected < filteredOrder?.length) {
          if (indexSelected === filteredOrder?.length - 1) {
            var filteredSortedLists = sortedLists.filter((listId) => {
              if (lists[listId]) {
                return true;
              }
              return false;
            });

            if (filteredSortedLists.length > 0) {
              var nextList =
                filteredSortedLists[
                  filteredSortedLists.indexOf(columnSelected) + 1
                ];

              if (nextList) {
                dispatch(setColumnSelected(nextList));
                setIndexSelected(0);
              } else {
                return;
              }
            }
          } else {
            setNavigatedViaKeyboard(true);
            setIndexSelected(indexSelected + 1);
          }
        }
      } else {
        var filteredOrder = taskOrder?.[selectedList]?.order?.filter(
          (taskId) => {
            if (tasks[taskId] && shouldShowTask(tasks[taskId])) {
              return true;
            }
            return false;
          }
        );

        if (indexSelected === null) {
          setIndexSelected(0);
        } else if (indexSelected < filteredOrder.length - 1) {
          setNavigatedViaKeyboard(true);
          setIndexSelected(indexSelected + 1);
        }
      }
      // ... set up our own saving dialog.P
    },
    {
      enabled:
        ["brain_dump", ...sortedLists].includes(columnSelected) &&
        !createActive,
    },
    [indexSelected, columnSelected, tasks, taskOrder]
  );

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();

      if (indexSelected === null) {
        return;
      }

      if (sidebar_layout === "all") {
        if (indexSelected > 0) {
          setNavigatedViaKeyboard(true);
          setIndexSelected(indexSelected - 1);
        } else {
          if (indexSelected === 0) {
            // We are at the end of the list, so we want to select the next list

            var filteredSortedLists = sortedLists.filter((listId) => {
              if (lists[listId]) {
                return true;
              }
              return false;
            });

            if (filteredSortedLists.length > 0) {
              var nextList =
                filteredSortedLists[
                  filteredSortedLists.indexOf(columnSelected) - 1
                ] || "brain_dump";

              if (columnSelected === "brain_dump") {
                return;
              }

              var filteredOrder = taskOrder?.[nextList]?.order?.filter(
                (taskId) => {
                  if (tasks[taskId] && shouldShowTask(tasks[taskId])) {
                    return true;
                  }
                  return false;
                }
              );

              if (nextList) {
                dispatch(setColumnSelected(nextList));
                setIndexSelected(filteredOrder?.length - 1 || 0);
              }
            }
          }
        }
      } else {
        if (indexSelected > 0) {
          setNavigatedViaKeyboard(true);
          setIndexSelected(indexSelected - 1);

          var filteredOrder = taskOrder?.[selectedList]?.order?.filter(
            (taskId) => {
              if (tasks[taskId]) {
                return true;
              }
              return false;
            }
          );
        }
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        ["brain_dump", ...sortedLists].includes(columnSelected) &&
        !createActive,
    },
    [indexSelected, columnSelected, taskOrder]
  );

  function manuallySelectColumn(newDate, index) {
    dispatch(setColumnSelected(newDate));
    setIndexSelected(index);
  }

  useEffect(() => {
    if (!columnSelected && indexSelected !== null) {
      setIndexSelected(null);
    }
  }, [columnSelected]);

  function isWindows() {
    if (isDesktopApp() && getOSPlatform() === "win32") return true;

    return false;
  }

  function getThemeFromUser(user_theme) {
    if (user_theme == "system") {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    } else {
      return user_theme;
    }
  }

  if (noContainer) {
    return (
      <SidebarContent
        taskOrder={taskOrder}
        createActive={createActive}
        setCreateActive={setCreateActive}
        navigatedViaKeyboard={navigatedViaKeyboard}
        setNavigatedViaKeyboard={setNavigatedViaKeyboard}
        indexSelected={indexSelected}
        setIndexSelected={setIndexSelected}
        manuallySelectColumn={manuallySelectColumn}
        columnSelected={columnSelected}
        activelyReorderingSidebar={activelyReorderingSidebar}
        sortedLists={sortedLists}
      />
    );
  }

  return (
    <a.div
      className="sidebar"
      style={sidebarSpringProps}
      onMouseMove={() => {
        if (navigatedViaKeyboard) {
          setNavigatedViaKeyboard(false);
        }
      }}
    >
      {sidebarVisible && (
        <Resizable
          size={{
            width: mobile ? "100%" : sidebar_width || 290,
            height: "100vh",
          }}
          minHeight={"100vh"}
          className="sidebar-container"
          maxWidth={mobile ? "1000px" : "400px"}
          minWidth={"290px"}
          enable={{
            top: false,
            right: mobile ? false : true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          onResizeStop={(e, direction, ref, d) => {
            const newWidth = (sidebar_width || 290) + d.width;

            dispatch(
              updateCurrentUser({
                newValues: {
                  sidebar_width: newWidth,
                },
                previousValues: {
                  sidebar_width: sidebar_width || 290,
                },
              })
            );
          }}
        >
          {!mobile && (
            <div
              className={`braindump-topbar ${
                (isElectron() || isDesktopApp()) && !isWindows() && hide_sidebar
                  ? "disabled"
                  : ""
              } flex flex-row justify-between items-center ${
                (isElectron() || isDesktopApp()) &&
                !isWindows() &&
                "!justify-end"
              }`}
            >
              <img
                src={getThemeFromUser(user_theme) == "dark" ? logoLight : logo}
                alt="logo"
                className={`sidebar-logo ${
                  (isElectron() || isDesktopApp()) && !isWindows()
                    ? "electron"
                    : ""
                }`}
              />

              {due_date_settings.enabled && (
                <Tooltip
                  placement="bottom"
                  title="✨ Due date section now available"
                  open={due_date_topbar}
                  onOpenChange={(open) => {
                    dispatch(
                      updatePowerFtux({
                        due_date_topbar: open,
                      })
                    );
                  }}
                  trigger={[]}
                  zIndex={900}
                >
                  <div
                    onClick={() => {
                      dispatch(
                        updatePowerFtux({
                          due_date_topbar: false,
                        })
                      );

                      analytics("Sidebar mode toggled", {
                        source: "web",
                        mode: sidebar_mode == "lists" ? "due_dates" : "lists",
                      });

                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            sidebar_mode:
                              sidebar_mode == "lists" ? "due_dates" : "lists",
                          },
                          previousValues: {
                            sidebar_mode: sidebar_mode,
                          },
                        })
                      );
                    }}
                    className="topbar-toggle"
                  >
                    <div
                      className={`${
                        sidebar_mode == "lists" ? "option active" : "option"
                      }`}
                    >
                      <TbList className="topbar-button-icon w-4 h-5" />
                    </div>

                    <div
                      className={
                        sidebar_mode == "due_dates" ? "option active" : "option"
                      }
                    >
                      <TbTargetArrow className="topbar-button-icon h-5 w-4" />
                    </div>
                  </div>
                </Tooltip>
              )}
            </div>
          )}

          <SidebarContent
            taskOrder={taskOrder}
            createActive={createActive}
            setCreateActive={setCreateActive}
            navigatedViaKeyboard={navigatedViaKeyboard}
            setNavigatedViaKeyboard={setNavigatedViaKeyboard}
            indexSelected={indexSelected}
            setIndexSelected={setIndexSelected}
            manuallySelectColumn={manuallySelectColumn}
            columnSelected={columnSelected}
            activelyReorderingSidebar={activelyReorderingSidebar}
            sortedLists={sortedLists}
          />
        </Resizable>
      )}
    </a.div>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Add conditions for all props that should trigger a re-render when changed
  return (
    prev.taskOrder === next.taskOrder &&
    prev.mobile === next.mobile &&
    prev.sortedLists === next.sortedLists &&
    prev.activelyReorderingSidebar === next.activelyReorderingSidebar
  );
}

export default React.memo(Sidebar, areEqual);

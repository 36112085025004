import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SparklesIcon } from "@heroicons/react/24/solid";

import { setUpgradeVisible } from "../../redux/appSlice";

export default function UpgradeBanner({ featureName = "This", style, plural = false }) {
  const dispatch = useDispatch();

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  if (!subscriptionActive) {
    return (
      <div style={style} className="pro-feature-container">
        <div className="pro-feature-text">
          <div className="pro-feature-h1">Ellie Pro</div>
          <div className="pro-feautre-h2">{featureName} {plural ? "are" : "is"} a Pro Feature</div>
        </div>
        <div
          onClick={() => {
            dispatch(setUpgradeVisible(true));
          }}
          className="pro-upgrade-button"
        >
          <SparklesIcon className="pro-upgrade-button-icon" />
          Upgrade
        </div>
      </div>
    );
  }

  return null;
}

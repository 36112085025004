import { Popover, Modal, Select } from "antd";
import { BsCheck } from "react-icons/bs";
import {
  IoNotificationsOffSharp,
  IoNotificationsOutline,
  IoNotificationsSharp,
} from "react-icons/io5";
import dayjs from "dayjs";
import CustomTimePicker from "../Sidebar/DatePicker/CustomTimePicker";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function TaskNotificationSettingDropdown({
  isVisible,
  setIsVisible,
  due_date,
  due_date_notification_meta,
  due_date_notification_time,
  updateTaskWithNotificationSettings,
}) {
  // Useeffect to update the due date notification time
  useEffect(() => {
    console.log(due_date_notification_time);
    setDueDateNotificationTime(due_date_notification_time);
  }, [due_date_notification_time]);

  useEffect(() => {
    setSelectedNotificationMeta(due_date_notification_meta);
  }, [due_date_notification_meta]);

  const [selectedNotificationMeta, setSelectedNotificationMeta] = useState(
    due_date_notification_meta
  );

  const [dueDateNotificationTime, setDueDateNotificationTime] = useState(
    due_date_notification_time
  );

  const { time_format = "12_hour" } = useSelector(
    (state) => state.app.currentUser
  );

  const notifications_enabled = useSelector(
    (state) => state.app.currentUser?.power_feature_settings?.due_dates?.notifications_enabled
    );

  const notificationMetas = {
    none: {
      stringValue: "none",
      title: "No notification",
    },
    same_time: {
      stringValue: "same_time",
      notification_before_mins: 0,
      notification_before_days: 0,
      title: "At the due date",
    },
    "5_min": {
      stringValue: "5_min",
      notification_before_mins: 5,
      notification_before_days: 0,
      title: "5 mins before",
    },
    "10_min": {
      stringValue: "10_min",
      notification_before_mins: 10,
      notification_before_days: 0,
      title: "10 mins before",
    },
    "0_day": {
      stringValue: "0_day",
      notification_before_days: 0,
      notification_before_mins: 0,
      title: "The day of",
    },
    "1_day": {
      stringValue: "1_day",
      notification_before_days: 1,
      notification_before_mins: 0,
      title: "The day before",
    },
    "2_day": {
      stringValue: "2_day",
      notification_before_days: 2,
      notification_before_mins: 0,
      title: "2 days before",
    },
    "1_week": {
      stringValue: "1_week",
      notification_before_days: 7,
      notification_before_mins: 0,
      title: "A week before",
    },
  };

  function shouldShowTimeSelection() {
    return (
      dueDateNotificationTime &&
      selectedNotificationMeta &&
      selectedNotificationMeta.stringValue !== "same_time" &&
      (selectedNotificationMeta.notification_before_days > 0 ||
        selectedNotificationMeta.stringValue === "0_day")
    );
  }

  // Notification text
  function getNotificationText() {
    if (!selectedNotificationMeta) {
      return null;
    }

    const time = dueDateNotificationTime
      ? dayjs(
          dueDateNotificationTime.toDate
            ? dueDateNotificationTime.toDate()
            : dueDateNotificationTime
        ).format("h:mm A")
      : null;

    if (selectedNotificationMeta.stringValue === "none") {
      return null;
    }

    if (selectedNotificationMeta.stringValue === "same_time") {
      return `At the due date ${time}`;
    }

    if (selectedNotificationMeta.stringValue === "5_min") {
      return "5 mins before";
    }

    if (selectedNotificationMeta.stringValue === "10_min") {
      return "10 mins before";
    }

    if (selectedNotificationMeta.stringValue === "0_day") {
      return `The day of at ${time}`;
    }

    if (selectedNotificationMeta.stringValue === "1_day") {
      return `The day before at ${time}`;
    }

    if (selectedNotificationMeta.stringValue === "2_day") {
      return `2 days before at ${time}`;
    }

    if (selectedNotificationMeta.stringValue === "1_week") {
      return `A week before at ${time}`;
    }

    return null;
  }

  if(!notifications_enabled) {
    return null;
  }

  if (
    !due_date
  ) {
    return null;
  }

  return (
    <Popover
      open={isVisible}
      onOpenChange={setIsVisible}
      content={
        <div className="ellie-menu" data-no-dnd="true">
          <div className="ellie-menu-header">
            <div className="ellie-menu-header-text">
              Send reminder notification
            </div>
          </div>

          {Object.values(notificationMetas).map((option) => {
            return (
              <div
                onClick={() => {
                  setSelectedNotificationMeta(option);

                  var updatedValue = {
                    due_date_notification_meta: option,
                  };

                  if (option.stringValue === "none") {
                    setDueDateNotificationTime(null);
                    updatedValue.due_date_notification_time = null;
                    updatedValue.due_date_notification_sent = null;
                    updatedValue.due_date_notification_meta = null;
                  } else if (option.stringValue === "same_time") {
                    setDueDateNotificationTime(due_date);
                    updatedValue.due_date_notification_time = due_date;
                  } else if (
                    option.stringValue === "5_min" ||
                    option.stringValue === "10_min"
                  ) {
                    setDueDateNotificationTime(
                      dayjs(due_date).subtract(
                        option.notification_before_mins,
                        "minutes"
                      )
                    );

                    updatedValue.due_date_notification_time = dayjs(
                      due_date
                    ).subtract(option.notification_before_mins, "minutes");
                  } else {
                    setDueDateNotificationTime(
                      dayjs(due_date)
                        .subtract(option.notification_before_days, "days")
                        .hour(9)
                        .minute(0)
                    );

                    updatedValue.due_date_notification_time = dayjs(due_date)
                      .subtract(option.notification_before_days, "days")
                      .hour(9)
                      .minute(0);
                  }

                  if (
                    dayjs().isBefore(due_date) &&
                    option.stringValue !== "none"
                  ) {
                    updatedValue.due_date_notification_sent = false;
                  } else {
                    updatedValue.due_date_notification_sent = null;
                  }

                  // If pdatedValue.due_date_notification_time is a dayjs object, convert it to a date
                  if (dayjs.isDayjs(updatedValue.due_date_notification_time)) {
                    updatedValue.due_date_notification_time =
                      updatedValue.due_date_notification_time.toDate();
                  }

                  updateTaskWithNotificationSettings(updatedValue);
                }}
                key={option.stringValue}
                className="ellie-menu-item"
              >
                <div className="check-container">
                  {option.stringValue ===
                    selectedNotificationMeta?.stringValue && (
                    <BsCheck className="ellie-menu-item-check" />
                  )}

                  {!selectedNotificationMeta &&
                    option.stringValue === "none" && (
                      <BsCheck className="ellie-menu-item-check" />
                    )}
                </div>
                <div className="elie-menu-item-text">{option.title}</div>
              </div>
            );
          })}
          {shouldShowTimeSelection() && (
            <div className="specific-time-section">
              <div className="enable">
                <div>And send at</div>
              </div>
              <CustomTimePicker
                time_format={time_format}
                value={dayjs(
                  dueDateNotificationTime.toDate
                    ? dueDateNotificationTime.toDate()
                    : dueDateNotificationTime
                ).format(time_format === "12_hour" ? "hh:mm A" : "HH:mm")}
                onChange={(timeString) => {
                  const timeParts = timeString.split(/[: ]/);
                  let hours = parseInt(timeParts[0], 10);
                  const minutes = parseInt(timeParts[1], 10);
                  const ampm = timeParts[2];

                  // Convert 12-hour format to 24-hour format
                  if (ampm === "PM" && hours < 12) hours += 12;
                  if (ampm === "AM" && hours === 12) hours = 0;

                  // Get the current date or task's start date
                  var date = dueDateNotificationTime
                    ? new Date(
                        dueDateNotificationTime.toDate
                          ? dueDateNotificationTime.toDate()
                          : dueDateNotificationTime
                      )
                    : new Date();

                  // Set the selected time to the date
                  date.setHours(hours);
                  date.setMinutes(minutes);
                  date.setSeconds(0);

                  setDueDateNotificationTime(date);

                  updateTaskWithNotificationSettings({
                    due_date_notification_time: date,
                    due_date_notification_sent: false,
                  });
                }}
              />
            </div>
          )}
        </div>
      }
      placement="bottom"
      trigger="click"
    >
      <div>
        <div
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          <div className="text-neutral-400 text-xs flex flex-row items-center gap-1 cursor-pointer hover:text-neutral-800 dark:hover:text-neutral-200">
            {!getNotificationText() ? (
              <IoNotificationsOffSharp />
            ) : (
              <IoNotificationsSharp />
            )}
            {getNotificationText()}
          </div>
        </div>
      </div>
    </Popover>
  );
}

import { InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { rrulestr, RRule } from "rrule";
import MonthSegmentedControl from "./MonthSegmentedControl";
import { addMinutes } from "date-fns";
import moment from "moment";
import _ from "lodash";

export default function CustomRecurrenceEditor({
  rrule,
  onUpdate,
  onDonePressed,
  onClose,
  taskDate,
}) {
  const [interval, setInterval] = useState(1);
  const [frequency, setFrequency] = useState("DAILY");
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);

  const [rruleCopy, setRruleCopy] = useState(rrule ? rrule.toString() : null);

  function updateRRuleString(currentRRuleString, options) {
    // Parse the existing rRule string to an RRule object
    var rruleObj = rrulestr(currentRRuleString);

    // Create a copy of the options
    var optionsCopy = {};

    // Go through and add all the options to the options copy
    Object.keys(rruleObj.options).forEach((key) => {
      optionsCopy[key] = rruleObj.options[key];
    });

    // Update the options copy with the new options
    Object.entries(options).forEach(([key, value]) => {
      // If this is a valid option key
      if (Object.keys(optionsCopy).includes(key.toLowerCase())) {
        optionsCopy[key.toLowerCase()] = value;

        // IF the valuei is bymonthday, also update the bynmonthday
        if (key.toLowerCase() === "bymonthday") {
          optionsCopy.bynmonthday = value;
        }
      }
    });

    // Let's remove the dtstart, byminute, byhour, bysecond options if they exist
    delete optionsCopy.dtstart;
    delete optionsCopy.byminute;
    delete optionsCopy.byhour;
    delete optionsCopy.bysecond;

    const taskDateUTC = moment(taskDate).utc().toDate();
    // Let's add the dtstart option
    optionsCopy.dtstart = taskDateUTC;

    // Remove any instance of BYNMONTHDAY from the options and swap it with BYMONTHDAY
    if (optionsCopy.bynmonthday && optionsCopy.bynmonthday.length > 0) {
      optionsCopy.bymonthday = optionsCopy.bynmonthday;
      delete optionsCopy.bynmonthday;
    }

    // Create new RRule object with the updated options
    const newRRule = new RRule(optionsCopy);
    const updatedRRuleString = newRRule.toString();

    return updatedRRuleString;
  }

  const handleChange = (options) => {
    const updatedRRuleString = updateRRuleString(rruleCopy, options);

    setRruleCopy(updatedRRuleString);
  };

  useEffect(() => {
    if (rrule) {
      setInterval(rrule.options.interval);
      setFrequency(RRule.FREQUENCIES[rrule.options.freq]);

      if (rrule.options.byweekday) {
        const weekdayLookup = {
          0: RRule.MO,
          1: RRule.TU,
          2: RRule.WE,
          3: RRule.TH,
          4: RRule.FR,
          5: RRule.SA,
          6: RRule.SU,
        };

        const weekdays = rrule.options.byweekday.map((wd) => {
          return weekdayLookup[wd];
        });

        setSelectedWeekdays(weekdays);
      } else {
        setSelectedWeekdays([]);
      }

      // LEt's setGeneratedRule from the rrule
      const rule = rrule.toString();
      setRruleCopy(rule);
    } else {
      setInterval(1);
      setFrequency("DAILY");
      setSelectedWeekdays([]);
    }
  }, [rrule]);

  const weekdays = [
    { label: "Su", value: RRule.SU },
    { label: "Mo", value: RRule.MO },
    { label: "Tu", value: RRule.TU },
    { label: "We", value: RRule.WE },
    { label: "Th", value: RRule.TH },
    { label: "Fr", value: RRule.FR },
    { label: "Sa", value: RRule.SA },
  ];

  const toggleWeekday = (weekday) => {
    setSelectedWeekdays((prevSelectedWeekdays) =>
      prevSelectedWeekdays.includes(weekday)
        ? prevSelectedWeekdays.filter((wd) => wd !== weekday)
        : [...prevSelectedWeekdays, weekday]
    );
  };

  useEffect(() => {
    // If selected weekdays changes, let's update the rrule
    if (selectedWeekdays && selectedWeekdays.length > 0) {
      handleChange({ byweekday: selectedWeekdays });
    }
  }, [selectedWeekdays]);

  const generateRule = () => {
    // Let's generate rule from generatedRule string

    if (onUpdate) {
      onUpdate(rruleCopy);
      onDonePressed(rruleCopy);
    }
  };

  const handleMonthOptionChange = (selectedOption) => {
    handleChange(selectedOption.value);
  };

  return (
    <div className="p-8 flex flex-col gap-4">
      <div className="text-xl font-medium">Repeat</div>
      <div className="flex flex-row gap-3 items-center">
        <div>Every</div>
        <InputNumber
          value={interval}
          min={1}
          max={100000}
          defaultValue={3}
          precision={0}
          onChange={(e) => {
            setInterval(e);
            handleChange({ interval: e });
          }}
        />
        <Select
          defaultValue="DAILY"
          style={{ width: 120 }}
          onChange={(e) => {
            setFrequency(e);

            // If frequency is changed, we want to wipe our certain options
            if (e === "WEEKLY") {
              handleChange({
                freq: RRule.FREQUENCIES.indexOf(e),
                byweekday: [],
                bymonthday: [],
                bymonth: [],
                bysetpos: [],
              });
            } else if (e === "MONTHLY") {
              handleChange({
                freq: RRule.FREQUENCIES.indexOf(e),
                byweekday: [],
                bymonthday: [],
                bysetpos: [],
                bymonth: [],
              });
            } else if (e === "YEARLY") {
              handleChange({
                freq: RRule.FREQUENCIES.indexOf(e),
                byweekday: [],
                bymonthday: [],
                bysetpos: [],
                bymonth: [],
              });
            } else if (e === "DAILY") {
              handleChange({
                freq: RRule.FREQUENCIES.indexOf(e),
                byweekday: [],
                bymonthday: [],
                bysetpos: [],
                bymonth: [],
              });
            } else {
              handleChange({ freq: RRule.FREQUENCIES.indexOf(e) });
            }
          }}
          value={frequency}
          options={[
            { label: "Day(s)", value: "DAILY" },
            { label: "Week(s)", value: "WEEKLY" },
            { label: "Month(s)", value: "MONTHLY" },
            { label: "Year(s)", value: "YEARLY" },
          ]}
        />
      </div>
      {frequency === "WEEKLY" && (
        <div className="flex flex-row gap-3 items-center justify-start">
          on
          {weekdays.map(({ label, value }) => (
            <div
              className={`cursor-pointer w-8 h-8 rounded-full flex items-center justify-center text-sm border-2 ${
                selectedWeekdays.includes(value)
                  ? "bg-purple-600 border-purple-600 text-white"
                  : "bg-gray-100 dark:border-slate-400 dark:bg-slate-800"
              }`}
              onClick={() => {
                toggleWeekday(value);
              }}
            >
              {label}
            </div>
          ))}
        </div>
      )}
      {frequency === "MONTHLY" && (
        <div className="flex flex-row gap-3 items-center justify-start">
          on
          <MonthSegmentedControl
            date={taskDate}
            onUpdate={handleMonthOptionChange}
            rrule={rruleCopy}
          />
        </div>
      )}

      <div className="flex flex-row justify-end items-center gap-3">
        <button
          onClick={(event) => {
            // Stop propagation so that we don't close the modal
            event.stopPropagation();

            onClose();
          }}
          className="button"
        >
          Cancel
        </button>
        <div className="right-side">
          <button
            onClick={(event) => {
              // Stop propagation so that we don't close the modal
              event.stopPropagation();

              generateRule();
            }}
            className="button primary"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";
import moment from "moment";

const LabelIcon = ({ color }) => (
  <span
    className="mr-2 inline-block w-2 h-2 rounded-full"
    style={{ backgroundColor: color }}
  />
);

const ImpactBar = ({ percentage, color }) => (
  <div className="w-32 bg-gray-200 dark:bg-neutral-700 rounded-full h-2">
    <div
      className="h-2 rounded-full"
      style={{ width: `${percentage}%`, backgroundColor: color }}
    />
  </div>
);

const formatTime = (seconds) => {
  if (isNaN(seconds)) return "0m";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

export default function ByLabelTable({ taskData, dateRange }) {
  const { data: labels } = useSelector((state) => state.labels);
  const [percentageType, setPercentageType] = useState("tasks");

  const labelData = useMemo(() => {
    if (!taskData || !labels || !dateRange) return [];

    const startDate = moment(dateRange.dates[0]).startOf("day").toDate();
    const endDate = moment(dateRange.dates[dateRange.dates.length - 1])
      .endOf("day")
      .toDate();

    const labelMap = new Map(
      Object.values(labels || {}).map((label) => [
        label.id,
        { ...label, tasks: 0, estimatedTime: 0, actualTime: 0, completed: 0 },
      ])
    );

    let totalActualTime = 0;
    let totalTasks = 0;

    Object.values(taskData).forEach((task) => {
      const taskDate = moment(task.date).toDate();
      if (
        taskDate >= startDate &&
        taskDate <= endDate &&
        task.label &&
        labelMap.has(task.label)
      ) {
        const labelInfo = labelMap.get(task.label);
        labelInfo.tasks += 1;
        labelInfo.estimatedTime += task.estimated_time || 0;
        labelInfo.actualTime += task.actual_time || 0;
        if (task.complete) labelInfo.completed += 1;
        totalActualTime += task.actual_time || 0;
        totalTasks += 1;
      }
    });

    return Array.from(labelMap.values())
      .filter((label) => label.tasks > 0)
      .map((label) => ({
        name: label.name,
        impact: label.actualTime,
        impactPercentageTime: (label.actualTime / totalActualTime) * 100,
        impactPercentageTasks: (label.tasks / totalTasks) * 100,
        tasksCompleted: label.completed,
        estimatedTime: label.estimatedTime,
        actualTime: label.actualTime,
        color: label.color || "#3B82F6",
        tasks: label.tasks,
      }))
      .sort((a, b) => {
        const aValue =
          percentageType === "tasks"
            ? a.impactPercentageTasks
            : a.impactPercentageTime;
        const bValue =
          percentageType === "tasks"
            ? b.impactPercentageTasks
            : b.impactPercentageTime;
        return bValue - aValue; // Descending order
      });
  }, [taskData, labels, dateRange, percentageType]);

  const totalCompleted = labelData.reduce(
    (sum, item) => sum + item.tasksCompleted,
    0
  );
  const totalEstimatedTime = labelData.reduce(
    (sum, item) => sum + item.estimatedTime,
    0
  );
  const totalActualTime = labelData.reduce(
    (sum, item) => sum + item.actualTime,
    0
  );

  return (
    <div className="bg-white dark:bg-[#282828] rounded-xl shadow-sm p-6 w-full border border-neutral-100 dark:text-white dark:border-none">
      <div className="mb-8 flex flex-row gap-2 items-center justify-between">
        <h2 className="text-lg font-semibold">Your Tasks by Label</h2>
        <Select
          value={percentageType}
          style={{ width: 170 }}
          onChange={setPercentageType}
          options={[
            { value: "tasks", label: "% (by # of tasks)" },
            { value: "time", label: "% (by actual time)" },
          ]}
        />
      </div>

      <table className="w-full">
        <thead>
          <tr className="text-left text-gray-500 text-xs">
            <th className="pb-2">Label</th>
            <th className="pb-2">
              {percentageType === "tasks"
                ? "% (by # of tasks)"
                : "% (by actual time)"}
            </th>
            <th className="pb-2">Tasks Completed</th>
            <th className="pb-2 pr-4">Estimated Time</th>
            <th className="pb-2 pr-4">Actual Time</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {labelData.map((item, index) => (
            <tr
              key={index}
              className="border-t border-gray-100 dark:border-neutral-700"
            >
              <td className="py-2 pr-4">
                <div className="flex items-center min-w-[200px]">
                  <LabelIcon color={item.color} />
                  <span className="truncate max-w-xs">{item.name}</span>
                </div>
              </td>
              <td className="py-2">
                <div className="flex items-center justify-start">
                  <span className="mr-2 w-16 text-left">
                    {percentageType === "tasks"
                      ? item.impactPercentageTasks.toFixed(1)
                      : item.impactPercentageTime.toFixed(1)}
                    %
                  </span>
                  <ImpactBar
                    percentage={
                      percentageType === "tasks"
                        ? item.impactPercentageTasks
                        : item.impactPercentageTime
                    }
                    color={item.color}
                  />
                </div>
              </td>
              <td className="py-2">{item.tasksCompleted}</td>
              <td className="py-2">{formatTime(item.estimatedTime)}</td>
              <td className="py-2">{formatTime(item.actualTime)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="border-t border-gray-200 dark:border-neutral-600 font-semibold text-sm">
            <td className="py-2 pr-4" colSpan="2">
              Total
            </td>
            <td className="py-2">{totalCompleted}</td>
            <td className="py-2">{formatTime(totalEstimatedTime)}</td>
            <td className="py-2">{formatTime(totalActualTime)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

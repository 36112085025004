import { useState } from "react";
import sunsamaLogo from "../../images/sunsama-logo.svg";
import csvIcon from "../../images/csv-icon.png";
import todoistIcon from "../../images/todoist-icon.png";
import ImportSunsama from "./ImportSunsama";

export default function ImportData() {
  const [sunsamaImportActive, setSunsamaImportActive] = useState(false);
  return (
    <div>
      {sunsamaImportActive && (
        <ImportSunsama setSunamaImportActive={setSunsamaImportActive} />
      )}
      {!sunsamaImportActive && (
        <div className="flex flex-col gap-4">
          <div className="text-[#172b4d] dark:text-white font-medium">
            Select how you want to import your files.
          </div>
          <div className="flex flex-col">
            {/* Sunsama */}
            <div className="add-calendar-container primary">
              <div className="flex items-center gap-2">
                <div>
                  <img
                    className="w-6 h-6 object-contain"
                    src={sunsamaLogo}
                    alt="Sunsama Logo"
                  />
                </div>
                <div>
                  <div className="text-base font-semibold">Sunsama</div>
                </div>
              </div>
              <button
                onClick={() => setSunsamaImportActive(true)}
                className="button border border-[#CACACA] dark:border-[#373737] p-1 rounded-lg text-xs font-medium"
              >
                Import
              </button>
            </div>
            {/* CSV */}
            <div className="add-calendar-container primary">
              <div className="flex items-center gap-2">
                <div>
                  <img
                    className="w-6 h-6 object-contain"
                    src={csvIcon}
                    alt="Csv Logo"
                  />
                </div>
                <div>
                  <div className=" text-base font-semibold">CSV</div>
                </div>
              </div>
              <button className="button disabled border border-[#CACACA] dark:border-[#373737] py-1 px-2.5 rounded-md text-xs font-medium">
                Coming Soon
              </button>
            </div>
            {/* Todoist */}
            <div className="add-calendar-container primary">
              <div className="flex items-center gap-2">
                <div>
                  <img
                    className="w-6 h-6 object-contain"
                    src={todoistIcon}
                    alt="Todoist Logo"
                  />
                </div>
                <div>
                  <div className="text-base font-semibold">Todoist</div>
                </div>
              </div>
              <button className="button disabled border border-[#CACACA] dark:border-[#373737] p-1 rounded-lg text-xs font-medium">
                Coming Soon
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { TbCalendar } from "react-icons/tb";
import "../CardModal/CardModal.css";

import DatePickerDropdown from "../Sidebar/DatePicker/DatePickerDropdown";
import InlineText from "./EditorValues/InlineText";

import TextareaAutosize from "react-textarea-autosize";
import { useSelector } from "react-redux";

export default function EventEditorContent({ originalEvent }) {
  const [calendarEventEditable, setCalendarEventEditable] = useState(false);

  const [startDatePickerVisible, setStartDatePickerVisible] = useState(false);
  const [endDatePickerVisible, setEndDatePickerVisible] = useState(false);

  const [isAllDay, setIsAllDay] = useState(false);

  useEffect(() => {
    if (originalEvent) {
      setCalendarEventEditable(originalEvent);
      setIsAllDay(originalEvent?.start?.date ? true : false);
    }
  }, [originalEvent]);

  if (!calendarEventEditable) return null;

  return (
    <div className="card-modal-body">
      <div className="modal-card-properties">
        <div className="modal-property">
          <div className="title">
            <TbCalendar className="icon" />
            Event date
          </div>
          <div className="value">
            <DatePickerDropdown
              dateSelected={
                calendarEventEditable?.start?.dateTime ||
                calendarEventEditable?.start?.date ||
                null
              }
              isVisible={startDatePickerVisible}
              setIsVisible={setStartDatePickerVisible}
              onDateChange={(date) => {
                // If this is an all day event, let's store in "date", if not let's store in "dateTime"
                if (isAllDay) {
                  setCalendarEventEditable({
                    ...calendarEventEditable,
                    start: {
                      ...calendarEventEditable?.start,
                      date: date,
                    },
                  });
                } else {
                  setCalendarEventEditable({
                    ...calendarEventEditable,
                    start: {
                      ...calendarEventEditable?.start,
                      dateTime: date,
                    },
                  });
                }
              }}
            />
            <DatePickerDropdown
              dateSelected={
                calendarEventEditable?.end?.dateTime ||
                calendarEventEditable?.end?.date ||
                null
              }
              isVisible={endDatePickerVisible}
              setIsVisible={setEndDatePickerVisible}
              onDateChange={(date) => {
                // If this is an all day event, let's store in "date", if not let's store in "dateTime"
                if (isAllDay) {
                  setCalendarEventEditable({
                    ...calendarEventEditable,
                    end: {
                      ...calendarEventEditable?.end,
                      date: date,
                    },
                  });
                } else {
                  setCalendarEventEditable({
                    ...calendarEventEditable,
                    end: {
                      ...calendarEventEditable?.end,
                      dateTime: date,
                    },
                  });
                }
              }}
            />
          </div>

          <div className="value">
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="all-day"
                checked={isAllDay}
                onChange={(e) => {
                  setIsAllDay(e.target.checked);
                }}
              />
              <label htmlFor="all-day">All day</label>
            </div>
          </div>
        </div>

        <div className="modal-property">
          <div className="title">
            <MdLocationPin className="icon" />
            Location
          </div>
          <div className="value">
            <InlineText
              value={calendarEventEditable?.location}
              onInputChange={(value) => {
                setCalendarEventEditable({
                  ...calendarEventEditable,
                  location: value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="modal-card-body-notes">
        <div className="modal-card-body-notes-header">Description</div>
        <TextareaAutosize
          onBlur={() => {
            // Let's see
          }}
          placeholder="Add any notes to the task"
          className=" ph-mask"
          value={calendarEventEditable?.description}
          onChange={(e) => {
            setCalendarEventEditable({
              ...calendarEventEditable,
              description: e.target.value,
            });
          }}
          style={{}}
        />
      </div>
    </div>
  );
}

import "../Sidebar/Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { ChevronRightIcon, ArrowSmLeftIcon } from "@heroicons/react/24/outline";

import AddTaskCard from "../Kanban/Column/AddTaskCard";

import Card from "../Kanban/Card";

import {
  updateCurrentUser,
  setColumnSelected,
  loadDatesFromStartDate,
} from "../../redux/appSlice";
import {
  updateTask,
  changeCalendarDate,
  addTasks,
  processTaskOrders,
} from "../../redux/tasksSlice";
import CreateCard from "../Kanban/Card/CreateCard";
import { Resizable } from "re-resizable";
import { useSpring, animated as a } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";
import { query, where, collection, onSnapshot, doc } from "firebase/firestore";
import logo from "../../images/ellie-solid.svg";
import ColumnBody from "./Column/ColumnBody";
import moment from "moment";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { db } from "../../firebase";

export default function MiniKanbanView({
  orderEditable = [],
  onDragEnd,
  saveOrder,
  todayMode = false,
  headerHidden = false,
  disableCompletedOpacity = false,
  customHeader = null,
  maxWidth = 290,
  date = moment().format("YYYY-MM-DD"),
}) {
  const userId = useSelector((state) => state.app.uid);
  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);
  const columnRef = useRef(null);
  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");
  const columnSelected = useSelector((state) => state.app.columnSelected);
  const { data: tasks } = useSelector((state) => state.tasks);

  const {
    hide_calendar_sidebar,
    sidebar_width = maxWidth,
    calendar_show_weekends = true,
    active_calendar_type = "week",
  } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  const [createActive, setCreateActive] = useState(null);

  const sidebarSpringProps = useSpring({
    width: hide_calendar_sidebar && !todayMode ? 0 : maxWidth,
    overflow: hide_calendar_sidebar && !todayMode ? "hidden" : "visible",
    maxWidth: maxWidth,
    minWidth: hide_calendar_sidebar && !todayMode ? 0 : maxWidth,
  });

  const [indexSelected, setIndexSelected] = useState(null);

  function manuallySelectColumn(newDate, index) {
    dispatch(setColumnSelected(newDate));
    setIndexSelected(index);
  }

  useEffect(() => {
    if (columnSelected !== date) {
      setIndexSelected(null);
    }
  }, [columnSelected]);

  useHotkeys(
    "shift+T",
    () => {
      dispatch(setColumnSelected(date));

      // if (columnSelected === date) {
      setIndexSelected(0);
      // }
    },
    [indexSelected, columnSelected]
  );

  useHotkeys(
    "down",
    () => {
      if (columnSelected === date) {
        if (indexSelected === null) {
          setIndexSelected(0);
        } else if (
          indexSelected < orderEditable.length - 1 &&
          indexSelected >= 0
        ) {
          setIndexSelected(indexSelected + 1);
        }
      }
    },
    [indexSelected]
  );

  useHotkeys(
    "up",
    () => {
      if (columnSelected === date) {
        if (indexSelected === null) {
          setIndexSelected(0);
        } else if (indexSelected > 0 && indexSelected < orderEditable.length) {
          setIndexSelected(indexSelected - 1);
        }
      }
    },
    [indexSelected]
  );

  function loadTasksForDate(date) {
    const tasksQuery = query(
      collection(db, "users", userId, "tasks"),
      where("date", ">=", moment(date, "YYYY-MM-DD").startOf("day").toDate()),
      where("date", "<=", moment(date, "YYYY-MM-DD").endOf("day").toDate())
    );

    const unsubTasks = onSnapshot(tasksQuery, (querySnapshot) => {
      const tasks = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
          if (change.type === "modified") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
        }
      });

      if (tasks && tasks.length > 0) {
        dispatch(addTasks({ tasks: tasks }));
      }
    });

    return unsubTasks;
  }

  const dates = useSelector((state) => state.app.dates);

  function loadTaskOrderForDate(date) {
    const taskOrderQuery = query(
      collection(db, "users", userId, "task_order"),
      where("date", ">=", moment(date, "YYYY-MM-DD").startOf("day").toDate()),
      where("date", "<=", moment(date, "YYYY-MM-DD").endOf("day").toDate())
    );

    const unsubTaskOrder = onSnapshot(taskOrderQuery, (querySnapshot) => {
      const taskOrdersAdded = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            // Let's remove duplicates from the order array
            if (data.order) {
              data.order = data.order.filter((item, index) => {
                return data.order.indexOf(item) === index;
              });
            } else {
              data.order = [];
            }
            taskOrdersAdded.push({ ...data, id: doc.id });
          }
          if (change.type === "modified") {
            // Let's remove duplicates from the order array
            data.order = data.order.filter((item, index) => {
              return data.order.indexOf(item) === index;
            });

            taskOrdersAdded.push({ ...data, id: doc.id });
          }
        }
      });

      var datesIncludingDate = dates;
      if (!dates.includes(date)) {
        datesIncludingDate = [...dates, date];
      }

      if (taskOrdersAdded && taskOrdersAdded.length > 0) {
        dispatch(
          processTaskOrders({
            taskOrder: taskOrdersAdded,
            dates: datesIncludingDate,
            override: true,
          })
        );
      } else {
        // Nothing to process, let's just do ghost tasks
        dispatch(
          processTaskOrders({
            taskOrder: [],
            dates: datesIncludingDate,
            override: true,
          })
        );
      }
    });

    return unsubTaskOrder;
  }

  useEffect(() => {
    if (userId && date) {
      const unsubTasks = loadTasksForDate(date);

      const unsubTaskOrder = loadTaskOrderForDate(date);

      return () => {
        unsubTasks();
        unsubTaskOrder();
      };
    }
  }, [dispatch, userId, date]);

  function changeDate(date) {
    dispatch(
      loadDatesFromStartDate({
        date: date,
        type: active_calendar_type,
      })
    );

    dispatch(
      changeCalendarDate({
        date: date,
      })
    );
  }

  const goToNearestMonday = () => {
    const now = moment();
    const dayOfWeek = now.day();

    if (dayOfWeek === 0) {
      // Today is Sunday; go forward 1 day to the next Monday
      return now.add(1, "days").format("YYYY-MM-DD");
    } else if (dayOfWeek === 6) {
      // Today is Saturday; go forward 2 days to the next Monday
      return now.add(2, "days").format("YYYY-MM-DD");
    } else {
      // Today is already a weekday (Monday to Friday)
      return now.format("YYYY-MM-DD");
    }
  };

  const changeDateByDaysSkippingWeekends = (days) => {
    let newDate = moment(date, "YYYY-MM-DD").add(days, "days");
    if (calendar_show_weekends) {
      return newDate.format("YYYY-MM-DD");
    }

    while (newDate.day() === 0 || newDate.day() === 6) {
      newDate = newDate.add(days, "days");
    }
    return newDate.format("YYYY-MM-DD");
  };

  return (
    <a.div
      className={`sidebar-right ${todayMode ? "today-mode" : ""}`}
      style={sidebarSpringProps}
      onMouseMove={() => {
        if (navigatedViaKeyboard) {
          setNavigatedViaKeyboard(false);
        }
      }}
    >
      <div
        className={`sidebar-container ${todayMode ? "today-mode" : ""}`}
        style={{
          paddingTop: todayMode ? (headerHidden ? 0 : 10) : 0,
        }}
      >
        {!todayMode && (
          <div className="mini-kanban-header">
            <div className="mini-cal-title">
              <div className="mini-cal-subdate">🌤️ Tasks</div>
            </div>
            <div className="mini-calendar-right">
              <div className="mini-calendar-nav">
                <FiChevronLeft
                  className="topbar-button nav-button"
                  onClick={() => {
                    changeDate(changeDateByDaysSkippingWeekends(-1));
                  }}
                />
                <div
                  onClick={() => {
                    changeDate(
                      calendar_show_weekends
                        ? moment().format("YYYY-MM-DD")
                        : goToNearestMonday()
                    );
                  }}
                  className="topbar-button today-button"
                >
                  Today
                </div>
                <FiChevronRight
                  className="topbar-button nav-button"
                  onClick={() => {
                    changeDate(changeDateByDaysSkippingWeekends(1));
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="braindump-header">
          {!headerHidden && (
            <div className="braindump-header-title-container">
              <div className="column-date-container mini w-full">
                <div className="flex flex-row gap-0 items-center">
                  <div className="column-day-text">
                    {moment(date, "YYYY-MM-DD").format("ddd")}
                  </div>
                  <div className="column-date-text">
                    {moment(date, "YYYY-MM-DD").format("MMM D")}
                  </div>
                  {moment(date, "YYYY-MM-DD").isSame(moment(), "d") && (
                    <div className="today-text">Today</div>
                  )}
                </div>
                <div className="right">
                  {moment(date, "YYYY-MM-DD").isSame(moment(), "d") && (
                    <div
                      onClick={() => {
                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              mode: "today",
                            },
                            previousValues: {
                              mode: mode,
                            },
                          })
                        );
                      }}
                      className="today-focus-mode-button"
                    >
                      Focus
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {customHeader && customHeader}

          <AddTaskCard setCreateActive={setCreateActive} date={date} />
          {createActive && (
            <CreateCard
              setCreateActive={setCreateActive}
              setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              date={date}
            />
          )}
        </div>

        <ColumnBody
          order={orderEditable}
          date={date}
          indexSelected={indexSelected}
          manuallySelectColumn={manuallySelectColumn}
          navigatedViaKeyboard={navigatedViaKeyboard}
          setIndexSelected={setIndexSelected}
          mini={true}
          disableCompletedOpacity={disableCompletedOpacity}
        />
      </div>
    </a.div>
  );
}

import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setUpgradeVisible, updateCurrentUser } from "../../redux/appSlice";
import { Default, Mobile } from "../../mediaUtils";
import { Select, Switch } from "antd";
import { analytics, isMac } from "../../utils";
import { posthog } from "posthog-js";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { toast } from "sonner";
import { set, update } from "lodash";
import moment from "moment";
import { FaChevronRight } from "react-icons/fa";

import LabelSelectorMini from "../Kanban/Card/Components/LabelSelector/LabelSelectorMini";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import TimerBackgroundGalery from "./TimerBackgroundGallery";
const { Option } = Select;

export default function Customize() {
  const {
    move_task_on_complete_disabled = false,
    new_task_position = "top",
    rollover_disabled = false,
    rollover_position = "bottom",
    focus_mode_type = "timer",
    auto_complete_on_subtasks_disabled = false,
    calendar_first_day_of_week = 0,
    calendar_show_weekends = true,
    user_theme = "system",
    calendar_snap_duration = "00:15:00",
    auto_fill_actual_time_disabled = false,
    sidebar_layout = "single_list",
    card_layout = "compact",
    show_declined_events = true,
    time_format = "12_hour",
    mac_calendar_tray_menu_enabled = true,
    slotMinTime = "00:00",
    inline_label_enabled = true,
    work_threshold = 8,
    enable_work_threshold = true,
    work_threshold_excluded_labels = [],
    recurring_task_rollover_enabled = false,
    enable_rich_links = true,
    rich_links = {},
    float_ellie_on_top = false,
    floating_timer_enabled = false,
    floating_timer_background = "color_black",
    timer_auto_next_task = false,
    default_estimated_time = 0,
  } = useSelector((state) => state.app.currentUser);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const [showRichLinkExtendedSettings, setShowRichLinkExtendedSettings] =
    useState(false);

  const [showTimerSettings, setShowTimerSettings] = useState(false);

  const [externalLinks, setExternalLinks] = useState({
    slack: {
      id: "slack",
      link: "Slack",
      icon: require("../../images/Slack-mark-RGB.png"),
    },
    notion: {
      id: "notion",
      link: "Notion",
      icon: require("../../images/Notion_app_logo.png"),
    },
    figma: {
      id: "figma",
      link: "Figma",
      icon: require("../../images/figma-logo.png"),
    },
    craft: {
      id: "craft",
      link: "Craft",
      icon: require("../../images/craft-logo.png"),
    },
    linear: {
      id: "linear",
      link: "Linear",
      icon: require("../../images/linear-logo.png"),
    },
    googleDocs: {
      id: "googleDocs",
      link: "Google Docs",
      icon: require("../../images/google-docs-logo.png"),
    },
    clickUp: {
      id: "clickUp",
      link: "ClickUp",
      icon: require("../../images/clickup-logo.png"),
    }
  });

  const [posthog_analytics_enabled, setPosthogAnalyticsEnabled] =
    useState(true);

  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const labels = useSelector((state) => state.labels.data);

  const dispatch = useDispatch();

  function updateUserSettings(newValues, oldValues) {
    Object.keys(newValues).forEach(function (key) {
      var value = newValues[key];
      analytics("Settings updated", {
        type: key,
        value: value,
      });
    });

    dispatch(
      updateCurrentUser({
        newValues: newValues,
        previousValues: oldValues,
      })
    );
  }

  const [prevSlotMinTime, setPrevSlotMinTime] = useState(slotMinTime);

  const timeRange = Array.from(
    { length: 25 },
    (_, i) => i.toString().padStart(2, "0") + ":00"
  );

  const isValidTime = (newTime, isStartTime) => {
    const newHour = parseInt(newTime.split(":")[0], 10);
    const startHour = parseInt(slotMinTime.split(":")[0], 10);
    const endHour = 24;

    if (isStartTime && (newHour >= endHour || endHour - newHour < 6)) {
      toast.error(
        "New start time should be before end time and the time range should be at least 6 hours."
      );
      return false;
    } else if (
      !isStartTime &&
      (startHour >= newHour || newHour - startHour < 6)
    ) {
      toast.error(
        "End time should be after start time and the time range should be at least 6 hours."
      );
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (posthog.has_opted_out_capturing()) {
      setPosthogAnalyticsEnabled(false);
    } else {
      setPosthogAnalyticsEnabled(true);
    }
  }, [posthog.has_opted_out_capturing()]);

  // let's sync the rich_links object with externalLinks
  useEffect(() => {
    const updatedExternalLinks = Object.keys(rich_links).reduce(
      (acc, key) => {
        if (acc[key]) {
          acc[key] = { ...acc[key], ...rich_links[key] };
        }
        return acc;
      },
      { ...externalLinks }
    );

    // Only call setExternalLinks if updatedExternalLinks is different from externalLinks
    if (
      JSON.stringify(updatedExternalLinks) !== JSON.stringify(externalLinks)
    ) {
      setExternalLinks(updatedExternalLinks);
    }
  }, [rich_links, externalLinks]);

  return (
    <>
      {showRichLinkExtendedSettings && (
        <div className="w-full h-full px-[30px] py-[15px]">
          <div
            onClick={() => {
              setShowRichLinkExtendedSettings(false);
            }}
            className="back-button"
          >
            <ArrowLeftCircleIcon className="back-button-icon" />
            Back
          </div>
          <div className="text-sm font-semibold">Manage rich link settings</div>
          <div className="text-sm text-neutral-500 my-2">
            Ellie will detect links for the following apps within your task
            notes and automatically create a deep link to the app. You can
            disable this on a per-app basis below.
          </div>
          <div className="flex flex-col gap-2 my-4">
            <div className="flex justify-between items-center ">
              <div className="flex items-center justify-center font-medium">
                External Links
              </div>
              <div className="flex gap-4">
                <div className="w-28 flex items-center justify-start font-medium">
                  Enable
                </div>
                <div className="w-32 font-medium">Enable Deeplink</div>
              </div>
            </div>
            <div className="bg-neutral-200 dark:bg-neutral-500 w-full p-[0.5px]" />
            <div className="flex flex-col gap-6 py-2">
              {Object.values(externalLinks).map(
                ({
                  link = null,
                  icon = null,
                  disabled = false,
                  deeplink_disabled = false,
                  id,
                }) => {
                  return (
                    <div className="flex justify-between" key={id}>
                      <div className="flex gap-2">
                        {icon && (
                          <div>
                            <img
                              src={icon}
                              alt={`${link} icon}`}
                              className={`w-5 h-5 object-contain ${
                                id === "googleDocs" && "object-cover"
                              }`}
                            />
                          </div>
                        )}
                        {link && <div className="">{link}</div>}
                      </div>
                      <div className="flex gap-4 items-center justify-center">
                        <div className="w-28 flex items-center justify-start">
                          <Switch
                            className="w-5"
                            size="small"
                            checked={!disabled}
                            onChange={(checked) => {
                              // we need to update the rich_links object
                              const updatedRichLinks = { ...rich_links };

                              updatedRichLinks[id] = {
                                ...updatedRichLinks[id],
                                disabled: !checked,
                              };

                              updateUserSettings(
                                { rich_links: updatedRichLinks },
                                { rich_links: rich_links }
                              );
                            }}
                          />
                        </div>
                        <div className="w-32 flex justify-start items-center">
                          <Switch
                            className="w-5"
                            size="small"
                            checked={!disabled && !deeplink_disabled}
                            disabled={disabled}
                            onChange={(checked) => {
                              const updatedRichLinks = { ...rich_links };

                              updatedRichLinks[id] = {
                                ...updatedRichLinks[id],
                                deeplink_disabled: !checked,
                              };

                              updateUserSettings(
                                { rich_links: updatedRichLinks },
                                { rich_links: rich_links }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      )}
      {showTimerSettings && (
        <div>
          <div className="px-[30px] pt-[15px]">
            <div
              onClick={() => {
                setShowTimerSettings(false);
              }}
              className="back-button"
            >
              <ArrowLeftCircleIcon className="back-button-icon" />
              Back
            </div>
          </div>

          <TimerBackgroundGalery />
        </div>
      )}
      {!showTimerSettings && !showRichLinkExtendedSettings && (
        <div className="px-[30px] py-[15px]">
          <div className="settings personal">
            <div className="settings-item">
              <div className="settings-item-title">Appearance</div>
              <div className="settings-item-value">
                <Select
                  size="small"
                  style={{ width: "200px" }}
                  value={user_theme}
                  onChange={(value) => {
                    updateUserSettings(
                      { user_theme: value },
                      { user_theme: user_theme }
                    );
                  }}
                >
                  <Option value="system">Use system settings</Option>
                  <Option value="light">Light mode</Option>
                  <Option value="dark">Dark mode</Option>
                </Select>
              </div>
            </div>

            {isDesktopApp() && (
              <>
                <div className="settings-rowdivider" />
                <div className="settings-item">
                  <div className="settings-item-title">Desktop settings</div>
                  <div className="settings-item-value">
                    Always show Ellie on top of other windows
                    <Switch
                      size="small"
                      style={{ marginLeft: "10px" }}
                      checked={float_ellie_on_top}
                      onChange={(checked) => {
                        updateUserSettings(
                          { float_ellie_on_top: checked },
                          { float_ellie_on_top: float_ellie_on_top }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="settings-rowdivider" />
            <div className="settings-item">
              <div className="settings-item-title">Timer settings</div>

              {isDesktopApp() && (
                <div className="settings-item-value">
                  Enable floating timer
                  <Switch
                    size="small"
                    style={{ marginLeft: "10px" }}
                    checked={floating_timer_enabled}
                    onChange={(checked) => {
                      updateUserSettings(
                        { floating_timer_enabled: checked },
                        { floating_timer_enabled: floating_timer_enabled }
                      );
                    }}
                  />
                </div>
              )}
              <div className="settings-item-value">
                Auto start next task after task completion
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={timer_auto_next_task}
                  onChange={(checked) => {
                    updateUserSettings(
                      { timer_auto_next_task: checked },
                      { timer_auto_next_task: timer_auto_next_task }
                    );
                  }}
                />
              </div>
              <div className="settings-item-value">
                <div
                  className="settings-item-value border rounded-lg p-1 text-sm w-52 items-center justify-center flex-row gap-1 group button border-[#CACACA] dark:border-[#373737]"
                  onClick={() => {
                    setShowTimerSettings(true);
                  }}
                >
                  Change Timer Background{" "}
                  <FaChevronRight className="w-2 h-2 group-hover:text-purple-600" />
                </div>
              </div>
            </div>

            <div className="settings-rowdivider" />

            <div className="settings-item">
              <div className="settings-item-title">Braindump & Lists</div>
              <div className="settings-item-value">
                Sidebar layout
                <Select
                  size="small"
                  style={{ marginLeft: "10px" }}
                  value={sidebar_layout}
                  onChange={(value) => {
                    updateUserSettings(
                      { sidebar_layout: value },
                      { sidebar_layout: sidebar_layout }
                    );
                  }}
                >
                  <Option value="single_list">Show one list</Option>
                  <Option value="all">Show all lists</Option>
                </Select>
              </div>
              {sidebar_layout == "all" && (
                <div className="settings-item-value">
                  Card layout
                  <Select
                    size="small"
                    style={{ marginLeft: "10px" }}
                    value={card_layout}
                    onChange={(value) => {
                      updateUserSettings(
                        { card_layout: value },
                        { card_layout: card_layout }
                      );
                    }}
                  >
                    <Option value="compact">Compact</Option>
                    <Option value="full">Full card</Option>
                  </Select>
                </div>
              )}
            </div>

            <div className="settings-rowdivider" />

            <div className="settings-item">
              <div className="settings-item-title">New Task/Update Task</div>
              <div className="settings-item-value">
                Add new tasks to the
                <Select
                  size="small"
                  style={{ marginLeft: "10px" }}
                  value={new_task_position}
                  onChange={(value) => {
                    updateUserSettings(
                      { new_task_position: value },
                      { new_task_position: new_task_position }
                    );
                  }}
                >
                  <Option value="top">Top of list</Option>
                  <Option value="bottom">Bottom of list</Option>
                </Select>
              </div>

              <div className="settings-item-value">
                Detect label in task title{" "}
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={inline_label_enabled}
                  onChange={(checked) => {
                    updateUserSettings(
                      { inline_label_enabled: checked },
                      { inline_label_enabled: inline_label_enabled }
                    );
                  }}
                />
                <a
                  href="https://guide.ellieplanner.com/features/tasks/labels#bonus-quickly-add-labels-to-task-by-using-label-name-in-the-title"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-2 text-neutral-500 text-xs hover:text-blue-500"
                >
                  (what is this?)
                </a>
              </div>

              <div className="settings-item-value">
                Default estimated time (for new tasks)
                <Select
                  size="small"
                  style={{ marginLeft: "10px", minWidth: "110px" }}
                  value={default_estimated_time}
                  onChange={(value) => {
                    updateUserSettings(
                      { default_estimated_time: value },
                      { default_estimated_time: default_estimated_time }
                    );
                  }}
                >
                  <Option value={0}>0 mins</Option>
                  <Option value={5 * 60}>5 mins</Option>
                  <Option value={10 * 60}>10 mins</Option>
                  <Option value={15 * 60}>15 mins</Option>
                  <Option value={30 * 60}>30 mins</Option>
                  <Option value={45 * 60}>45 mins</Option>
                  <Option value={60 * 60}>1 hour</Option>
                  <Option value={2 * 60 * 60}>2 hours</Option>
                </Select>
              </div>
            </div>

            <div className="settings-rowdivider" />

            <div className="settings-item">
              <div className="settings-item-title">Task Rollover</div>
              <div className="settings-item-value">
                Roll-over tasks to the next day
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={!rollover_disabled}
                  onChange={(checked) => {
                    updateUserSettings(
                      { rollover_disabled: !checked },
                      { rollover_disabled: rollover_disabled }
                    );
                  }}
                />
              </div>
              <div className="settings-item-value">
                Roll-over recurring tasks
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={recurring_task_rollover_enabled}
                  onChange={(checked) => {
                    updateUserSettings(
                      { recurring_task_rollover_enabled: checked },
                      {
                        recurring_task_rollover_enabled:
                          recurring_task_rollover_enabled,
                      }
                    );
                  }}
                />
              </div>
              <div className="settings-item-value">
                Roll over tasks to the
                <Select
                  size="small"
                  style={{ marginLeft: "10px" }}
                  value={rollover_position}
                  onChange={(value) => {
                    updateUserSettings(
                      { rollover_position: value },
                      { rollover_position: rollover_position }
                    );
                  }}
                >
                  <Option value="top">Top of list</Option>
                  <Option value="bottom">Bottom of list</Option>
                </Select>
              </div>
            </div>

            <div className="settings-rowdivider" />

            <div className="settings-item">
              <div className="settings-item-title">After Task Completion</div>
              <div className="settings-item-value">
                Move tasks (and subtasks) to the bottom of the list on complete
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={!move_task_on_complete_disabled}
                  onChange={(checked) => {
                    updateUserSettings(
                      { move_task_on_complete_disabled: !checked },
                      {
                        move_task_on_complete_disabled:
                          move_task_on_complete_disabled,
                      }
                    );
                  }}
                />
              </div>
              <div className="settings-item-value">
                Mark tasks as complete when subtasks are complete
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={!auto_complete_on_subtasks_disabled}
                  onChange={(checked) => {
                    updateUserSettings(
                      { auto_complete_on_subtasks_disabled: !checked },
                      {
                        auto_complete_on_subtasks_disabled:
                          auto_complete_on_subtasks_disabled,
                      }
                    );
                  }}
                />
              </div>
              <div className="settings-item-value">
                Automatically set "actual time" when task is complete
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={!auto_fill_actual_time_disabled}
                  onChange={(checked) => {
                    updateUserSettings(
                      { auto_fill_actual_time_disabled: !checked },
                      {
                        auto_fill_actual_time_disabled:
                          auto_fill_actual_time_disabled,
                      }
                    );
                  }}
                />
              </div>

              <div
                className={`${enable_rich_links && "border-l-2  my-2 pl-4"}`}
              >
                <div className="settings-item-value">
                  Enable deep link detection for external apps
                  <Switch
                    size="small"
                    style={{ marginLeft: "10px" }}
                    checked={enable_rich_links}
                    onChange={(checked) => {
                      updateUserSettings(
                        { enable_rich_links: checked },
                        {
                          enable_rich_links: enable_rich_links,
                        }
                      );
                    }}
                  />
                </div>

                {enable_rich_links && (
                  <div
                    className="settings-item-value border rounded-lg p-1 text-sm w-24 items-center justify-center flex-row gap-1 group button border-[#CACACA] dark:border-[#373737]"
                    onClick={() => setShowRichLinkExtendedSettings(true)}
                  >
                    Manage{" "}
                    <FaChevronRight className="w-2 h-2 group-hover:text-purple-600" />
                  </div>
                )}
              </div>
            </div>

            <div className="settings-rowdivider" />

            <div className="settings-item">
              <div className="settings-item-title">
                Calendar / Kanban settings
              </div>

              <div className="settings-item-value">
                Start week on
                <Select
                  size="small"
                  style={{ marginLeft: "10px", minWidth: "110px" }}
                  value={calendar_first_day_of_week}
                  onChange={(value) => {
                    updateUserSettings(
                      { calendar_first_day_of_week: value },
                      { calendar_first_day_of_week: calendar_first_day_of_week }
                    );
                  }}
                >
                  <Option value={0}>Sunday</Option>
                  <Option value={1}>Monday</Option>
                  <Option value={2}>Tuesday</Option>
                  <Option value={3}>Wednesday</Option>
                  <Option value={4}>Thursday</Option>
                  <Option value={5}>Friday</Option>
                  <Option value={6}>Saturday</Option>
                </Select>
              </div>

              <div className="settings-item-value">
                Show weekends
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={calendar_show_weekends}
                  onChange={(checked) => {
                    updateUserSettings(
                      { calendar_show_weekends: checked },
                      {
                        calendar_show_weekends: calendar_show_weekends,
                      }
                    );
                  }}
                />
              </div>

              <div
                className={`flex flex-col items-start justify-center gap-2 ${
                  enable_work_threshold && "border-l-2  my-2 pl-4"
                }`}
              >
                <div className="settings-item-value">
                  Set a workday threshold?
                  <Switch
                    size="small"
                    style={{ marginLeft: "10px" }}
                    checked={enable_work_threshold}
                    onChange={(checked) => {
                      updateUserSettings(
                        { enable_work_threshold: checked },
                        {
                          enable_work_threshold: enable_work_threshold,
                        }
                      );
                    }}
                  />
                </div>

                {enable_work_threshold && (
                  <>
                    <div className="settings-item-value">
                      Workload Threshold
                      <Select
                        size="small"
                        style={{ marginLeft: "10px", minWidth: "110px" }}
                        value={work_threshold}
                        onChange={(value) => {
                          updateUserSettings(
                            { work_threshold: value },
                            { work_threshold: work_threshold }
                          );
                        }}
                      >
                        <Option value={3}>3 hours</Option>
                        <Option value={4}>4 hours</Option>
                        <Option value={5}>5 hours</Option>
                        <Option value={6}>6 hours</Option>
                        <Option value={8}>8 hours</Option>
                        <Option value={10}>10 hours</Option>
                        <Option value={12}>12 hours</Option>
                        <Option value={14}>14 hours</Option>
                        <Option value={16}>16 hours</Option>
                        <Option value={18}>18 hours</Option>
                      </Select>
                    </div>

                    <div>
                      <div className="settings-item-value">
                        Exclude tasks with these labels from workload
                        calculation:
                      </div>
                      <div
                        className={`flex flex-col gap-2 ${
                          work_threshold_excluded_labels &&
                          work_threshold_excluded_labels.length > 0
                            ? "mb-2"
                            : ""
                        }`}
                      >
                        {work_threshold_excluded_labels.map((labelId) => {
                          if (labels[labelId]) {
                            return (
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <div
                                    style={{
                                      backgroundColor: labels[labelId].color,
                                      borderRadius: "50%",
                                      width: "10px",
                                      height: "10px",
                                    }}
                                  />
                                  <div className="label">
                                    {labels[labelId].name}
                                  </div>
                                </div>
                                <div
                                  className="cursor-pointer text-neutral-500 text-xs hover:text-blue-500"
                                  onClick={() => {
                                    updateUserSettings(
                                      {
                                        work_threshold_excluded_labels:
                                          work_threshold_excluded_labels.filter(
                                            (id) => id !== labelId
                                          ),
                                      },
                                      {
                                        work_threshold_excluded_labels:
                                          work_threshold_excluded_labels,
                                      }
                                    );
                                  }}
                                >
                                  Remove
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>

                      <LabelSelectorMini
                        labelPickerActive={labelPickerActive}
                        setLabelPickerActive={setLabelPickerActive}
                        excludeLabels={work_threshold_excluded_labels}
                        onLabelSelect={(labelId) => {
                          updateUserSettings(
                            {
                              work_threshold_excluded_labels: [
                                ...work_threshold_excluded_labels,
                                labelId,
                              ],
                            },
                            {
                              work_threshold_excluded_labels:
                                work_threshold_excluded_labels,
                            }
                          );
                        }}
                      >
                        <div
                          onClick={() => {
                            setLabelPickerActive(!labelPickerActive);
                          }}
                          className="button"
                        >
                          Add label to exclusion list
                        </div>
                      </LabelSelectorMini>
                    </div>
                  </>
                )}
              </div>

              <div className="settings-item-value">
                Show declined events
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={show_declined_events}
                  onChange={(checked) => {
                    updateUserSettings(
                      { show_declined_events: checked },
                      {
                        show_declined_events: show_declined_events,
                      }
                    );
                  }}
                />
              </div>

              <div className="settings-item-value">
                Start day at
                <Select
                  size="small"
                  style={{ marginLeft: "10px", minWidth: "110px" }}
                  value={slotMinTime}
                  onChange={(value) => {
                    console.log("value", value);
                    if (isValidTime(value, true)) {
                      setPrevSlotMinTime(value); // update the previous value
                      updateUserSettings(
                        { slotMinTime: value },
                        { slotMinTime: slotMinTime }
                      );
                    } else {
                      console.log("Invalid start time");
                      // You can set some state variable here to show an error message to the user
                      // Resetting to the previous value
                      updateUserSettings(
                        { slotMinTime: prevSlotMinTime },
                        { slotMinTime: slotMinTime }
                      );
                    }
                  }}
                >
                  {timeRange.map((time) => (
                    <Option value={time}>
                      {time_format === "12_hour"
                        ? moment(time, "HH:mm").format("h:mm A")
                        : time}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="settings-item-value">
                Calendar time increments
                <Select
                  size="small"
                  style={{ marginLeft: "10px", minWidth: "110px" }}
                  value={calendar_snap_duration}
                  onChange={(value) => {
                    updateUserSettings(
                      { calendar_snap_duration: value },
                      { calendar_snap_duration: calendar_snap_duration }
                    );
                  }}
                >
                  <Option value={"00:05:00"}>5 minute</Option>
                  <Option value={"00:10:00"}>10 minute</Option>
                  <Option value={"00:15:00"}>15 minute</Option>
                  <Option value={"00:30:00"}>30 minute</Option>
                  <Option value={"01:00:00"}>1 hour</Option>
                </Select>
              </div>
              <div className="settings-item-value">
                Time format
                <Select
                  size="small"
                  style={{ marginLeft: "10px", minWidth: "110px" }}
                  value={time_format}
                  onChange={(value) => {
                    updateUserSettings(
                      { time_format: value },
                      { time_format: time_format }
                    );
                  }}
                >
                  <Option value={"12_hour"}>12 hour</Option>
                  <Option value={"24_hour"}>24 hour</Option>
                </Select>
              </div>
              {isDesktopApp() && isMac() && (
                <div className="settings-item-value">
                  Enable Tray Menu
                  <Switch
                    size="small"
                    style={{ marginLeft: "10px" }}
                    checked={
                      subscriptionActive && mac_calendar_tray_menu_enabled
                    }
                    disabled={!subscriptionActive}
                    onChange={(checked) => {
                      updateUserSettings(
                        { mac_calendar_tray_menu_enabled: checked },
                        {
                          mac_calendar_tray_menu_enabled:
                            mac_calendar_tray_menu_enabled,
                        }
                      );

                      analytics("Mac tray menu setting toggled", {
                        enabled: checked,
                      });
                    }}
                  />
                  {!subscriptionActive && (
                    <div
                      onClick={() => {
                        dispatch(setUpgradeVisible(true));
                      }}
                      className="cursor-pointer text-sm text-transparent bg-clip-text ml-3 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500"
                    >
                      ✨ Pro feature
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="settings-rowdivider" />

            <div className="settings-item">
              <div className="settings-item-title">Privacy</div>

              <div className="settings-item-value">
                Allow anonymous usage tracking (helps us improve the app)
                <Switch
                  size="small"
                  style={{ marginLeft: "10px" }}
                  checked={posthog_analytics_enabled}
                  onChange={(checked) => {
                    if (checked) {
                      setPosthogAnalyticsEnabled(true);
                      posthog.opt_in_capturing();
                    } else {
                      setPosthogAnalyticsEnabled(false);
                      posthog.opt_out_capturing();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { rrulestr } from "rrule";
import { addMinutes } from "date-fns";
import { generateTaskFromTemplateAndDate } from "../utils";

export function generateTasksForSpecificDates(
  recurringTasks,
  dates,
  specificDates,
  taskOrdersEditable
) {
  var tasksToAdd = [];
  var taskOrdersEditableClone = { ...taskOrdersEditable };

  try {
    const recurringTasksToAdd = Object.values(recurringTasks)
      .filter((task) => task.task_template)
      .sort((a, b) => {
        try {
          var aStartDate = a.task_template.start?.toDate
            ? a.task_template.start.toDate()
            : a.task_template.start;
          var bStartDate = b.task_template.start?.toDate
            ? b.task_template.start.toDate()
            : b.task_template.start;

          if (aStartDate) {
            aStartDate = moment(aStartDate)
              .set({
                year: moment().year(),
                month: moment().month(),
                date: moment().date(),
              })
              .toDate();
          }

          if (bStartDate) {
            bStartDate = moment(bStartDate)
              .set({
                year: moment().year(),
                month: moment().month(),
                date: moment().date(),
              })
              .toDate();
          }

          if (aStartDate && bStartDate) {
            return aStartDate - bStartDate;
          }
        } catch (error) {
          console.error("Error during recurring tasks sorting:", error);
          return 0;
        }
      });

    if (recurringTasksToAdd.length > 0) {
      recurringTasksToAdd.reverse();
    }

    recurringTasksToAdd.forEach((recurringTask) => {
      try {
        if (!recurringTask.rrule) {
          return;
        }

        const rruleObject = rrulestr(recurringTask.rrule);

        if (!rruleObject) {
          return;
        }

        const startDate = moment(dates[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate();
        const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate();

        const tzOffset = new Date().getTimezoneOffset();

        const fromRRuleOutput = (date) => {
          return addMinutes(date, tzOffset);
        };

        const ocurrenceDates = rruleObject
          .between(startDate, endDate)
          .map(fromRRuleOutput);

        const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
          return moment(date).format("YYYY-MM-DD");
        });

        ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
          try {
            if (recurringTask.exclusions?.includes(ocurrenceDate)) {
              return;
            }

            if (!specificDates.includes(ocurrenceDate)) {
              return;
            }

            const newTask = {
              ...generateTaskFromTemplateAndDate(
                recurringTask.task_template,
                moment(ocurrenceDate, "YYYY-MM-DD").toDate()
              ),
              id: uuidv4(),
              complete: false,
              recurring: true,
              recurring_id: recurringTask.id,
              created_at: new Date(),
            };

            tasksToAdd.push(newTask);

            if (taskOrdersEditableClone[ocurrenceDate]) {
              taskOrdersEditableClone[ocurrenceDate].order.unshift(newTask.id);
            } else {
              taskOrdersEditableClone[ocurrenceDate] = {
                date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
                order: [newTask.id],
                id: ocurrenceDate,
              };
            }
          } catch (error) {
            console.error("Error processing ocurrence date:", error);
          }
        });
      } catch (error) {
        console.error("Error processing recurring task:", error);
      }
    });

    return { tasksToAdd, taskOrdersEditable: taskOrdersEditableClone };
  } catch (error) {
    console.error("Unexpected error in generateTasksForSpecificDates:", error);
    return { tasksToAdd: [], taskOrdersEditable: {} }; // Default return in case of top-level error
  }
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Column.css";

import moment from "moment";

import AddTaskCard from "./AddTaskCard";

import { createTask } from "../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../redux/appSlice";
import { analytics } from "../../../utils";

export default function ColumnHeader({ date, setCreateActive }) {
  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");
  const dispatch = useDispatch();
  return (
    <div className="column-header">
      <div className="column-date-container">
        <div className="left">
          <div className="column-day-text">
            {moment(date, "YYYY-MM-DD").format("ddd")}
          </div>
          <div className="column-date-text">
            {moment(date, "YYYY-MM-DD").format("MMM D")}
          </div>
          {moment(date, "YYYY-MM-DD").isSame(moment(), "d") && (
            <div>
              <div className="today-text">Today</div>
            </div>
          )}
        </div>
        <div className="right">
          {moment(date, "YYYY-MM-DD").isSame(moment(), "d") && (
            <div
              onClick={() => {
                analytics("View mode changed", {
                  source: "Web",
                  mode: "today",
                });

                dispatch(
                  updateCurrentUser({
                    newValues: {
                      mode: "today",
                    },
                    previousValues: {
                      mode: mode,
                    },
                  })
                );
              }}
              className="today-focus-mode-button"
            >
              Focus
            </div>
          )}
        </div>
      </div>
      <AddTaskCard setCreateActive={setCreateActive} date={date} />
    </div>
  );
}

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { Modal } from "antd";

import { useMediaQuery } from "react-responsive";

import { XMarkIcon } from "@heroicons/react/24/outline";

import success from "../../images/success.gif";

import "./Upgrade.css";

import confetti from "canvas-confetti";
import { analytics } from "../../utils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Success({ setIsExpanded }) {
  let query = useQuery();

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  var count = 200;
  var defaults = {
    origin: { y: 0.7 },
  };

  function fire(particleRatio, opts) {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  }

  useEffect(() => {
    var session_id = query.get("session_id");

    if (session_id) {
      analytics("Upgrade flow complete", {
        source: "Web",
      });

      navigate("/");
      setShow(true);

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }
  }, [query]);

  return (
    <div>
      <Modal
        closable={false}
        footer={null}
        style={{ top: isMobile ? "20px" : "30px" }}
        bodyStyle={{ padding: "0px" }}
        className={"light-theme"}
        open={show}
        width={450}
        zIndex={10000000000}
        onCancel={() => setShow(false)}
      >
        <div className="upgrade-container">
          {" "}
          <div className="top-half no-border" style={{ padding: "20px" }}>
            <div
              onClick={() => {
                setShow(false);
              }}
              className="close-button-container"
            >
              <XMarkIcon className="close-upgrade-modal-button" />
            </div>
            <div className="success-h1">Welcome to Ellie Pro! 🥳</div>
            <div className="success-h2">
              Your account has successfully been upgraded and all pro features
              are now unlocked 🙌
            </div>
          </div>
          <img className="success-image" src={success} />
        </div>
      </Modal>
    </div>
  );
}

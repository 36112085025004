import React, { useEffect } from "react";

import "./Calendar.css";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa6";

import { useSelector, useDispatch } from "react-redux";

import { changeCalendarDate } from "../../redux/tasksSlice";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { GoArrowRight } from "react-icons/go";

import { useSpring, animated as a } from "react-spring";

import { StyleCalendarWrapper } from "./WeekView";

import CustomFullCalendar from "./CustomFullCalendar";
import MiniToolbar from "./MiniToolbar";
import { useDroppable } from "@dnd-kit/core";
import { calculateHeightForZoomLevel } from "../../utils";
import { setUpgradeVisible } from "../../redux/appSlice";

function MiniDayView({ todayMode = false, ritualMode = false }) {
  const {
    hide_calendar_sidebar,
    calendar_show_weekends = true,
    calendar_zoom_level = 100,
  } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  const date = useSelector((state) => state.tasks.calendarDate);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const sidebarSpringProps = useSpring({
    width: hide_calendar_sidebar ? 0 : 290,
    overflow: hide_calendar_sidebar ? "hidden" : "visible",
    maxWidth: 290,
    minWidth: hide_calendar_sidebar ? 0 : 290,
  });

  const fixedSize = useSpring({
    width: 400,
    maxWidth: 400,
    minWidth: 400,
  });

  const { isOver, setNodeRef } = useDroppable({
    id: "CALENDAR",
  });

  // If this is today mode, change date to today
  useEffect(() => {
    if (todayMode) {
      dispatch(
        changeCalendarDate({
          date: moment().format("YYYY-MM-DD"),
        })
      );
    }
  }, [todayMode]);

  const goToNearestMonday = () => {
    const now = moment();
    const dayOfWeek = now.day();

    if (dayOfWeek === 0) {
      // Today is Sunday; go forward 1 day to the next Monday
      return now.add(1, "days").format("YYYY-MM-DD");
    } else if (dayOfWeek === 6) {
      // Today is Saturday; go forward 2 days to the next Monday
      return now.add(2, "days").format("YYYY-MM-DD");
    } else {
      // Today is already a weekday (Monday to Friday)
      return now.format("YYYY-MM-DD");
    }
  };

  const changeDateByDaysSkippingWeekends = (days) => {
    let newDate = moment(date, "YYYY-MM-DD").add(days, "days");
    if (calendar_show_weekends) {
      return newDate.format("YYYY-MM-DD");
    }

    while (newDate.day() === 0 || newDate.day() === 6) {
      newDate = newDate.add(days, "days");
    }
    return newDate.format("YYYY-MM-DD");
  };

  return (
    <a.div
      style={todayMode ? fixedSize : sidebarSpringProps}
      className={`sidebar-right ${todayMode ? "today-mode longer" : ""}`}
    >
      <div
        className={`${
          ritualMode
            ? "sidebar-right-container ritual-mode"
            : "sidebar-right-container"
        }`}
      >
        {!todayMode && (
          <div className="mini-calendar-header">
            <div className="mini-cal-title">
              <div className="mini-cal-subdate">🕓 Timebox</div>
            </div>
            <div className="mini-calendar-right">
              <div className="mini-calendar-nav">
                <FiChevronLeft
                  className="topbar-button nav-button"
                  onClick={() => {
                    dispatch(
                      changeCalendarDate({
                        date: changeDateByDaysSkippingWeekends(-1),
                      })
                    );
                  }}
                />
                <div
                  onClick={() => {
                    dispatch(
                      changeCalendarDate({
                        date: calendar_show_weekends
                          ? moment().format("YYYY-MM-DD")
                          : goToNearestMonday(),
                      })
                    );
                  }}
                  className="topbar-button today-button"
                >
                  Today
                </div>
                <FiChevronRight
                  className="topbar-button nav-button"
                  onClick={() => {
                    dispatch(
                      changeCalendarDate({
                        date: changeDateByDaysSkippingWeekends(1),
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div ref={setNodeRef} className="mini-calendar-container relative">
          <StyleCalendarWrapper
            todayMode={todayMode}
            zoomLevel={calculateHeightForZoomLevel(calendar_zoom_level)}
          >
            <CustomFullCalendar calendar_view="day" todayMode={todayMode} />
          </StyleCalendarWrapper>
          {!subscriptionActive && (
            <div className="absolute bottom-0 w-full flex flex-row justify-center items-center z-10  my-2">
              <div className=" bg-white dark:bg-neutral-900 px-4 py-4 rounded-lg drop-shadow-xl border border-neutral-100 border-0.5 flex-col flex gap-2 dark:border-neutral-800">
                <div className="text-sm font-medium dark:text-white">
                  Upgrade to unlock full timeboxing features ✨
                </div>
                <div
                  className="flex items-center text-xs gap-1 text-purple-500 cursor-pointer hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-500"
                  onClick={() => {
                    dispatch(setUpgradeVisible(true));
                  }}
                >
                  Learn more <GoArrowRight className="w-3 h-3" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="calendar-settings-bar">
          <MiniToolbar type="expanded" />
        </div>
      </div>
    </a.div>
  );
}

function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#3c4043" : "white";
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Check if the taskOrder is the same, using lodash _.isEqual
  return true;
}

export default React.memo(MiniDayView, areEqual);

import React, { useEffect, useCallback, useRef } from "react";
import useState from "react-usestateref";
import Kanban from "../Kanban";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTask,
  updateTaskOrder,
  bulkUpdateTaskOrder,
} from "../../redux/tasksSlice";
import moment from "moment";
import { createPortal, unstable_batchedUpdates } from "react-dom";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

import _ from "lodash";

import { Mobile, Default } from "../../mediaUtils";

import DayView from "../Mobile/DayView";
import Braindump from "../Mobile/Braindump";

import CreateTask from "../Mobile/Task/CreateTask";
import Filters from "../Mobile/Filters";

import FocusMode from "../Mobile/Task/FocusMode";
import MiniDayView from "../Calendar/MiniDayView";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import { useDragLayer } from "react-dnd";
import CardPreview from "../Kanban/Card/CardPreview";
import SubtaskPreview from "../Kanban/Card/Components/Subtasks/SubtaskPreview";
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  DndContext,
  DragOverlay,
  getFirstCollision,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
  KeyboardSensor,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  AdjustmentsVerticalIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import WeekView from "../Calendar/WeekView";
import SendUpdatesModal from "../Calendar/SendUpdatesModal";
import {
  clearBulkSelect,
  refreshKabanCursor,
  selectNextColumn,
  selectPreviousColumn,
  updateActivelyDraggedTask,
  updateCurrentUser,
} from "../../redux/appSlice";
import Timer from "../Timer";
import { Popover, Switch } from "antd";
import Labels from "../Labels";
import { analytics, isMac } from "../../utils";
import MiniListDragPreview from "../Sidebar/MiniListDragPreview";
import { useMediaQuery } from "react-responsive";
import { useHotkeys } from "react-hotkeys-hook";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { TbTag, TbTrash } from "react-icons/tb";
import { HiMiniTag, HiMiniTrash } from "react-icons/hi2";
import BulkEditBar from "../Generics/BulkEditBar";
import { Route, Routes } from "react-router-dom";
import DailyPlanning from "../Rituals/DailyPlanning";
import DailyShutdown from "../Rituals/DailyShutdown";

class MyPointerSensor extends TouchSensor {
  static activators = [
    {
      eventName: "onTouchStart",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

function shouldHandleEvent(element) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "textarea",
    "select",
    "option",
  ];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}

export function restrictToBoundingRect(transform, rect, boundingRect) {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }

  return value;
}

export const restrictToSecondScrollableAncestor = ({
  draggingNodeRect,
  transform,
  scrollableAncestorRects,
}) => {
  const firstScrollableAncestorRect = scrollableAncestorRects[0];

  if (!draggingNodeRect || !firstScrollableAncestorRect) {
    return transform;
  }

  return restrictToBoundingRect(
    transform,
    draggingNodeRect,
    firstScrollableAncestorRect
  );
};

function DnDContainer() {
  const bulkSelectedTasks = useSelector(
    (state) => state.app.bulkSelectedTasks || []
  );

  const date = useSelector((state) => state.tasks.calendarDate);
  const tasks = useSelector((state) => state.tasks.data);

  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");

  const hide_complete = useSelector(
    (state) => state.app.currentUser?.hide_complete || false
  );

  const auto_fill_actual_time_disabled = useSelector(
    (state) => state.app.currentUser?.auto_fill_actual_time_disabled || false
  );

  const floating_timer_enabled = useSelector(
    (state) => state.app.currentUser?.floating_timer_enabled || false
  );

  const cardModalActiveItem = useSelector(
    (state) => state.tasks.data[state.app.cardModalActiveFor]
  );

  const taskOrder = useSelector((state) => state.tasks.order);

  const mobilePageActive = useSelector((state) => state.app.mobilePageActive);

  const [isDragging, setIsDragging] = useState(false);

  const [createTaskActive, setCreateTaskActive] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);

  const [taskOrderEditable, setTaskOrderEditable, taskOrderEditableRef] =
    useState({});

  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const [clonedItems, setClonedItems] = useState(null);

  const selectedList = useSelector(
    (state) => state.app.currentUser?.selectedList || "brain_dump"
  );

  const list_order = useSelector(
    (state) => state.app.currentUser?.list_order || []
  );

  // List keys
  const listKeys = useSelector((state) =>
    state.tasks.lists
      ? [...Object.keys(state.tasks.lists), "brain_dump"]
      : ["brain_dump"]
  );

  const lists = useSelector((state) => state.tasks.lists || {});

  function getParentContainer(collision, parentIds) {
    // If the collision id is in parentIds, return it
    if (parentIds.includes(collision.id)) {
      return collision.id;
    }

    return (
      collision.data.droppableContainer?.data?.current?.sortable?.containerId ||
      collision.id
    );
  }

  const [sortedLists, setSortedLists] = useState([]);

  useEffect(() => {
    if (list_order && list_order.length > 0) {
      // Any keys not found in list_order should be added to the end
      var list_order_copy = [...list_order];

      Object.keys(lists).forEach(function (key) {
        if (!list_order_copy.includes(key)) {
          list_order_copy.push(key);
        }
      });

      // If the list_order differs from sortedLists, update sortedLists (Both ar arrays)
      if (!_.isEqual(list_order_copy, sortedLists)) {
        setSortedLists(list_order_copy);
      }
    } else {
      // Let's get the keys sorted by list title (alphabetically)
      var sortedKeys = Object.keys(lists).sort(function (a, b) {
        return lists[a].title.localeCompare(lists[b].title);
      });

      setSortedLists(sortedKeys);
    }
  }, [JSON.stringify(list_order), lists]);

  const columnSelected = useSelector((state) => state.app.columnSelected);

  useHotkeys(
    "right",
    (e) => {
      e.preventDefault();

      dispatch(selectNextColumn());

      //  setNavigatedViaKeyboard(true);
    },
    {
      enabled: !["brain_dump", ...sortedLists].includes(columnSelected),
    },
    [columnSelected, sortedLists]
  );

  useHotkeys(
    "left",
    (e) => {
      e.preventDefault();

      dispatch(selectPreviousColumn());

      //  setNavigatedViaKeyboard(true);
    },
    {
      enabled: !["brain_dump", ...sortedLists].includes(columnSelected),
    },
    [columnSelected, sortedLists]
  );

  const [activelyReorderingSidebar, setActivelyReorderingSidebar] =
    useState(null);

  const collisionDetectionStrategy = useCallback(
    (args) => {
      // If we are sorting a list itself (the id is in listKeys), then we want to only allow sorting within other lists
      if (Object.keys(lists).includes(args.active.id)) {
        const intersections = closestCenter(args);

        // Filter out intersections that aren't part of the 'lists'
        const validIntersections = intersections.filter(
          (intersection) => lists[intersection.id]
        );

        var i = validIntersections.length > 0 ? [validIntersections[0]] : [];
        return i;
      }

      if (activeId && activeId in taskOrderEditable) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in taskOrderEditable
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
          pointerIntersections
          : rectIntersection(args);

      let overId = getFirstCollision(intersections, "id");
      const collisionIds = intersections.map((intersection) => intersection.id);

      // If there are 4 collision ids, get the first one whose parent is selectedList
      if (collisionIds.length >= 2) {
        const brainDumpCollision = intersections.find((intersection) =>
          listKeys.includes(
            getParentContainer(intersection, Object.keys(taskOrderEditable))
          )
        );

        if (brainDumpCollision) {
          overId = brainDumpCollision.id;
        }

        // If CALENDAR is one of the collisions, return it
        if (collisionIds.includes("CALENDAR")) {
          overId = "CALENDAR";
        }
      }

      if (overId != null) {
        if (overId === "CALENDAR") {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app

          return [{ id: "CALENDAR" }];
        }

        if (overId in taskOrderEditable) {
          const containerItems = taskOrderEditable[overId].order || [];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            var closestId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;

            // If after this closestId is null, return the overId
            if (closestId != null) {
              closestId = overId;
            }
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, taskOrderEditable, selectedList, lists]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [taskOrderEditable]);

  useEffect(() => {
    // If taskOrder is different from taskOrderEditable, update taskOrderEditable
    if (!_.isEqual(taskOrder, taskOrderEditable)) {
      setTaskOrderEditable(taskOrder);
    }
  }, [taskOrder]);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const sensors = useSensors(
    mouseSensor,
    useSensor(MyPointerSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 5,
      },
    })
  );

  const mobileSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const findContainer = (id) => {
    if (Object.keys(taskOrderEditable).includes(id)) {
      return id;
    }

    return Object.keys(taskOrderEditable).find((key) =>
      taskOrderEditable[key]?.order.includes(id)
    );
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers

      setTaskOrderEditable(clonedItems);
    }

    setIsDragging(false);
    setActiveId(null);
    setClonedItems(null);
    setActivelyReorderingSidebar(null);
  };

  function getNewCompletionStatus(newDate, currentCompletionStatus) {
    // If newDate === "brain_dump", return false

    if (newDate === "brain_dump" || listKeys.includes(newDate)) {
      return false;
    }

    // If newDate is before the start of today, return true
    if (
      moment(newDate, "YYYY-MM-DD").toDate() < moment().startOf("day").toDate()
    ) {
      return true;
    }

    return currentCompletionStatus;
  }

  useEffect(() => {
    if (activeId) {
      dispatch(updateActivelyDraggedTask(activeId));
    } else {
      dispatch(updateActivelyDraggedTask(null));
    }
  }, [activeId]);

  function saveOrder(originalTasks, newDate) {
    // Iterate through the tasks

    Object.values(originalTasks).forEach(function (originalTask) {
      analytics("Task moved", {
        task_id: originalTask.id,
        from: originalTask.date ? "date" : selectedList,
        to: newDate === selectedList ? selectedList : "date",
      });

      // Only update the task if the date is different
      const originalDateString = moment(originalTask.date).format("YYYY-MM-DD");

      if (originalDateString !== newDate) {
        var newCompletionStatus = getNewCompletionStatus(
          newDate,
          originalTask.complete
        );

        var newData = {
          date: listKeys.includes(newDate)
            ? null
            : moment(newDate, "YYYY-MM-DD").toDate(),
          complete: newCompletionStatus,
          listId: listKeys.includes(newDate) ? newDate : null,
        };

        // Let's auto fill actual_time
        if (newCompletionStatus && !originalTask.actual_time) {
          var newActualTime = originalTask.actual_time || 0;

          if (
            newCompletionStatus &&
            newCompletionStatus == true &&
            !auto_fill_actual_time_disabled
          ) {
            // If auto_fill_actual_time_disabled is false, then let's fill in the actual time if estimated_time is set and actual_time is not
            if (
              originalTask.estimated_time &&
              (!originalTask.actual_time || originalTask.actual_time == 0)
            ) {
              newActualTime = originalTask.estimated_time;
            }

            newData = {
              ...newData,
              actual_time: newActualTime,
            };
          }
        }

        dispatch(
          updateTask({
            taskId: originalTask.id,
            newData: newData,
            currentTask: originalTask,
            saveGhostOrder: false,
          })
        );
      }
    });

    var taskOrderChanges = [];

    Object.keys(taskOrderEditableRef.current).map(function (key, index) {
      var originalTaskOrder = taskOrder[key];
      var newTaskOrder = taskOrderEditableRef.current[key];

      // If the original task order was null, we need to save
      if (
        originalTaskOrder == null ||
        !_.isEqual(originalTaskOrder, newTaskOrder)
      ) {
        // Save the new task order

        taskOrderChanges.push({
          date: listKeys.includes(key)
            ? key
            : moment(key, "YYYY-MM-DD").toDate(),
          newOrder: newTaskOrder.order,
          previousOrder: originalTaskOrder?.order || [],
        });
      }
    });

    if (taskOrderChanges.length === 2) {
      // We are doing a multi update

      var bulkUpdate = {
        newOrder: [
          {
            date: taskOrderChanges[0].date,
            order: taskOrderChanges[0].newOrder,
          },
          {
            date: taskOrderChanges[1].date,
            order: taskOrderChanges[1].newOrder,
          },
        ],
        previousOrder: [
          {
            date: taskOrderChanges[0].date,
            order: taskOrderChanges[0].previousOrder,
          },
          {
            date: taskOrderChanges[1].date,
            order: taskOrderChanges[1].previousOrder,
          },
        ],
      };

      // Print it out as a string

      dispatch(bulkUpdateTaskOrder(bulkUpdate));
    } else {
      taskOrderChanges.forEach(function (taskOrderChange) {
        // Get date in format YYYY-MM-DD
        const dateString = listKeys.includes(taskOrderChange.date)
          ? taskOrderChange.date
          : moment(taskOrderChange.date).format("YYYY-MM-DD");

        dispatch(
          updateTaskOrder({
            date: dateString,
            order: taskOrderChange.newOrder,
            previousOrder: taskOrderChange.previousOrder,
          })
        );
      });
    }
  }

  const dropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.5",
        },
      },
    }),
  };

  return (
    <>
      <div id={"app-container"} className="app-container">
        <DndContext
          sensors={isMobile ? mobileSensors : sensors}
          collisionDetection={collisionDetectionStrategy}
          autoScroll={{
            threshold: {
              // Left and right 10% of the scroll container activate scrolling
              x: 0,
              // Top and bottom 25% of the scroll container activate scrolling
              y: 0.25,
            },
            // Accelerate slower than the default value (10)
            acceleration: 5,
            // Auto-scroll every 10ms instead of the default value of 5ms
            interval: 5,
            canScroll(element) {
              return true;
            },
          }}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          onDragStart={({ active }) => {
            // IF this is a list, set setActivelyReorderingSidebar to the id
            if (listKeys.includes(active.id)) {
              setActivelyReorderingSidebar(active.id);
            } else {
              setActiveId(active.id);

              setTimeout(() => {
                var cloned = _.cloneDeep(taskOrderEditable);

                // Go through cloned and remove all items where the id is in bulkSelectedTasks and the id is not the active id
                Object.keys(cloned).forEach(function (key) {
                  cloned[key].order = cloned[key].order.filter(
                    (item) =>
                      item === active.id || !bulkSelectedTasks.includes(item)
                  );
                });

                setTaskOrderEditable(cloned);

                setClonedItems(taskOrderEditable);
                setIsDragging(true);
              }, 0);
            }
          }}
          onDragOver={({ active, over }) => {
            const overId = over?.id;

            // If active is a list, we don't do anything
            if (listKeys.includes(active.id)) {
              return;
            }

            if (overId == null || overId === "CALENDAR") {
              return;
            }

            const overContainer = findContainer(overId);
            const activeContainer = findContainer(active.id);

            console.log("active", active.id);
            console.log("over", overId);

            console.log("Active container", activeContainer);
            console.log("Over container", overContainer);

            if (!overContainer || !activeContainer) {
              return;
            }

            if (activeContainer !== overContainer) {
              setTaskOrderEditable((taskOrders) => {
                const activeItems = taskOrders[activeContainer]?.order || [];
                const overItems = taskOrders[overContainer]?.order || [];
                const overIndex = overItems.indexOf(overId);
                const activeIndex = activeItems.indexOf(active.id);

                let newIndex;

                if (overId in taskOrders) {
                  newIndex = overItems.length + 1;
                } else {
                  const isBelowOverItem =
                    over &&
                    active.rect.current.translated &&
                    active.rect.current.translated.top >
                    over.rect.top + over.rect.height;

                  const modifier = isBelowOverItem ? 1 : 0;

                  newIndex =
                    overIndex >= 0
                      ? overIndex + modifier
                      : overItems.length + 1;
                }

                recentlyMovedToNewContainer.current = true;

                return {
                  ...taskOrders,
                  [activeContainer]: {
                    ...taskOrders[activeContainer],
                    order: taskOrders[activeContainer]?.order.filter(
                      (item) => item !== active.id
                    ),
                  },
                  [overContainer]: {
                    ...taskOrders[overContainer],
                    order: [
                      ...taskOrders[overContainer]?.order.slice(0, newIndex),
                      taskOrders[activeContainer]?.order[activeIndex],
                      ...taskOrders[overContainer]?.order.slice(
                        newIndex,
                        taskOrders[overContainer]?.order.length
                      ),
                    ],
                  },
                };
              });
            }
          }}
          onDragEnd={({ active, over }) => {
            // If this is a list, don't do anything for now, just return
            if (listKeys.includes(active.id)) {
              setActivelyReorderingSidebar(null);

              // Let's reorder sortedLists
              const activeIndex = sortedLists.indexOf(active.id);
              const overIndex = sortedLists.indexOf(over?.id);

              if (activeIndex !== overIndex) {
                // Get the new list order to save on currentUser
                const newListOrder = [...sortedLists];

                newListOrder.splice(activeIndex, 1);
                newListOrder.splice(overIndex, 0, active.id);

                dispatch(
                  updateCurrentUser({
                    newValues: {
                      list_order: newListOrder,
                    },
                    previousValues: {
                      list_order: sortedLists,
                    },
                  })
                );

                setSortedLists((sortedLists) => {
                  const newListOrder = [...sortedLists];

                  newListOrder.splice(activeIndex, 1);
                  newListOrder.splice(overIndex, 0, active.id);

                  return newListOrder;
                });
              }

              return;
            }

            setIsDragging(false);
            const overId = over?.id;

            const activeContainer = findContainer(active.id);

            if (!activeContainer) {
              setActiveId(null);
              return;
            }

            if (overId == null) {
              setActiveId(null);
              return;
            }

            if (overId === "CALENDAR") {
              if (clonedItems) {
                // Reset items to their original state in case items have been
                // Dragged across containers

                setTaskOrderEditable(taskOrder);
              }

              setActiveId(null);
              setClonedItems(null);
              setActiveId(null);

              return;
            }

            const overContainer = findContainer(overId);

            if (overContainer) {
              const activeIndex = taskOrderEditable[
                activeContainer
              ]?.order.indexOf(active.id);
              const overIndex =
                taskOrderEditable[overContainer]?.order.indexOf(overId);

              setTaskOrderEditable((taskOrders) => {
                const newOrder = [...taskOrders[overContainer]?.order];

                if (bulkSelectedTasks.length > 1) {
                  // Remove all bulk tasks from the current order
                  bulkSelectedTasks.forEach((taskId) => {
                    const index = newOrder.indexOf(taskId);
                    if (index > -1) {
                      newOrder.splice(index, 1);
                    }
                  });

                  // Insert the rest of the bulk tasks after the active task
                  if (overIndex === -1) {
                    // Add the bulk tasks to the end of the list
                    newOrder.push(...bulkSelectedTasks);
                  } else {
                    // Insert the bulk tasks after the active task
                    newOrder.splice(overIndex, 0, ...bulkSelectedTasks);
                  }
                } else {
                  // Lets just move the active task
                  newOrder.splice(activeIndex, 1);
                  if (overIndex === -1) {
                    // If overIndex is -1, move the active task to the end
                    newOrder.push(active.id);
                  } else {
                    // Otherwise, insert the active task at the overIndex
                    newOrder.splice(overIndex, 0, active.id);
                  }
                }

                return {
                  ...taskOrders,
                  [overContainer]: {
                    ...taskOrders[overContainer],
                    order: newOrder,
                  },
                };
              });
            }

            var tasksMoved = [];
            const taskMoved = tasks[active.id];

            // If there are bulkSelectedTasks, add them to the tasksMoved array
            if (bulkSelectedTasks.length > 0) {
              bulkSelectedTasks.forEach((taskId) => {
                const task = tasks[taskId];

                if (task) {
                  tasksMoved.push(task);
                }
              });
            } else {
              if (taskMoved) {
                tasksMoved.push(taskMoved);
              }
            }

            saveOrder(tasksMoved, activeContainer);

            setActiveId(null);
          }}
          onDragCancel={onDragCancel}
        >
          <SendUpdatesModal />
          <Default>
            <Routes>
              <Route
                path="*"
                element={
                  <>
                    {mode != "today" ? (
                      <>
                        {mode === "kanban" ? (
                          <Kanban
                            isDragging={isDragging}
                            taskOrder={taskOrderEditable}
                          />
                        ) : (
                          <WeekView />
                        )}

                        <Sidebar
                          taskOrder={taskOrderEditable}
                          sortedLists={sortedLists}
                          activelyReorderingSidebar={activelyReorderingSidebar}
                        />

                        {mode === "kanban" ? (
                          <MiniDayView />
                        ) : (
                          <MiniKanbanView
                            orderEditable={taskOrderEditable?.[date]?.order}
                            date={date}
                          />
                        )}
                      </>
                    ) : (
                      <div
                        className={`electron-draggable ${cardModalActiveItem ? "drag-disabled" : ""
                          }`}
                      >
                        <div className="today-focus-container">
                          <MiniKanbanView
                            orderEditable={taskOrderEditable?.[moment().format("YYYY-MM-DD")]?.order}
                            date={moment().format("YYYY-MM-DD")}
                            todayMode={true}
                          />
                          <MiniDayView todayMode={true} />
                          <div
                            onClick={() => {
                              dispatch(
                                updateCurrentUser({
                                  newValues: {
                                    mode: "kanban",
                                  },
                                  previousValues: {
                                    mode: mode,
                                  },
                                })
                              );

                              dispatch(
                                refreshKabanCursor(
                                  moment().format("YYYY-MM-DD")
                                )
                              );
                            }}
                            className="today-mode-exit-button"
                          >
                            Exit today-only mode
                          </div>
                          {(!isDesktopApp() || !floating_timer_enabled) && (
                            <Timer />
                          )}
                        </div>
                      </div>
                    )}

                    {createPortal(
                      <DragOverlay
                        adjustScale={false}
                        dropAnimation={dropAnimation}
                      >
                        {activelyReorderingSidebar ? (
                          <MiniListDragPreview
                            listId={activelyReorderingSidebar}
                          />
                        ) : activeId ? (
                          <CardPreview
                            taskId={activeId}
                            stackedCount={
                              bulkSelectedTasks ? bulkSelectedTasks.length : 0
                            }
                            draggable={true}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body
                    )}

                    {bulkSelectedTasks.length > 0 && <BulkEditBar />}
                  </>
                }
              />

              <Route
                path="daily-planning"
                element={
                  <>
                    <DailyPlanning
                      taskOrderEditable={taskOrderEditable}
                      activelyReorderingSidebar={activelyReorderingSidebar}
                      sortedLists={sortedLists}
                    />
                    {createPortal(
                      <DragOverlay
                        adjustScale={false}
                        dropAnimation={dropAnimation}
                      >
                        {activelyReorderingSidebar ? (
                          <MiniListDragPreview
                            listId={activelyReorderingSidebar}
                          />
                        ) : activeId ? (
                          <CardPreview
                            taskId={activeId}
                            stackedCount={
                              bulkSelectedTasks ? bulkSelectedTasks.length : 0
                            }
                            draggable={true}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body
                    )}

                    {bulkSelectedTasks.length > 0 && <BulkEditBar />}
                  </>
                }
              />

              <Route
                path="daily-shutdown"
                element={
                  <>
                    <DailyShutdown
                      taskOrderEditable={taskOrderEditable}
                      activelyReorderingSidebar={activelyReorderingSidebar}
                      sortedLists={sortedLists}
                    />
                    {createPortal(
                      <DragOverlay
                        adjustScale={false}
                        dropAnimation={dropAnimation}
                      >
                        {activelyReorderingSidebar ? (
                          <MiniListDragPreview
                            listId={activelyReorderingSidebar}
                          />
                        ) : activeId ? (
                          <CardPreview
                            taskId={activeId}
                            stackedCount={
                              bulkSelectedTasks ? bulkSelectedTasks.length : 0
                            }
                            draggable={true}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body
                    )}

                    {bulkSelectedTasks.length > 0 && <BulkEditBar />}
                  </>
                }
              />
            </Routes>
          </Default>

          <Mobile>
            <Routes>
              <Route
                path="*"
                element={
                  <>
                    {mobilePageActive === "timebox" && (
                      <div style={{ width: "100%" }}>
                        <MiniDayView />
                      </div>
                    )}

                    {mobilePageActive === "tasks" && (
                      <div style={{ width: "100%" }}>
                        <MiniKanbanView
                          orderEditable={taskOrderEditable?.[date]?.order}
                          date={date}
                          todayMode={false}
                        />
                      </div>
                    )}

                    {mobilePageActive === "braindump" && (
                      <div style={{ width: "100%" }}>
                        <Sidebar
                          mobile={true}
                          taskOrder={taskOrderEditable}
                          sortedLists={sortedLists}
                          activelyReorderingSidebar={activelyReorderingSidebar}
                        />
                      </div>
                    )}

                    {mobilePageActive !== "timebox" && (
                      <div className="floating-buttons-mobile">
                        <Popover
                          placement="bottom"
                          trigger="click"
                          zIndex={900}
                          content={
                            <div>
                              <Labels />

                              <div className="pt-2 pb-3 px-3 font-medium text-[13px] flex flex-row gap-2 items-center justify-between">
                                Show Complete{" "}
                                <Switch
                                  size="small"
                                  checked={!hide_complete}
                                  defaultChecked
                                  onClick={() => {
                                    // Update the current user's hide_complete value

                                    analytics("Hide complete toggled", {
                                      active: !hide_complete,
                                    });

                                    dispatch(
                                      updateCurrentUser({
                                        newValues: {
                                          hide_complete: !hide_complete,
                                        },
                                        previousValues: {
                                          hide_complete: hide_complete,
                                        },
                                      })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          }
                        >
                          <div
                            onClick={() => {
                              setFiltersActive(!filtersActive);
                            }}
                            className="floating-button-mobile filter"
                          >
                            {" "}
                            <AdjustmentsVerticalIcon className="floating-button-mobile-icon" />
                          </div>
                        </Popover>
                      </div>
                    )}
                  </>
                }
              />

              <Route
                path="daily-planning"
                element={
                  <>
                    <DailyPlanning
                      taskOrderEditable={taskOrderEditable}
                      activelyReorderingSidebar={activelyReorderingSidebar}
                      sortedLists={sortedLists}
                    />
                    {createPortal(
                      <DragOverlay
                        adjustScale={false}
                        dropAnimation={dropAnimation}
                      >
                        {activelyReorderingSidebar ? (
                          <MiniListDragPreview
                            listId={activelyReorderingSidebar}
                          />
                        ) : activeId ? (
                          <CardPreview
                            taskId={activeId}
                            stackedCount={
                              bulkSelectedTasks ? bulkSelectedTasks.length : 0
                            }
                            draggable={true}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body
                    )}

                    {bulkSelectedTasks.length > 0 && <BulkEditBar />}
                  </>
                }
              />
            </Routes>
          </Mobile>
        </DndContext>
      </div>
    </>
  );
}

export const CustomDragLayer = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
      >
        {itemType === "task" ? (
          <CardPreview taskId={item.id} />
        ) : (
          <SubtaskPreview taskId={item.taskId} subtaskId={item.id} />
        )}
      </div>
    </div>
  );
};

const layerStyles = {
  position: "fixed",
  userSelect: "none",
  zIndex: 100000,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;
  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export function snapToGrid(x, y) {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}

export default React.memo(DnDContainer, (prev, next) => {
  return true;
});

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { refreshKabanCursor, updateCurrentUser } from "../../redux/appSlice";
import moment from "moment";

export default function KanbanCalendarToggle() {
  const dispatch = useDispatch();

  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");

  useHotkeys(
    "tab",
    (e) => {
      e.preventDefault();

      //   analytics("View mode changed", {
      //     source: "Web",
      //     mode: mode === "calendar" ? "kanban" : "calendar",
      //   });

      dispatch(
        updateCurrentUser({
          newValues: {
            mode: mode === "calendar" ? "kanban" : "calendar",
          },
          previousValues: {
            mode: mode,
          },
        })
      );

      dispatch(refreshKabanCursor(moment().format("YYYY-MM-DD")));
    },
    {
      enabled: true,
    },
    [mode]
  );

  return <></>;
}

import MiniKanbanView from "../Kanban/MiniKanbanView";
import moment from "moment";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import Sidebar from "../Sidebar";
import PlannerContainer, { BackButton, NextButton } from "./PlannerContainer";

export default function TodayReview({
  taskOrderEditable,
  setStep,
  sortedLists,
  activelyReorderingSidebar,
  setSkipStep2Active,
  totalEstimatedTime,
  work_threshold,
}) {
  const todayDateKey = moment().format("YYYY-MM-DD");
  return (
    <PlannerContainer
      sidebarContent={
        <div
          className="flex flex-col gap-5 justify-start"
          style={{ WebkitAppRegion: "no-drag" }}
        >
          <div className="flex flex-col gap-2 items-start">
            <div className="inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline bg-violet-500/15 text-violet-700 group-data-[hover]:bg-violet-500/25 dark:text-violet-400 dark:group-data-[hover]:bg-violet-500/20">
              Step 2
            </div>
            <div className="text-xl font-semibold">
              What do you want to get done today?
            </div>
            <div className="text-sm text-neutral-500">
              Let's add some tasks you need to get done today. Feel free to pull
              tasks from any of your lists as well.
            </div>
          </div>
        </div>
      }
      mainContent={
        <div className="flex flex-row gap-4">
          <div
            className="flex flex-col gap-0 w-80 overflow-y-scroll"
            style={{
              WebkitAppRegion: "no-drag",
              height: "calc(100vh - 30px)",
            }}
          >
            <Sidebar
              taskOrder={taskOrderEditable}
              sortedLists={sortedLists}
              activelyReorderingSidebar={activelyReorderingSidebar}
              noContainer={true}
            />
          </div>
          <div className="flex flex-col gap-0">
            <MiniKanbanView
              key={todayDateKey}
              orderEditable={taskOrderEditable?.[todayDateKey]?.order}
              date={todayDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">Today</div>
                  <div className="text-sm text-neutral-500">Add some tasks</div>
                </div>
              }
              maxWidth={350}
            />
          </div>
        </div>
      }
      backButton={<BackButton onClick={() => setStep(0)} />}
      nextButton={
        <NextButton
          onClick={() => {
            if (totalEstimatedTime / 3600 > work_threshold) {
              setStep(2);
            } else {
              setSkipStep2Active(true);
              setStep(3);
            }
          }}
        />
      }
    />
  );
}
